import {Text} from '@dropbox/dig-components/dist/typography';
import {FunctionComponent, ReactNode} from 'react';

import styles from './SelectedItem.module.css';

export interface SelectedItemProps {
  accessory?: ReactNode;
  action?: ReactNode;
  title: string;
  children: ReactNode;
}

export const SelectedItem: FunctionComponent<SelectedItemProps> = ({
  accessory,
  action,
  children,
  title,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {accessory}
        <Text variant="paragraph" size="xlarge" tagName="span" isBold>
          {title}
        </Text>
        {action && <div className={styles.action}>{action}</div>}
      </div>
      <>{children}</>
    </div>
  );
};
