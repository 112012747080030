import {Employee} from 'client';
import {useCallback, useState} from 'react';

export function useSelectEmployees(
  initial?: Employee[]
): [
  Employee[],
  (employee: Employee) => void,
  (employees: Employee[]) => void,
  (employee: Employee) => void,
  () => void,
] {
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>(initial || []);

  const removeEmployee = (employee: Employee) => {
    setSelectedEmployees(selectedEmployees.filter((e) => e.ldap !== employee.ldap));
  };

  const selectEmployee = useCallback(
    (selected: Employee) => {
      const found = selectedEmployees.find((employee) => employee.ldap === selected.ldap);
      if (!found) {
        setSelectedEmployees([...selectedEmployees, selected]);
      }
    },
    [selectedEmployees, setSelectedEmployees]
  );

  const selectEmployees = useCallback(
    (selected: Employee[]) => {
      const newSelectedEmployees = selected.filter(
        (employee) => !selectedEmployees.find((e) => e.ldap === employee.ldap)
      );
      setSelectedEmployees([...selectedEmployees, ...newSelectedEmployees]);
    },
    [selectedEmployees, setSelectedEmployees]
  );

  const resetEmployees = () => setSelectedEmployees([]);

  return [selectedEmployees, selectEmployee, selectEmployees, removeEmployee, resetEmployees];
}
