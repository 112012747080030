import {Banner} from '@dropbox/dig-components/dist/banner';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {InfoLine} from '@dropbox/dig-icons/assets';
import DropboxOSIconDark from 'assets/DropboxOSIconDarkMobile.svg';
import DropboxOSIcon from 'assets/DropboxOSIconMobile.svg';
import {derivedThemeAtom} from 'atoms/layout';
import {Link} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import {useAtomValue} from 'jotai';
import {ReactNode} from 'react';

export const Path = ({path}: {path: string}) => (
  <Box as={Text} style={{fontFamily: 'monospace'}} size="small" color="Text Subtle" marginLeft="8">
    <Link
      to={`https://github.com/dropbox-internal/dropbox-pulse-web/blob/main/${path}`}
      monochromatic
    >
      {path}
    </Link>
  </Box>
);

export const Note = ({
  type,
  title,
  path,
  info,
  children,
}: {
  type: string;
  info: ReactNode;
  title?: string;
  path?: string;
  children?: ReactNode;
}) => {
  const isDarkMode = useAtomValue(derivedThemeAtom) === 'dark';
  return (
    <Box
      borderRadius="Large"
      padding="12"
      borderWidth="1"
      borderStyle="Solid"
      borderColor="transparent"
      boxShadow="Raised"
    >
      <Stack>
        <Box style={{top: 100, zIndex: 3}} position="sticky" backgroundColor="Background Base">
          <Box
            as={Title}
            id={type.toLowerCase().replace(/[/ ]/g, '-')}
            marginBottom="12"
            size="small"
            withAccessoryStart={
              path && <img src={isDarkMode ? DropboxOSIconDark : DropboxOSIcon} height="26px" />
            }
            withAccessoryEnd={path && <Path path={path} />}
          >
            {type}
          </Box>
        </Box>

        <Box
          as={Banner}
          padding="8"
          type="attention"
          marginBottom="12"
          withLeftIcon={
            <UIIcon className={atoms({color: 'Highlight Blue - Bolder'})} src={InfoLine} />
          }
        >
          <Box as={Text} color="Identity On Blue" style={{maxWidth: 600}}>
            {info}
          </Box>
        </Box>

        {children && (
          <Box
            backgroundColor="Background Subtle"
            borderRadius="Large"
            padding="12"
            overflowX="auto"
          >
            {children}
          </Box>
        )}
        {title && (
          <Text size="small">
            <Box
              as="pre"
              padding="8"
              paddingLeft="12"
              overflowX="auto"
              marginTop="8"
              borderRadius="Large"
              color="Text Subtle"
              backgroundColor="Background Subtle"
              style={{fontFamily: 'monospace'}}
            >
              {title}
            </Box>
          </Text>
        )}
      </Stack>
    </Box>
  );
};
