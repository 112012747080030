import {Banner} from '@dropbox/dig-components/banner';
import {UIIcon} from '@dropbox/dig-icons';
import {InfoLine} from '@dropbox/dig-icons/assets';
import {pulseUserAtom} from 'atoms/auth';
import {Employee, GoalUser} from 'client';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {Trans} from 'react-i18next';

const isGoalUser = (user: GoalUser | Employee): user is GoalUser =>
  !!(user as GoalUser).display_name;

export const DelegationBanner = ({
  delegatingAs,
  padding,
}: {
  delegatingAs: GoalUser | Employee;
  padding?: boolean;
}) => {
  const curUser = useAtomValue(pulseUserAtom);
  const isDelegation = delegatingAs.email !== curUser?.email;

  return !isDelegation ? null : (
    <>
      <Banner type="attention" withLeftIcon={<UIIcon src={InfoLine} />}>
        <Banner.Message>
          <Trans
            i18nKey="delegation_banner"
            t={t}
            values={{
              employee: isGoalUser(delegatingAs) ? delegatingAs.display_name : delegatingAs.name,
            }}
            components={{b: <b />}}
          />
        </Banner.Message>
      </Banner>
      {padding && <br />}
    </>
  );
};
