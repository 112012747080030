import {TrafficRoadBlockedSpot} from '@dropbox/dig-illustrations';
import * as Sentry from '@sentry/react';
import {ApiError} from 'client';
import {NonIdealState} from 'components/shared/NonIdealState';
import {ROUTE_PATHS} from 'constant';
import {reportAndLogError} from 'helpers/logging';
import {t} from 'i18next';
import React from 'react';
import {useCallback} from 'react';
import {isRouteErrorResponse, useRouteError} from 'react-router-dom';
import {getLoginUrl, isUserDeactivatedApiError} from 'utilities';

const ErrorPage = () => {
  const error = useRouteError();
  const redirectToLogin = useCallback(() => {
    const loginUrl = getLoginUrl();
    const currentPath = window.location.pathname.startsWith(ROUTE_PATHS.LOGOUT)
      ? ''
      : window.location.pathname.substring(1) + window.location.search + window.location.hash;

    window.location.href = loginUrl + currentPath;
  }, []);

  const redirectToWaitlist = useCallback(() => {
    window.location.href = ROUTE_PATHS.WAITLIST;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApiError = useCallback(
    (apiError: ApiError) => {
      if (apiError.status === 403) {
        redirectToLogin();
      }
      // Handle other API error statuses if needed
    },
    [redirectToLogin]
  );

  React.useEffect(() => {
    if (
      typeof error === 'object' &&
      error !== null &&
      'status' in error &&
      (error.status === 403 || error.status === 404)
    ) {
      return;
    }
    Sentry.captureException(error);
  }, [error]);

  if (isRouteErrorResponse(error)) {
    if (error.status === 403) {
      redirectToLogin();
    } else if (error.status === 404) {
      window.location.href = '/404';
    } else {
      reportAndLogError(error);
    }
    return <></>;
  }

  if (error instanceof ApiError && error.status === 403) {
    if (isUserDeactivatedApiError(error)) {
      redirectToWaitlist();
    } else {
      handleApiError(error);
    }
    return <></>;
  }

  return (
    <Sentry.ErrorBoundary>
      <NonIdealState
        image={<TrafficRoadBlockedSpot />}
        title={t('error_page_title')}
        description={t('we_are_on_it')}
      />
    </Sentry.ErrorBoundary>
  );
};

export {ErrorPage};
