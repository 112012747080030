/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_add_card_api_v1_cards_post } from '../models/Body_add_card_api_v1_cards_post';
import type { Body_update_card_image_api_v1_cards__card_id__image_post } from '../models/Body_update_card_image_api_v1_cards__card_id__image_post';
import type { Card } from '../models/Card';
import type { CardLabel } from '../models/CardLabel';
import type { CardLabelCreate } from '../models/CardLabelCreate';
import type { CardLabelUpdate } from '../models/CardLabelUpdate';
import type { CardUpdate } from '../models/CardUpdate';
import type { ManageCardLabels } from '../models/ManageCardLabels';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CardService {

    /**
     * Read Cards
     * @returns Card Successful Response
     * @throws ApiError
     */
    public static readCardsApiV1CardsGet(): CancelablePromise<Array<Card>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/cards',
        });
    }

    /**
     * Add Card
     * @param formData
     * @returns Card Successful Response
     * @throws ApiError
     */
    public static addCardApiV1CardsPost(
        formData: Body_add_card_api_v1_cards_post,
    ): CancelablePromise<Card> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/cards',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Employees Endpoint
     * @param key
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getImageApiV1CardsImagesKeyGet(
        key: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/cards/images/{key}',
            path: {
                'key': key,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Label
     * @param labelId
     * @param requestBody
     * @returns CardLabel Successful Response
     * @throws ApiError
     */
    public static updateLabelApiV1CardsLabelsLabelIdPatch(
        labelId: number,
        requestBody: CardLabelUpdate,
    ): CancelablePromise<CardLabel> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/cards/labels/{label_id}',
            path: {
                'label_id': labelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Labels
     * @returns CardLabel Successful Response
     * @throws ApiError
     */
    public static getLabelsApiV1CardsLabelsGet(): CancelablePromise<Array<CardLabel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/cards/labels',
        });
    }

    /**
     * Create Label
     * @param requestBody
     * @returns CardLabel Successful Response
     * @throws ApiError
     */
    public static createLabelApiV1CardsLabelsPost(
        requestBody: CardLabelCreate,
    ): CancelablePromise<CardLabel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/cards/labels',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Card Image
     * @param cardId
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateCardImageApiV1CardsCardIdImagePost(
        cardId: number,
        formData: Body_update_card_image_api_v1_cards__card_id__image_post,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/cards/{card_id}/image',
            path: {
                'card_id': cardId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Card
     * @param cardId
     * @param requestBody
     * @returns Card Successful Response
     * @throws ApiError
     */
    public static updateCardApiV1CardsCardIdPost(
        cardId: number,
        requestBody: CardUpdate,
    ): CancelablePromise<Card> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/cards/{card_id}',
            path: {
                'card_id': cardId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Labels To Card
     * @param cardId
     * @param requestBody
     * @returns Card Successful Response
     * @throws ApiError
     */
    public static addLabelsToCardApiV1CardsCardIdLabelsPost(
        cardId: number,
        requestBody: ManageCardLabels,
    ): CancelablePromise<Card> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/cards/{card_id}/labels',
            path: {
                'card_id': cardId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Labels To Card
     * @param cardId
     * @param requestBody
     * @returns Card Successful Response
     * @throws ApiError
     */
    public static removeLabelsToCardApiV1CardsCardIdLabelsDelete(
        cardId: number,
        requestBody: ManageCardLabels,
    ): CancelablePromise<Card> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/cards/{card_id}/labels',
            path: {
                'card_id': cardId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
