import {Button} from '@dropbox/dig-components/dist/buttons';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {HideLine} from '@dropbox/dig-icons/assets';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {
  PulseGrouping,
  PulseQuestion,
  PulseQuestionResponseCount,
  PulseResponse,
  PulseResponseWithEmployee,
  PulseSummary,
  QuestionMetric,
} from 'client';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useState} from 'react';

import {MetricResult} from './MetricResult';
import {PulseInsight} from './PulseInsight';
import {PulseResponseBox} from './PulseResponseBox';
import {PulseResponseModal} from './PulseResponseModal';

export const PulseQuestionBox = ({
  index,
  pillar,
  question,
  responseCounts,
  metrics,
  response,
  grouping,
}: {
  index: number;
  pillar: string;
  question: PulseQuestion;
  responseCounts: PulseQuestionResponseCount[];
  metrics: QuestionMetric[];
  response?: PulseResponse;
  grouping?: PulseGrouping;
}) => {
  const [modalInsight, setModalInsight] = useState<PulseSummary | boolean>();
  const count = responseCounts.find(({question_id}) => question_id === question.id)?.count;
  const {employee} = useAtomValue(loggedInEmployeeAtom);
  const responseWithEmployee = {...response, employee} as PulseResponseWithEmployee;

  return (
    <Box as={Stack} borderRadius="Medium" borderColor="Border Subtle" borderStyle="Solid">
      <Split gap="12" alignY="top" paddingY="24" paddingX="20">
        {!response && (
          <Split.Item width="hug">
            <Box
              as="div"
              borderRadius="Circular"
              color="Text Subtle"
              marginTop="4"
              style={{height: 24, width: 24}}
              alignItems="center"
              justifyContent="center"
              display="flex"
              backgroundColor="Primary Surface - State 1"
            >
              <Text isBold size="small">
                {index + 1}
              </Text>
            </Box>
          </Split.Item>
        )}
        <Split.Item width="fill">
          <Stack gap="12">
            <Stack gap="4">
              <Text size="large" isBold tagName="div">
                {question.prompt}
              </Text>
              {!response && (
                <Text color="faint" tagName="div" size="small">
                  <Split alignY="center" gap="4">
                    <Split.Item>
                      <Split alignY="center" gap="2">
                        {question.type === 'esat' && (
                          <Split.Item>
                            <Box
                              as={UIIcon}
                              src={HideLine}
                              position="relative"
                              size="small"
                              style={{top: 3}}
                            />
                          </Split.Item>
                        )}
                        <Split.Item>
                          {question.type === 'esat'
                            ? 'Anonymous'
                            : question.type === 'text-input'
                            ? 'Open text'
                            : 'Rating'}
                        </Split.Item>
                      </Split>
                    </Split.Item>
                    <Split.Item>•</Split.Item>
                    <Split.Item>
                      {question.type !== 'text-input' ? (
                        t('based_on_response', {count})
                      ) : count ? (
                        <Button
                          variant="transparent"
                          size="small"
                          onClick={() => setModalInsight(true)}
                        >
                          <Text size="small" color="faint">
                            {t('response', {count})}
                          </Text>
                        </Button>
                      ) : (
                        <Text size="small" color="faint">
                          {t('response', {count})}
                        </Text>
                      )}
                    </Split.Item>
                  </Split>
                </Text>
              )}
            </Stack>
            {response && responseWithEmployee?.employee?.name && (
              <PulseResponseBox response={responseWithEmployee} borderBottom={false} />
            )}
            {metrics
              .find(({question_id}) => question_id === question.id)
              ?.insights?.map((insight) => (
                <Box
                  key={insight.id}
                  borderRadius="Medium"
                  paddingY="4"
                  {...withShade({
                    direction: 'up',
                    style: {marginLeft: -4},
                    // bgColors: {base: 'Primary Surface', active: 'Primary Surface Dark'},
                  })}
                >
                  <PulseInsight onClick={() => setModalInsight(insight)} insight={insight} />
                </Box>
              ))}
          </Stack>
        </Split.Item>
        {question.type === 'esat' && (
          <Split.Item>
            <Box marginLeft="36">
              <MetricResult value={metrics?.find((metric) => metric.type === 'esat')?.metric} />
            </Box>
          </Split.Item>
        )}
      </Split>
      {question.type === 'text-input' && modalInsight && (
        <PulseResponseModal
          pillar={pillar}
          question={question}
          insights={
            metrics.find(
              (metric) => metric.question_id === question.id && metric.type === 'text-input'
            )?.insights
          }
          selectedInsight={modalInsight === true ? undefined : modalInsight}
          grouping={grouping}
          onClose={() => setModalInsight(false)}
        />
      )}
    </Box>
  );
};
