import {Spinner} from '@dropbox/dig-components/dist/progress_indicators';
import {Truncate} from '@dropbox/dig-components/dist/truncate';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Stack} from '@dropbox/dig-foundations';
import {LaptopMini} from '@dropbox/dig-illustrations';
import {useQuery} from '@tanstack/react-query';
import {AuditLog, TeamService} from 'client';
import {EmptyState} from 'components/DSYS/EmptyState';
import {UpdatePost} from 'components/DSYS/UpdatePost';
import {t} from 'i18next';
import {Trans} from 'react-i18next';
import {getService} from 'utilities';

const groupByTimeAndType = (logs: AuditLog[]) => {
  const grouped: {[key: string]: {[type: string]: AuditLog[]}} = {};

  logs.forEach((log) => {
    const date = new Date(log.date);

    const minutes = Math.floor(date.getMinutes() / 1);
    date.setMinutes(minutes, 0, 0);
    const intervalKey = date.toISOString();

    if (!grouped[intervalKey]) {
      grouped[intervalKey] = {};
    }

    if (!grouped[intervalKey][log.type]) {
      grouped[intervalKey][log.type] = [];
    }

    grouped[intervalKey][log.type].push(log);
  });

  return grouped;
};

const ChangeLogLine = ({type, value}: {type: 'add' | 'remove'; value: string}) => (
  <Box
    backgroundColor={type === 'add' ? 'Success Surface' : 'Alert Surface'}
    paddingX="8"
    borderRadius="XSmall"
  >
    <Box display="flex" alignItems="flex-start">
      <Text
        isBold
        color="faint"
        size="small"
        style={{width: 4}}
        className={atoms({marginRight: '8'})}
      >
        {type === 'add' ? '+' : '-'}
      </Text>
      <Text color="faint" size="small" style={{width: 540}}>
        <Truncate tooltipControlProps={{placement: 'top'}}>{value}</Truncate>
      </Text>
    </Box>
  </Box>
);

export const TeamAuditLog = ({slug}: {slug: string}) => {
  const {data, isLoading} = useQuery({
    queryKey: ['team', 'logs', slug],
    queryFn: () => getService(TeamService).getTeamLogsApiV1TeamsSlugLogsGet(slug),
  });

  const groupedLogs = groupByTimeAndType(data ?? []);

  if (isLoading) {
    return (
      <Box width="100%" display="flex" justifyContent="center" marginTop="68">
        <Spinner monochromatic />
      </Box>
    );
  }

  return (
    <>
      {!data?.length ? (
        <EmptyState
          title={t('audit_log_empty_title')}
          body={t('audit_log_empty_body')}
          image={<LaptopMini width="64" altText={''} />}
          hideBorder
        />
      ) : (
        Object.entries(groupedLogs)
          .reverse()
          .map(([timestamp, changesByType]) => {
            const changeTypes = Object.keys(changesByType);
            const firstChange = changesByType[changeTypes[0]][0];

            const creationType = changesByType['team'];

            return (
              <div key={timestamp}>
                <UpdatePost ldap={firstChange.employee.ldap} update={{created_at: timestamp}}>
                  <Stack gap="8">
                    {!creationType &&
                      Object.entries(changesByType).map(([type, changes]) => (
                        <Stack key={`${timestamp}-${type}`}>
                          {changes.map(({before, after, date}) => (
                            <Stack key={`${type}-${before}-${after}—${date}`}>
                              {after && <ChangeLogLine type="add" value={after} />}
                              {before && <ChangeLogLine type="remove" value={before} />}
                            </Stack>
                          ))}
                        </Stack>
                      ))}
                    {creationType && (
                      <Text>
                        <Trans
                          i18nKey="audit_log_new_team"
                          t={t}
                          components={{b: <Text isBold />}}
                          values={{data: creationType[0].after}}
                        />
                      </Text>
                    )}
                  </Stack>
                </UpdatePost>
              </div>
            );
          })
      )}
    </>
  );
};
