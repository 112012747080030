import {Button} from '@dropbox/dig-components/dist/buttons';
import {FormLabel, FormRow} from '@dropbox/dig-components/dist/form_row';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {TextArea} from '@dropbox/dig-components/dist/text_fields';
import {Toggletip} from '@dropbox/dig-components/tooltips';
import {UIIcon} from '@dropbox/dig-icons';
import {EditLine} from '@dropbox/dig-icons/assets';
import {snackbarAtom} from 'atoms/snackbar';
import {GoalUser, KeyResult} from 'client';
import {Title} from 'components/DSYS/Title';
import {useKeyResultService} from 'components/goals/hooks';
import {DelegationBanner} from 'components/shared/DelegationBanner';
import {reportAndLogError} from 'helpers/logging';
import {t} from 'i18next';
import {useSetAtom} from 'jotai';
import {FormEvent, useCallback, useEffect, useState} from 'react';

import styles from './KeyResultEditModal.module.css';

type StateType = {open: boolean; action: string};

export const KeyResultEditModal = ({
  state,
  setState,
  keyResult,
  user,
  timeframe,
}: {
  state: StateType;
  setState: React.Dispatch<React.SetStateAction<StateType>>;
  keyResult: KeyResult;
  user: GoalUser;
  timeframe: string;
}) => {
  const [title, setTitle] = useState(keyResult ? keyResult.title : '');
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const {updateKeyResult} = useKeyResultService({timeframe});

  const abort = () => {
    setState({open: false, action: ''});
  };
  const confirm = useCallback(async () => {
    try {
      if (keyResult) {
        await updateKeyResult({
          keyResultId: keyResult.id,
          userId: user.user_id,
          data: {
            ...keyResult,
            title: title,
            contributors: keyResult.contributors.map(({ldap}) => ldap),
            updated_at: new Date().toISOString(),
          },
        });
        setState({open: false, action: ''});
        setSnackbarMessage({text: t('saved')});
      }
    } catch (error) {
      reportAndLogError(error, 'Could not save key result');
      setSnackbarMessage({text: t('couldnt_save')});
    }
  }, [keyResult, setSnackbarMessage, setState, title, updateKeyResult, user]);

  const handleTitleChange = (e: FormEvent<HTMLTextAreaElement>) => {
    setTitle(e.currentTarget.value);
  };

  useEffect(() => {
    const handleShortcut = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
        const canSave = !(keyResult && title === keyResult.title) && title !== '';

        if (canSave) {
          confirm();
        }
      }
    };
    document.addEventListener('keydown', handleShortcut);
    return () => {
      document.removeEventListener('keydown', handleShortcut);
    };
  }, [keyResult, title, confirm]);

  useEffect(() => {
    const handleShortcut = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === 'k') {
        setState({open: false, action: ''});
      }
    };

    document.addEventListener('keydown', handleShortcut);
    return () => {
      document.removeEventListener('keydown', handleShortcut);
    };
  }, [setState]);

  return (
    <Modal
      open={state.open}
      onRequestClose={abort}
      isCentered
      withCloseButton="Close"
      className={styles.statusModalContainer}
    >
      <Modal.Header>
        <Modal.Title className={styles.header}>
          <UIIcon src={EditLine} />
          <Title>{keyResult ? keyResult.title : t('add_key_result')}</Title>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormRow>
          <DelegationBanner delegatingAs={user} />
        </FormRow>
        <FormRow>
          <FormLabel
            htmlFor="keyResultEdit"
            subtext={<Toggletip title={t('key_result_tooltip')} />}
          >
            {t('key_result_alone_title')}
          </FormLabel>
          {/* <FormHelperText id="keyResultEdit">{t('key_results')}</FormHelperText> */}
          <TextArea
            placeholder={t('start_writing_placeholder').toString()}
            id="keyResultEdit"
            onChange={(e) => handleTitleChange(e)}
            value={title}
            onFocus={(e) => {
              const val = e.target.value;
              const target = e.target;
              target.value = '';
              target.value = val;
            }}
            autoFocus
            spellCheck="true"
          />
        </FormRow>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="opacity" onClick={abort}>
          {t('cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={confirm}
          disabled={(keyResult && title === keyResult.title) || title === ''}
        >
          {t('save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
