import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const queryClient = new QueryClient({
  defaultOptions: {
    // Apply to all queries, override if needed
    queries: {
      // By default if a request fails, react-query will retry it 3 times.
      retry: false,
      // If you want to refetch data, add it to the queryKey array below
      staleTime: Infinity,
    },
  },
});

export const QueryClientWrapper = ({children}: {children: React.ReactNode}) => {
  // Invalidate queries when navigating to a new page
  const location = useLocation();
  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['notifications'],
    });
  }, [location.pathname]);

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
