import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Table} from '@dropbox/dig-components/dist/table';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {ReactNode} from 'react';

export const LabelGroupCell = ({
  width,
  isBold,
  text,
  subText,
  withLeftAccessory,
}: {
  width?: number;
  isBold?: boolean;
  text: ReactNode;
  subText?: ReactNode;
  withLeftAccessory?: ReactNode;
}) => {
  return (
    <Table.Cell>
      <Box padding="12">
        <LabelGroup
          withLeftAccessory={withLeftAccessory}
          withText={
            <Text isBold={isBold}>
              <div style={{display: 'flex', width, whiteSpace: 'nowrap'}}>
                <span style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{text}</span>
              </div>
            </Text>
          }
          withSubtext={
            <Text color="faint" size="small">
              <div style={{display: 'flex', width, whiteSpace: 'nowrap'}}>{subText}</div>
            </Text>
          }
        />
      </Box>
    </Table.Cell>
  );
};

export const LabelGroupSubtleCell = ({
  text,
  subText,
  withLeftAccessory,
}: {
  text: ReactNode;
  subText?: ReactNode;
  withLeftAccessory?: ReactNode;
}) => {
  return (
    <Table.Cell>
      <LabelGroup
        withLeftAccessory={withLeftAccessory}
        withText={<Text size="small">{text}</Text>}
        withSubtext={
          <Text color="faint" size="xsmall">
            {subText}
          </Text>
        }
      />
    </Table.Cell>
  );
};
