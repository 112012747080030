import {Button} from '@dropbox/dig-components/dist/buttons';
import {TextArea} from '@dropbox/dig-components/dist/text_fields';
import {Box} from '@dropbox/dig-foundations';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {pulseUserAtom} from 'atoms/auth';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {gratitudeRecipient} from 'atoms/gratitude';
import {Card, Employee} from 'client';
import {LabelText} from 'components/gratitiude/LabelText';
import {DelegateSelectMenu} from 'components/shared/DelegateSelectMenu';
import {PeopleSearchMenu} from 'components/shared/PeopleSearchMenu';
import {useSelectEmployees} from 'hooks/useSelectEmployee';
import {t} from 'i18next';
import {useAtom, useAtomValue} from 'jotai';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {getGratitudesService} from 'utilities';

import styles from './Form.module.css';

export const CardModalForm = ({
  card,
  onSubmit,
  open,
}: {
  card?: Card;
  onSubmit: () => void;
  open: boolean;
}) => {
  const user = useAtomValue(pulseUserAtom);
  const [message, setMessage] = useState('');
  const [recipient, setBrowseRecpient] = useAtom(gratitudeRecipient);
  const gratitudesService = getGratitudesService();

  const [selectedEmployees, selectEmployee, selectEmployees, removeEmployee, resetEmployees] =
    useSelectEmployees(recipient ? [recipient] : undefined);

  const canSubmit = selectedEmployees.length > 0 && message !== '';
  const [sender, setSender] = useState<string | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (open) {
      setMessage('');
    }
  }, [open, setMessage]);

  const handleSendClick = useCallback(() => {
    if (user && card && canSubmit && !isSubmitting) {
      setIsSubmitting(true);
      gratitudesService
        .createGratitudeApiV1GratitudesCreatePost(
          {
            gratitude_create: {
              card_id: card.id,
              message,
            },
            recipients: selectedEmployees.map((employee) => employee.user_id),
          },
          sender
        )
        .then(() => {
          analyticsLogger().logEvent('GRATITUDE_SENT', {cardId: card.id});
          setBrowseRecpient(undefined);
          resetEmployees();
          onSubmit();
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  }, [
    canSubmit,
    card,
    gratitudesService,
    message,
    onSubmit,
    resetEmployees,
    selectedEmployees,
    sender,
    setBrowseRecpient,
    user,
    isSubmitting,
  ]);

  useEffect(() => {
    const handleShortcut = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
        handleSendClick();
      }
    };

    document.addEventListener('keydown', handleShortcut);

    return () => {
      document.removeEventListener('keydown', handleShortcut);
    };
  }, [handleSendClick]);

  return (
    <div className={styles.form}>
      <TextArea
        maxLength={600}
        value={message}
        aria-label="Gratitude message"
        placeholder={t('placeholder_gratitude')}
        isTransparent
        onChange={(e) => setMessage(e.currentTarget.value)}
        autoFocus
        resizable="auto"
      />
      <Box
        className={styles.toAndFrom}
        borderTop="Solid"
        borderColor="Border Subtle"
        borderWidth="1"
      >
        <Box flexGrow={1} className={styles.toAndFromContainer}>
          <AddRecipients
            sender={sender}
            setSender={(id) => setSender(id)}
            selectedEmployees={selectedEmployees}
            selectEmployee={selectEmployee}
            selectEmployees={selectEmployees}
            removeEmployee={removeEmployee}
            handleSendClick={handleSendClick}
            canSubmit={canSubmit}
            isSubmitting={isSubmitting}
          />
        </Box>
      </Box>
    </div>
  );
};

interface AddRecipientsProps {
  selectedEmployees: Employee[];
  selectEmployee: (employee: Employee) => void;
  selectEmployees: (employees: Employee[]) => void;
  removeEmployee: (employee: Employee) => void;
  handleSendClick: () => void;
  canSubmit: boolean;
  sender?: string;
  setSender: (senderId?: string) => void;
  isSubmitting: boolean;
}

const AddRecipients: FunctionComponent<AddRecipientsProps> = ({
  selectedEmployees,
  selectEmployee,
  selectEmployees,
  removeEmployee,
  handleSendClick,
  canSubmit,
  sender,
  setSender,
  isSubmitting,
}) => {
  const {delegatedBy, employee} = useAtomValue(loggedInEmployeeAtom);

  return (
    <div className={styles.addRecipients}>
      {delegatedBy && delegatedBy?.length > 0 && employee && (
        <div>
          <LabelText tagName="label">{t('from')}:</LabelText>
          <div>
            <DelegateSelectMenu delegateId={sender} setDelegateId={setSender} />
          </div>
        </div>
      )}
      <div>
        <LabelText tagName="label">{t('TO')}:</LabelText>
        <Box style={{marginBottom: -16}}>
          <PeopleSearchMenu
            selectedEmployees={selectedEmployees}
            onRemoveEmployee={removeEmployee}
            onSelectEmployee={selectEmployee}
            onSelectEmployees={selectEmployees}
            placeholderText={selectedEmployees.length > 0 ? '' : t('find_a_gratitude')}
          />
        </Box>
      </div>
      <div>
        <Button
          variant="primary"
          className={styles.button}
          onClick={handleSendClick}
          disabled={!canSubmit || isSubmitting}
        >
          {isSubmitting ? t('sending') : t('send')}
        </Button>
      </div>
    </div>
  );
};
