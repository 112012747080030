import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {MoreHorizontalLine, WinLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {useQuery} from '@tanstack/react-query';
import {AboutMeSection} from 'components/profile/AboutMe/AboutMeSection';
import {t} from 'i18next';
import {useMemo, useState} from 'react';
import {getEmployeeService} from 'utilities';

import {BadgeItem} from './BadgeItem';
import {BadgeModal} from './BadgeModal';

export const ProfileBadges = ({ldap}: {ldap: string}) => {
  const {data: badges} = useQuery({
    queryKey: ['badges', ldap],
    queryFn: () => getEmployeeService().getBadgesApiV1PeopleUserIdReceivedGet(ldap),
  });

  const [allBadgesOpen, setAllBadgesOpen] = useState(false);
  const collected = useMemo(() => {
    if (!badges) return [];
    return badges.map((badge) => badge.id);
  }, [badges]);

  return (
    <AboutMeSection title={t('badges')}>
      <Box display="flex" alignItems="center" flexWrap="wrap" style={{marginLeft: -8}}>
        {badges?.length ? (
          badges.map((badge) => <BadgeItem badge={badge} key={badge.id} collected={collected} />)
        ) : (
          <Box
            borderColor="Border Subtle"
            borderWidth="1"
            borderStyle="Solid"
            paddingLeft="12"
            paddingRight="16"
            borderRadius="Circular"
            color="Text Subtle"
            display="flex"
            alignItems="center"
            style={{marginLeft: 8, height: 32}}
          >
            <UIIcon src={WinLine} />
            <Text color="subtle" className={atoms({marginLeft: '8'})}>
              {t('about_me_empty_badges')}
            </Text>
          </Box>
        )}
        <IconButton
          variant="outline"
          onClick={() => setAllBadgesOpen(true)}
          className={atoms({marginLeft: '8'})}
        >
          <UIIcon src={MoreHorizontalLine} />
        </IconButton>
      </Box>
      {allBadgesOpen && (
        <BadgeModal
          collected={collected}
          badge={null}
          onRequestClose={() => setAllBadgesOpen(false)}
          closeModal={() => setAllBadgesOpen(false)}
        />
      )}
    </AboutMeSection>
  );
};
