import {Notifications} from 'client';
import {queryClient} from 'views/QueryClientWrapper';

export const findNotification = ({type, data}: {type: string; data?: string}) => {
  const notifications = queryClient.getQueryData<Notifications>(['notifications']);

  return (notifications?.action_items ?? [])
    .concat(notifications?.notifications ?? [])
    .find((n) => n.type === type && (data ? n.data.data === data : true));
};

export const getActivePulse = () => {
  const notifications = queryClient.getQueryData<Notifications>(['notifications']);

  return (notifications?.action_items ?? []).find((n) => n.type === 'pulse_active');
};
