import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {TargetLine} from '@dropbox/dig-icons/assets';
import {NotificationCard} from 'components/notifications/NotificationCard';
import {useNotificationContext} from 'components/notifications/utils/useNotificationContext';
import {TimeAgo} from 'components/shared/TimeAgo';
import {useEmployee} from 'hooks/useEmployee';
import {t} from 'i18next';
import {Trans} from 'react-i18next';
export const ContributorJoinNotification = () => {
  const {
    notification: {archived, data, updated_at},
  } = useNotificationContext();
  const {content, to, data: jsonData} = data;
  const {ldap} = JSON.parse(jsonData);
  const {employee} = useEmployee({ldap});

  return (
    <NotificationCard
      accessory={<Box as={UIIcon} src={TargetLine} color="Text Subtle" />}
      header={
        <Trans
          i18nKey={content}
          t={t}
          values={{data: employee?.name}}
          components={{b: <Text isBold />}}
        />
      }
      subtext={<TimeAgo timestamp={updated_at} />}
      to={to}
      archived={archived}
      truncate
    />
  );
};
