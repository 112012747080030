import {totalNotificationsAtom} from 'atoms/notifications';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useEffect} from 'react';

export const useDocumentTitle = (title: string) => {
  const notificationCount: number = useAtomValue(totalNotificationsAtom);
  useEffect(() => {
    document.title =
      notificationCount > 0
        ? `(${notificationCount}) ${title} - ${t('dropbox_os')}`
        : title + ' - ' + t('dropbox_os');
  }, [notificationCount, title]);
};
