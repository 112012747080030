import {GlobalSearch} from './GlobalSearch';
import styles from './Search.module.css';

export const SearchInput = () => {
  return (
    <div className={styles.searchContainer} style={{padding: '18px', borderRadius: '10px'}}>
      <div className={styles.searchInputContainer}>
        <GlobalSearch />
      </div>
    </div>
  );
};
