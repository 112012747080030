import {Box, BoxProps} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {SVGProps} from 'react';
import {ReactNode} from 'react';

export const KeyResultLine = (props: SVGProps<SVGSVGElement>): React.ReactElement => {
  const iconFill = 'currentColor';
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.32086 4.37939L3.29175 4.37944V9.52563H5.11278L5.11278 7.54094L11.1922 13.5964V17.2484H13.0383V14.126H16.0834V12.2799H12.4864L6.41493 6.2322L8.32086 6.23218V4.37939Z"
        fill={iconFill}
      />
    </svg>
  );
};

export const SubtleKeyResultIcon = () => {
  return (
    <Box as="div" style={{marginLeft: '-5px', width: '24px', height: '24px'}} padding="2">
      <Box as={UIIcon} src={KeyResultLine} style={{width: 20, height: 21}} color="Text Subtle" />
    </Box>
  );
};

export const ObjectiveLine = (props: SVGProps<SVGSVGElement>): React.ReactElement => {
  const iconFill = 'currentColor';
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
      <path
        d="M5.51182 14.6869C4.66104 13.8361 4.11768 12.4366 4.11768 10.1989C4.11768 7.96115 4.66104 6.56157 5.51182 5.71079C6.3626 4.86002 7.76217 4.31666 9.99988 4.31666C11.0623 4.31666 11.9359 4.43915 12.6514 4.65888L13.7081 3.59955C12.6945 3.17151 11.4596 2.95923 9.99988 2.95923C7.56255 2.95923 5.75186 3.55106 4.55197 4.75095C3.35208 5.95083 2.76025 7.76153 2.76025 10.1989C2.76025 12.6362 3.35208 14.4469 4.55197 15.6468C5.75186 16.8466 7.56255 17.4385 9.99988 17.4385C12.4372 17.4385 14.2479 16.8466 15.4478 15.6468C16.6477 14.4469 17.2395 12.6362 17.2395 10.1989C17.2395 8.73137 17.025 7.49105 16.5923 6.47444L15.5357 7.53371C15.758 8.25178 15.8821 9.12964 15.8821 10.1989C15.8821 12.4366 15.3387 13.8361 14.4879 14.6869C13.6372 15.5377 12.2376 16.081 9.99988 16.081C7.76217 16.081 6.3626 15.5377 5.51182 14.6869Z"
        fill={iconFill}
      />
      <path
        d="M6.84668 10.1989C6.84668 11.4643 7.15395 12.1962 7.57824 12.6205C8.00254 13.0448 8.73446 13.352 9.99988 13.352C11.2653 13.352 11.9972 13.0448 12.4215 12.6205C12.8458 12.1962 13.1531 11.4643 13.1531 10.1989C13.1531 10.1054 13.1514 10.0148 13.1481 9.92711L14.3619 8.71032C14.462 9.16101 14.5105 9.65799 14.5105 10.1989C14.5105 11.6639 14.1548 12.8069 13.3814 13.5803C12.608 14.3537 11.4649 14.7095 9.99988 14.7095C8.53484 14.7095 7.3918 14.3537 6.6184 13.5803C5.84499 12.8069 5.48925 11.6639 5.48925 10.1989C5.48925 8.73381 5.84499 7.59077 6.6184 6.81737C7.3918 6.04397 8.53484 5.68823 9.99988 5.68823C10.5368 5.68823 11.0305 5.73601 11.4786 5.83462L10.2658 7.05039C10.1799 7.04726 10.0913 7.04566 9.99988 7.04566C8.73446 7.04566 8.00254 7.35293 7.57824 7.77722C7.15395 8.20151 6.84668 8.93343 6.84668 10.1989Z"
        fill={iconFill}
      />
      <circle cx="9.9546" cy="10.1533" r="1.76466" fill={iconFill} />
      <path
        d="M13.6708 16.7995L14.3237 18.5932L15.7325 18.4948L14.8245 15.9909L13.6708 16.7995Z"
        fill={iconFill}
      />
      <path
        d="M7.19446 16.7995L6.54151 18.5932L5.13276 18.4948L6.04069 15.9909L7.19446 16.7995Z"
        fill={iconFill}
      />
      <path
        d="M5.51182 5.71055C6.3626 4.85977 7.76217 4.31641 9.99988 4.31641C12.2376 4.31641 13.6372 4.85977 14.4879 5.71055C15.3387 6.56133 15.8821 7.96091 15.8821 10.1986C15.8821 11.2611 15.7596 12.1346 15.5399 12.8501L16.5992 13.9069C17.0272 12.8933 17.2395 11.6583 17.2395 10.1986C17.2395 7.76128 16.6477 5.95059 15.4478 4.7507C14.2479 3.55081 12.4372 2.95898 9.99988 2.95898C7.56255 2.95898 5.75186 3.55081 4.55197 4.7507C3.35208 5.95059 2.76025 7.76128 2.76025 10.1986C2.76025 12.6359 3.35208 14.4466 4.55197 15.6465C5.75186 16.8464 7.56255 17.4382 9.99988 17.4382C11.4674 17.4382 12.7077 17.2237 13.7243 16.7911L12.665 15.7344C11.9469 15.9568 11.0691 16.0808 9.99988 16.0808C7.76217 16.0808 6.3626 15.5374 5.51182 14.6867C4.66104 13.8359 4.11768 12.4363 4.11768 10.1986C4.11768 7.9609 4.66104 6.56133 5.51182 5.71055Z"
        fill={iconFill}
      />
      <path
        d="M9.99988 7.04541C8.73446 7.04541 8.00254 7.35268 7.57824 7.77697C7.15395 8.20127 6.84668 8.93319 6.84668 10.1986C6.84668 11.464 7.15395 12.196 7.57824 12.6202C8.00254 13.0445 8.73446 13.3518 9.99988 13.3518C10.0933 13.3518 10.1839 13.3501 10.2716 13.3469L11.4884 14.5607C11.0377 14.6608 10.5407 14.7092 9.99988 14.7092C8.53484 14.7092 7.3918 14.3535 6.6184 13.5801C5.84499 12.8067 5.48925 11.6637 5.48925 10.1986C5.48925 8.73357 5.84499 7.59053 6.6184 6.81713C7.3918 6.04372 8.53484 5.68798 9.99988 5.68798C11.4649 5.68798 12.608 6.04372 13.3814 6.81713C14.1548 7.59053 14.5105 8.73357 14.5105 10.1986C14.5105 10.7355 14.4627 11.2292 14.3641 11.6773L13.1483 10.4645C13.1515 10.3786 13.1531 10.29 13.1531 10.1986C13.1531 8.93319 12.8458 8.20127 12.4215 7.77697C11.9972 7.35268 11.2653 7.04541 9.99988 7.04541Z"
        fill={iconFill}
      />
    </svg>
  );
};

export const SubtleObjectiveIcon = (): React.ReactElement => {
  return (
    <Box as="div" style={{marginLeft: '-5px', width: '24px', height: '24px'}} padding="2">
      <Box as={UIIcon} src={ObjectiveLine} style={{width: 20, height: 21}} color="Text Subtle" />
    </Box>
  );
};

export const ComponentWithLeftBullet = ({
  children,
  marginLeft,
  marginRight,
}: {
  children: ReactNode;
  marginLeft?: BoxProps<'div'>['marginLeft'];
  marginRight?: BoxProps<'div'>['marginRight'];
}) => {
  return (
    <Box display="inline-flex" alignItems="center">
      <Box
        color="Text Subtle"
        marginLeft={marginLeft ?? '6'}
        marginRight={marginRight ?? '6'}
        fontSize="Text XSmall"
      >
        •
      </Box>
      {children}
    </Box>
  );
};
