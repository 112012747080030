import {Button} from '@dropbox/dig-components/dist/buttons';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Text} from '@dropbox/dig-components/dist/typography';
import {FilePaperLarge} from '@dropbox/dig-content-icons';
import {Box, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {OpenLine} from '@dropbox/dig-icons/assets';
import {strategiesAtom, StrategyItem} from 'atoms/strategies';
import {Accordion, AccordionIconButton} from 'components/DSYS/Accordion';
import {Layout} from 'components/DSYS/Layout';
import {Link} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import {useStrategyTimeframeOptions} from 'constant';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useState} from 'react';

import styles from './Strategies.module.css';

export const Strategies = () => {
  const strategies = useAtomValue(strategiesAtom);
  const strategyTimeframeOptions = useStrategyTimeframeOptions();

  const [strategyTimeframe, setStrategyTimeframe] = useState(strategyTimeframeOptions[0]);
  const selectedStrategy = strategies[strategyTimeframe];

  useDocumentTitle(t('strategy_plural'));

  const renderIconButton = (isActive: boolean, toggle: () => void) => (
    <AccordionIconButton
      isActive={isActive}
      toggle={(e) => {
        e.stopPropagation();
        toggle();
      }}
    />
  );

  const renderStrategy = (
    strategy: StrategyItem,
    expanded: boolean,
    _active: boolean,
    toggle: () => void,
    level = 0
  ) => (
    <>
      <Box
        as="div"
        borderRadius="Medium"
        paddingX="16"
        marginTop="8"
        cursor="pointer"
        onClick={() => window.open(strategy.link, '_blank')}
        {...withShade({
          state: expanded ? 'animated' : undefined,
          className: styles.strategyContainer,
        })}
      >
        <div className={styles.strategyColumn}>
          {strategy.subStrategies && renderIconButton(expanded, toggle)}
        </div>
        <div className={styles.strategyColumn}>
          <FilePaperLarge className={styles.strategyIcon} />
        </div>
        <div className={styles.strategyColumn}>
          <div className={styles.strategyTextColumn}>
            <Text size="large" variant="paragraph" tagName="p" isBold>
              {strategy.name}
            </Text>
            <Text size="small" variant="label" tagName="span">
              {strategy.owners.map((owner, index) => (
                <>
                  <Link
                    to={'/people/' + owner.username}
                    hasNoUnderline
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    {owner.name}
                  </Link>
                  {index < strategy.owners.length - 1 ? ', ' : ''}
                </>
              ))}
            </Text>
          </div>
          <div className={styles.strategyLinkColumn}>
            <Text size="small" variant="label" tagName="span">
              {t('view_doc')} <UIIcon src={OpenLine} size="small"></UIIcon>
            </Text>
          </div>
        </div>
      </Box>
      {expanded && strategy.subStrategies && (
        <Accordion items={strategy.subStrategies} renderItem={renderStrategy} level={level + 1} />
      )}
    </>
  );

  return (
    <Layout.Container>
      <Title
        size={24}
        withAccessoryEnd={
          <Menu.Wrapper onSelection={(value) => setStrategyTimeframe(value)}>
            {({getContentProps, getTriggerProps}) => (
              <>
                <Button variant="borderless" withDropdownIcon {...getTriggerProps()}>
                  {strategyTimeframe}
                </Button>
                <Menu.Content {...getContentProps()}>
                  <Menu.Segment>
                    {strategyTimeframeOptions.map((option) => (
                      <Menu.ActionItem key={option} value={option}>
                        {option}
                      </Menu.ActionItem>
                    ))}
                  </Menu.Segment>
                </Menu.Content>
              </>
            )}
          </Menu.Wrapper>
        }
      >
        {t('strategy_plural')}
      </Title>
      <Accordion items={selectedStrategy} renderItem={renderStrategy} tabSize={0} />
    </Layout.Container>
  );
};
