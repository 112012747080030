/* eslint-disable no-param-reassign */
import {Spinner} from '@dropbox/dig-components/dist/progress_indicators';
import {Slider} from '@dropbox/dig-components/dist/slider';
import {Box, Stack} from '@dropbox/dig-foundations';
import React, {useEffect, useRef, useState} from 'react';

export const DraggableZoomableImage = ({
  isLoading,
  image,
  crop,
  onMove,
}: {
  isLoading: boolean;
  image: File;
  crop: {x: number; y: number; scale: number};
  onMove: (canvas: HTMLCanvasElement, crop: {x: number; y: number; scale: number}) => void;
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffsetX, setDragOffsetX] = useState(0);
  const [dragOffsetY, setDragOffsetY] = useState(0);
  const [zoomConstraints, setZoomConstraints] = useState({min: 0.01, max: 2});
  const imgRef = useRef<HTMLImageElement | null>(null);

  // Load the image and set up the canvas
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas && image) {
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = URL.createObjectURL(image);

      img.onload = () => {
        imgRef.current = img;
        if (ctx) {
          const canvasWidth = canvas.width;
          const canvasHeight = canvas.height;
          const scaleX = canvasWidth / img.width;
          const scaleY = canvasHeight / img.height;
          const minZoom = Math.max(0.01, Math.max(scaleX, scaleY));
          const maxZoom = Math.min(
            2,
            25 * Math.min(canvasWidth / img.width, canvasHeight / img.height)
          );

          const scale = minZoom;

          // Center the image
          const x = (canvasWidth - img.width * scale) / 2;
          const y = (canvasHeight - img.height * scale) / 2;

          const initialCrop = {x, y, scale};

          // Draw the image with the initial crop
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.save();
          ctx.translate(initialCrop.x, initialCrop.y);
          ctx.scale(initialCrop.scale, initialCrop.scale);
          ctx.imageSmoothingEnabled = true;
          ctx.imageSmoothingQuality = 'high';
          ctx.drawImage(img, 0, 0);
          ctx.restore();

          onMove(canvas, initialCrop);
          setZoomConstraints({min: minZoom, max: maxZoom});
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  // Function to draw the image on the canvas
  const drawImage = (ctx: CanvasRenderingContext2D, img: HTMLImageElement) => {
    ctx.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);

    ctx.save();

    // Apply translation and scaling
    ctx.translate(crop.x, crop.y);
    ctx.scale(crop.scale, crop.scale);

    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';

    // Draw the image
    ctx.drawImage(img, 0, 0);

    ctx.restore();
  };

  // Handle mouse down event to start dragging
  const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const mousePos = getMousePos(canvas, e);
      setIsDragging(true);
      setDragOffsetX(mousePos.x - crop.x);
      setDragOffsetY(mousePos.y - crop.y);
    }
  };

  // Handle mouse up event to stop dragging
  const handleMouseUp = () => {
    setIsDragging(false);
    onMove(canvasRef.current!, crop);
  };
  // Helper to clamp values within a range
  const clamp = (value: number, min: number, max: number) => Math.max(min, Math.min(max, value));

  // Calculate new crop position with boundary checking
  const calculateBoundaries = (
    canvas: HTMLCanvasElement,
    img: HTMLImageElement,
    clampedCrop: {x: number; y: number; scale: number}
  ) => {
    const scaledWidth = img.width * clampedCrop.scale;
    const scaledHeight = img.height * clampedCrop.scale;

    // Calculate minimum and maximum allowed positions to keep the image within bounds
    const minX = Math.min(0, canvas.width - scaledWidth);
    const maxX = 0;
    const minY = Math.min(0, canvas.height - scaledHeight);
    const maxY = 0;

    return {
      x: clamp(clampedCrop.x, minX, maxX),
      y: clamp(clampedCrop.y, minY, maxY),
      scale: clampedCrop.scale,
    };
  };

  // Handle mouse move event to drag the image
  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (isDragging) {
      const canvas = canvasRef.current;
      const ctx = canvas?.getContext('2d');
      const mousePos = getMousePos(canvas!, e);
      const img = imgRef.current;

      const newX = mousePos.x - dragOffsetX;
      const newY = mousePos.y - dragOffsetY;

      // Clamp the new position within the canvas bounds
      const updatedCrop = calculateBoundaries(canvas!, img!, {
        x: newX,
        y: newY,
        scale: crop.scale,
      });

      onMove(canvas!, updatedCrop);
      if (ctx && img) {
        drawImage(ctx, img);
      }
    }
  };

  // Handle zoom change with boundary checking
  const handleZoomChange = (newScale: number) => {
    const canvas = canvasRef.current;
    if (!canvas || !imgRef.current) return;

    const img = imgRef.current;

    const precision = 1000;
    const adjustedScale = Math.round(newScale * precision) / precision;

    const minStep = 0.01;
    const clampedScale = Math.max(adjustedScale, zoomConstraints.min + minStep);

    const canvasCenterX = canvas.width / 2;
    const canvasCenterY = canvas.height / 2;

    // Calculate the relative position of the image center to the canvas center
    const relCenterX = (canvasCenterX - crop.x) / crop.scale;
    const relCenterY = (canvasCenterY - crop.y) / crop.scale;

    // Calculate new image position based on the new (clamped) scale
    const newX = canvasCenterX - relCenterX * clampedScale;
    const newY = canvasCenterY - relCenterY * clampedScale;

    // Apply boundaries after zoom
    const updatedCrop = calculateBoundaries(canvas, img, {
      x: newX,
      y: newY,
      scale: clampedScale,
    });

    onMove(canvas, updatedCrop);

    const ctx = canvas.getContext('2d');
    if (ctx) {
      drawImage(ctx, img);
    }
  };

  const getMousePos = (canvas: HTMLCanvasElement, evt: React.MouseEvent<HTMLCanvasElement>) => {
    const rect = canvas.getBoundingClientRect();
    return {
      x: evt.clientX - rect.left,
      y: evt.clientY - rect.top,
    };
  };

  return (
    <Stack display="flex" align="center">
      <div style={{position: 'relative', width: 211, height: 211}}>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Spinner />
          </Box>
        ) : (
          <canvas
            ref={canvasRef}
            width={211}
            height={211}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            style={{borderRadius: 6, position: 'absolute', zIndex: 1}}
          />
        )}
        <div
          style={{
            borderRadius: 6,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            mask: 'radial-gradient(circle, transparent 70.5%, black 26%)',
            pointerEvents: 'none',
            zIndex: 2,
          }}
        />
      </div>
      <Box paddingY="16" paddingX="8">
        <Slider
          aria-labelledby=""
          min={zoomConstraints.min}
          max={zoomConstraints.max}
          step={0.01}
          value={Math.max(zoomConstraints.min, Math.min(zoomConstraints.max, crop.scale))}
          onChange={handleZoomChange}
          labelFn={() => null}
        />
      </Box>
    </Stack>
  );
};
