import {Button} from '@dropbox/dig-components/dist/buttons';
import {Table} from '@dropbox/dig-components/dist/table';
import {Split} from '@dropbox/dig-foundations';
import {ClockLine} from '@dropbox/dig-icons/assets';
import {useMutation, useQuery} from '@tanstack/react-query';
import {Pulse, PulseService, PulseWithGrouping} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {Title} from 'components/shared/ManagePage/Title';
import {t} from 'i18next';
import {useState} from 'react';
import {getService} from 'utilities';

import {NewPulseModal} from './pulse/NewPulseModal';
import {PulseRow} from './pulse/PulseRow';

export const PulseScheduler = () => {
  const {data} = useQuery({
    queryKey: ['pulse', 'scheduled'],
    queryFn: getService(PulseService).scheduledApiV1PulseScheduledGet,
  });
  const {mutateAsync: startPulse} = useMutation({
    mutationFn: getService(PulseService).sendBugbashPulseApiV1PulseBugbashSendGet,
  });
  const {mutateAsync: finalizePulse} = useMutation({
    mutationFn: getService(PulseService).finalizeBugbashPulseApiV1PulseBugbashFinalizeGet,
  });

  const [notificationModal, addNotificationModal] = useState(false);

  return (
    <Layout.Container>
      <Split gap="16">
        <Split.Item width="fill">
          <Title
            icon={ClockLine}
            text={t('manage_pulses')}
            addButtonOnClick={() => {
              addNotificationModal(true);
            }}
          />
        </Split.Item>
        <Split.Item>
          <Button variant="opacity" onClick={() => startPulse(true)}>
            {t('send')} to CompanyOS team
          </Button>
        </Split.Item>
        <Split.Item>
          <Button variant="opacity" onClick={() => startPulse(false)}>
            {t('send')} to me
          </Button>
        </Split.Item>
        <Split.Item>
          <Button variant="opacity" onClick={() => finalizePulse()}>
            {t('finalize')}
          </Button>
        </Split.Item>
      </Split>
      <Table>
        <Table.Header>
          <Table.Row>
            {['eSAT question', 'Company question', 'Org', 'Start', 'End'].map((col, idx) => (
              <Table.HeaderCell width={idx === 1 ? '400px' : ''} key={idx}>
                {col}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {(data as unknown as (PulseWithGrouping & {pulse: Pulse})[])?.map((row) => (
            <PulseRow key={row.pulse.id} {...row} />
          ))}
        </Table.Body>
      </Table>
      {notificationModal && <NewPulseModal onClose={() => addNotificationModal(false)} />}
    </Layout.Container>
  );
};
