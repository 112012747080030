import {Button} from '@dropbox/dig-components/dist/buttons';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/typography';
import {Box} from '@dropbox/dig-foundations';
import {
  CelebrateLine,
  ClockLine,
  DropboxLine,
  LocationLine,
  PersonLine,
  SendLine,
} from '@dropbox/dig-icons/assets';
import {pulseUserAtom} from 'atoms/auth';
import {Employee, EmployeeFull} from 'client';
import {CircleIcon} from 'components/DSYS/CircleIcon';
import {Link} from 'components/DSYS/Link';
import {format, parseISO} from 'date-fns';
import {formatInTimeZone} from 'date-fns-tz';
import {reportAndLogError} from 'helpers/logging';
import {useMobile} from 'hooks/isMobile';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {Fragment, FunctionComponent, useEffect, useState} from 'react';
import {VacationLine} from 'views/home/VacationLine';

import {EmployeeLocations, formatLocation, toMetro} from './EmployeeLocations';
import styles from './ProfileDetails.module.css';

export const ProfileDetails = ({
  employee,
  admin,
  onClick,
}: {
  employee: EmployeeFull;
  admin?: Employee | null;
  onClick?: (action: string) => void;
}) => {
  const [showMoreDetailsMobile, setShowMoreDetailsMobile] = useState(false);
  const [showNearbyEmployees, setShowNearbyEmployees] = useState(false);
  const isMobile = useMobile();
  const pulseUser = useAtomValue(pulseUserAtom);

  const formatBirthday = (dateUnformatted: string): string => {
    const [month, day] = dateUnformatted.split('/');

    return Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'long',
      timeZone: 'UTC',
    }).format(new Date(`2020-${month}-${day}`)); // 2020 is a leap year
  };
  const [showMoreContact, setShowMoreContact] = useState(false);

  useEffect(() => {
    setShowMoreContact(false);
  }, [employee]);

  const startDate = employee.upcoming_pto
    ? formatLeaveDate(employee.upcoming_pto.split(' ')[0])
    : null;
  const endDate = employee.upcoming_pto
    ? formatLeaveDate(employee.upcoming_pto.split(' ')[1])
    : null;

  const calculateTimeDifference = (timezone: string) => {
    try {
      const diff = getTimezoneOffset(timezone);

      if (diff === 0) {
        return t('same_yours');
      } else {
        return t('current_time_info', {
          countHours: Math.abs(diff),
          hours: Math.abs(diff) === 1 ? t('hour') : t('hours'),
          aheadOrBehind: diff > 0 ? t('ahead') : t('behind'),
        });
      }
    } catch (e) {
      reportAndLogError(e, 'Error calculating time difference');
      return '';
    }
  };

  const calculateTenure = (tenure: number): string => {
    if (tenure < 1) {
      return t('less_month');
    }

    const years = Math.floor(tenure / 12);
    const months = tenure % 12;

    let result = '';

    if (years > 0) {
      result += t('year', {count: years});
      if (months > 0) {
        result += ' ';
      }
    }

    if (months > 0) {
      result += t('month', {count: months});
    }

    return result;
  };

  const isBoomerang = (tenure: number, hireDate: string): boolean => {
    const current = new Date();
    const [month, year] = hireDate.split('/').map(Number);

    const hired = new Date(year, month - 1, 1);

    let years = current.getFullYear() - hired.getFullYear();
    let months = current.getMonth() - hired.getMonth();
    if (months < 0) {
      years--;
      months += 12;
    }

    const monthsSinceHireDate = years * 12 + months;

    return Math.abs(tenure - monthsSinceHireDate) > 1;
  };

  const isValidEmail = (email: string): boolean => {
    // contractor email is not assigned and hide it from UI.
    return email !== 'donotuse@dropbox.com';
  };

  const details = [
    <Item
      key="contact"
      icon={SendLine}
      primary={t('profile_contact')}
      secondary={
        showMoreContact ? (
          <>
            <Link
              key={employee.ldap}
              monochromatic
              hasNoUnderline
              to={`https://dropbox.slack.com/team/${employee.ldap}`}
              target="_blank"
              onClick={() => onClick?.('slack')}
            >
              {'@' + employee.ldap}
            </Link>
            {employee.email && isValidEmail(employee.email) && (
              <Link
                monochromatic
                hasNoUnderline
                to={`mailto:${employee.email}`}
                key={employee.email}
                onClick={() => onClick?.('email')}
              >
                {employee.email}
              </Link>
            )}
            {employee.phone_number && (
              <Link
                monochromatic
                hasNoUnderline
                to={`tel:${employee.phone_number}`}
                key={employee.phone_number}
                onClick={() => onClick?.('phone')}
              >
                {employee.phone_number}
              </Link>
            )}
          </>
        ) : (
          <Link
            key={employee.ldap}
            hasNoUnderline
            to={`https://dropbox.slack.com/team/${employee.ldap}`}
            target="_blank"
            onClick={() => onClick?.('slack')}
          >
            {'@' + employee.ldap}
          </Link>
        )
      }
      supporting={
        employee.phone_number ? (
          <span onClick={() => setShowMoreContact(!showMoreContact)} className={styles.showMore}>
            {showMoreContact ? t('show_less') : t('show_more')}
          </span>
        ) : (
          employee.email &&
          isValidEmail(employee.email) && (
            <Link
              monochromatic
              hasNoUnderline
              to={`mailto:${employee.email}`}
              key={employee.email}
              onClick={() => onClick?.('email')}
            >
              {employee.email}
            </Link>
          )
        )
      }
    />,
  ];
  if (employee.time_zone && getLocalTime(employee.time_zone)) {
    details.push(
      <Item
        icon={ClockLine}
        primary={t('profile_current_time')}
        secondary={getLocalTime(employee.time_zone)}
        supporting={
          employee.email !== pulseUser?.email ? calculateTimeDifference(employee.time_zone) : ''
        }
      />
    );
  }
  if (employee.upcoming_pto && isMoreThanToday(employee.upcoming_pto)) {
    details.push(
      <Item
        icon={VacationLine}
        primary={t('profile_pto')}
        secondary={startDate === endDate ? startDate : `${startDate} - ${endDate}`}
      />
    );
  }

  if (employee.location) {
    details.push(
      <Item
        icon={LocationLine}
        primary={t('location')}
        secondary={formatLocation(employee.city) || toMetro(employee.location)}
        supporting={
          <Link
            hasNoUnderline
            monochromatic
            to="#"
            onClick={() => {
              setShowNearbyEmployees(true);
              onClick?.('location');
            }}
          >
            {t('view_dropboxers')}
          </Link>
        }
      />
    );
  }

  if (employee?.hire_date && employee?.tenure != null) {
    details.push(
      <Item
        icon={DropboxLine}
        primary={t('at_company')}
        secondary={calculateTenure(employee.tenure)}
        supporting={
          (isBoomerang(employee.tenure, employee.hire_date) ? t('first_started') : t('started')) +
          ' ' +
          formatHireDate(employee.hire_date)
        }
        withTooltip={
          employee.tenure_percentile
            ? t('tenure_percentile', {
                percentile: calculateTenurePercentile(employee.tenure_percentile),
              })
            : undefined
        }
      />
    );
  }
  if (admin) {
    details.push(
      <Item
        icon={PersonLine}
        primary={isMobile ? t('admin_mobile') : t('admin')}
        secondary={
          <Link
            style={{color: 'inherit'}}
            hasNoUnderline
            to={'/people/' + admin.ldap}
            onClick={() => onClick?.('admin')}
          >
            {admin.name}
          </Link>
        }
        supporting={
          <Link
            style={{color: 'inherit'}}
            hasNoUnderline
            to={'https://dropbox.slack.com/team/' + admin.ldap}
            onClick={() => onClick?.('slack')}
          >
            @{admin.ldap}
          </Link>
        }
      />
    );
  }
  if (employee.birthday) {
    details.push(
      <Item
        icon={CelebrateLine}
        primary={t('birthday')}
        secondary={formatBirthday(employee.birthday)}
      />
    );
  }

  const visibleDetails = showMoreDetailsMobile || !isMobile ? details : details.slice(0, 2);

  return (
    <>
      {showNearbyEmployees && (employee.city || employee.location) && (
        <EmployeeLocations
          city={employee.city}
          location={employee.location}
          handleClose={() => setShowNearbyEmployees(false)}
        />
      )}
      <div className={styles.container}>
        {visibleDetails.map((detail, index) => (
          <Fragment key={index}>{detail}</Fragment>
        ))}
        {details.length > 2 && !showMoreDetailsMobile && isMobile && (
          <div className={styles.moreContainer}>
            <Button
              variant="opacity"
              size="small"
              withDropdownIcon
              className={styles.moreButton}
              onClick={() => setShowMoreDetailsMobile(true)}
            >
              {t('more_title')}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

interface ItemProps {
  icon: React.ComponentType<React.SVGAttributes<SVGElement>>;
  primary: string;
  secondary?: string | React.ReactNode;
  supporting?: string | React.ReactNode;
  withTooltip?: string;
}

const Item: FunctionComponent<ItemProps> = ({
  icon,
  primary,
  secondary,
  supporting,
  withTooltip,
}) => {
  return (
    <Box className={styles.item} padding="12">
      <CircleIcon src={icon} />

      <div className={styles.details}>
        <Text variant="paragraph" isBold tagName="div">
          {primary}
        </Text>
        {secondary && withTooltip && (
          <Tooltip title={withTooltip} placement="bottom">
            <Text variant="paragraph" tagName="div">
              {secondary}
            </Text>
          </Tooltip>
        )}
        {secondary && !withTooltip && (
          <Text variant="paragraph" tagName="div">
            {secondary}
          </Text>
        )}
        {supporting && (
          <Text variant="paragraph" size="small" color="faint">
            {supporting}
          </Text>
        )}
      </div>
    </Box>
  );
};

const formatHireDate = (dateUnformatted: string): string => {
  let [month, year] = dateUnformatted.split('/');
  if (!dateUnformatted.includes('/')) {
    [year, month] = dateUnformatted.split('-');
  }

  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    year: 'numeric',
    timeZone: 'UTC',
  }).format(new Date(`${year}-${month}-01`));
};

function getLocalTime(timezone: string): string {
  try {
    return new Date().toLocaleString('en-US', {
      timeZone: timezone,
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    });
  } catch (e) {
    reportAndLogError(e, 'Error getting local time');
    return '';
  }
}

function getTimezoneOffset(timeZone: string): number {
  const now = new Date();
  const localOffset = -now.getTimezoneOffset();
  const formattedDateInTargetTimezone = formatInTimeZone(now, timeZone, 'XXX');

  const match =
    formattedDateInTargetTimezone === 'Z'
      ? ['', '0', '0']
      : formattedDateInTargetTimezone.match(/([+-]\d{2}):?(\d{2})/);
  if (!match) {
    return 0;
  }
  if (!match) {
    return 0;
  }

  const [, hours, minutes] = match;
  const targetOffsetInMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10);
  return (targetOffsetInMinutes - localOffset) / 60;
}

const formatLeaveDate = (dateUnformatted: string): string => {
  const parsedDate = parseISO(dateUnformatted);
  return format(parsedDate, 'MMM d');
};

const isMoreThanToday = (dateUnformatted: string): boolean => {
  const startDate = dateUnformatted.split(' ')[1];
  const parsedDate = parseISO(startDate);
  const today = new Date();
  // get the date of today
  today.setHours(0, 0, 0, 0);
  return parsedDate >= today;
};

const calculateTenurePercentile = (tenure_percentile: number): number => {
  // Floor the number to one decimal place
  let floored = Math.floor(tenure_percentile * 10) / 10;
  if (Math.floor(tenure_percentile * 100) % 10 >= 5) {
    floored += 0.1;
  }
  return parseFloat(floored.toFixed(1));
};
