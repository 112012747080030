/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Setting } from '../models/Setting';
import type { SettingCreate } from '../models/SettingCreate';
import type { UpdateList } from '../models/UpdateList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SettingService {

    /**
     * Get Setting
     * Get settings.
     * @returns Setting Successful Response
     * @throws ApiError
     */
    public static getSettingApiV1SettingsGet(): CancelablePromise<Setting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/settings',
        });
    }

    /**
     * Upsert Setting
     * Create/Update the setting.
     * @param requestBody
     * @returns Setting Successful Response
     * @throws ApiError
     */
    public static upsertSettingApiV1SettingsPost(
        requestBody: SettingCreate,
    ): CancelablePromise<Setting> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/settings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Test
     * @returns any Successful Response
     * @throws ApiError
     */
    public static testApiV1TestGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/test',
        });
    }

    /**
     * Bulk Upload
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static bulkUploadApiV1SettingsBulkUploadWorkingWithMePost(
        requestBody: UpdateList,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/settings/bulk-upload-working-with-me',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
