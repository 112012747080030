import {Button} from '@dropbox/dig-components/dist/buttons';
import {Checkbox} from '@dropbox/dig-components/dist/controls';
import {FormHelperText, FormLabel, FormRow} from '@dropbox/dig-components/dist/form_row';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {TextArea, TextInput} from '@dropbox/dig-components/dist/text_fields';
import {PeopleSearchMenu} from 'components/shared/PeopleSearchMenu';
import {useSelectEmployees} from 'hooks/useSelectEmployee';
import {t} from 'i18next';
import {useState} from 'react';
import {getBadgesService} from 'utilities';

export const AddBadgeModal = ({open, onClose}: {open: boolean; onClose: () => void}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [hidden, setHidden] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedEmployees, selectEmployee, selectEmployees, removeEmployee] = useSelectEmployees();

  return (
    <Modal isCentered open={open} onRequestClose={onClose} withCloseButton="Close">
      <Modal.Header>
        <Modal.Title>{t('new_badge')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormRow>
          <FormLabel htmlFor="image">{t('badge_image')}</FormLabel>
          <FormHelperText>{t('badge_image_helper')}</FormHelperText>
          <input
            name="image"
            type="file"
            onChange={(event) => {
              if (event.target.files) {
                setSelectedFile(event.target.files[0]);
              }
            }}
          />
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="name">{t('badge_name')}</FormLabel>
          <FormHelperText>{t('badge_name_helper')}</FormHelperText>
          <TextInput
            name="name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            maxLength={40}
            placeholder={t('badge_name_placeholder')}
            autoComplete="off"
          />
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="description">{t('badge_description')}</FormLabel>
          <FormHelperText>{t('badge_description_helper')}</FormHelperText>
          <TextArea
            name="description"
            value={description}
            onChange={(e) => setDescription(e.currentTarget.value)}
            maxLength={300}
            placeholder={t('badge_description_placeholder')}
          />
        </FormRow>
        <FormRow>
          <FormLabel>{t('badge_managed_by')}</FormLabel>
          <FormHelperText>{t('badge_managed_by_helper')}</FormHelperText>
          <PeopleSearchMenu
            selectedEmployees={selectedEmployees}
            onRemoveEmployee={removeEmployee}
            onSelectEmployee={selectEmployee}
            onSelectEmployees={selectEmployees}
            allowSelf
          />
        </FormRow>
        <FormRow>
          <FormLabel>{t('hide_badge')}</FormLabel>
          <Checkbox
            aria-labelledby="hidden_from_library"
            onChange={(e) => setHidden(e.target.checked)}
            checked={hidden}
          />
        </FormRow>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="opacity" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (selectedFile) {
              return getBadgesService()
                .createBadgeApiV1BadgesPost({
                  file: selectedFile,
                  owner_ids: selectedEmployees.map((o) => o.ldap),
                  badge_data: JSON.stringify({
                    name,
                    hidden_from_library: hidden,
                    description,
                  }),
                })
                .then(() => {
                  onClose();
                });
            }
          }}
        >
          {t('save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
