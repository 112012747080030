import {Button} from '@dropbox/dig-components/dist/buttons';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Table} from '@dropbox/dig-components/dist/table';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {PersonLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {Goal, GoalData, GoalRead} from 'client';
import {AccordionIconButton} from 'components/DSYS/Accordion';
import {Eyebrow} from 'components/DSYS/Eyebrow';
import {Title} from 'components/DSYS/Title';
import {CreateCell} from 'components/shared/table/CreateCell';
import {Header} from 'components/shared/table/Header';
import {ColumnConfig} from 'components/shared/table/useColumnResize';
import {useTeamSuspense} from 'components/teams/hooks';
import {ROUTE_PATHS} from 'constant';
import {isGoalType} from 'helpers/utils';
import {usePeopleWidget} from 'hooks/useDashboards';
import {useEmployeesBatchSuspense} from 'hooks/useEmployee';
import {useAtomValue} from 'jotai';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  EmptyGoalsTable,
  EmptyMyGoalsTable,
  EmptyOthersGoalsTable,
  NoResultsTable,
} from './EmptyGoalsTable';
import {GoalsTableRow} from './GoalsTableRow';
import {NoGoalsRollup} from './NoGoalsRollup';
import {DrawerGoalData, GoalsTableProps} from './types';
import {
  getExpandedState,
  getLatestUpdate,
  groupGoalsByLdap,
  hasSecondaryFiltersSelected,
  isFilterSelectedForTeamTable,
  shouldIncludeSubteamMembers,
} from './utils';

export const getDefaultColumns = (): ColumnConfig[] => {
  return [
    {type: 'name', width: 550, fixed: true},
    {type: 'owners', width: 100, fixed: true},
    {type: 'status', width: 70, fixed: true},
    {type: 'update', short: 'upd', width: 160, fixed: true},
    {type: '', width: 24, fixed: true},
  ];
};

const getNewExpandedStateWithPrevState = (
  prevState: {[id: string]: boolean},
  data: Goal[],
  ownerLdap: string | undefined,
  selectedGoalData: DrawerGoalData | undefined,
  expandedParentGoals: {[id: string]: GoalRead} = {},
  shouldExpandOwnerGoals: boolean,
  shouldExpandContributingKRs: boolean,
  shouldGroupGoalsByEmployee: boolean
): {[id: string]: boolean} => {
  const newExpanded = {...prevState};
  const updatedExpanded = getExpandedState(
    data,
    ownerLdap,
    selectedGoalData,
    expandedParentGoals,
    shouldExpandOwnerGoals,
    shouldExpandContributingKRs,
    shouldGroupGoalsByEmployee
  );
  // Maintain previously expanded goals by only updating the new expanded goals
  Object.keys(updatedExpanded).forEach((key) => {
    if (updatedExpanded[key]) {
      newExpanded[key] = true;
    }
  });
  return newExpanded;
};

const ShowMoreLoadingSkeleton = () => {
  const style = {marginTop: '10px', marginLeft: '8px'};
  return (
    <Table.Row isSelectable={false}>
      <Table.Cell>
        <Skeleton.Box height={20} style={{...style, width: '450px'}} />
      </Table.Cell>
      <Table.Cell>
        <Skeleton.Box height={20} width={50} style={style} />
      </Table.Cell>
      <Table.Cell>
        <Skeleton.Box height={20} width={50} style={style} />
      </Table.Cell>
      <Table.Cell>
        <Skeleton.Box height={20} width={75} style={style} />
      </Table.Cell>
    </Table.Row>
  );
};

export const GoalsTable = ({
  keyPrefix,
  type,
  withTitle = '',
  data = [],
  timeframe,
  nextTimeframe,
  limitGoals,
  setNextTimeframe,
  ownerLdap,
  columnConfigs,
  onColumnDrag,
  isGoalsV2DrawerOpen,
  onRowClick,
  onContributorsClick,
  onContinueGoalClick,
  hasScrolledToRow,
  setScrolledToRow,
  selectedGoalData,
  showActionMenu = true,
  showCreate = false,
  showHeader = true,
  showUpdates = true,
  containerProps = {},
  rowProps = {},
  teamInfo,
  shouldExpandOwnerGoals = false,
  shouldExpandContributingKRs = false,
  shouldGroupGoalsByEmployee = false,
  shouldShowMore = false,
  isShowMoreLoading = false,
  setShowMore,
  disableExpandGoalActionMenu = false,
  filter,
  subGoalCountsOverride,
}: GoalsTableProps & {
  limitGoals?: number;
  keyPrefix: string;
}) => {
  const {employee: loggedInEmployee} = useAtomValue(loggedInEmployeeAtom);
  const [, setSort] = useState({type: 'name', asc: true});
  const [showAll, setShowAll] = useState(false);

  const [expandedParentGoals, setExpandedParentGoals] = useState<{[id: string]: GoalData}>({});
  const [expanded, setExpanded] = useState<{[id: string]: boolean}>(
    getExpandedState(
      data,
      ownerLdap,
      selectedGoalData,
      expandedParentGoals,
      shouldExpandOwnerGoals,
      shouldExpandContributingKRs,
      shouldGroupGoalsByEmployee
    )
  );
  const {t} = useTranslation();

  const teamSlug = teamInfo?.teamSlug ?? '';
  const team = useTeamSuspense({
    slug: teamSlug,
    includeSubteamMembers: filter && shouldIncludeSubteamMembers(teamSlug, filter),
  });

  const ldaps = useMemo(() => {
    const teamLdaps = team?.employees.flatMap((member) => member.ldap);
    const ldapList = teamLdaps
      ? new Set(teamLdaps)
      : new Set(filter?.people ?? [loggedInEmployee.ldap]);
    if (ownerLdap) {
      ldapList.add(ownerLdap);
    }

    return Array.from(ldapList);
  }, [team, filter?.people, loggedInEmployee.ldap, ownerLdap]);

  const employees = useEmployeesBatchSuspense({
    ldaps,
    enabled: !!ldaps.length,
  });

  const sortedEmployees = employees; // useEmployeesBatch is already sorted
  const sortedEmployeeLdaps = sortedEmployees.map((e) => e.ldap);
  // Move the owner to the end
  if (loggedInEmployee) {
    const ownerIndex = sortedEmployees.findIndex((e) => e.ldap === loggedInEmployee.ldap);
    if (ownerIndex > -1) {
      sortedEmployees.push(sortedEmployees.splice(ownerIndex, 1)[0]);
    }
  }

  const shouldShowNoGoalsRollup =
    shouldGroupGoalsByEmployee &&
    team?.slug &&
    filter &&
    !isFilterSelectedForTeamTable(filter) &&
    !shouldShowMore;
  const {people: employeesWithNoGoals} = usePeopleWidget({
    type: 'participation',
    teamSlug: team?.slug ?? '',
    timeframe,
    limit: 1000,
    excludeLowLevelAndTenure: false,
    enabled: !!shouldShowNoGoalsRollup,
  });

  const groupedGoals = useMemo(
    () => groupGoalsByLdap(data, ldaps, shouldGroupGoalsByEmployee, sortedEmployeeLdaps),
    [data, ldaps, shouldGroupGoalsByEmployee, sortedEmployeeLdaps]
  );

  const sortedGoals = useMemo(() => {
    const sortedGrouped: Array<[string, Goal[]]> = [];
    sortedEmployees.forEach((employee) => {
      const ldap = employee.ldap;
      if (groupedGoals[ldap]) {
        sortedGrouped.push([ldap, groupedGoals[ldap]]);
      }
    });
    return sortedGrouped;
  }, [sortedEmployees, groupedGoals]);

  const handleSetExpandedParentGoals = useCallback(
    (goal: GoalData, parentGoal: GoalRead) => {
      expandedParentGoals[goal.id] = {
        ...parentGoal,
        children: [goal],
        private: parentGoal.is_custom_privacy_included || goal.private,
        // We need to use goal's updated_at and created_at so that the table does not reorder
        updated_at: goal.updated_at,
        created_at: goal.created_at,
      };
      setExpandedParentGoals(expandedParentGoals);
      setExpanded(
        getNewExpandedStateWithPrevState(
          expanded,
          data,
          ownerLdap,
          selectedGoalData,
          expandedParentGoals,
          shouldExpandOwnerGoals,
          shouldExpandContributingKRs,
          shouldGroupGoalsByEmployee
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(expandedParentGoals), JSON.stringify(selectedGoalData)]
  );

  useEffect(() => {
    if (!data) {
      return;
    }
    setExpanded(
      getNewExpandedStateWithPrevState(
        expanded,
        data,
        ownerLdap,
        selectedGoalData,
        expandedParentGoals,
        shouldExpandOwnerGoals,
        shouldExpandContributingKRs,
        shouldGroupGoalsByEmployee
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length, JSON.stringify(selectedGoalData)]);

  if (!data || !data.length) {
    if (shouldGroupGoalsByEmployee) {
      return <EmptyGoalsTable columnConfigs={columnConfigs} withTitle={withTitle} />;
    }
    if (
      type === 'self' &&
      filter &&
      filter.people.length === 1 &&
      filter.teams.length === 0 &&
      filter.reports === '0'
    ) {
      return hasSecondaryFiltersSelected(filter) ? (
        <NoResultsTable />
      ) : (
        <EmptyMyGoalsTable timeframe={timeframe} />
      );
    } else if (
      type === 'other' &&
      filter &&
      filter.people.length === 1 &&
      filter.teams.length === 0 &&
      filter.reports === '0'
    ) {
      return hasSecondaryFiltersSelected(filter) ? <NoResultsTable /> : <EmptyOthersGoalsTable />;
    }
    return <EmptyGoalsTable columnConfigs={columnConfigs} withTitle={withTitle} />;
  }

  const renderRows = (rows: Goal[], ldap: string) => {
    const rowTitle = withTitle.toLowerCase().replace(' ', '_') ?? '';
    const rowKeyPrefix = `${keyPrefix}-table_row-${rowTitle}-${ldap}`;

    let subsetRows = rows;
    if (limitGoals && !showAll) {
      subsetRows = rows.slice(0, limitGoals);
    }

    return subsetRows.map((row) => (
      <GoalsTableRow
        {...row}
        key={`${rowKeyPrefix}-${row.id}`}
        rowKeyPrefix={rowKeyPrefix}
        row={
          isGoalType(row) && row.parent && expandedParentGoals[row.id]
            ? expandedParentGoals[row.id]
            : row
        }
        goalOwner={row.users![0]}
        parentGoalOwner={row.parent?.users?.[0] ?? row.users![0]}
        isGoalsV2DrawerOpen={isGoalsV2DrawerOpen}
        onRowClick={onRowClick}
        onContributorsClick={onContributorsClick}
        onContinueGoalClick={onContinueGoalClick}
        hasScrolledToRow={hasScrolledToRow}
        setScrolledToRow={setScrolledToRow}
        selectedGoalData={selectedGoalData}
        expandedParentGoals={expandedParentGoals}
        handleSetExpandedParentGoals={handleSetExpandedParentGoals}
        rowProps={rowProps}
        shouldGroupGoalsByEmployee={shouldGroupGoalsByEmployee}
        columnConfigs={columnConfigs}
        expandId={`goals-${row.id}`}
        setExpanded={setExpanded}
        expanded={expanded}
        indent={0}
        showActionMenu={showActionMenu}
        showUpdates={showUpdates}
        ownerLdap={ownerLdap}
        teamInfo={teamInfo}
        timeframe={timeframe}
        nextTimeframe={nextTimeframe}
        setNextTimeframe={setNextTimeframe}
        disableExpandGoalActionMenu={disableExpandGoalActionMenu}
        subGoalCountOverride={subGoalCountsOverride?.[row.id]}
        withLeftAccessory={
          <Box
            onClick={(e: any) => {
              e.stopPropagation();
              e.preventDefault();
              setExpanded({
                ...expanded,
                [`goals-${row.id}`]: !expanded[`goals-${row.id}`],
              });
            }}
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
            height="100%"
          >
            <AccordionIconButton
              isHidden={false}
              isActive={expanded[`goals-${row.id}`]}
              toggle={(e) => {
                e.stopPropagation();
                e.preventDefault();
                const toggleExpanded = !expanded[`goals-${row.id}`];
                if (toggleExpanded) {
                  const {latestUpdate} = getLatestUpdate(row.updates);
                  analyticsLogger().logEvent('GOAL_TABLE_EXPANDED', {
                    status: latestUpdate?.status ?? 'no_status',
                  });
                }
                setExpanded({
                  ...expanded,
                  [`goals-${row.id}`]: toggleExpanded,
                });
              }}
            />
            {expanded[`goals-${row.id}`] &&
              ((row.children && row.children.length > 0) || row.key_results.length > 0) && (
                <Box
                  as="div"
                  style={{
                    width: '1px',
                    height: 'calc(100% - 24px)',
                  }}
                  backgroundColor="Border Subtle"
                  marginLeft="12"
                ></Box>
              )}
          </Box>
        }
      />
    ));
  };

  return (
    <Box
      as="div"
      paddingX="24"
      paddingY="20"
      borderRadius="Medium"
      borderColor="Border Subtle"
      borderStyle="Solid"
      borderWidth="1"
      maxWidth="100%"
      marginX="auto"
      style={{width: '100%', overflowX: 'auto', marginTop: 0}}
      {...containerProps}
    >
      {withTitle ? (
        <Box as={Title} id={withTitle ?? undefined} size={18} paddingBottom="4" display="block">
          {withTitle}
        </Box>
      ) : null}
      <Table hasDividers={false} spacing="small" verticalAlign="center" data-testid="goals-table">
        {showHeader && (
          <Header
            columnConfigs={columnConfigs}
            sort={undefined}
            setSort={setSort}
            getMouseDownHandler={onColumnDrag}
          />
        )}
        <Table.Body>
          {showHeader && <Box as="div" style={{height: 8}} />}
          {shouldGroupGoalsByEmployee
            ? sortedGoals.map(([ldap, rows]) => {
                const employee = employees.find((e) => e.ldap === ldap);
                return (
                  <>
                    <Box display="flex" marginTop="8" marginBottom="8">
                      <Box
                        as={UIIcon}
                        src={PersonLine}
                        size="small"
                        color="Text Subtle"
                        marginX="8"
                        marginTop="4"
                      />

                      <Box as={Eyebrow} color="Text Subtle" marginTop="8" marginBottom="4">
                        {employee?.name ?? ldap}
                      </Box>
                    </Box>
                    {rows.length ? (
                      renderRows(rows, ldap)
                    ) : (
                      <EmptyGoalsTable
                        columnConfigs={columnConfigs}
                        showHeader={false}
                        containerProps={{
                          borderWidth: 0,
                          paddingX: 12,
                          paddingY: 0,
                          marginBottom: 0,
                        }}
                      />
                    )}
                  </>
                );
              })
            : renderRows(data, ownerLdap ?? '')}
          {shouldShowMore &&
            setShowMore &&
            (isShowMoreLoading ? (
              <ShowMoreLoadingSkeleton />
            ) : (
              <Box paddingTop="12">
                <Button variant="transparent" size="small" onClick={setShowMore}>
                  {t('show_more')}
                </Button>
              </Box>
            ))}
          {limitGoals && data.length > limitGoals && (
            <Box paddingTop="12">
              <Button variant="transparent" size="small" onClick={() => setShowAll(!showAll)}>
                {showAll ? t('show_less') : t('show_more_count', {count: data.length - limitGoals})}
              </Button>
            </Box>
          )}
          {showCreate && (
            <Table.Row isSelectable={false}>
              <CreateCell
                tooltip={t('add_goal')}
                to={ROUTE_PATHS.GOALS_V2_ONBOARDING}
                state={{source: 'table-row', timeframe}}
                marginLeft="0"
              />
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      {shouldShowNoGoalsRollup && employeesWithNoGoals.length > 0 && (
        <NoGoalsRollup teamName={team?.name} employeesWithNoGoals={employeesWithNoGoals} />
      )}
    </Box>
  );
};
