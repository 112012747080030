import DropboxOSBadgeIcon from 'assets/DropboxOSBadgeIcon.svg';
import DropboxOSBadgeDarkIcon from 'assets/DropboxOSBadgeIconDark.svg';
import {atom, useSetAtom} from 'jotai';
import {useEffect} from 'react';

export const darkModeAtom = atom(false);

const setFavicon = (darkMode: boolean) => {
  const link = document.querySelector("link[rel*='icon']");
  if (link) {
    (link as HTMLLinkElement).href = darkMode ? DropboxOSBadgeDarkIcon : DropboxOSBadgeIcon;
  }
};

export const useSetThemeWatcher = () => {
  const setIsDarkMode = useSetAtom(darkModeAtom);

  useEffect(() => {
    const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');
    const handler = (e: MediaQueryListEvent) => {
      setIsDarkMode(e.matches);
      setFavicon(e.matches);
    };

    setIsDarkMode(mediaQueryList.matches);
    setFavicon(mediaQueryList.matches);

    mediaQueryList.addEventListener('change', handler);
    return () => mediaQueryList.removeEventListener('change', handler);
  }, [setIsDarkMode]);
};
