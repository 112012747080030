import {Box} from '@dropbox/dig-foundations';
import {ReactNode} from 'react';

export const DrawerSection = ({children}: {children: ReactNode}) => {
  return <Box marginBottom="16">{children}</Box>;
};

export const DrawerSectionTitle = ({children}: {children: ReactNode}) => {
  return (
    <Box marginTop="8" marginBottom="8">
      {children}
    </Box>
  );
};
