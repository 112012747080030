import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Split, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon, UIIconProps} from '@dropbox/dig-icons';
import {GratitudeLine, InfoLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {SharingCakeMini} from '@dropbox/dig-illustrations';
import {useQuery} from '@tanstack/react-query';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {HomeService, MyPeople} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {EmptyState} from 'components/DSYS/EmptyState';
import {ButtonLink, Link} from 'components/DSYS/Link';
import {addDays, differenceInDays, format, parseISO} from 'date-fns';
import {sortEmployees} from 'helpers/utils';
import {t} from 'i18next';
import {useState} from 'react';
import {getService} from 'utilities';

import styles from './People.module.css';
import {Section} from './Section';

const getYearsAtCompany = (tenure: number) => {
  const yearsAtCompany = Number(tenure) / 12;

  return t('year', {count: yearsAtCompany});
};

const formatLeaveDate = (dateUnformatted: string): string => {
  const parsedDate = parseISO(dateUnformatted);
  return format(parsedDate, 'MMM d');
};

const getSubtext = (employee: MyPeople) => {
  if (employee.event === 'birthday' && employee.birthday) {
    return t('home_people_birthday', {date: employee.birthday});
  }
  if (employee.event === 'anniversary' && employee.tenure) {
    return t('home_people_anniversary', {tenure: getYearsAtCompany(employee.tenure)});
  }
  if (employee.event === 'pto') {
    const startDate = employee.upcoming_pto
      ? formatLeaveDate(employee.upcoming_pto.split(' ')[0])
      : null;
    const endDate = employee.upcoming_pto
      ? formatLeaveDate(employee.upcoming_pto.split(' ')[1])
      : null;

    return t(startDate === endDate ? 'home_people_pto' : 'home_people_pto_range', {
      date: startDate === endDate ? startDate : `${startDate} - ${endDate}`,
    });
  }
  if (employee.event === 'new') {
    return t('home_people_new', {role: employee.role});
  }
  return '';
};

// Helper to calculate event date for sorting
const getEventDate = (
  filter: ('birthday' | 'anniversary' | 'pto' | 'new')[],
  person: MyPeople
): Date | null => {
  const today = new Date();
  const upcomingDays = Array.from({length: 24}, (_, i) => addDays(today, i));

  // Check birthday within 24 days
  if (person.birthday && filter.includes('birthday')) {
    const birthdayThisYear = parseISO(`${new Date().getFullYear()}-${person.birthday}`);
    if (
      upcomingDays.some(
        (day) =>
          day.getMonth() === birthdayThisYear.getMonth() &&
          day.getDate() === birthdayThisYear.getDate()
      )
    ) {
      return birthdayThisYear;
    }
  }

  // Check PTO within 24 days
  if (person.upcoming_pto && filter.includes('pto')) {
    const ptoDate = parseISO(person.upcoming_pto);
    if (differenceInDays(ptoDate, today) <= 24) {
      return ptoDate;
    }
  }

  return null;
};

// Reuse sortEmployees and incorporate event date
const sortPeople =
  (filter: ('birthday' | 'anniversary' | 'pto' | 'new')[]) =>
  (a: MyPeople, b: MyPeople): number => {
    const eventDateA = getEventDate(filter, a);
    const eventDateB = getEventDate(filter, b);

    // Sort by event date first
    if (eventDateA && !eventDateB) return -1;
    if (!eventDateA && eventDateB) return 1;
    if (eventDateA && eventDateB) return eventDateA.getTime() - eventDateB.getTime();

    // If no event date / dates are same, use default sort
    return sortEmployees(a, b);
  };

const defaultEmployeeCount = 3;

export const PeopleSection = ({
  title,
  iconSrc,
  filter,
}: {
  title: string;
  iconSrc: UIIconProps['src'];
  filter: ('birthday' | 'anniversary' | 'pto' | 'new')[];
}) => {
  const [showMore, setShowMore] = useState(false);
  // const [filter, setFilter] = useState<'all' | 'birthday' | 'anniversary' | 'pto' | 'new'>('all');

  const {data, isLoading} = useQuery({
    queryKey: ['home', 'people'],
    queryFn: () =>
      getService(HomeService).peopleApiV1HomePeopleGet(
        encodeURIComponent(new Date().toISOString())
      ),
  });

  const filteredEmployees = data
    ?.filter(({event}) => filter.includes((event ?? '') as any))
    .sort(sortPeople(filter));

  const shownEmployees = showMore
    ? filteredEmployees
    : filteredEmployees?.slice(0, defaultEmployeeCount);

  const handleEventClick = (type: string, event?: string, reason?: string) =>
    analyticsLogger().logEvent('HOME_PEOPLE_CLICKED', {type, event, reason});

  return (
    <>
      <Section
        title={
          <Link hasNoUnderline to="/people" onClick={() => handleEventClick('header')}>
            {title}
          </Link>
        }
        withAccessoryStart={<UIIcon src={iconSrc} className={atoms({color: 'Text Subtle'})} />}
        isLoading={isLoading}
        loadingSkeleton={
          <Stack gap="0">
            {Array.from({length: 3}).map((_, index) => (
              <Split key={index} alignY="center">
                <Split.Item key={index} width="fill">
                  <Box
                    paddingY="8"
                    paddingX="12"
                    borderRadius="Medium"
                    {...withShade({
                      duration: 'None',
                      style: {marginLeft: -8, opacity: (10 - index * 2) / 8},
                    })}
                  >
                    <LabelGroup
                      size="large"
                      withLeftAccessory={<Skeleton.Avatar />}
                      withText={<Box as={Skeleton.Text} style={{width: 100}} />}
                      withSubtext={<Skeleton.Text size="small" width={200} />}
                    />
                  </Box>
                </Split.Item>
              </Split>
            ))}
          </Stack>
        }
        content={
          !isLoading && (
            <Stack>
              {shownEmployees?.map((employee) => (
                <Box
                  key={`${employee.user_id}-${employee.event}`}
                  paddingY="8"
                  paddingX="12"
                  borderRadius="Medium"
                  cursor="pointer"
                  {...withShade({duration: 'None', style: {marginLeft: -8}})}
                >
                  <Link
                    to={`/people/${employee.ldap}`}
                    state={{source: 'home'}}
                    onClick={() => handleEventClick('person', employee.event, employee.reason)}
                    hasNoUnderline
                  >
                    <Split className={styles.person} alignY="center" gap="8">
                      <Split.Item width="fill">
                        <LabelGroup
                          withLeftAccessory={<Avatar user={employee} />}
                          withText={<Text isBold>{employee.name}</Text>}
                          withSubtext={
                            <Text size="small" color="faint">
                              {getSubtext(employee)}
                            </Text>
                          }
                        />
                      </Split.Item>
                      {['birthday', 'anniversary', 'new'].includes(employee.event ?? '') && (
                        <Split.Item className={styles.button}>
                          <Link
                            to={`/gratitude?to=${employee.ldap}`}
                            hasNoUnderline
                            onClick={() =>
                              handleEventClick('gratitude', employee.event, employee.reason)
                            }
                          >
                            <Tooltip title={t('pick_a_card')}>
                              <IconButton variant="borderless">
                                <UIIcon src={GratitudeLine} />
                              </IconButton>
                            </Tooltip>
                          </Link>
                        </Split.Item>
                      )}
                      {employee.reason && (
                        <Split.Item className={styles.button}>
                          <Tooltip title={t(`home_reason_${employee.reason}`)}>
                            <Box paddingTop="4">
                              <UIIcon src={InfoLine} className={atoms({color: 'Disabled Base'})} />
                            </Box>
                          </Tooltip>
                        </Split.Item>
                      )}
                    </Split>
                  </Link>
                </Box>
              ))}
              {filteredEmployees?.length ? (
                Boolean(filteredEmployees?.length > defaultEmployeeCount) && (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    paddingTop="12"
                  >
                    <Box
                      as={Button}
                      variant="transparent"
                      size="small"
                      onClick={() => setShowMore(!showMore)}
                    >
                      {showMore
                        ? t('show_less')
                        : t('show_more_count', {
                            count: filteredEmployees?.length - defaultEmployeeCount,
                          })}
                    </Box>
                    <>
                      {showMore && filter.includes('new') && (
                        <ButtonLink
                          to="/people"
                          variant="transparent"
                          size="small"
                          onClick={() => setShowMore(!showMore)}
                        >
                          {t('view_all')}
                        </ButtonLink>
                      )}
                    </>
                  </Box>
                )
              ) : (
                <EmptyState
                  paddingY="52"
                  title={t('home_empty_people_title')}
                  body={t('home_empty_people_body')}
                  image={<SharingCakeMini width="64" altText={t('home_empty_news_title')} />}
                  hideBorder
                />
              )}
            </Stack>
          )
        }
      />
    </>
  );
};
