import {Text} from '@dropbox/dig-components/dist/typography';
import {useTheme} from '@dropbox/dig-foundations';
import gratitudeSpinner from 'assets/images/gratitude_spinner.svg';
import gratitudeSpinnerDark from 'assets/images/gratitude_spinner_dark.svg';
import {LabelText} from 'components/gratitiude/LabelText';
import {t} from 'i18next';
import {FunctionComponent, ReactNode} from 'react';
import {NavLink} from 'react-router-dom';

import styles from './Gratitude.module.css';

interface Props {
  children: ReactNode;
  flavor?: ReactNode;
  title: string;
}

export const Gratitude: FunctionComponent<Props> = ({children, flavor, title}) => {
  const {mode} = useTheme();

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.content}
        style={{backgroundColor: mode === 'dark' ? '#413937' : '#ebbfaf'}}
      >
        <img
          className={styles.spinner}
          src={mode === 'dark' ? gratitudeSpinnerDark : gratitudeSpinner}
        />
        <div className={styles.nav}>
          <NavLink className={styles.link} to="/gratitude">
            <LabelText tagName="span">{t('browse')}</LabelText>
          </NavLink>
          <NavLink className={styles.link} to="/gratitude/inbox">
            <LabelText tagName="span">{t('inbox')}</LabelText>
          </NavLink>
          <NavLink className={styles.link} to="/gratitude/outbox">
            <LabelText tagName="span">{t('outbox')}</LabelText>
          </NavLink>
        </div>
        <div className={styles.title}>
          <Text className={styles.titleText}>{title}</Text>
        </div>
        {flavor && <div className={styles.flavor}>{flavor}</div>}
        <div>{children}</div>
      </div>
    </div>
  );
};
