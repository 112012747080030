import {Button} from '@dropbox/dig-components/dist/buttons';
import {DatePickerInput} from '@dropbox/dig-components/dist/date_picker';
import {FormLabel, FormRow} from '@dropbox/dig-components/dist/form_row';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Select, TextArea} from '@dropbox/dig-components/dist/text_fields';
import {Split} from '@dropbox/dig-foundations';
import {useMutation} from '@tanstack/react-query';
import {PulseCreate, PulseService} from 'client';
import {t} from 'i18next';
import {useState} from 'react';
import {getService} from 'utilities';
import {
  fromServerDate,
  getTodayUTC9am,
  toCalendarDate,
  toEODISOString,
  toServerDate,
} from 'views/admin/utils';
import {queryClient} from 'views/QueryClientWrapper';

const today9Am = getTodayUTC9am().toISOString().slice(0, -1);
const tomorrow9Am = new Date(getTodayUTC9am().getTime() + 24 * 60 * 60 * 1000)
  .toISOString()
  .slice(0, -1);

export const NewPulseModal = ({onClose}: {onClose: () => void}) => {
  const [sendAt, setSendAt] = useState(today9Am);
  const [endAt, setEndAt] = useState(tomorrow9Am);

  const {mutateAsync: createPulse} = useMutation({
    mutationFn: ({pulse}: {pulse: PulseCreate}) =>
      getService(PulseService).createPulseApiV1PulseCreatePost(pulse),
  });

  const handleSetSendAt = (value: Date | string) => {
    setSendAt(toServerDate(sendAt, value));
  };

  const handleSetEndAt = (value: Date | string) => {
    setEndAt(toServerDate(sendAt, value));
  };

  const handleCreate = async () => {
    onClose();

    await createPulse({
      pulse: {
        questions: [
          {
            prompt: 'I am happy working at Dropbox',
            type: 'esat',
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
          },
          {
            prompt:
              'What is one actionable change we could make that would significantly improve your developer productivity?',
            type: 'text-input',
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
          },
        ],
        survey_start_date: sendAt,
        survey_end_date: toEODISOString(endAt)!,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      },
    });
    queryClient.invalidateQueries({queryKey: ['pulse', 'scheduled']});
    queryClient.invalidateQueries({queryKey: ['notifications']});
  };

  return (
    <Modal isCentered open withCloseButton="Close" onRequestClose={onClose}>
      <Modal.Header hasBottomSpacing="title-standard">
        <Modal.Title weightVariant="emphasized">New pulse survey</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormRow>
          <FormLabel htmlFor="type">Company Question</FormLabel>
          <TextArea value="I am happy working at Dropbox" disabled />
        </FormRow>

        <FormRow>
          <FormLabel htmlFor="type">Pillar Question</FormLabel>
          <TextArea
            value="What is one actionable change we could make that would significantly improve your developer productivity?"
            disabled
          />
        </FormRow>

        <FormRow>
          <FormLabel htmlFor="type">Send date</FormLabel>
          <Split gap="8" alignY="center">
            <Split.Item>
              <DatePickerInput
                id="send-at-date"
                value={toCalendarDate(sendAt)}
                onChange={(value) => handleSetSendAt(value ?? today9Am)}
              />
            </Split.Item>
            <Split.Item>
              <Select
                id="send-at-time"
                value={fromServerDate(sendAt).getUTCHours().toString()}
                onChange={handleSetSendAt}
              >
                {Array.from({length: 24}, (_, i) => i).map((hour) => (
                  <Select.Option key={hour} value={hour.toString()}>
                    {`${hour % 12 || 12}${hour < 12 ? 'am' : 'pm'}`}
                  </Select.Option>
                ))}
              </Select>
            </Split.Item>
          </Split>
        </FormRow>

        <FormRow>
          <FormLabel htmlFor="type">Due date</FormLabel>
          <DatePickerInput
            id="end-at"
            value={toCalendarDate(endAt)}
            onChange={(value) => handleSetEndAt(value ?? tomorrow9Am)}
          />
        </FormRow>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="opacity" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button variant="primary" onClick={handleCreate}>
          {t('save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
