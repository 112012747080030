import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Truncate} from '@dropbox/dig-components/dist/truncate';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Split, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon, UIIconProps} from '@dropbox/dig-icons';
import {
  CloseLine,
  EmailLine,
  LinkLine,
  MobileLine,
  PersonMultipleLine,
  SlackExternalLogo,
  SoundOnLine,
  TeamLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import {useQuery} from '@tanstack/react-query';
import {snackbarAtom} from 'atoms/snackbar';
import {EmployeeService, EmployeeWithHierarchy, TeamWithHierarchyCounts} from 'client';
import {Avatar, AvatarIcon} from 'components/DSYS/Avatar';
import {CircleIcon} from 'components/DSYS/CircleIcon';
import {ButtonLink} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import {useTeam} from 'components/teams/hooks';
import {t} from 'i18next';
import {useSetAtom} from 'jotai';
import {useCallback, useEffect} from 'react';
import {getService} from 'utilities';

import {HierarchyData} from './helpers';

const getSlackURL = (slackChannel: string) => {
  const channel = slackChannel.replace('#', '');
  if (channel.startsWith('https://')) {
    return channel;
  } else {
    return 'https://dropbox.slack.com/archives/' + channel;
  }
};

const CardIconMenu = ({
  items,
  type,
  onClick,
  src,
}: {
  type: 'copy' | 'link';
  items: {title: string; subtitle?: string; to?: string}[];
  src: UIIconProps['src'];
  onClick?: () => void;
}) => {
  const setSnackbarMessage = useSetAtom(snackbarAtom);

  const copyToClipboard = useCallback(
    (text: string) => {
      navigator.clipboard.writeText(text);
      setSnackbarMessage({text: t('copied_to_clipboard')});
      onClick?.();
    },
    [onClick, setSnackbarMessage]
  );

  return (
    <Menu.Wrapper>
      {({getTriggerProps, getContentProps}) => (
        <>
          <IconButton {...getTriggerProps()} variant="outline">
            <UIIcon src={src} />
          </IconButton>
          <Menu.Content {...getContentProps()} placement="top-end" style={{minWidth: 200}}>
            <Menu.Segment>
              {items.map((item, index) =>
                type === 'copy' ? (
                  <Menu.ActionItem
                    key={index}
                    onClick={() => copyToClipboard(item.title)}
                    withTitle={item.title}
                    withSubtitle={item.subtitle}
                  />
                ) : (
                  <Menu.LinkItem
                    key={index}
                    href={item.to!}
                    withTitle={item.title}
                    onClick={onClick}
                    target="_blank"
                  />
                )
              )}
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};

export const ChartCard = ({
  open,
  isFocus,
  children,
  expanded,
  onClose,
}: {
  open: boolean;
  isFocus: boolean;
  children: React.ReactNode;
  expanded: boolean;
  onClose: () => void;
}) => {
  useEffect(() => {
    if (!expanded) {
      onClose();
    }
  }, [expanded, onClose]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (open && !isFocus) {
          event.preventDefault();
          event.stopPropagation();
        }
        onClose();
      }
    },
    [open, isFocus, onClose]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  if (!expanded) {
    return null;
  }

  return (
    <>
      <Box
        cursor="default"
        position="absolute"
        display="flex"
        overflow="hidden"
        style={{
          bottom: 16,
          right: 16,
          width: 350,
          maxHeight: 'calc(100% - 84px)',
          opacity: open ? 1 : 0,
          transform: `translateX(${open ? 0 : 500}px)`,
          transition: open
            ? `transform var(--easing__enter) var(--duration__125), opacity ease var(--duration__surface)`
            : 'none',
        }}
        boxShadow="Floating"
        backgroundColor="Background Raised"
        borderRadius="Large"
        borderColor="Border Subtle"
        borderWidth="1"
        borderStyle="Solid"
      >
        <Box overflow="auto" display="flex" flexDirection="column" style={{flex: 1}}>
          {children}
        </Box>

        <IconButton
          tabIndex={open ? 0 : -1}
          variant="borderless"
          onClick={() => onClose()}
          className={atoms({position: 'absolute'})}
          style={{right: 8, top: 8}}
        >
          <UIIcon src={CloseLine} />
        </IconButton>
      </Box>
    </>
  );
};

const TeamInfo = ({team, onViewTeam}: {team?: TeamWithHierarchyCounts; onViewTeam: () => void}) => {
  const teamData = useTeam({slug: team?.slug ?? ''});

  return (
    <Stack
      gap="8"
      className={atoms({
        padding: '24',
      })}
    >
      <AvatarIcon size="large" src={TeamLine} />

      <Title>{team?.name ?? <Skeleton.Text width={160} />}</Title>

      {team?.description && (
        <Text color="subtle" className={atoms({marginY: '8', display: 'block'})}>
          <Truncate lines={5}>{team.description}</Truncate>
        </Text>
      )}
      <Text isBold color="subtle" className={atoms({marginY: '8', display: 'block'})}>
        {team?.total_employee_count || team?.total_employee_count === 0 ? (
          t('member', {
            count: team?.total_employee_count,
            countString: team?.total_employee_count.toLocaleString(),
          })
        ) : (
          <Skeleton.Text width={200} />
        )}
      </Text>
      {team?.name && (
        <Split gap="8">
          <Split.Item>
            <ButtonLink variant="outline" to={`/teams/${team.slug}`} onClick={onViewTeam}>
              {t('view_team')}
            </ButtonLink>
          </Split.Item>

          {Boolean(teamData?.slack_channels?.length) && (
            <Split.Item>
              <CardIconMenu
                src={SlackExternalLogo}
                type="link"
                items={teamData!.slack_channels.map((channel) => ({
                  title: channel.startsWith('#') ? channel : `#${channel}`,
                  to: getSlackURL(channel),
                }))}
              />
            </Split.Item>
          )}
          {Boolean(teamData?.emails?.length) && (
            <Split.Item>
              <CardIconMenu
                src={EmailLine}
                type="copy"
                items={teamData!.emails.map((title) => ({title}))}
              />
            </Split.Item>
          )}
          {Boolean(teamData?.links?.length) && (
            <Split.Item>
              <CardIconMenu
                src={LinkLine}
                type="link"
                items={teamData!.links.map((item) => ({
                  title: item.text,
                  subtitle: item.url,
                  to: item.url,
                }))}
              />
            </Split.Item>
          )}
        </Split>
      )}
    </Stack>
  );
};
ChartCard.Team = TeamInfo;

const EmployeeCard = ({
  employee,
  onClick,
  onViewProfile,
}: {
  employee: EmployeeWithHierarchy;
  onClick: (data: HierarchyData) => void;
  onViewProfile: () => void;
}) => {
  return (
    <>
      <EmployeeInfo employee={employee} onViewProfile={onViewProfile} />
      <EmployeeData employee={employee} onClick={onClick} />
    </>
  );
};
ChartCard.Employee = EmployeeCard;

const EmployeeInfo = ({
  employee,
  onViewProfile,
}: {
  employee: EmployeeWithHierarchy;
  onViewProfile: () => void;
}) => {
  const {data} = useQuery({
    queryKey: ['people', 'card', employee!.ldap],
    queryFn: () => getService(EmployeeService).getCardApiV1PeopleLdapCardGet(employee!.ldap!),
    enabled: !!employee?.ldap,
  });

  return (
    <Stack gap="16" className={atoms({padding: '24', flexGrow: 1})}>
      <Avatar key={employee.ldap} size="profile" user={employee} />

      <Title>
        {employee.name}
        {window.speechSynthesis && data?.pronunciation && (
          <>
            <IconButton
              className={atoms({marginLeft: '2'})}
              variant="borderless"
              onClick={() => {
                window.speechSynthesis.speak(new SpeechSynthesisUtterance(data.pronunciation!));
              }}
            >
              <Box as={UIIcon} color="Text Subtle" src={SoundOnLine} />
            </IconButton>
          </>
        )}
        {data?.pronouns && (
          <Text size="small" color="faint" className={atoms({marginLeft: '2'})}>
            {data?.pronouns}
          </Text>
        )}
      </Title>

      <Text color="subtle" className={atoms({marginY: '8'})}>
        {`${employee.role} ${employee.level ? `(${employee.level})` : ''}`}
      </Text>
      {employee.name && (
        <Split gap="8">
          <Split.Item>
            <ButtonLink variant="outline" to={`/people/${employee.ldap}`} onClick={onViewProfile}>
              {t('view_profile')}
            </ButtonLink>
          </Split.Item>
          <Split.Item>
            <CardIconMenu
              src={SlackExternalLogo}
              type="link"
              items={[
                {title: `@${employee.ldap}`, to: `https://dropbox.slack.com/team/${employee.ldap}`},
              ]}
            />
          </Split.Item>
          <Split.Item>
            <CardIconMenu
              src={EmailLine}
              type="copy"
              items={[{title: `${employee.ldap}@dropbox.com`}]}
            />
          </Split.Item>
          {data?.phone_number && (
            <Split.Item>
              <CardIconMenu src={MobileLine} type="copy" items={[{title: data.phone_number}]} />
            </Split.Item>
          )}
        </Split>
      )}
    </Stack>
  );
};

const EmployeeData = ({
  employee,
  onClick,
}: {
  employee: EmployeeWithHierarchy;
  onClick: (team: HierarchyData) => void;
}) => {
  const {data: card} = useQuery({
    queryKey: ['people', 'card', employee.ldap],
    queryFn: () => getService(EmployeeService).getCardApiV1PeopleLdapCardGet(employee.ldap!),
    enabled: !!employee.ldap,
  });

  if (!employee.direct_report_count && (!card || !card?.teams.length)) {
    return null;
  }

  return (
    <Stack
      gap="4"
      className={atoms({
        paddingX: '24',
        paddingY: '12',
        borderTop: 'Solid',
        borderColor: 'Border Subtle',
        // overflowY: 'auto',
      })}
      style={{minHeight: 54}}
    >
      {card?.teams.map((team) => (
        <Box
          key={team.slug}
          onClick={() => onClick(team as TeamWithHierarchyCounts)}
          cursor="pointer"
          padding="8"
          borderRadius="Medium"
          {...withShade({
            style: {marginLeft: -8, marginRight: -8},
          })}
        >
          <LabelGroup
            withText={<Text isBold>{team.name}</Text>}
            withLeftAccessory={<CircleIcon src={TeamLine} />}
            withSubtext={
              <>
                {team.total_employee_count
                  ? t('member', {
                      count: team.total_employee_count,
                      countString: team.total_employee_count.toLocaleString(),
                    })
                  : null}
              </>
            }
          />
        </Box>
      ))}
      {Boolean(employee.direct_report_count) && (
        <Box
          onClick={() => onClick(employee as EmployeeWithHierarchy)}
          cursor="pointer"
          padding="8"
          borderRadius="Medium"
          {...withShade({
            style: {marginLeft: -8, marginRight: -8},
          })}
        >
          <LabelGroup
            withText={<Text isBold>{t('direct_reports')}</Text>}
            withLeftAccessory={<CircleIcon src={PersonMultipleLine} />}
            withSubtext={t('people_count', {count: employee.direct_report_count})}
          />
        </Box>
      )}
    </Stack>
  );
};
