import {Button} from '@dropbox/dig-components/dist/buttons';
import {Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {CopyLine, RotateLeftLine} from '@dropbox/dig-icons/assets';
import achievableImage from 'assets/images/achievable.svg';
import achievableDisableImage from 'assets/images/achievable_disable.svg';
import actionImage from 'assets/images/action.svg';
import actionDisableImage from 'assets/images/action_disable.svg';
import CompleteIcon from 'assets/images/complete_goal.svg';
import importanceImage from 'assets/images/importance.svg';
import importanceDisableImage from 'assets/images/importance_disable.svg';
import KRLogo from 'assets/images/kr.svg';
import loaderImage from 'assets/images/loader.svg';
import metricImage from 'assets/images/metrics.svg';
import metricDisableImage from 'assets/images/metrics_disable.svg';
import OKRLogo from 'assets/images/okr_logo.svg';
import outcomeImage from 'assets/images/outcome.svg';
import outcomeDisableImage from 'assets/images/outcome_disable.svg';
import progressiveVerbImage from 'assets/images/progressive_verb.svg';
import progressiveVerbDisableImage from 'assets/images/progressive_verb_disable.svg';
import {Link} from 'components/DSYS/Link';
import {FOCUSED_OKR, OKREntry, OKREntryDetailStatus, OKREntryStatus} from 'helpers/okr/types';
import {
  OKR_GRADING_IN_PROGRESS_TITLE,
  OKR_GRADING_NO_INPUT_DESCRIPTION,
  OKR_GRADING_NO_INPUT_LEARN_MORE,
  OKR_GRADING_NO_INPUT_OR,
  OKR_GRADING_NO_INPUT_START_WRITING,
  OKR_GRADING_NO_INPUT_TITLE,
  OKR_GRADING_OBJECTIVE_FOOTER_CONTINUE,
  OKR_GRADING_OVERALL_FAIL_DESCRIPTION,
  OKR_GRADING_OVERALL_FAIL_TITLE,
  OKR_GRADING_OVERALL_RESULT_CLIPBOARD,
  OKR_GRADING_OVERALL_RESULT_COMPLETED,
  OKR_GRADING_OVERALL_RESULT_COPY,
  OKR_GRADING_OVERALL_RESULT_RESTART,
  OKR_GRADING_OVERALL_RESULT_RESTART_WITH_NEW_OBJECTIVE,
  OKR_GRADING_OVERALL_RESULT_SELECT_KEY_RESULT,
  OKR_GRADING_OVERALL_RESULT_SELECT_KEY_RESULT_DESCRIPTION,
  OKR_GRADING_OVERALL_SUCCESS_DESCRIPTION,
  OKR_GRADING_OVERALL_SUCCESS_TITLE,
} from 'strings';

import styles from './OKRGradingComponent.module.css';

const OKRGradingLoadingHeader = () => (
  <>
    <div style={{display: 'flex', gap: '3px', marginBottom: '20px'}}>
      <img src={actionImage} alt="action image" />
      <img src={outcomeImage} alt="outcome image" />
      <img src={importanceImage} alt="importance image" />
      <img src={loaderImage} alt="loader image" />
    </div>
  </>
);

interface OKRGradingHeaderProps {
  okrEntry: OKREntry;
}
const OKRGradingHeader = ({okrEntry}: OKRGradingHeaderProps) => {
  if (okrEntry.status === OKREntryStatus.IN_PROGRESS) {
    return (
      <>
        <OKRGradingLoadingHeader />
        <Text size="large" style={{fontWeight: '500', marginBottom: '16px'}}>
          {OKR_GRADING_IN_PROGRESS_TITLE}
        </Text>
      </>
    );
  }
  return (
    <div style={{padding: '0px 8px', paddingBottom: '8px'}}>
      <Text size="large" style={{fontWeight: '500'}}>
        {okrEntry.title}
      </Text>
      <Text style={{color: '#736C64', display: 'block'}}> {okrEntry.description}</Text>
    </div>
  );
};

const OKRGradingNoInputHeader = () => {
  return (
    <div className={styles.gradingHeader}>
      <img src={OKRLogo} alt="OKR Logo" />
      <Text size="xlarge" style={{fontWeight: '500'}}>
        {OKR_GRADING_NO_INPUT_TITLE}
      </Text>
      <div style={{textAlign: 'center'}}>
        <Text size="medium" color="faint">
          {OKR_GRADING_NO_INPUT_DESCRIPTION}
        </Text>
      </div>
      <div style={{marginTop: '12px'}}>
        <Text style={{color: '#736C64', fontWeight: '500'}}>
          {OKR_GRADING_NO_INPUT_START_WRITING}
        </Text>{' '}
        <Text style={{color: '#736C64'}}>{OKR_GRADING_NO_INPUT_OR}</Text>{' '}
        <Text>
          <Link
            to={
              'https://dropboxprod.service-now.com/esc_dbx?id=kb_article&sys_kb_id=349638dd974fa150ec23d914a253af40&spa=1'
            }
            style={{color: '#736C64'}}
            target="_blank"
          >
            {OKR_GRADING_NO_INPUT_LEARN_MORE}
          </Link>
        </Text>
      </div>
    </div>
  );
};

interface OKRGradingOverallResultHeaderProps {
  focusedOKR: FOCUSED_OKR;
}

const OKRGradingOverallResultHeader = ({focusedOKR}: OKRGradingOverallResultHeaderProps) => {
  return (
    <>
      {focusedOKR === FOCUSED_OKR.ALL_SUCCESS && (
        <div className={styles.gradingHeader}>
          <img src={CompleteIcon} alt="OKR Logo" />
          <Text size="xlarge" style={{fontWeight: '500'}}>
            {OKR_GRADING_OVERALL_SUCCESS_TITLE}
          </Text>
          <div
            style={{
              marginLeft: 50,
              marginRight: 50,
              display: 'flex',
              textAlign: 'center',
            }}
          >
            <Text size="medium" color="faint" style={{fontWeight: '400'}}>
              {OKR_GRADING_OVERALL_SUCCESS_DESCRIPTION}
            </Text>
          </div>
        </div>
      )}
      {focusedOKR === FOCUSED_OKR.ALL_FAILED && (
        <div style={{display: 'flex', flexDirection: 'column', gap: 5}}>
          <Text size="large" style={{fontWeight: '500'}}>
            {OKR_GRADING_OVERALL_FAIL_TITLE}
          </Text>
          <Text color="faint">{OKR_GRADING_OVERALL_FAIL_DESCRIPTION}</Text>
        </div>
      )}
    </>
  );
};

interface OKRGradingEntryProps {
  title: string;
  description: string;
  iconColor?: string;
  backgroundColor?: string;
  handleOnClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  status?: OKREntryDetailStatus;
  imageName?: string;
  focusedOKR?: FOCUSED_OKR;
}

const ImageIcon = ({imageName}: {imageName?: string}) => {
  return (
    <div className={styles.imageIcon}>
      {imageName === 'action' && <img src={actionImage} />}
      {imageName === 'outcome' && <img src={outcomeImage} />}
      {imageName === 'importance' && <img src={importanceImage} />}
      {imageName === 'progressiveVerb' && <img src={progressiveVerbImage} />}
      {imageName === 'achievable' && <img src={achievableImage} />}
      {imageName === 'metric' && <img src={metricImage} />}
    </div>
  );
};

const ImageDisableIcon = ({imageName}: {imageName?: string}) => {
  return (
    <div className={styles.imageIcon}>
      {imageName === 'action' && <img src={actionDisableImage} />}
      {imageName === 'outcome' && <img src={outcomeDisableImage} />}
      {imageName === 'importance' && <img src={importanceDisableImage} />}
      {imageName === 'progressiveVerb' && <img src={progressiveVerbDisableImage} />}
      {imageName === 'achievable' && <img src={achievableDisableImage} />}
      {imageName === 'metric' && <img src={metricDisableImage} />}
    </div>
  );
};

const OKRGradingEntryComponent = ({
  title,
  description,
  status,
  imageName,
}: OKRGradingEntryProps) => {
  return (
    <div className={styles.entryContainer}>
      <div className={styles.entryHeader}>
        {status !== OKREntryDetailStatus.SUCCESS && <ImageDisableIcon imageName={imageName} />}

        {status === OKREntryDetailStatus.SUCCESS && <ImageIcon imageName={imageName} />}

        <Text
          style={{
            fontWeight: 500,
          }}
        >
          {title}
        </Text>
      </div>
      {status !== OKREntryDetailStatus.FAILED && (
        <Text className={styles.entryText}>{description}</Text>
      )}
      {status === OKREntryDetailStatus.FAILED && (
        <>
          <Text className={styles.entryText}>{description}</Text>
        </>
      )}
    </div>
  );
};

interface OKRGradingComponentProps {
  isTyping: boolean;
  currentOKREntry: OKREntry;
  focusedOKR: FOCUSED_OKR;
  handleCopyClick: () => void;
}

const OKRGradingComponent = ({
  isTyping,
  currentOKREntry,
  focusedOKR,
  handleCopyClick,
}: OKRGradingComponentProps) => {
  if (!isTyping) {
    return (
      <div className={styles.container}>
        <OKRGradingNoInputHeader />
      </div>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const renderGrading = (currentOKREntry: OKREntry) => {
    return currentOKREntry.details.map((detail, index) => {
      return (
        <OKRGradingEntryComponent
          key={index}
          title={detail.title}
          description={detail.description}
          iconColor={detail.iconColor}
          status={detail.status}
          imageName={detail.imageName}
          focusedOKR={focusedOKR}
        />
      );
    });
  };

  return (
    <div className={styles.container}>
      {focusedOKR === FOCUSED_OKR.ALL_SUCCESS && (
        <OKRGradingOverallResultHeader focusedOKR={focusedOKR} />
      )}
      {focusedOKR === FOCUSED_OKR.ALL_FAILED && (
        <OKRGradingOverallResultHeader focusedOKR={focusedOKR} />
      )}
      {focusedOKR !== FOCUSED_OKR.ALL_SUCCESS && focusedOKR !== FOCUSED_OKR.ALL_FAILED && (
        <OKRGradingHeader okrEntry={currentOKREntry} />
      )}

      <div className={styles.gradingSection}>
        {focusedOKR === FOCUSED_OKR.OBJECTIVE && <>{renderGrading(currentOKREntry)}</>}

        {focusedOKR === FOCUSED_OKR.KEY_RESULT && <>{renderGrading(currentOKREntry)}</>}

        {focusedOKR === FOCUSED_OKR.ALL_SUCCESS && (
          <OKRGradingOverralResultComponent
            focusedOKR={focusedOKR}
            handleCopyClick={handleCopyClick}
          />
        )}
        {focusedOKR === FOCUSED_OKR.ALL_FAILED && (
          <OKRGradingOverralResultComponent
            focusedOKR={focusedOKR}
            handleCopyClick={handleCopyClick}
          />
        )}
        {focusedOKR === FOCUSED_OKR.OBJECTIVE &&
          currentOKREntry.status === OKREntryStatus.SUCCESS && (
            <OKRGradingObjectiveFooterComponent />
          )}
      </div>
    </div>
  );
};

const OKRGradingObjectiveFooterComponent = () => {
  return (
    <div className={styles.footerContainer}>
      <Text className={styles.footerText} color="faint">
        {OKR_GRADING_OBJECTIVE_FOOTER_CONTINUE}
      </Text>
    </div>
  );
};

interface OKRGradingOverallResultComponentProps {
  focusedOKR: FOCUSED_OKR;
  handleCopyClick: () => void;
}

const OKRGradingOverralResultComponent = ({
  focusedOKR,
  handleCopyClick,
}: OKRGradingOverallResultComponentProps) => {
  const handleReset = () => {
    window.location.reload();
  };

  return (
    <>
      {focusedOKR === FOCUSED_OKR.ALL_SUCCESS && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              borderBottom: '#DFDCD8 solid 1px',
              paddingBottom: '20px',
              cursor: 'pointer',
            }}
            onClick={handleCopyClick}
          >
            <UIIcon src={CopyLine} />
            <div>
              <Text size="medium" style={{fontWeight: '500'}}>
                {OKR_GRADING_OVERALL_RESULT_COPY}
              </Text>{' '}
              <Text size="medium" color="faint">
                {OKR_GRADING_OVERALL_RESULT_CLIPBOARD}
              </Text>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              height: 40,
              marginTop: 20,
              cursor: 'pointer',
            }}
            onClick={handleReset}
          >
            <UIIcon src={RotateLeftLine} />
            <div>
              <Text size="medium" style={{fontWeight: '500'}}>
                {OKR_GRADING_OVERALL_RESULT_RESTART}
              </Text>{' '}
              <Text size="medium" color="faint" style={{fontWeight: '400'}}>
                {OKR_GRADING_OVERALL_RESULT_RESTART_WITH_NEW_OBJECTIVE}
              </Text>
            </div>
          </div>
        </div>
      )}
      {focusedOKR === FOCUSED_OKR.ALL_FAILED && (
        <div
          style={{
            display: 'flex',
            marginTop: 19,
            gap: 8,
            backgroundColor: '#F7F5F2',
            paddingTop: 16,
            paddingBottom: 18,
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 8,
          }}
        >
          <div>
            <img src={KRLogo} alt="KR Logo" />
          </div>
          <div>
            <Text color="faint" style={{fontWeight: '500'}}>
              {OKR_GRADING_OVERALL_RESULT_SELECT_KEY_RESULT}
            </Text>{' '}
            <Text color="faint" style={{fontWeight: '400'}}>
              {OKR_GRADING_OVERALL_RESULT_SELECT_KEY_RESULT_DESCRIPTION}
            </Text>
          </div>
        </div>
      )}
    </>
  );
};

interface KeyResultsCompletedComponentProps {
  isKeyResultEntriesCompleted: boolean;
  handleFinishClick: () => void;
}

const KeyResultsCompletedComponent = ({
  isKeyResultEntriesCompleted,
  handleFinishClick,
}: KeyResultsCompletedComponentProps) => {
  return (
    <div>
      {isKeyResultEntriesCompleted && (
        <div className={styles.finishButtonContainer}>
          <Button variant="transparent" onClick={handleFinishClick} size="large">
            {OKR_GRADING_OVERALL_RESULT_COMPLETED}
          </Button>
        </div>
      )}
    </div>
  );
};

export {KeyResultsCompletedComponent, OKRGradingComponent};
