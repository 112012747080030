import {WrapperRefObject} from '@dropbox/dig-components/dist/menu';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/typography';
import {Box} from '@dropbox/dig-foundations';
import {pulseUserAtom} from 'atoms/auth';
import {ProfilePage} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {TableSkeleton} from 'components/shared/table/TableSkeleton';
import {ColumnConfig, useColumnResize} from 'components/shared/table/useColumnResize';
import {DEFAULT_TIMEFRAME} from 'constant';
import {useProfile} from 'hooks/useProfile';
import {useAtomValue} from 'jotai';
import {Suspense, useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {emailToLdap} from 'utilities';
import {JoinQuickList, QuickListSkeleton} from 'views/goals_v2/QuickList';
import {GoalsTableFilter} from 'views/goals_v2/types';
import {getInitialJoinFilter} from 'views/goals_v2/utils';

import {GoalsJoinTable} from './GoalsJoinTable';
import {GoalsJoinTableHeader} from './GoalsJoinTableHeader';

export const getDefaultColumns = (): ColumnConfig[] => {
  return [
    {type: 'name', width: 550, fixed: true, paddingTop: '20'},
    {type: 'owners', width: 100, fixed: true, paddingTop: '20'},
    {type: '', width: 24, fixed: true, paddingTop: '20'},
  ];
};

export const GoalsJoinTableView = ({
  delegateLdap,
  delegateId,
}: {
  delegateLdap?: string;
  delegateId?: string;
}) => {
  const user = useAtomValue(pulseUserAtom);

  const {data, isLoading} = useProfile({
    ldap: delegateLdap ? delegateLdap : emailToLdap(user?.email),
  });

  if (isLoading || !user || !data) {
    return null;
  }

  return (
    <GoalsJoinTableComponent data={data} delegateLdap={delegateLdap} deelgateId={delegateId} />
  );
};

export const GoalsJoinTableComponent = ({
  data,
  delegateLdap,
  deelgateId,
}: {
  data: ProfilePage;
  delegateLdap?: string;
  deelgateId?: string;
}) => {
  const user = useAtomValue(pulseUserAtom);

  const {t} = useTranslation();
  const {columnConfigs, getMouseDownHandler} = useColumnResize(getDefaultColumns());

  const location = useLocation();

  const {reportingLine} = data ?? {};

  const pageTimeframe = location.state?.timeframe ?? DEFAULT_TIMEFRAME;

  const [timeframe, setTimeframe] = useState<string>(pageTimeframe);
  const [filter, setFilter] = useState<GoalsTableFilter>(
    getInitialJoinFilter(reportingLine && reportingLine.length > 1 ? [reportingLine[1].ldap] : [])
  );

  const [isFindClicked, setIsFindClicked] = useState(false);

  const handleSetTimeframe = (newTimeframe: string) => {
    setTimeframe(newTimeframe);
  };

  const handleSetFilter = (newFilter: GoalsTableFilter) => {
    setFilter(newFilter);
    // if in the new filter there is reporting line employees, then set the isFindClicked to false
    if (
      newFilter.people.length > 0 &&
      reportingLine &&
      reportingLine.filter((r) => r.ldap === newFilter.people[0]).length > 0
    ) {
      setIsFindClicked(false);
    }
  };

  const handleQuickFilterSelected = (ldap: string, isFindSelected: boolean) => {
    if (isFindSelected) {
      setFilter({
        ...filter,
        people: [],
        search: '',
      });
      setIsFindClicked(true);
      handlerAddFilterButtonClick();
    } else {
      setIsFindClicked(false);
      setFilter({
        ...filter,
        people: [ldap],
        search: '',
      });
    }
  };

  const filterButtonRef = useRef<WrapperRefObject>(null);

  const handlerAddFilterButtonClick = useCallback(() => {
    filterButtonRef.current?.focusTrigger();
    setTimeout(() => {
      const focusedElement = document.activeElement;
      if (focusedElement) {
        const spacebarKeydownEvent = new KeyboardEvent('keydown', {
          key: ' ',
          code: 'Space',
          keyCode: 32,
          charCode: 32,
          which: 32,
          bubbles: true,
          cancelable: true,
        });
        const spacebarKeyupEvent = new KeyboardEvent('keyup', {
          key: ' ',
          code: 'Space',
          keyCode: 32,
          charCode: 32,
          which: 32,
          bubbles: true,
          cancelable: true,
        });
        focusedElement.dispatchEvent(spacebarKeydownEvent);
        setTimeout(() => {
          focusedElement.dispatchEvent(spacebarKeyupEvent);
        }, 100); // Adjust the delay as needed
      }
    }, 0);
  }, []);

  const reportingLineString = JSON.stringify(reportingLine);
  useEffect(() => {
    setFilter(
      getInitialJoinFilter(reportingLine && reportingLine.length > 1 ? [reportingLine[1].ldap] : [])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportingLineString, deelgateId]);

  return (
    <Layout.Container>
      <Suspense fallback={<QuickListSkeleton />}>
        {!user ? (
          <QuickListSkeleton />
        ) : (
          <Box as="div" display="flex" style={{gap: '12px'}} flexDirection="column">
            <Box as="div" display="flex" style={{gap: '4px'}} flexDirection="column">
              <Box as={Text} size="xlarge" isBold variant="label" display="block">
                {t('select_objective_and_key_results_to_join')}
              </Box>
              <Box as={Text} size="large" variant="label" display="block" color="Text Subtle">
                {t('browse_by_people_and_choose_which_to_join')}
              </Box>
            </Box>
            <Box as="div">
              <JoinQuickList
                loggedInUser={user}
                filter={filter}
                isFindClicked={isFindClicked}
                handleQuickFilterClick={handleQuickFilterSelected}
                delegateLdap={delegateLdap}
              />
            </Box>
          </Box>
        )}
      </Suspense>

      <Box>
        <Suspense
          fallback={
            <Box paddingTop="12" paddingBottom="10">
              <Skeleton.Box width={200} height={32} />
            </Box>
          }
        >
          <GoalsJoinTableHeader
            goalsHeaderData={undefined}
            timeframe={timeframe}
            setTimeframe={handleSetTimeframe}
            filter={filter}
            setFilter={handleSetFilter}
            filterButtonRef={filterButtonRef}
            delegateLdap={delegateLdap}
          />
        </Suspense>
        <Suspense fallback={<TableSkeleton columnConfigs={columnConfigs} />}>
          <GoalsJoinTable
            filter={filter}
            columnConfigs={columnConfigs}
            onColumnDrag={getMouseDownHandler}
            timeframe={timeframe}
            handlerAddFilterButtonClick={handlerAddFilterButtonClick}
            delegateId={deelgateId}
            delegateLdap={delegateLdap}
          />
        </Suspense>
      </Box>
    </Layout.Container>
  );
};
