import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$insertNodeToNearestRoot} from '@lexical/utils';
import {$createVimeoNode, VimeoNode} from 'components/DSYS/editor/nodes/VimeoNode';
import {COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand} from 'lexical';
import {useEffect} from 'react';

export const INSERT_VIMEO_COMMAND: LexicalCommand<string> = createCommand('INSERT_VIMEO_COMMAND');

export const VimeoPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([VimeoNode])) {
      throw new Error('VimeoPlugin: VimeoNode not registered on editor');
    }

    return editor.registerCommand<string>(
      INSERT_VIMEO_COMMAND,
      (payload) => {
        const vimeoNode = $createVimeoNode(payload);
        $insertNodeToNearestRoot(vimeoNode);

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
};
