import {Text} from '@dropbox/dig-components/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {Twinkle2Line} from '@dropbox/dig-icons/assets';
import {ReactNode} from 'react';

export const DashboardsInfoMessage = ({message}: {message: ReactNode}) => {
  return (
    <Box display="flex" alignItems="center" style={{gap: '3px'}}>
      <UIIcon src={Twinkle2Line} size="small" color="var(--dig-color__text__subtle)" />
      <Text size="small">{message}</Text>
    </Box>
  );
};
