export enum OKREntryStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  STARTED = 'STARTED',
}

export enum OKREntryDetailStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum FOCUSED_OKR {
  OBJECTIVE = 'OBJECTIVE',
  KEY_RESULT = 'KEY_RESULT',
  ALL_SUCCESS = 'ALL_SUCCESS',
  ALL_FAILED = 'ALL_FAILED',
}

interface OKREntryDetail {
  title: string;
  description: string;
  status?: OKREntryDetailStatus;
  iconColor: string;
  imageName?: string;
}

export interface OKREntry {
  status: OKREntryStatus;
  details: OKREntryDetail[];
  title: string;
  description: string;
  suggestion?: string;
}

export type GeneratedObjectiveResult = {
  [x: string]: string;
};
