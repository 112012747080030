import {Breadcrumb} from '@dropbox/dig-components/breadcrumb';
import {Text} from '@dropbox/dig-components/typography';
import {Team} from 'client';
import {useHierarchySuspense} from 'components/teams/hooks';
import {createPath, To, useNavigate} from 'react-router-dom';

export interface BreadcrumbData {
  name: string;
  path?: To;
  isBold?: boolean;
}

const getStringValueOfPath = (path: To) => {
  return typeof path === 'string' ? path : createPath(path ?? {});
};

export const TeamBreadcrumbs = ({
  teamSlug,
  formatDashboardsBreadcrumbs,
  prefixBreadcrumbs,
  onClick,
}: {
  teamSlug: string;
  formatDashboardsBreadcrumbs: (teamHierarchy: Team[]) => BreadcrumbData[];
  prefixBreadcrumbs?: BreadcrumbData[];
  onClick?: (path: string) => void;
}) => {
  const team = useHierarchySuspense({slug: teamSlug});
  const teamHierarchy = team?.hierarchy || [];
  const breadcrumbs = formatDashboardsBreadcrumbs(teamHierarchy);
  return (
    <BreadcrumbsComponent
      breadcrumbs={breadcrumbs}
      prefixBreadcrumbs={prefixBreadcrumbs}
      onClick={onClick}
    />
  );
};

export const BreadcrumbsComponent = ({
  breadcrumbs,
  prefixBreadcrumbs = [],
  onClick,
}: {
  breadcrumbs: BreadcrumbData[];
  prefixBreadcrumbs?: BreadcrumbData[];
  onClick?: (path: string) => void;
}) => {
  const navigate = useNavigate();

  return (
    <Breadcrumb typography="text" size="small">
      {[...prefixBreadcrumbs, ...breadcrumbs].map((breadcrumb) => (
        <Breadcrumb.Link
          key={`breadcrumb__${breadcrumb.name}`}
          onClick={() => {
            const path = breadcrumb.path;
            if (path) {
              onClick?.(getStringValueOfPath(path));
              navigate(path);
            }
          }}
        >
          <Text color="subtle" isBold={breadcrumb.isBold}>
            {breadcrumb.name}
          </Text>
        </Breadcrumb.Link>
      ))}
    </Breadcrumb>
  );
};
