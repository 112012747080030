import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {DeleteLine, EditLine, MoreHorizontalLine} from '@dropbox/dig-icons/assets';
import {t} from 'i18next';

export const CommentMenu = ({
  commentId,
  handleSelection,
}: {
  commentId: string;
  handleSelection: (value: string) => void;
}) => {
  return (
    <Menu.Wrapper
      onSelection={handleSelection}
      shouldPropagateClickOutsideMouseEvents
      id="commentEditMenu"
    >
      {({getContentProps, getTriggerProps}) => (
        <>
          <IconButton {...getTriggerProps()} variant="borderless" shape="circular" size="small">
            <UIIcon src={MoreHorizontalLine} />
          </IconButton>
          <Menu.Content {...getContentProps()} minWidth="200px" id="commentEditAction">
            <Menu.Segment>
              <Menu.ActionItem
                key="edit"
                value={`edit:${commentId}`}
                withLeftAccessory={<Box as={UIIcon} src={EditLine} />}
              >
                {t('edit')}
              </Menu.ActionItem>
              <Menu.ActionItem
                key="delete"
                value={`delete:${commentId}`}
                withLeftAccessory={<Box as={UIIcon} src={DeleteLine} />}
              >
                {t('delete')}
              </Menu.ActionItem>
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};
