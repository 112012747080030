import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {ReactNode} from 'react';

export const AboutMeSection = ({title, children}: {title: string; children: ReactNode}) => (
  <Box>
    <Box marginBottom="8">
      <Text isBold>{title}</Text>
    </Box>
    <Box>{children}</Box>
  </Box>
);
