import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/dist/typography';
import {useQuery} from '@tanstack/react-query';
import {Badge} from 'client';
import {BadgeImage} from 'components/badges/BadgeImage';
import {NotificationCard} from 'components/notifications/NotificationCard';
import {useNotificationContext} from 'components/notifications/utils/useNotificationContext';
import {TimeAgo} from 'components/shared/TimeAgo';
import {t} from 'i18next';
import {Trans} from 'react-i18next';
import {getBadgesService} from 'utilities';

export const BadgeReceived = () => {
  const {
    notification: {archived, data, updated_at},
  } = useNotificationContext();
  const {content, data: badgeId, to} = data;
  const {data: badge} = useQuery<Badge>({
    queryKey: ['badge', badgeId],
    queryFn: () => getBadgesService().getBadgeApiV1BadgesIdGet(parseInt(badgeId, 10)),
  });

  return (
    <NotificationCard
      accessory={
        badge?.image_key ? (
          <BadgeImage badge={badge.image_key} size={24} />
        ) : (
          <Skeleton.Box height={24} style={{width: 24}} />
        )
      }
      header={
        <Trans
          i18nKey={content}
          t={t}
          values={{data: badge?.name ?? 'a badge'}}
          components={{b: <Text isBold />}}
        />
      }
      subtext={<TimeAgo timestamp={updated_at} />}
      to={to}
      archived={archived}
    />
  );
};
