import {useQuery, useSuspenseQuery} from '@tanstack/react-query';
import {EmployeeService} from 'client';
import {getService} from 'utilities';

export const useProfileSuspense = ({ldap}: {ldap: string}) => {
  const {data} = useSuspenseQuery({
    queryKey: ['profile', ldap],
    queryFn: () => {
      try {
        return getService(EmployeeService).getProfileApiV1PeopleUserIdGet(ldap);
      } catch (e) {
        return undefined;
      }
    },
  });

  return data;
};

export const useProfile = ({
  ldap,
  throwOnError = true,
}: {
  ldap?: string;
  throwOnError?: boolean;
}) => {
  const {data, isLoading} = useQuery({
    queryKey: ['profile', ldap],
    queryFn: () => getService(EmployeeService).getProfileApiV1PeopleUserIdGet(ldap ?? ''),
    throwOnError,
    enabled: !!ldap,
  });

  return {data: data, isLoading};
};
