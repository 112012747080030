import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {DRAG_DROP_PASTE} from '@lexical/rich-text';
import {snackbarAtom} from 'atoms/snackbar';
import {handleFiles} from 'components/DSYS/editor/nodes/fileUploadUtil';
import {t} from 'i18next';
import {useSetAtom} from 'jotai';
import {COMMAND_PRIORITY_LOW} from 'lexical';
import {useEffect} from 'react';
import {useMediaCreate} from 'views/news/hooks';

export const DragDropPastePlugin = () => {
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const [editor] = useLexicalComposerContext();
  const {uploadMedia} = useMediaCreate();

  useEffect(
    () =>
      editor.registerCommand(
        DRAG_DROP_PASTE,
        (files: File[]) => {
          const validFiles = files.filter((file) => file.size <= 6 * 1024 * 1024);

          if (validFiles.length !== files.length) {
            setSnackbarMessage({
              text: t('rich_text_upload_size_error'),
            });
          }

          handleFiles(validFiles, uploadMedia, editor);
          return true;
        },
        COMMAND_PRIORITY_LOW
      ),
    [editor, setSnackbarMessage, uploadMedia]
  );

  return null;
};
