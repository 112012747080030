import {WrapperRefObject} from '@dropbox/dig-components/dist/menu';
import {useTeamsMapSuspense} from 'components/teams/hooks';
import {RefObject, useEffect} from 'react';
import {NoFilterTable} from 'views/goals_v2/EmptyGoalsTable';
import {GoalsTablesComponent} from 'views/goals_v2/TableComponents/GoalsTablesComponent';
import {GoalsTablePropsWithoutData} from 'views/goals_v2/types';
import {hasPrimaryFiltersSelected} from 'views/goals_v2/utils';

export const OtherGoalsTableView = (
  tableProps: GoalsTablePropsWithoutData & {
    shouldOpenFilter: boolean;
    filterButtonRef: RefObject<WrapperRefObject>;
    handlerAddFilterButtonClick: () => void;
  }
) => {
  const {filter, filterButtonRef, handlerAddFilterButtonClick, shouldOpenFilter} = tableProps;
  const filterTeamSlugs = filter?.teams.map(({slug}) => slug ?? '').filter(Boolean) || [];
  const filterTeamsMap = useTeamsMapSuspense({slugs: filterTeamSlugs});
  const filterTeams = filterTeamSlugs.map((slug) => filterTeamsMap[slug]);

  useEffect(() => {
    if (shouldOpenFilter && filterButtonRef?.current) {
      handlerAddFilterButtonClick();
    }
  }, [filterButtonRef, handlerAddFilterButtonClick, shouldOpenFilter]);

  if (filter && !hasPrimaryFiltersSelected(filter)) {
    return <NoFilterTable onButtonClick={handlerAddFilterButtonClick} />;
  }

  const keyPrefix = 'other_goals';
  return <GoalsTablesComponent keyPrefix={keyPrefix} tableProps={tableProps} teams={filterTeams} />;
};
