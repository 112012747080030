import {Button, StylelessButton} from '@dropbox/dig-components/dist/buttons';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Cluster, Split, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ChevronDownLine} from '@dropbox/dig-icons/assets';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {derivedShowAIGoalAssistantDrawerAtom} from 'atoms/goals';
import {Employee, GoalCreate, GoalUpdate} from 'client';
import {useProfile} from 'hooks/useProfile';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {useMemo} from 'react';

import styles from './GoalPrivacyMenu.module.css';
import {countUniqueEmployee} from './utils';

export const GoalPrivacyMenu = ({
  goalCreateValues,
  handleVisibilitySelection,
  setShowCustomPrivacyModal,
  delegateId,
}: {
  goalCreateValues: GoalCreate | GoalUpdate;
  handleVisibilitySelection: (value: number) => void;
  setShowCustomPrivacyModal: (value: boolean) => void;
  delegateId?: string;
}) => {
  const {reportingLine, delegates} = useAtomValue(loggedInEmployeeAtom);
  const {data, isLoading} = useProfile({ldap: delegateId});

  if (!delegateId) {
    return (
      <GoalPrivacyMenuContent
        goalCreateValues={goalCreateValues}
        handleVisibilitySelection={handleVisibilitySelection}
        setShowCustomPrivacyModal={setShowCustomPrivacyModal}
        reportingLine={reportingLine}
        delegates={delegates || []}
      />
    );
  }

  if (isLoading) {
    return null;
  }

  return (
    <GoalPrivacyMenuContent
      goalCreateValues={goalCreateValues}
      handleVisibilitySelection={handleVisibilitySelection}
      setShowCustomPrivacyModal={setShowCustomPrivacyModal}
      reportingLine={data?.reportingLine || []}
      delegates={data?.delegates || []}
    />
  );
};

const GoalPrivacyMenuContent = ({
  goalCreateValues,
  handleVisibilitySelection,
  setShowCustomPrivacyModal,
  reportingLine,
  delegates,
}: {
  goalCreateValues: GoalCreate | GoalUpdate;
  handleVisibilitySelection: (value: number) => void;
  setShowCustomPrivacyModal: (value: boolean) => void;
  reportingLine: Employee[];
  delegates: Employee[];
}) => {
  const setShowAIGoalAssistantDrawer = useSetAtom(derivedShowAIGoalAssistantDrawerAtom);

  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowCustomPrivacyModal(true);
  };

  const teamCount = useMemo(
    () => goalCreateValues.team_privacies?.length ?? 0,
    [goalCreateValues.team_privacies?.length]
  );

  const employeeCount = useMemo(
    () =>
      countUniqueEmployee(goalCreateValues.individual_privacies || [], reportingLine, delegates),
    [delegates, goalCreateValues.individual_privacies, reportingLine]
  );

  return (
    <Menu.Wrapper
      onSelection={handleVisibilitySelection}
      onClick={() => setShowAIGoalAssistantDrawer(false)}
      // hasMaxHeight={68}
      style={{
        border: '1px solid var(--dig-color__border__subtle)',
        borderRadius: '8px',
        width: '100%',
      }}
    >
      {({getContentProps, getTriggerProps}) => (
        <>
          <Box
            {...getTriggerProps()}
            as={StylelessButton}
            cursor="pointer"
            paddingY="12"
            paddingX="16"
            textAlign="left"
            borderRadius="Medium"
            {...withShade({style: {width: 705}})}
          >
            <Box as={Split} width="100%" alignY="center">
              <Split.Item width="fill">
                <Box as={Stack} display="flex" align="start" width="100%">
                  <Text isBold>
                    {goalCreateValues.is_custom_privacy_included
                      ? t('custom')
                      : goalCreateValues.private
                      ? t('reporting_line')
                      : t('all_dropboxers')}
                  </Text>
                  <Cluster alignX="center" alignY="center" gap="4">
                    <Cluster.Item>
                      {goalCreateValues.is_custom_privacy_included ? (
                        <CustomPrivacySubtext employeeCount={employeeCount} teamCount={teamCount} />
                      ) : (
                        <Box as={Text} size="small" color="Text Subtle">
                          {goalCreateValues.private
                            ? t('restricted_subtitle')
                            : t('all_dropboxers_subtitle')}
                        </Box>
                      )}
                    </Cluster.Item>
                    {goalCreateValues.is_custom_privacy_included && (
                      <Cluster.Item>
                        <Button
                          variant="transparent"
                          size="small"
                          onClick={(e: React.MouseEvent) => handleEditClick(e)}
                          className={styles.editButton}
                        >
                          <Box as={Text} size="small" isBold variant="label">
                            {t('edit')}
                          </Box>
                        </Button>
                      </Cluster.Item>
                    )}
                  </Cluster>
                </Box>
              </Split.Item>
              <Split.Item>
                <UIIcon src={ChevronDownLine} color="var(--dig-color__text__subtle)" />
              </Split.Item>
            </Box>
          </Box>

          <Menu.Content
            {...getContentProps()}
            minWidth={739}
            triggerOffset={{mainAxis: -64, crossAxis: -2}}
          >
            <Menu.Segment>
              <Menu.SelectItem
                key={0}
                value={0}
                selected={!goalCreateValues.private && !goalCreateValues.is_custom_privacy_included}
                withTitle={
                  <Text isBold tagName="label">
                    {t('all_dropboxers')}
                  </Text>
                }
                withSubtitle={t('all_dropboxers_subtitle')}
              ></Menu.SelectItem>
              <Menu.SelectItem
                key={1}
                value={1}
                selected={goalCreateValues.private || undefined}
                withTitle={
                  <Text isBold tagName="label">
                    {t('reporting_line')}
                  </Text>
                }
                withSubtitle={t('restricted_subtitle')}
              ></Menu.SelectItem>
              <Menu.SelectItem
                key={2}
                value={2}
                selected={goalCreateValues.is_custom_privacy_included || undefined}
                withTitle={
                  <>
                    <Text isBold tagName="label">
                      {t('custom')}
                    </Text>
                  </>
                }
                withSubtitle={
                  goalCreateValues.is_custom_privacy_included ? (
                    <CustomPrivacySubtext employeeCount={employeeCount} teamCount={teamCount} />
                  ) : (
                    t('select_teams_or_people_who_may_view_align_towards_this_goal')
                  )
                }
              ></Menu.SelectItem>
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};

export const CustomPrivacySubtext = ({
  employeeCount,
  teamCount,
}: {
  employeeCount: number;
  teamCount: number;
}) => {
  return (
    <Box>
      <Box as={Text} size="small" color="Text Subtle">
        {t('people_count', {count: employeeCount})}
      </Box>
      {teamCount > 0 && (
        <>
          <Box as={Text} size="small" color="Text Subtle">
            {' '}
            {t('and')}{' '}
          </Box>
          <Box as={Text} size="small" color="Text Subtle">
            {t('team', {count: teamCount})}
          </Box>
        </>
      )}
      <Box as={Text} size="small" color="Text Subtle">
        {' '}
        {t('may_view_and_align_to_this_goal')}
      </Box>
    </Box>
  );
};
