import {DoorAwaySpot} from '@dropbox/dig-illustrations';
import {NonIdealState} from 'components/shared/NonIdealState';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';

const Waitlist = () => {
  useDocumentTitle('Waitlist');
  return (
    <NonIdealState
      image={<DoorAwaySpot />}
      title={t('you_are_waitlist')}
      description={t('we_will_let_you_know_once_you_get_access')}
    />
  );
};

export {Waitlist};
