import {atom} from 'jotai';

export interface StrategyItem {
  id: number;
  year: string;
  name: string;
  owners: {name: string; username: string}[];
  link: string;
  subStrategies?: StrategyItem[];
}

interface Strategies {
  [key: string]: StrategyItem[];
}

// Returns all strategies as a map of id -> StrategyItem
export const strategiesByIdAtom = atom((get) => {
  const strategies = get(strategiesAtom);

  const flattenedMap = new Map<number, StrategyItem>();

  Object.values(strategies).forEach((strategyItems: StrategyItem[]) => {
    strategyItems.forEach((strategy) => {
      flattenedMap.set(strategy.id, strategy);
      if (strategy.subStrategies) {
        strategy.subStrategies.forEach((subStrategy) => {
          flattenedMap.set(subStrategy.id, subStrategy);
        });
      }
    });
  });

  return flattenedMap;
});

export const strategiesAtom = atom<Strategies>({
  2025: [
    {
      id: 10,
      year: '2025',
      name: 'Company Strategy',
      owners: [{name: 'Drew Houston', username: 'drew'}],
      link: 'https://www.dropbox.com/scl/fi/heqjozdee8wxgvpqrsrcz/2025-Company-Strategy-Company.paper?rlkey=nil9lq0qma0qpe73kjpi90xoj&st=ehib3b8c&dl=0',
    },
    {
      id: 102,
      year: '2025',
      name: 'Core Narrative',
      owners: [{name: 'Ashraf Alkarmi', username: 'ashraf'}],
      link: 'https://www.dropbox.com/scl/fi/cogq5rcnvci6ranhpu3lc/2025-Core-Narrative-Company.paper?rlkey=7ya8bkxf2b5vthislthob0oph&st=fds4xtu0&dl=0',
    },
    {
      id: 101,
      year: '2025',
      name: 'OTC Narrative',
      owners: [{name: 'Manik Singh', username: 'maniksingh'}],
      link: 'https://www.dropbox.com/scl/fi/weo9cjb6kb1juyfssbaqj/2025-OTC-Narrative-Company.paper?rlkey=refoty8u2fhhs0y5hd8t2qkcg&dl=0',
    },
    {
      id: 103,
      year: '2025',
      name: 'FSS + Dash Narrative',
      owners: [{name: 'Ashraf Alkarmi', username: 'ashraf'}],
      link: 'https://www.dropbox.com/scl/fi/eegxx6t3h19xdvm8xxt3o/2025-FSS-Dash-Narrative-Company.paper?rlkey=5xfxwaz9fjcxbsxbogqidrnbb&dl=0',
    },
    {
      id: 104,
      year: '2025',
      name: 'GTM Pillar Narrative',
      owners: [{name: 'Eric Cox', username: 'ecox'}],
      link: 'https://www.dropbox.com/scl/fi/308mr9xml50e7r7z3v9e2/2025-GTM-Pillar-Narrative-Company.paper?rlkey=rwnzhj6wfc4p6u629uslt1uf9&st=awssf1mq&dl=0',
    },
    {
      id: 105,
      year: '2025',
      name: 'G&A Pillar Narrative',
      owners: [
        {name: 'Tim Regan', username: 'tregan'},
        {name: 'Melanie Rosenwasser', username: 'melanie'},
        {name: 'Bart Volkmer', username: 'bart'},
        {name: 'Charlotte Hunt', username: 'charlottehunt'},
      ],
      link: 'https://www.dropbox.com/scl/fi/e58my2q0jv3trim4il98n/2025-GA-Pillar-Narrative-Company.paper?rlkey=1toso6zcn3cszehra6l2j32fy&dl=0',
    },
    {
      id: 106,
      year: '2025',
      name: 'Tech Pillar Narrative',
      owners: [
        {name: 'Chase Seibert', username: 'cseibert'},
        {name: 'Ali Zafar', username: 'azafar'},
        {name: 'Josh Clemm', username: 'jclemm'},
      ],
      link: 'https://www.dropbox.com/scl/fi/ry4sz4muecbsp4s63vrrc/2025-Tech-Pillar-Narrative-Company.paper?rlkey=8vns9fz5561m2pv4x92xkwuqc&dl=0',
    },
  ],
  2024: [
    {
      id: 10,
      year: '2024',
      name: '2024 Company Strategy Narrative',
      owners: [{name: 'Drew Houston', username: 'drew'}],
      link: 'https://www.dropbox.com/scl/fi/7q098fjjfx6hknhliypxd/2024-Company-Strategy-Narrative-Company.paper?rlkey=d0nvzlbeij4unv3ewlx5cp92b&dl=0',
      subStrategies: [
        {
          id: 11,
          year: '2024',
          name: 'Scale Dash Overlay',
          owners: [{name: 'Sateesh Srinivasan', username: 'ssrinivasan'}],
          link: 'https://www.dropbox.com/scl/fi/y77cb08n8ebvn7ycchqyg/2024-Scale-Dash-Overlay-Company.paper?rlkey=k74crsaq1sr661dj6ch70570y&dl=0',
        },
        {
          id: 12,
          year: '2024',
          name: 'Unlock Teams Overlay',
          owners: [{name: 'Ran Chen', username: 'ranchen'}],
          link: 'https://www.dropbox.com/scl/fi/p1dkwdjw3a874gyhb3eux/2024-Unlock-Teams-Overlay-Company.paper?rlkey=j5ds94x10lnhvf0yai60l36f8&dl=0',
        },
        {
          id: 13,
          year: '2024',
          name: 'Bridge from FSS to Dash Overlay',
          owners: [{name: 'Frances Klein', username: 'fklein'}],
          link: 'https://www.dropbox.com/scl/fi/lix5fdhec505t3z8b7a1p/2024-Bridge-from-FSS-to-Dash-Overlay-Company.paper?rlkey=ichn0oeohog4qi3t4z9hrhrsq&dl=0',
        },
        {
          id: 14,
          year: '2024',
          name: 'Reboot Customer Focus Overlay',
          owners: [{name: 'Eric Cox', username: 'ecox'}],
          link: 'https://www.dropbox.com/scl/fi/nlf1a1l4wjjxqrbw1xq0w/2024-Reboot-Customer-Focus-Overlay-Company.paper?rlkey=1k3r304c3n0nae985sm6fec9l&dl=0',
        },
        {
          id: 15,
          year: '2024',
          name: 'Level Up EPD Overlay',
          owners: [{name: 'Mila Richman', username: 'mila'}],
          link: 'https://www.dropbox.com/scl/fi/5b0lyb6k3vnphw1800o96/2024-Level-Up-EPD-Overlay-Company.paper?rlkey=0twrgpj04e8a1w5hxrjgg87fc&dl=0',
        },
      ],
    },
    {
      id: 101,
      year: '2024',
      name: '[OTC] 2024 Strategy Narrative',
      owners: [{name: 'Sateesh Srinivasan', username: 'ssrinivasan'}],
      link: 'https://www.dropbox.com/scl/fi/f0x4q2ier8hqum0nhw047/OTC-2024-Strategy-Narrative.paper?rlkey=7v3dde61xd3m52s6zl9yoe2oj&dl=0',
      subStrategies: [
        {
          id: 1012,
          year: '2024',
          name: 'OTC - Build Dash, Dash for Business, and Unified Desktop [KI 1,2,5]',
          owners: [{name: 'Manik Singh', username: 'maniksingh'}],
          link: 'https://www.dropbox.com/scl/fi/dggws9htu6j0atw48lqss/OTC-Build-Dash-Dash-for-Business-and-Unified-Desktop-KI-125.paper?rlkey=rel1dstcg9dwr4ikvvdk269g3&dl=0',
        },
        {
          id: 1013,
          year: '2024',
          name: 'OTC - Scale and Monetize Dash [KI4]',
          owners: [{name: 'Gustavo Sanchez', username: 'gustavosanchez'}],
          link: 'https://www.dropbox.com/scl/fi/4tx0f9glw9tn9q5hjqivr/OTC-Scale-and-Monetize-Dash-KI-4.paper?rlkey=jtz5wabpeb6ufjb5468vsc33i&dl=0',
        },
      ],
    },
    {
      id: 102,
      year: '2024',
      name: '[Core] 2024 Strategy Narrative',
      owners: [{name: 'Lyndsey Bauers', username: 'lyndsey'}],
      link: 'https://www.dropbox.com/scl/fi/dymvtittn8vjvvlwriqhq/Core-2024-Strategy-Narrative-Company.paper?rlkey=jyhwqnos8jleumszosgym95ep&dl=0',
      subStrategies: [
        {
          id: 1023,
          year: '2024',
          name: 'Core - Help Customers Share [KI1]',
          owners: [{name: 'Kevin Sung', username: 'kevinsung'}],
          link: 'https://www.dropbox.com/scl/fi/x8gtledu4ep8kjgr09hc4/Core-Help-Customers-Share-KI1-Company.paper?rlkey=nvf9fkoog2ev0h98ryzqg4tb3&dl=0',
        },
        {
          id: 1027,
          year: '2024',
          name: 'Core - Multi-Product Enablement [KI2]',
          owners: [{name: 'Frances Klein', username: 'fklein'}],
          link: 'https://www.dropbox.com/scl/fi/p0ptv4cfh7j1lhxskepb0/Core-Multi-Product-Enablement-KI2-Company.paper?rlkey=y5s1oikmv83odyuvk3oc9b5ay&dl=0',
        },
        {
          id: 1026,
          year: '2024',
          name: 'Core - Grow Teams Expansion [KI3]',
          owners: [{name: 'Ran Chen', username: 'ranchen'}],
          link: 'https://www.dropbox.com/scl/fi/x3qf4ij1vaqssyfdov2yh/Core-Grow-Teams-Expansion-KI3-Company.paper?rlkey=gwdt0inlvnhymgb969szx2yr0&dl=0',
        },
        {
          id: 1028,
          year: '2024',
          name: 'Core - Enhance FSS Usability [KI4]',
          owners: [{name: 'Lainie Bradley', username: 'lbradley'}],
          link: 'https://www.dropbox.com/scl/fi/v50npgb9ludi9owbygwtp/Core-Enhance-FSS-Usability-KI4-Company.paper?rlkey=oj88wj0e37h2n1f7vkeselee5&dl=0',
        },
        {
          id: 1025,
          year: '2024',
          name: 'Core - Optimize Team Admin Experience [KI5]',
          owners: [{name: 'Kaylee Prior', username: 'kaylee'}],
          link: 'https://www.dropbox.com/scl/fi/1w2kspdbiwucrgguixz7h/Core-Optimize-Team-Admin-Experience-KI5-Company.paper?rlkey=oj6bq5h86ia28n5x1cxhy7eq6&dl=0',
        },
        {
          id: 1029,
          year: '2024',
          name: 'Core - Sign and FormSwift Revenue & Profitability [KI6]',
          owners: [{name: 'Frances Klein', username: 'fklein'}],
          link: 'https://www.dropbox.com/scl/fi/729xeq6367jqa5bcclrpm/Core-Sign-and-FormSwift-Revenue-Profitability-KI6-Company.paper?rlkey=0mqtsiw6pazrgpvlvewmsy8y1&dl=0',
        },
        {
          id: 1024,
          year: '2024',
          name: 'Core - Engineering',
          owners: [{name: 'Greg Conklin', username: 'gconklin'}],
          link: 'https://www.dropbox.com/scl/fi/yraep1yxqfjwvttroay27/Core-Engineering-Company.paper?rlkey=a1v4r0qjnrm9zxoc0eo0kqejg&dl=0',
        },
        {
          id: 1021,
          year: '2024',
          name: 'Core - Data Science & Engineering',
          owners: [{name: 'Ashwini Chaube', username: 'achaube'}],
          link: 'https://www.dropbox.com/scl/fi/3jth6aqzerfcrq6x7zdnt/Core-Data-Science-and-Engineering-Narrative-Company.paper?rlkey=ebd4nwbje16ryfc72jrk8bfha&dl=0',
        },
      ],
    },
    {
      id: 103,
      year: '2024',
      name: '[Technology] 2024 Strategy Narrative',
      owners: [{name: 'Stephen Voorhees', username: 'voorhees'}],
      link: 'https://www.dropbox.com/scl/fi/owx05xn6zh7kz6k0np0cv/Technology-2024-Strategy-Narrative-Company.paper?rlkey=vuw6vt2r30mjdtjk9ygfjr17g&dl=0',
      subStrategies: [
        {
          id: 1037,
          year: '2024',
          name: 'Technology - Unified API Platform [KI1]',
          owners: [{name: 'Uma Namasivayam', username: 'unamasivayam'}],
          link: 'https://www.dropbox.com/scl/fi/s5tqgn2jsxwm7yw5gyw5t/Technology-Unified-API-KI1.paper?rlkey=j4g6dxgrwwbxee1gta238nskj&dl=0#',
        },
        {
          id: 1034,
          year: '2024',
          name: 'Technology - Multi-Product [KI2]',
          owners: [{name: 'Matt Jezorek', username: 'mattj'}],
          link: 'https://www.dropbox.com/scl/fi/vv80jgfwgwi26v0i9pbda/Technology-Multi-Product-KI2-Company.paper?rlkey=7nxnlq0ef1mls09a5vvvh75zn&dl=0',
        },
        {
          id: 1033,
          year: '2024',
          name: 'Technology - Data Platform and Governance [KI3]',
          owners: [{name: 'Jeremy Johnson', username: 'jeremyj'}],
          link: 'https://www.dropbox.com/scl/fi/e1s6h3f3yj4wsylbqfcq8/Technology-Data-Platform-and-Governance-KI3-Company.paper?rlkey=ip97gofa4q8c54mez535ed2ks&dl=0',
        },
        {
          id: 1032,
          year: '2024',
          name: 'Technology - Frontend Foundation & Developer Experience [KI4]',
          owners: [{name: 'Colin Hostert', username: 'chostert'}],
          link: 'https://www.dropbox.com/scl/fi/wm1phq4hfv27q6exvipl2/Technology-Frontend-Foundations-and-Developer-Experience-KI-4-Company.paper?rlkey=a0wp3ddug7j0ojclg0h9k6toe&dl=0',
        },
        {
          id: 1035,
          year: '2024',
          name: 'Technology - Boost Productivity [KI5]',
          owners: [{name: 'Sheila Wakida', username: 'swakida'}],
          link: 'https://www.dropbox.com/scl/fi/hfoydj4a88sl4yju6y2zx/Technology-Boost-Productivity-KI5-Company.paper?rlkey=lfr305hqheiemz8kfxtisnzj9&dl=0',
        },
        {
          id: 1031,
          year: '2024',
          name: 'Technology - Hybrid Infrastructure',
          owners: [{name: 'Ali Zafar', username: 'azafar'}],
          link: 'https://www.dropbox.com/scl/fi/ixus8fnw0swq18uudvf3w/Technology-Hybrid-Infrastructure-Company.paper?rlkey=t1g40pntod525f5qroq394nan&dl=0',
        },
        {
          id: 1036,
          year: '2024',
          name: 'Technology - Platform',
          owners: [{name: 'Stephen Voorhees', username: 'voorhees'}],
          link: 'https://www.dropbox.com/scl/fi/gtx5c7m2oz30h7a8coesz/Technology-Platform-Company.paper?rlkey=42xo2ljaqqockrfmmu5xqpfsl&dl=0',
        },
      ],
    },
    {
      id: 104,
      year: '2024',
      name: '[GTM] 2024 Strategy Narrative',
      owners: [{name: 'Eric Cox', username: 'ecox'}],
      link: 'https://www.dropbox.com/scl/fi/a5bcj96xr0a84zfvpo8a0/GTM-2024-Strategy-Narrative-Company.paper?rlkey=x6xqwl345e03ka68w9qhu6kg3&dl=0',
      subStrategies: [
        {
          id: 1044,
          year: '2024',
          name: 'GTM - Marketing [KI 1,2,5]',
          owners: [{name: 'Saman Asheer', username: 'saman'}],
          link: 'https://www.dropbox.com/scl/fi/9zbw8o2mee7yl15s9jafx/GTM-Marketing-KI-1-2-5-Company.paper?rlkey=2kzo28nf1hsn7hfol7xsknfob&dl=0',
        },
        {
          id: 1042,
          year: '2024',
          name: 'GTM - Global Sales and Channel [KI 3,5]',
          owners: [{name: 'Debbie McClure', username: 'dmcclure'}],
          link: 'https://www.dropbox.com/scl/fi/rl4qyi480zyx8iq7gdrpl/GTM-Global-Sales-and-Channel-KI-3-5-Company.paper?rlkey=es9fluvwfjp4pr0l7pss881on&dl=0',
        },
        {
          id: 1047,
          year: '2024',
          name: 'GTM - Commercial Stratgey & Operations [KI 4]',
          owners: [{name: 'Grant Clarke', username: 'gclarke'}],
          link: 'https://www.dropbox.com/scl/fi/yxxszs7w1vgti1p1gs050/GTM-Commercial-Strategy-Operations-KI-4-Company.paper?rlkey=x4nt1rp3ved0l04foi8kpjrso&dl=0',
        },
        {
          id: 1046,
          year: '2024',
          name: 'GTM - Customer Experience',
          owners: [{name: 'Steve Yager', username: 'syager'}],
          link: 'https://www.dropbox.com/scl/fi/ml63nhwl8ormwc4x1oe8u/GTM-Customer-Experience-Company.paper?rlkey=o840f64ablpgulf9ejgsm1kw1&dl=0',
        },
        {
          id: 1047,
          year: '2024',
          name: 'GTM - Business Development',
          owners: [{name: 'Aladdin Edris', username: 'aedris'}],
          link: 'https://www.dropbox.com/scl/fi/aok8x998l9d0vra77tu4c/GTM-Business-Development-Company.paper?rlkey=d7luzypuc79bfpqcafxbe0d1e&dl=0',
        },
      ],
    },
    {
      id: 105,
      year: '2024',
      name: '[G&A] 2024 Strategy Narrative',
      owners: [{name: 'Tim Regan', username: 'tregan'}],
      link: 'https://www.dropbox.com/scl/fi/cmamen74095f16ys9fsa1/G-A-2024-Narrative-Company.paper?rlkey=90tskl05mi0shkltliccoqx2c&dl=0',
      subStrategies: [
        {
          id: 1056,
          year: '2024',
          name: 'G&A - Controller [KI1]',
          owners: [{name: 'Sarah Schubach', username: 'sarahs'}],
          link: 'https://www.dropbox.com/scl/fi/s89ihs50k2tpxfl1d4whh/G-A-Controller-KI1-Company.paper?rlkey=k43biwgc1fbg96yp1acp8jerl&dl=0',
        },
        {
          id: 1052,
          year: '2024',
          name: 'G&A - Company Strategy & Operations [KI2]',
          owners: [{name: 'Charlotte Hunt', username: 'charlottehunt'}],
          link: 'https://www.dropbox.com/scl/fi/ssrjixs5qkzy64c1iuy5y/G-A-Company-Strategy-Operations-KI2-Company.paper?rlkey=m9tt7ey7up0gzpam43qfemppa&dl=0',
        },
        {
          id: 1057,
          year: '2024',
          name: 'G&A - Chief Finance Officer [KI3]',
          owners: [{name: 'Tim Regan', username: 'tregan'}],
          link: 'https://www.dropbox.com/scl/fi/lswlmt5fykqylvfor19k0/G-A-CFO-KI3-Company.paper?rlkey=bt3dmgcwmmuemcmd6imv0kfrj&dl=0',
        },
        {
          id: 1055,
          year: '2024',
          name: 'G&A - Be the Glue For Upleveling EPD [KI4]',
          owners: [{name: 'Mila Richman', username: 'mila'}],
          link: 'https://www.dropbox.com/scl/fi/yqnwjzmj3au4cg3hmxh84/G-A-Be-The-Glue-For-Leveling-Up-EPD-KI4-Company.paper?rlkey=dcgbb24lfi5rxu8wsxc56zkfs&dl=0',
        },
        {
          id: 1051,
          year: '2024',
          name: 'G&A - Virtual First [KI5]',
          owners: [{name: 'Allison Vendt', username: 'avendt'}],
          link: 'https://www.dropbox.com/scl/fi/gcs0ynewm69uhrrvvob7g/G-A-Virtual-First-KI5-Company.paper?rlkey=8dk47haplu49jqakgk3g9o83k&dl=0',
        },
        {
          id: 1051,
          year: '2024',
          name: 'G&A - Chief Legal Officer',
          owners: [{name: 'Bart Volkmer', username: 'bart'}],
          link: 'https://www.dropbox.com/scl/fi/gxo2xdzfn90rkj07n1940/G-A-Chief-Legal-Officer-Company.paper?rlkey=cawfg91vo5sp4gxphthwhqqrj&dl=0',
        },
        {
          id: 1053,
          year: '2024',
          name: 'G&A - People Team',
          owners: [{name: 'Eric Trickett', username: 'etrickett'}],
          link: 'https://www.dropbox.com/scl/fi/b42raw9zwh38o4hag5nz7/G-A-People-Team-Company.paper?rlkey=92uc9bhhmg9665vw8iv59tcya&dl=0',
        },
        {
          id: 1054,
          year: '2024',
          name: 'G&A - FP&A',
          owners: [{name: 'Lisa Cummings', username: 'lcummings'}],
          link: 'https://www.dropbox.com/scl/fi/wzn15pcuwgbqp0t0cg0ae/2024-G-A-FP-A-Narrative-Company.paper?rlkey=5fxbtsa13zhl1rv2opr8i27bo&dl=0',
        },
        {
          id: 1058,
          year: '2024',
          name: 'G&A - Finance Org',
          owners: [{name: 'Thom Rose', username: 'thom'}],
          link: 'https://www.dropbox.com/scl/fi/okahxcjaq60735i06djfm/G-A-Finance-Org-Company.paper?rlkey=0riu2ibg4rqca3qt0a2aewv5r&dl=0',
        },
        {
          id: 1059,
          year: '2024',
          name: 'G&A - DEI',
          owners: [{name: 'Maria Kelts', username: 'kelts'}],
          link: 'https://www.dropbox.com/scl/fi/l64pe7bttiz2tqhlvkp8v/G-A-DEI-Company.paper?rlkey=ysfgx0kwlpgi0dw5djj2mlciu&dl=0',
        },
      ],
    },
    {
      id: 106,
      year: '2024',
      name: '2024 Design Narrative',
      owners: [{name: 'Alastair Simpson', username: 'alastair'}],
      link: 'https://www.dropbox.com/scl/fi/r3zh6g213h75ooqtbxkh4/Design-2024-Narrative.paper?rlkey=4sqqpp3xnu5tqcszjn3wkbccr&dl=0',
    },
  ],
});
