import {EmployeeFull, PulseGrouping, Team} from 'client';
import {TableSort} from 'components/shared/table/Header';

export const sortTable =
  (sort: TableSort, employee: EmployeeFull, teams: Team[]) =>
  (a: PulseGrouping, b: PulseGrouping) => {
    const direction = sort.asc ? 1 : -1;

    const pulseGroupA = toPulseGroup(a, employee, teams);
    const pulseGroupB = toPulseGroup(b, employee, teams);

    // Always sort self to the top
    if (pulseGroupA.self && !pulseGroupB.self) {
      return -1;
    }
    if (pulseGroupB.self && !pulseGroupA.self) {
      return 1;
    }

    // Always sort 0 responses to bottom
    if (!a.responses_count && b.responses_count) {
      return 1;
    }
    if (a.responses_count && !b.responses_count) {
      return -1;
    }

    if (sort.type === 'name') {
      return direction * pulseGroupA.name.localeCompare(pulseGroupB.name);
    }

    if (sort.type === 'esat') {
      // If responses <= 4, then sort to bottom
      if (a.responses_count <= 4 && b.responses_count > 4) {
        return 1;
      }
      if (a.responses_count > 4 && b.responses_count <= 4) {
        return -1;
      }
      if (a.responses_count <= 4 && b.responses_count <= 4) {
        return 0;
      }

      return (
        direction *
        ((a.metrics.find((metric) => metric.type !== 'text-input')?.metric ?? 0) -
          (b.metrics.find((metric) => metric.type !== 'text-input')?.metric ?? 0))
      );
    }

    if (sort.type === 'participation') {
      if (a.employee_count === 0 && b.employee_count !== 0) {
        return 1;
      }
      if (a.employee_count !== 0 && b.employee_count === 0) {
        return -1;
      }
      if (a.employee_count === 0 && b.employee_count === 0) {
        return 0;
      }

      return (
        direction * (a.responses_count / a.employee_count - b.responses_count / b.employee_count)
      );
    }

    return 0;
  };

export const toPulseGroup = (
  grouping: Pick<PulseGrouping, 'subteam' | 'manager' | 'team' | 'pillar'>,
  employee?: EmployeeFull,
  teams?: Team[]
) => ({
  type: grouping.subteam
    ? 'subteam'
    : grouping.manager
    ? 'manager'
    : grouping.team
    ? 'team'
    : 'pillar',
  self:
    (employee?.subteam_id && employee.subteam_id === grouping.subteam?.id) ||
    (employee?.manager_id && employee.manager_id === grouping.manager?.user_id) ||
    teams?.some((team) => team.team_id === grouping.team?.team_id),
  id:
    grouping.subteam?.id ?? grouping.manager?.user_id ?? grouping.team?.team_id ?? grouping.pillar,
  name: grouping.subteam?.name ?? grouping.manager?.name ?? grouping.team?.name ?? grouping.pillar,
  slug: grouping.subteam?.slug ?? grouping.manager?.ldap ?? grouping.team?.slug ?? grouping.pillar,
  image: grouping.manager?.profile_photo_url ?? '',
});
