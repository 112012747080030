import {Button} from '@dropbox/dig-components/dist/buttons';
import {Checkbox} from '@dropbox/dig-components/dist/controls';
import {FormHelperText, FormLabel, FormRow} from '@dropbox/dig-components/dist/form_row';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {TextArea, TextInput} from '@dropbox/dig-components/dist/text_fields';
import {snackbarAtom} from 'atoms/snackbar';
import {Badge, Employee} from 'client';
import {PeopleSearchMenu} from 'components/shared/PeopleSearchMenu';
import {useSelectEmployees} from 'hooks/useSelectEmployee';
import {t} from 'i18next';
import {useSetAtom} from 'jotai';
import {FunctionComponent, useState} from 'react';
import {getBadgesService} from 'utilities';

interface Props {
  badge: Badge;
  owners: Employee[];
  onSubmit: () => void;
  onCancel: () => void;
}

export const UpdateBadgesForm: FunctionComponent<Props> = ({badge, owners, onSubmit, onCancel}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [hidden, setHidden] = useState(badge.hidden_from_library);
  const [name, setName] = useState(badge.name);
  const [description, setDescription] = useState(badge.description);
  const [selectedEmployees, selectEmployee, selectEmployees, removeEmployee] =
    useSelectEmployees(owners);

  const setSnackbarMessage = useSetAtom(snackbarAtom);
  return (
    <>
      <Modal.Body>
        <FormRow>
          <FormLabel htmlFor="image">{t('badge_image')}</FormLabel>
          <FormHelperText>{t('badge_image_helper')}</FormHelperText>
          <input
            name="image"
            type="file"
            onChange={(event) => {
              if (event.target.files) {
                setSelectedFile(event.target.files[0]);
              }
            }}
          />
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="name">{t('badge_name')}</FormLabel>
          <FormHelperText>{t('badge_name_helper')}</FormHelperText>
          <TextInput
            name="name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            maxLength={40}
            placeholder={t('badge_name_placeholder')}
            autoComplete="off"
          />
        </FormRow>
        <FormRow>
          <FormLabel htmlFor="description">{t('badge_description')}</FormLabel>
          <FormHelperText>{t('badge_description_helper')}</FormHelperText>
          <TextArea
            name="description"
            value={description}
            onChange={(e) => setDescription(e.currentTarget.value)}
            maxLength={300}
            placeholder={t('badge_description_placeholder')}
            spellCheck="true"
          />
        </FormRow>
        <FormRow>
          <FormLabel>{t('badge_managed_by')}</FormLabel>
          <FormHelperText>{t('badge_managed_by_helper')}</FormHelperText>
          {owners ? (
            <PeopleSearchMenu
              selectedEmployees={selectedEmployees}
              onRemoveEmployee={removeEmployee}
              onSelectEmployee={selectEmployee}
              onSelectEmployees={selectEmployees}
              allowSelf
            />
          ) : null}
        </FormRow>
        <FormRow>
          <FormLabel>{t('hide_badge')}</FormLabel>
          <Checkbox
            aria-labelledby="hidden_from_library"
            onChange={(e) => setHidden(e.target.checked)}
            checked={hidden}
          />
        </FormRow>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="opacity" onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            const badgeInfoUpdate = getBadgesService().updateBadgeApiV1BadgesIdPost(badge.id, {
              name,
              description,
              hidden_from_library: hidden,
              owner_ids: selectedEmployees.map((owner) => owner.ldap),
            });
            const updates = [badgeInfoUpdate];
            if (selectedFile) {
              updates.push(
                getBadgesService().updateBadgeImageApiV1BadgesIdImagePost(badge.id, {
                  file: selectedFile,
                })
              );
            }
            Promise.all(updates).then(() => {
              setSnackbarMessage({text: t('saved')});
              onSubmit();
            });
          }}
        >
          {t('save')}
        </Button>
      </Modal.Footer>
    </>
  );
};
