import {Table} from '@dropbox/dig-components/dist/table';
import {Box, BoxProps} from '@dropbox/dig-foundations';
import {Goal, GoalRead, GoalUser, KeyResult, Update} from 'client';
import {GoalStatusMenu} from 'views/goals_v2/GoalStatusMenu';
import {TeamInfo} from 'views/goals_v2/types';

type ActionMenuBoxProps = {
  user: GoalUser;
  source: string;
  goal?: Goal | GoalRead;
  keyResult?: KeyResult;
  canDelete: boolean;
  teamInfo?: TeamInfo;
  parentGoalUser?: GoalUser;
  ownerLdap?: string;
  latestUpdate?: Update;
  isDraftRow: boolean;
  timeframe: string;
  nextTimeframe: string;
  setNextTimeframe: (timeframe: string) => void;
  setIsStatusModalOpen?: (isOpen: boolean) => void;
  variant: 'outline' | 'borderless';
  paddingTop?: BoxProps<'div'>['paddingTop'];
  marginLeft?: BoxProps<'div'>['marginLeft'];
  onDeleteCallback?: () => void;
  onContributorCallback: () => void;
  onContinueGoalCallback: (selectedStatus: string) => void;
};

export const ActionMenuCell = (props: ActionMenuBoxProps) => {
  return (
    <Table.Cell verticalAlign="top">
      <ActionMenuBox {...props} paddingTop={props.canDelete ? '8' : '12'} />
    </Table.Cell>
  );
};

export const ActionMenuBox = ({
  user,
  source,
  goal,
  keyResult,
  canDelete,
  teamInfo,
  parentGoalUser,
  ownerLdap,
  latestUpdate,
  isDraftRow,
  timeframe,
  nextTimeframe,
  setNextTimeframe,
  setIsStatusModalOpen,
  variant,
  paddingTop,
  marginLeft,
  onDeleteCallback,
  onContributorCallback,
  onContinueGoalCallback,
}: ActionMenuBoxProps) => {
  return (
    <Box paddingTop={paddingTop} marginLeft={marginLeft}>
      <GoalStatusMenu
        source={source}
        user={user}
        goal={goal}
        keyResult={keyResult}
        canDelete={canDelete}
        teamInfo={teamInfo}
        parentGoalUser={parentGoalUser}
        ownerLdap={ownerLdap}
        latestUpdate={latestUpdate}
        isDraftRow={isDraftRow}
        timeframe={timeframe}
        nextTimeframe={nextTimeframe}
        setNextTimeframe={setNextTimeframe}
        setIsStatusModalOpen={setIsStatusModalOpen}
        variant={variant}
        onDeleteCallback={onDeleteCallback}
        onContributorCallback={onContributorCallback}
        onContinueGoalCallback={onContinueGoalCallback}
      />
    </Box>
  );
};
