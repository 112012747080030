import {Drawer} from '@dropbox/dig-components/dist/drawer';
import {Box} from '@dropbox/dig-foundations';
import {UIIconProps} from '@dropbox/dig-icons';
import {isMobileAtom} from 'atoms/layout';
import cx from 'classnames';
import {useAtomValue} from 'jotai';
import {Children, createContext, ReactNode, useContext} from 'react';

import {DrawerContainer} from './DrawerContainer';
import styles from './Layout.module.css';

type GridSize = 'condensed' | 'full';

const GridContext = createContext<{
  withNav: boolean;
}>({withNav: false});

const Container = ({size = 'full', children}: {size?: GridSize; children: ReactNode}) => {
  const withNav = Children.toArray(children).some((child: any) => child.type === Nav);
  const hasHelper = Children.toArray(children).some((child: any) =>
    [Nav, Body, Column].includes(child.type)
  );

  const isMobile = useAtomValue(isMobileAtom);

  if (!hasHelper) {
    if (size === 'full') {
      return (
        <Box
          marginX="auto"
          style={{
            maxWidth: 1240,
          }}
        >
          {children}
        </Box>
      );
    } else if (size === 'condensed') {
      return (
        <Box
          marginX="auto"
          style={{
            display: 'grid',
            columnGap: 16,
            gridTemplateColumns: `repeat(7, 1fr)`,
            maxWidth: 1240,
          }}
        >
          <Column index={isMobile ? 0 : 1} span={isMobile ? 7 : 5}>
            {children}
          </Column>
        </Box>
      );
    }
    //  else if (size === 'condensed') {
    //   return (
    //     <Box
    //       marginX="auto"
    //       style={{
    //         display: 'grid',
    //         columnGap: 16,
    //         gridTemplateColumns: `repeat(5, 1fr)`,
    //         maxWidth: 1240,
    //       }}
    //     >
    //       <Column index={isMobile ? 0 : 1} span={isMobile ? 7 : 3}>
    //         {children}
    //       </Column>
    //     </Box>
    //   );
    // }

    return (
      <Box
        marginX="auto"
        style={{
          display: 'grid',
          columnGap: 16,
          gridTemplateColumns: `repeat(5, 1fr)`,
          maxWidth: 1240,
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <GridContext.Provider value={{withNav}}>
      <Box
        marginX="auto"
        style={{
          display: 'grid',
          columnGap: 16,
          gridTemplateColumns: `repeat(${!withNav ? 5 : 7}, 1fr)`,
          maxWidth: 1240,
        }}
      >
        {children}
      </Box>
    </GridContext.Provider>
  );
};

const Nav = ({children}: {children: ReactNode}) => {
  const isMobile = useAtomValue(isMobileAtom);

  return (
    <Box
      marginBottom={isMobile ? '16' : undefined}
      style={{gridColumn: `1 / span ${isMobile ? 7 : 2}`}}
    >
      {children}
    </Box>
  );
};

const Body = ({children, marginLeft = 40}: {marginLeft?: number; children: ReactNode}) => {
  const {withNav} = useContext(GridContext);
  const isMobile = useAtomValue(isMobileAtom);

  if (!withNav) {
    throw new Error('Body must be a child of a Container with a Nav');
  }

  return (
    <Box
      style={{
        marginLeft,
        gridColumn: `${isMobile ? 1 : withNav ? 3 : 2} / span ${isMobile ? 7 : withNav ? 5 : 3}`,
      }}
    >
      {children}
    </Box>
  );
};

/** Prefer other components to this one */
const Column = ({
  span,
  index,
  // preferComposition,
  children,
}: {
  span: 1 | 2 | 3 | 5 | 7;
  index?: number;
  // preferComposition?: boolean;
  children: ReactNode;
}) => {
  const {withNav} = useContext(GridContext);
  const size = withNav ? 7 : 5;
  const startColumn = index !== undefined ? index + 1 : Math.floor((size - span) / 2) + 1;

  return (
    <Box
      // paddingX={preferComposition ? undefined : '16'}
      style={{gridColumn: `${startColumn} / span ${span}`}}
    >
      {children}
    </Box>
  );
};

// Don't use this one, prefer InlineDrawerContainer
export const DIGInlineDrawerContainer = ({
  open,
  setOpen,
  drawerIcon,
  drawerHeader,
  drawerBody,
  children,
  onDrawerClose,
  onDrawerOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  drawerHeader: string;
  drawerIcon?: UIIconProps['src'];
  drawerBody: ReactNode;
  children: ReactNode;
  onDrawerClose?: () => void;
  onDrawerOpen?: () => void;
}) => {
  return (
    <Drawer.InlineFrame
      style={{marginTop: '-52px', marginRight: '-68px'}}
      withDrawer={
        <Drawer.InlineDrawer
          isOpen={open}
          alignment="right"
          width="475px"
          style={{overflow: 'scroll', position: 'fixed', marginTop: '52px'}}
          onWillClose={onDrawerClose}
          onWillOpen={onDrawerOpen}
        >
          <DrawerContainer
            header={drawerHeader}
            icon={drawerIcon}
            open={open}
            onClose={() => setOpen(false)}
          >
            {drawerBody}
          </DrawerContainer>
        </Drawer.InlineDrawer>
      }
    >
      <Box
        marginTop="52"
        marginX="auto"
        // paddingLeft="8"
        // paddingRight="36"
        style={{...(open && {maxWidth: '737px'})}}
      >
        <Container size={open ? 'full' : 'condensed'}>{children}</Container>
      </Box>
    </Drawer.InlineFrame>
  );
};

const InlineDrawerContainer = ({
  open,
  onClose,
  size = 'full',
  drawerIcon,
  drawerHeader,
  drawerBody,
  children,
}: {
  open: boolean;
  onClose: () => void;
  size?: GridSize;
  drawerHeader: ReactNode;
  drawerIcon?: UIIconProps['src'];
  drawerBody: ReactNode;
  children: ReactNode;
}) => {
  const isMobile = useAtomValue(isMobileAtom);

  return (
    <>
      <Box className={open ? styles.open : undefined}>
        <Box className={cx(styles.container, {[styles.slide]: !isMobile && size !== 'full'})}>
          <Container size={size}>{children}</Container>
        </Box>

        <Box className={styles.drawer}>
          <DrawerContainer header={drawerHeader} icon={drawerIcon} open={open} onClose={onClose}>
            {drawerBody}
          </DrawerContainer>
        </Box>
      </Box>
    </>
  );
};

export const Layout = {
  Container,
  Nav,
  Body,
  Column,
  InlineDrawerContainer,
};
