import {Button} from '@dropbox/dig-components/dist/buttons';
import {Box, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {PersonMultipleLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {snackbarAtom} from 'atoms/snackbar';
import {Employee} from 'client';
import {ContributorsBody} from 'components/goals/Contributors';
import {useGoal, useKeyResultService} from 'components/goals/hooks';
import {DelegateSelectMenu} from 'components/shared/DelegateSelectMenu';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {useEffect, useState} from 'react';
import {emailToLdap} from 'utilities';

import {DrawerHeader} from './DrawerHeader';
import {DrawerContainer, DrawerOverlay} from './DrawerOverlay';
import {GoalsV2DrawerProps} from './types';

export const ContributorsDrawerHeader = ({
  onClose,
  onBack,
}: {
  onClose: () => void;
  onBack?: () => void;
}) => (
  <DrawerHeader
    headerTitle={t('owners')}
    headerIcon={<Box as={UIIcon} src={PersonMultipleLine} />}
    onBack={onBack}
    onClose={onClose}
  />
);

export const ContributorsDrawer = (props: GoalsV2DrawerProps) => {
  return (
    <DrawerOverlay isOpen={props.isOpen}>
      <ContributorsDrawerContainer {...props} />
    </DrawerOverlay>
  );
};

export const ContributorsDrawerContainer = ({
  isOpen,
  source,
  goalData,
  userLdaps,
  teamsSlug,
  setGoalsV2DrawerData,
  onClose,
  autoFocus = true,
  showBackButton = true,
  shouldToggleVisibility,
  timeframe,
}: GoalsV2DrawerProps) => {
  const {goalId, keyResultId} = goalData;
  const [contributors, setContributors] = useState<Employee[]>([]);
  const {employee, delegatedBy} = useAtomValue(loggedInEmployeeAtom);
  const setSnackbarMessage = useSetAtom(snackbarAtom);

  const goal = useGoal({goalId});
  const [joinAs, setJoinAs] = useState<string | undefined>(undefined);
  const ownerLdap = emailToLdap(goal.users?.[0].email);
  const keyResult = goal?.key_results.find(({id}) => id === keyResultId);
  const keyResultContributorLdaps = keyResult?.contributors?.map(({ldap}) => ldap) ?? [];

  const contributorLdaps = contributors.map(({ldap}) => ldap);

  const canEdit = ownerLdap === employee.ldap || delegatedBy?.some(({ldap}) => ldap === ownerLdap);
  const hasDelegates = !!delegatedBy?.length;
  const employeeWithDelegates = [employee, ...(delegatedBy ?? [])];

  const isEmployeeContributor = keyResultContributorLdaps.includes(employee.ldap);
  const showJoinButtons =
    !canEdit &&
    (hasDelegates
      ? Boolean(
          !isEmployeeContributor ||
            delegatedBy.some(({ldap}) => !keyResultContributorLdaps.includes(ldap))
        )
      : !isEmployeeContributor);
  const hasContributorsUpdated =
    JSON.stringify(keyResultContributorLdaps.sort()) !== JSON.stringify(contributorLdaps.sort());

  const {updateKeyResult, updateKRContributors} = useKeyResultService({
    timeframe: goal?.timeframe ?? timeframe,
    teamInfo: goalData.teamInfo,
    ownerLdaps: userLdaps,
    teamsSlug: teamsSlug,
  });

  useEffect(() => {
    if (isOpen && source) {
      analyticsLogger().logEvent('CONTRIBUTORS_DRAWER_OPENED', {
        source,
      });
    }
  }, [isOpen, source]);

  const handleUpdateContributors = (updatedContributors: string[]) => {
    if (!keyResult || !goal.users) {
      return;
    }

    if (canEdit) {
      updateKeyResult({
        keyResultId: keyResult.id,
        userId: goal.users[0].user_id,
        data: {
          title: keyResult.title,
          created_at: keyResult.created_at,
          goal_id: keyResult.goal_id,
          sort: keyResult.sort,
          updated_at: new Date().toISOString(),
          contributors: updatedContributors,
        },
      });
    } else if (hasDelegates) {
      employeeWithDelegates.forEach((delegate) => {
        if (keyResultContributorLdaps.includes(delegate.ldap)) {
          const join = updatedContributors.includes(delegate.ldap);
          handleContribute(join, delegate.user_id);
        }
      });
    }

    setSnackbarMessage({text: t('co-owners_updated')});
  };

  const onSuccess = () => {
    setGoalsV2DrawerData({drawerType: 'goal', isOpen: true, source: 'drawer'});
  };

  const handleContribute = (join: boolean, employeeId: string) => {
    if (!keyResult || !goal.users) {
      return;
    }
    updateKRContributors({
      keyResultId: keyResult.id,
      ownerId: goal.users[0].user_id,
      employeeId,
      join,
    });
    onSuccess();
    setSnackbarMessage({text: t('co-owners_updated')});
  };

  if (!keyResult) {
    return null;
  }

  const joinAsId =
    joinAs ??
    employeeWithDelegates.filter(({ldap}) => ![ownerLdap, ...contributorLdaps].includes(ldap))?.[0]
      ?.user_id ??
    employee?.user_id;

  return (
    <DrawerContainer
      isOpen={isOpen}
      shouldToggleVisibility={shouldToggleVisibility}
      header={
        <ContributorsDrawerHeader
          onBack={showBackButton ? onSuccess : undefined}
          onClose={onClose}
        />
      }
      body={
        <ContributorsBody
          isOpen={isOpen}
          isOwner={canEdit}
          ownerLdap={ownerLdap}
          contributorLdaps={keyResultContributorLdaps}
          contributors={contributors}
          setContributors={setContributors}
          handleContribute={showJoinButtons ? handleContribute : undefined}
          autoFocus={autoFocus}
        />
      }
      footer={
        <Split gap="8">
          <Split.Item width="fill">
            {!canEdit && hasDelegates && (
              <DelegateSelectMenu
                filterLdaps={[ownerLdap, ...keyResultContributorLdaps]}
                delegateId={joinAs}
                setDelegateId={setJoinAs}
              />
            )}
          </Split.Item>
          <Split.Item>
            <Button variant="opacity" onClick={showBackButton ? onSuccess : onClose}>
              {t('cancel')}
            </Button>
          </Split.Item>
          <Split.Item>
            {showJoinButtons ? (
              <Button variant="primary" onClick={() => handleContribute(true, joinAsId)}>
                {t('join')}
              </Button>
            ) : isEmployeeContributor && !hasDelegates ? (
              <Button
                variant="primary"
                onClick={() => handleContribute(false, joinAsId)}
                disabled={!hasContributorsUpdated}
              >
                {t('save')}
              </Button>
            ) : (
              <Button
                variant="primary"
                onClick={() => {
                  const updatedContributors = contributors.map(({ldap}) => ldap);
                  handleUpdateContributors(updatedContributors);
                  onSuccess();
                }}
                disabled={!hasContributorsUpdated}
              >
                {t('save')}
              </Button>
            )}
          </Split.Item>
        </Split>
      }
    />
  );
};
