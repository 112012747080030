import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack} from '@dropbox/dig-foundations';
import {DocumentMini} from '@dropbox/dig-illustrations';
import {Layout} from 'components/DSYS/Layout';
import {ButtonLink} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import {t} from 'i18next';

import styles from './NewsEmptyState.module.css';

export const NewsEmptyState = ({isEditorView}: {isEditorView: boolean}) => {
  return (
    <>
      <Layout.Container>
        <Split>
          <Split.Item width="fill">
            <Title>{t('news')}</Title>
          </Split.Item>
        </Split>
      </Layout.Container>
      <Layout.Container>
        <EmptyNews isEditorView={isEditorView} />
      </Layout.Container>
    </>
  );
};

export const EmptyNews = ({isEditorView}: {isEditorView: boolean}) => (
  <div className={styles.emptyStateContainer}>
    <Split gap="8" direction="vertical" alignX="center" alignY="center">
      <Split.Item>
        <Box display={'flex'} alignItems="center" justifyContent="center">
          <DocumentMini altText="document" width={64} height={64} />
        </Box>
      </Split.Item>
      <Split.Item>
        <Stack display="flex" justify="center" align="center" gap="8" paddingBottom="8">
          <Box as={Text} isBold display="block" variant="paragraph" size="large">
            {t('no_articles')}
          </Box>
          <Box as={Text} display="block" textAlign="center" color="Text Subtle" variant="paragraph">
            {t('no_articles_subtitle')}
          </Box>
          {isEditorView && (
            <div style={{paddingTop: 8}}>
              <ButtonLink to="/news/new" variant="opacity">
                {t('new_post')}
              </ButtonLink>
            </div>
          )}
        </Stack>
      </Split.Item>
    </Split>
  </div>
);

export const BlankPost = () => {
  return <div className={styles.blankContainer}></div>;
};
