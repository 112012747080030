import {Table} from '@dropbox/dig-components/dist/table';
import {Box} from '@dropbox/dig-foundations';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {GoalData} from 'client';
import {AccordionIconButton} from 'components/DSYS/Accordion';
import {Header} from 'components/shared/table/Header';
import {ColumnConfig, useColumnResize} from 'components/shared/table/useColumnResize';
import {ReactNode, Suspense, useCallback, useState} from 'react';
import {emailToLdap} from 'utilities';
import styles from 'views/dashboards/Dashboards.module.css';
import {DashboardsChartContainer} from 'views/dashboards/DashboardsChartContainer';
import {
  DashboardsWidgetShowAllButton,
  DashboardsWidgetSpinner,
} from 'views/dashboards/widgets/DashboardsWidget';
import {GoalsTableRow} from 'views/goals_v2/GoalsTableRow';
import {DrawerGoalData} from 'views/goals_v2/types';
import {getBrowsePath, getExpandedState, getLatestUpdate} from 'views/goals_v2/utils';

export interface DashboardsGoalsWidgetProps {
  teamName: string;
  teamSlug: string;
  timeframe: string;
}

type DashboardsGoalsWidgetType = 'missed-and-cancelled';

export type DashboardsGoalsWidgetComponentProps = DashboardsGoalsWidgetProps & {
  type: DashboardsGoalsWidgetType;
  title: string;
  subtitle: string;
  icon: React.ComponentType<React.SVGAttributes<SVGElement>>;
  status: string;
  goals: GoalData[] | null;
  totalGoalsCount: number;
  goalsWidgetSelectorRef?: React.RefObject<HTMLDivElement>;
  showAll?: boolean;
  showAllCount?: number;
  setShowAll?: () => void;
  statusSelectorWithInfoMessage?: ReactNode;
  emptyState?: ReactNode;
};

const getDefaultColumns = () => [
  {type: 'name', width: 530, fixed: true},
  {type: 'owners', width: 100, fixed: true},
  {type: 'status', width: 70, fixed: true},
  {type: 'update', short: 'upd', width: 200, fixed: true},
];

export const DashboardsGoalsWidgetRow = ({
  type,
  status,
  goal,
  columnConfigs,
  expanded,
  setExpanded,
}: {
  type: string;
  status: string;
  goal: GoalData;
  columnConfigs: ColumnConfig[];
  expanded: {[id: string]: boolean};
  setExpanded: (value: React.SetStateAction<{[id: string]: boolean}>) => void;
}) => {
  const goalOwner = goal.users![0]!;
  const ownerLdap = emailToLdap(goalOwner.email);

  const handleRowClick = useCallback(
    (goalData: DrawerGoalData) => {
      if (goalData.goalId) {
        analyticsLogger().logEvent('DASHBOARD_WIDGET_CLICKED', {
          type,
          status,
          event: 'row',
        });

        // Open goal in new tab
        window.open(getBrowsePath(goalData, false), '_blank');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [type, status]
  );

  return (
    <GoalsTableRow
      id={goal.id}
      rowKeyPrefix={`dashboards-goals-table-row__${goal.id}`}
      row={goal}
      goalOwner={goalOwner}
      isGoalsV2DrawerOpen={false}
      onRowClick={handleRowClick}
      onContributorsClick={() => {}}
      onContinueGoalClick={() => {}}
      hasScrolledToRow={true}
      setScrolledToRow={() => {}}
      selectedGoalData={undefined}
      expandedParentGoals={{}}
      handleSetExpandedParentGoals={() => {}}
      shouldGroupGoalsByEmployee={false}
      columnConfigs={columnConfigs}
      expandId={`goals-${goal.id}`}
      setExpanded={setExpanded}
      expanded={expanded}
      indent={0}
      showActionMenu={false}
      showUpdates={true}
      ownerLdap={ownerLdap}
      timeframe=""
      nextTimeframe=""
      setNextTimeframe={() => {}}
      disableExpandGoalActionMenu={true}
      disableStatusEdit={true}
      withLeftAccessory={
        <Box
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
            setExpanded({
              ...expanded,
              [`goals-${goal.id}`]: !expanded[`goals-${goal.id}`],
            });
          }}
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
          height="100%"
        >
          <AccordionIconButton
            isHidden={false}
            isActive={expanded[`goals-${goal.id}`]}
            toggle={(e) => {
              e.stopPropagation();
              e.preventDefault();
              const toggleExpanded = !expanded[`goals-${goal.id}`];
              if (toggleExpanded) {
                const {latestUpdate} = getLatestUpdate(goal.updates);
                analyticsLogger().logEvent('DASHBOARD_WIDGET_CLICKED', {
                  type,
                  status: latestUpdate?.status ?? 'no_status',
                  event: 'expand',
                });
              }
              setExpanded({
                ...expanded,
                [`goals-${goal.id}`]: toggleExpanded,
              });
            }}
          />
          {expanded[`goals-${goal.id}`] && goal.key_results.length > 0 && (
            <Box
              as="div"
              style={{
                width: '1px',
                height: 'calc(100% - 24px)',
              }}
              backgroundColor="Border Subtle"
              marginLeft="12"
            />
          )}
        </Box>
      }
    />
  );
};

export const DashboardsGoalsWidgetComponent = (props: DashboardsGoalsWidgetComponentProps) => {
  const {
    type,
    title,
    subtitle,
    icon,
    status,
    goals,
    totalGoalsCount,
    goalsWidgetSelectorRef,
    showAll,
    showAllCount,
    setShowAll,
    statusSelectorWithInfoMessage,
    emptyState,
  } = props;
  const {columnConfigs, getMouseDownHandler} = useColumnResize(getDefaultColumns());

  const [expanded, setExpanded] = useState<{[id: string]: boolean}>(
    getExpandedState(goals ?? [], undefined, undefined, {}, false, false, false)
  );

  return (
    <DashboardsChartContainer title={title} subtitle={subtitle} icon={icon}>
      <Box marginTop="12" marginBottom="16" borderColor="Border Subtle" borderBottom="Solid" />
      {statusSelectorWithInfoMessage}
      {goals ? (
        <>
          {totalGoalsCount ? (
            goals.length ? (
              <Box
                ref={goalsWidgetSelectorRef}
                marginTop="12"
                className={showAll ? styles.dashboardsGoalsListScroll : undefined}
                style={{width: '100%', overflowX: 'auto'}}
              >
                <Table hasDividers={false} spacing="small" verticalAlign="center">
                  <Header
                    columnConfigs={columnConfigs}
                    sort={undefined}
                    setSort={() => {}}
                    getMouseDownHandler={getMouseDownHandler}
                    className={styles.dashboardsGoalsListHeader}
                  />
                  <Table.Body>
                    <Box as="div" style={{height: 8}} />
                    <Suspense fallback={<DashboardsWidgetSpinner marginTop="4" />}>
                      {goals.map((goal) => (
                        <DashboardsGoalsWidgetRow
                          key={goal.id}
                          type={type}
                          status={status}
                          goal={goal}
                          columnConfigs={columnConfigs}
                          expanded={expanded}
                          setExpanded={setExpanded}
                        />
                      ))}
                    </Suspense>
                  </Table.Body>
                </Table>
                {!!showAllCount && setShowAll && (
                  <DashboardsWidgetShowAllButton
                    showAllCount={showAllCount}
                    setShowAll={setShowAll}
                  />
                )}
              </Box>
            ) : (
              <Box marginTop="16">{emptyState}</Box>
            )
          ) : (
            emptyState
          )}
        </>
      ) : (
        <DashboardsWidgetSpinner marginTop="16" />
      )}
    </DashboardsChartContainer>
  );
};
