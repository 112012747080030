import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {EditLine, ImageLine} from '@dropbox/dig-icons/assets';
import {pulseUserAtom} from 'atoms/auth';
import {snackbarAtom} from 'atoms/snackbar';
import {Layout} from 'components/DSYS/Layout';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {isSuperUser} from 'helpers/utils';
import {useDebouncedValue} from 'hooks/useDebounce';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {EditorState} from 'lexical';
import {useEffect, useRef, useState} from 'react';

import {NotFound} from './NotFound';
import {useSettingsQuery} from './Settings';

export const Editor = () => {
  const pulseUser = useAtomValue(pulseUserAtom);
  const {settings, setSettings} = useSettingsQuery();
  const [editable, setEditable] = useState(true);
  const [value, setValue] = useState<EditorState>();
  const [isOpen, setOpen] = useState(false);

  const content = useRef(settings.working_with_me ?? undefined);
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const debouncedValue = useDebouncedValue(value, 300);

  useEffect(() => {
    const debouncedJson = debouncedValue?.toString() ?? '';
    if (debouncedJson && debouncedJson !== settings.working_with_me) {
      setSettings({...settings, working_with_me: debouncedJson}).then(() =>
        setSnackbarMessage({text: t('saved')})
      );
    }
  }, [settings, setSettings, debouncedValue, setSnackbarMessage]);

  if (!pulseUser) {
    return null;
  }

  if (!isSuperUser(pulseUser.email)) {
    return <NotFound />;
  }

  return (
    <Layout.InlineDrawerContainer
      open={isOpen}
      drawerHeader="editor"
      drawerBody={<>hey</>}
      onClose={() => setOpen(false)}
    >
      <Layout.Container size="condensed">
        <Box width="100%" display="flex" justifyContent="flex-end" style={{maxWidth: '1200px'}}>
          <Tooltip title={editable ? 'Click to preview post' : 'Edit post'}>
            <IconButton
              shape="circular"
              variant="transparent"
              onClick={() => setEditable(!editable)}
            >
              <UIIcon src={!editable ? EditLine : ImageLine} />
            </IconButton>
          </Tooltip>
        </Box>

        <div
          style={{
            width: editable ? 630 : 600,
            marginTop: editable ? 0 : 44,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <button onClick={() => setOpen(!isOpen)}>{isOpen ? 'open' : 'close'}</button>
          <RichTextArea
            editable={editable}
            mentions
            autoFocus={false}
            media
            theme="news"
            source="editor-page"
            placeholder="Write a news story..."
            value={content.current}
            onChange={(state) => setValue(state)}
          />
        </div>
      </Layout.Container>
    </Layout.InlineDrawerContainer>
  );
};
