import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Split, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {NewsLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {DocumentMini} from '@dropbox/dig-illustrations';
import {useQuery} from '@tanstack/react-query';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {HomeService, NewsPostWithRead} from 'client';
import {EmptyState} from 'components/DSYS/EmptyState';
import {ButtonLink, Link} from 'components/DSYS/Link';
import {NewsThumbnail} from 'components/news/NewsThumbnail';
import {calculateTimeAgo} from 'components/shared/TimeAgo';
import {t} from 'i18next';
import {getService} from 'utilities';

import {Section} from './Section';

const NewsItem = ({post}: {post: NewsPostWithRead}) => {
  const handleEventClick = () => analyticsLogger().logEvent('HOME_NEWS_CLICKED', {type: 'event'});

  return (
    <Box
      key={post.id}
      paddingY="8"
      paddingX="12"
      borderRadius="Medium"
      cursor="pointer"
      {...withShade({duration: 'None', style: {marginLeft: -8}})}
    >
      <Link
        to={`/news/${post.id}`}
        state={{source: 'discover'}}
        onClick={handleEventClick}
        hasNoUnderline
      >
        <LabelGroup
          align="top"
          withLeftAccessory={<NewsThumbnail id={post.id} isNewsFeed isSmall />}
          withText={<Text isBold>{post.title}</Text>}
          withSubtext={
            <Split gap="4">
              <Split.Item>
                <Text color="faint" size="small">
                  {calculateTimeAgo(post.created_at)}
                </Text>
              </Split.Item>
              {/* {post.read && (
                <Split.Item>
                  <Box
                    as={UIIcon}
                    marginTop="2"
                    src={CheckmarkCircleLine}
                    size="small"
                    color="Text Subtle"
                  />
                </Split.Item>
              )} */}
            </Split>
          }
        />
      </Link>
    </Box>
  );
};

export const NewsSection = () => {
  const {data, isLoading} = useQuery({
    queryKey: ['home', 'news'],
    queryFn: () => getService(HomeService).newsApiV1HomeNewsGet(),
  });

  const handleEventClick = () => analyticsLogger().logEvent('HOME_NEWS_CLICKED', {type: 'header'});

  return (
    <Section
      withAccessoryStart={<UIIcon src={NewsLine} className={atoms({color: 'Text Subtle'})} />}
      title={
        <Link hasNoUnderline to="/news" onClick={handleEventClick}>
          {t('news')}
        </Link>
      }
      isLoading={isLoading}
      loadingSkeleton={
        <Stack gap="0">
          {Array.from({length: 5}).map((_, index) => (
            <Box
              key={index}
              paddingY="8"
              paddingX="12"
              borderRadius="Medium"
              {...withShade({duration: 'None', style: {marginLeft: -8}})}
            >
              <LabelGroup
                size="large"
                withLeftAccessory={<Box as={Skeleton.Box} style={{width: 61, height: 40}} />}
                withText={<Box as={Skeleton.Text} style={{width: 200}} />}
                withSubtext={<Skeleton.Text size="small" width={40} />}
              />
            </Box>
          ))}
        </Stack>
      }
      content={
        data?.length ? (
          <Stack>
            {data?.map((post) => <NewsItem key={post.id} post={post} />)}

            <ButtonLink
              variant="transparent"
              size="small"
              className={atoms({paddingTop: '12'})}
              to="/news"
              state={{source: 'home-view-all'}}
              onClick={() => {
                analyticsLogger().logEvent('HOME_NEWS_CLICKED', {type: 'view_all'});
              }}
            >
              {t('view_all')}
            </ButtonLink>
          </Stack>
        ) : (
          <EmptyState
            title={t('no_articles')}
            body={t('no_articles_subtitle')}
            image={<DocumentMini width="64" altText={t('no_articles')} />}
            hideBorder
          />
        )
      }
    />
  );
};
