import {useGoalThreads} from 'components/comments/hooks';
import {useGoal} from 'components/goals/hooks';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SubtleKeyResultIcon, SubtleObjectiveIcon} from 'views/goals_v2/icons';

import {CommentDrawerBody} from './CommentDrawerBody';
import {DrawerContainer} from './DrawerOverlay';
import {GoalDetailsDrawerBody} from './GoalDetailsDrawerBody';
import {GoalDetailsDrawerHeader} from './GoalDetailsDrawerHeader';
import {GoalsV2DrawerProps} from './types';
import {getThreads, getTotalCommentsCount} from './utils';

export const GoalDetailsDrawerContainer = ({
  isOpen,
  goalData,
  timeframe,
  nextTimeframe,
  setNextTimeframe,
  onClose,
  setGoalsV2DrawerData,
  handleContinueGoalClick,
}: GoalsV2DrawerProps) => {
  const {goalId, keyResultId, parentGoalId, teamInfo} = goalData;

  const {t} = useTranslation();
  const goal = useGoal({goalId});
  const keyResult = goal?.key_results.find(({id}) => id === keyResultId);

  const title = keyResult ? t('key_result_alone') : t('objective');
  const icon = keyResult ? SubtleKeyResultIcon : SubtleObjectiveIcon;

  // get query param to see if there is comment=1 in the url
  const urlParams = new URLSearchParams(window.location.search);
  const showCommentsQueryParam = urlParams.get('comment');
  const shouldShowComments = showCommentsQueryParam === '1';

  const [showComments, setShowComments] = useState(shouldShowComments);
  const threads = useGoalThreads({goalId, shouldRefetch: isOpen, disabled: !goal?.is_draft});

  useEffect(() => {
    setShowComments(false);
    if (shouldShowComments) {
      setShowComments(true);
    }
  }, [goalId, keyResultId, shouldShowComments]);

  return (
    <DrawerContainer
      isOpen={isOpen}
      header={
        <GoalDetailsDrawerHeader
          title={title}
          icon={icon}
          onClose={onClose}
          goal={goal}
          keyResult={keyResult}
          teamInfo={teamInfo}
          timeframe={timeframe}
          nextTimeframe={nextTimeframe}
          totalCommentsCount={getTotalCommentsCount(getThreads(goalId, threads, keyResultId))}
          showComments={showComments}
          setShowComments={setShowComments}
          setNextTimeframe={setNextTimeframe}
          setShowConfidentialDetails={() =>
            setGoalsV2DrawerData({drawerType: 'privacy', isOpen: true, source: 'drawer'})
          }
          handleContributorsDrawerOpen={(
            open: boolean,
            drawerSource: string,
            autoFocus: boolean
          ) => {
            setGoalsV2DrawerData({
              drawerType: 'contributor',
              isOpen: open,
              source: drawerSource,
              autoFocus,
            });
          }}
          handleContinueGoalClick={handleContinueGoalClick}
        />
      }
      body={
        showComments && goal.is_draft ? (
          <CommentDrawerBody
            threads={getThreads(goalId, threads, keyResultId)}
            goal={goal}
            keyResult={keyResult}
          />
        ) : (
          <GoalDetailsDrawerBody
            goal={goal}
            keyResult={keyResult}
            parentGoalId={parentGoalId}
            setIsGoalDetailsDrawerOpen={(open: boolean) => {
              setGoalsV2DrawerData({
                drawerType: 'goal',
                isOpen: open,
                source: 'drawer',
              });
            }}
          />
        )
      }
    />
  );
};
