import {List} from '@dropbox/dig-components/dist/list';
import {Spinner} from '@dropbox/dig-components/dist/progress_indicators';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Modal} from '@dropbox/dig-components/modal';
import {Box} from '@dropbox/dig-foundations';
import {useQuery} from '@tanstack/react-query';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {PulseGrouping, PulseQuestion, PulseService, PulseSummary} from 'client';
import styles from 'components/activity/ActivityModal.module.css';
import {useEffect, useState} from 'react';
import {getService} from 'utilities';

import {PulseInsight} from './PulseInsight';
import {PulseResponseBox} from './PulseResponseBox';
import {toPulseGroup} from './utils';

export const PulseResponseModal = ({
  pillar,
  question,
  grouping,
  selectedInsight,
  insights,
  onClose,
}: {
  pillar: string;
  question: PulseQuestion;
  grouping?: PulseGrouping;
  selectedInsight?: PulseSummary;
  insights?: PulseSummary[];
  onClose: () => void;
}) => {
  const [active, setActive] = useState<number | undefined>(selectedInsight?.id);
  const groupInfo = grouping ? toPulseGroup(grouping) : undefined;

  const handleClick = (index?: number) => {
    setActive(index);
  };

  const groupType = groupInfo?.type ?? 'pillar';
  const groupSlug = groupInfo?.slug ?? pillar;

  const {data, isLoading} = useQuery({
    queryKey: ['pulse', 'question', groupType, groupSlug, question.id],
    queryFn: () =>
      getService(PulseService).getResponsesApiV1PulseGroupIdQuestionQuestionIdGet(
        groupType,
        groupSlug,
        question.id
      ),
  });

  const nonInsights = data?.filter(
    ({id}) => !insights?.some((insight) => insight.response_ids.includes(id))
  );

  const activeInsight = insights?.find((insight) => insight.id === active);

  useEffect(() => {
    analyticsLogger().logEvent('PULSE_RESPONSE_MODAL_OPENED');
  }, []);

  useEffect(() => {
    analyticsLogger().logEvent('PULSE_RESPONSE_MODAL_SELECTION');
  }, [active]);

  return (
    <Modal
      open
      isCentered
      width="large"
      withCloseButton="Close"
      onRequestClose={onClose}
      className={styles.modal}
    >
      <Modal.Content>
        <Modal.Header hasBottomSpacing="title-standard" className={styles.headerLine}>
          <Modal.Title style={{maxWidth: 800}} weightVariant="emphasized">
            {question.prompt}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <div className={styles.nav}>
            <List isSelectable verticalAlign="top" paddingY="0">
              <Box
                as={List.Item}
                paddingY="8"
                paddingX="16"
                marginBottom="4"
                borderRadius="Medium"
                onClick={() => handleClick()}
                isSelected={active === undefined}
              >
                <List.Content>
                  <Text tagName="div">All responses</Text>
                </List.Content>
                <List.Accessory>
                  <Text tagName="div" size="small" color="subtle">
                    {data?.length ?? '—'}
                  </Text>
                </List.Accessory>
              </Box>
              {insights?.map((insight) => (
                <Box
                  key={insight.id}
                  as={List.Item}
                  paddingY="8"
                  paddingX="16"
                  isSelected={active === insight.id}
                  borderRadius="Medium"
                  onClick={() => handleClick(insight.id)}
                >
                  <List.Content>
                    <Text tagName="div">{insight.theme}</Text>
                  </List.Content>
                  <List.Accessory>
                    <Text tagName="div" size="small" color="subtle">
                      {insight.response_ids.length}
                    </Text>
                  </List.Accessory>
                </Box>
              ))}
              {!nonInsights?.length || nonInsights.length === data?.length ? null : (
                <Box
                  as={List.Item}
                  paddingY="8"
                  paddingX="16"
                  borderRadius="Medium"
                  onClick={() => handleClick(-1)}
                  isSelected={active === -1}
                >
                  <List.Content>
                    <Text tagName="div">Other responses</Text>
                  </List.Content>
                  <List.Accessory>
                    <Text tagName="div" size="small" color="subtle">
                      {nonInsights?.length ?? '—'}
                    </Text>
                  </List.Accessory>
                </Box>
              )}
            </List>
          </div>
          <Box overflow="auto" paddingLeft="24" width="100%">
            {insights && activeInsight && (
              <Box
                borderRadius="Medium"
                borderStyle="Solid"
                borderColor="Border Subtle"
                paddingLeft="16"
                paddingRight="8"
                paddingY="12"
              >
                <PulseInsight insight={activeInsight} />
              </Box>
            )}
            {isLoading ? (
              <Box width="100%" display="flex" justifyContent="center" marginTop="68">
                <Spinner monochromatic />
              </Box>
            ) : (
              data
                ?.filter(({id}) => {
                  if (active === undefined) {
                    return true;
                  }

                  if (active === -1) {
                    return !insights?.some((insight) => insight.response_ids.includes(id));
                  }

                  return activeInsight?.response_ids.includes(id);
                })
                ?.sort((a, b) => a.employee.name.localeCompare(b.employee.name))
                ?.map((response, lastEmployee) => (
                  <PulseResponseBox
                    key={response.user_id}
                    response={response}
                    borderBottom={lastEmployee + 1 !== data.length}
                  />
                ))
            )}
          </Box>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
