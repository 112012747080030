import {Badge} from '@dropbox/dig-components/dist/badge';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {t} from 'i18next';

import styles from './ReportCountBadge.module.css';

interface ReportCountBadgeProps {
  direct_reports_count: number;
  total_report_count: number;
}

export const ReportCountBadge = ({
  direct_reports_count,
  total_report_count,
}: ReportCountBadgeProps) => {
  return (
    <>
      <Tooltip
        title={
          <Box display="flex" flexDirection="column">
            <Text size="small">{t('direct_reports', {count: direct_reports_count})}</Text>
            {total_report_count > direct_reports_count ? (
              <Text size="small">{t('total_reports', {count: total_report_count})}</Text>
            ) : (
              ''
            )}
          </Box>
        }
      >
        <div className={styles.badge}>
          <Badge className={styles.container}>
            {direct_reports_count}
            {total_report_count && total_report_count > direct_reports_count ? '+' : ''}
          </Badge>
        </div>
      </Tooltip>
    </>
  );
};
