import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  AddLine,
  CalendarLine,
  FigmaExternalLogo,
  MoreHorizontalLine,
} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {isMobileAtom} from 'atoms/layout';
import {Project, ProjectDetails} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {Link} from 'components/DSYS/Link';
import {LinkIcon} from 'components/shared/LinkIcon';
import {ProfileAvatarName} from 'components/shared/ProfileAvatarName';
import {toLocaleString} from 'components/shared/TimeAgo';
import {useAtomValue} from 'jotai';
import {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import styles from './ProjectsView.module.css';
import {sortContributors} from './utils';

const HeaderSeperator = ({show}: {show: boolean}) =>
  !show ? null : (
    <Split.Item>
      <Box
        borderColor="Border Subtle"
        borderWidth="1"
        borderLeft="Solid"
        marginLeft="8"
        marginRight="12"
        marginY="6"
        style={{height: 24}}
      />
    </Split.Item>
  );

export const ProjectDetailRow = ({data, details}: {data: Project; details?: ProjectDetails}) => {
  const navigate = useNavigate();
  const isMobile = useAtomValue(isMobileAtom);
  const [isFigmaModalOpen, setFigmaModalOpen] = useState<string | undefined>();
  const contributors = details?.contributors.sort(sortContributors);
  const containerRef = useRef<HTMLDivElement>(null);

  const [contributorsToShow, setContributorsToShow] = useState(2);
  const firstContributors = contributors?.slice(0, contributorsToShow);
  const remainingContributors = contributors?.slice(contributorsToShow);

  const [linksToShow, setLinksToShow] = useState(2);
  const firstLinks = details?.links?.slice(0, linksToShow);
  const remainingLinks = details?.links?.slice(linksToShow);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;

        const contributorCount = contributors?.reduce(
          (acc, contributor) => {
            const width = acc.width + contributor.employee.name.length * 10 + 75;
            if (width > containerWidth - 200) {
              return acc;
            }
            return {
              width,
              count: acc.count + 1,
            };
          },
          {width: 0, count: 0}
        );

        const linkCount = details?.links?.reduce(
          (acc, link) => {
            const width = acc.width + link.text.length * 10 + 50;
            if (width > containerWidth) {
              return acc;
            }
            return {
              width,
              count: acc.count + 1,
            };
          },
          {width: 0, count: 0}
        );

        setContributorsToShow(contributorCount?.count ?? 1);
        setLinksToShow(linkCount?.count ?? 1);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [contributors, details?.links, isMobile]);

  return (
    <>
      <Box as={Stack} marginY="16">
        <Box
          as="div"
          ref={containerRef}
          borderBottom={firstLinks?.length ? 'Solid' : undefined}
          paddingBottom={firstLinks?.length ? '4' : undefined}
          borderWidth="1"
          borderColor="Border Subtle"
        >
          <Box as={Split}>
            <Box as={Split.Item} flexShrink={0}>
              <Box display="flex" alignItems="center" marginY="6" marginRight="8">
                <Box as={UIIcon} color="Text Subtle" src={CalendarLine} marginRight="4" />
                <Text>{`Available ${toLocaleString(data.available)}`}</Text>
              </Box>
            </Box>

            <HeaderSeperator show={Boolean(contributors?.length)} />

            {firstContributors?.map((contributor) => (
              <Split.Item key={contributor.employee.user_id}>
                <ProfileAvatarName
                  user={contributor.employee}
                  detail={contributor.role.charAt(0).toUpperCase()}
                  tooltip={
                    contributor.role.charAt(0).toUpperCase() + contributor.role.slice(1) + ' DRI'
                  }
                  source="project"
                />
              </Split.Item>
            ))}

            {!remainingContributors?.length ? null : (
              <Split.Item>
                <Menu.Wrapper
                  onSelection={(value) => {
                    navigate(`/people/${value}`, {state: {source: 'project'}});
                  }}
                >
                  {({getContentProps, getTriggerProps}) => (
                    <>
                      <IconButton {...getTriggerProps()} variant="borderless" isRounded>
                        <Box as={UIIcon} src={MoreHorizontalLine} color="Text Subtle" />
                      </IconButton>
                      <Menu.Content {...getContentProps()}>
                        <Menu.Segment>
                          {remainingContributors.map((contributor) => (
                            <Menu.SelectItem
                              preferComposition
                              key={contributor.employee.user_id}
                              value={contributor.employee.ldap}
                            >
                              <Menu.RowAccessory>
                                <Avatar user={contributor.employee} />
                              </Menu.RowAccessory>
                              <Menu.RowContent>
                                <Menu.RowTitle>{contributor.employee.name}</Menu.RowTitle>
                                <Menu.RowSubtitle>
                                  {contributor.role.charAt(0).toUpperCase() +
                                    contributor.role.slice(1)}
                                </Menu.RowSubtitle>
                              </Menu.RowContent>
                            </Menu.SelectItem>
                          ))}
                        </Menu.Segment>
                        <Menu.Segment>
                          <Menu.ActionItem
                            preferComposition
                            onClick={() => {
                              navigate(`/projects/${data.id}/edit`, {
                                state: {autofocus: 'contributors'},
                              });
                            }}
                          >
                            <Menu.RowAccessory>
                              <UIIcon src={AddLine} color="Text Subtle" />
                            </Menu.RowAccessory>
                            <Menu.RowContent>
                              <Menu.RowTitle>Add people</Menu.RowTitle>
                            </Menu.RowContent>
                          </Menu.ActionItem>
                        </Menu.Segment>
                      </Menu.Content>
                    </>
                  )}
                </Menu.Wrapper>
              </Split.Item>
            )}
          </Box>
        </Box>

        <Box paddingTop="4" style={{marginLeft: -8}}>
          {firstLinks?.map((link, index) => (
            <Split.Item key={index}>
              {link.icon === 'figma' ? (
                <Button
                  tabIndex={-1}
                  variant="borderless"
                  withIconStart={<LinkIcon icon={link.icon} />}
                  onClick={() => {
                    analyticsLogger().logEvent('PROJECT_LINK_CLICKED', {
                      type: 'figma',
                    });
                    setFigmaModalOpen(link.url);
                  }}
                >
                  {link.text}
                </Button>
              ) : (
                <Link hasNoUnderline to={link.url} target="_blank">
                  <Button
                    tabIndex={-1}
                    variant="borderless"
                    withIconStart={<LinkIcon icon={link.icon} />}
                    onClick={() =>
                      analyticsLogger().logEvent('PROJECT_LINK_CLICKED', {
                        type: link.icon,
                      })
                    }
                  >
                    {link.text}
                  </Button>
                </Link>
              )}
            </Split.Item>
          ))}

          {remainingLinks?.length ? (
            <>
              <Split.Item>
                <Menu.Wrapper>
                  {({getContentProps, getTriggerProps}) => (
                    <>
                      <IconButton {...getTriggerProps()} variant="borderless" isRounded>
                        <Box as={UIIcon} src={MoreHorizontalLine} color="Text Subtle" />
                      </IconButton>
                      <Menu.Content {...getContentProps()}>
                        {!remainingLinks?.length ? null : (
                          <>
                            <Menu.Segment>
                              {remainingLinks?.map((link) => (
                                <Menu.LinkItem
                                  preferComposition
                                  key={link.id}
                                  href={link.url}
                                  target="_blank"
                                  onClick={() =>
                                    analyticsLogger().logEvent('PROJECT_LINK_CLICKED', {
                                      type: link.icon,
                                    })
                                  }
                                >
                                  <Menu.RowAccessory>
                                    <LinkIcon icon={link.icon} />
                                  </Menu.RowAccessory>
                                  <Menu.RowContent>
                                    <Menu.RowTitle>{link.text}</Menu.RowTitle>
                                  </Menu.RowContent>
                                </Menu.LinkItem>
                              ))}
                            </Menu.Segment>
                            <Menu.Segment>
                              <Menu.ActionItem
                                preferComposition
                                onClick={() => {
                                  navigate(`/projects/${data.id}/edit`, {
                                    state: {autofocus: 'resources'},
                                  });
                                }}
                              >
                                <Menu.RowAccessory>
                                  <UIIcon src={AddLine} color="Text Subtle" />
                                </Menu.RowAccessory>

                                <Menu.RowContent>
                                  <Menu.RowTitle>Add resource</Menu.RowTitle>
                                </Menu.RowContent>
                              </Menu.ActionItem>
                            </Menu.Segment>
                          </>
                        )}
                      </Menu.Content>
                    </>
                  )}
                </Menu.Wrapper>
              </Split.Item>
            </>
          ) : null}
        </Box>
      </Box>

      <Box
        as={Modal}
        open={Boolean(isFigmaModalOpen)}
        isCentered
        withCloseButton="Close"
        aria-label="Figma"
        className={styles.modal}
        onRequestClose={() => setFigmaModalOpen(undefined)}
      >
        <Modal.Header hasBottomSpacing="title-standard">
          <Modal.Title>
            <Split gap="4">
              <Split.Item paddingTop="2">
                <UIIcon isOpticallyAligned src={FigmaExternalLogo} />{' '}
              </Split.Item>
              <Split.Item>
                <Text isBold size="xlarge">
                  Design
                </Text>
              </Split.Item>
            </Split>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Box
            as="iframe"
            borderRadius="Medium"
            width="100%"
            style={{height: '956px'}}
            src={`https://www.figma.com/embed?embed_host=share&url=${isFigmaModalOpen}`}
          />
        </Modal.Body>
      </Box>
    </>
  );
};
