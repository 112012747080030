import {Button} from '@dropbox/dig-components/dist/buttons';
import {UIIcon} from '@dropbox/dig-icons';
import {ArrowLeftLine} from '@dropbox/dig-icons/assets';

import styles from './BadgeModalHeader.module.css';

export interface BadgeModalHeaderProps {
  label: string;
  onBack: () => void;
}

export const BadgeModalHeader = ({label, onBack}: BadgeModalHeaderProps) => (
  <div className={styles.container}>
    <Button
      variant="borderless"
      withIconStart={<UIIcon src={ArrowLeftLine} />}
      onClick={onBack}
      size="small"
    >
      {label}
    </Button>
  </div>
);
