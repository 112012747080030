import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Cluster} from '@dropbox/dig-foundations';
import {EmptyFileCabinetSpot, PeacefulHammockSpot, TravelVanSpot} from '@dropbox/dig-illustrations';
import {derivedThemeAtom} from 'atoms/layout';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';

import styles from './NotificationEmptyState.module.css';

const NotificationEmptyInbox = () => {
  const isDarkMode = useAtomValue(derivedThemeAtom) === 'dark';
  return (
    <Box
      as={Cluster}
      position="relative"
      paddingTop="20"
      paddingBottom="10"
      style={{width: 200, height: 200}}
    >
      <Box as={Cluster.Item} position="absolute" style={{width: 186, height: 186}}>
        <Box backgroundColor="Background Subtle" borderRadius="Circular" height="100%" />
      </Box>

      <Box
        as={Cluster.Item}
        position="absolute"
        className={isDarkMode ? styles.hammockDark : styles.hammock}
      >
        <PeacefulHammockSpot style={{width: 200}} />
      </Box>
    </Box>
  );
};

const NotificationEmptyUpcoming = () => {
  const isDarkMode = useAtomValue(derivedThemeAtom) === 'dark';
  return (
    <Box
      as={Cluster}
      position="relative"
      paddingTop="24"
      paddingBottom="10"
      style={{width: 282, height: 200}}
    >
      <Box as={Cluster.Item} position="absolute" style={{width: 282, height: 67, bottom: 30}}>
        <Box
          as="div"
          borderRadius="Circular"
          height="100%"
          className={isDarkMode ? styles.upcomingDark : styles.upcoming}
          style={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0}}
        />
      </Box>

      <Box as={Cluster.Item} position="absolute">
        <TravelVanSpot style={{width: 282, height: 200}} />
      </Box>
    </Box>
  );
};

const NotificationEmptyArchive = () => {
  const isDarkMode = useAtomValue(derivedThemeAtom) === 'dark';
  return (
    <Box
      as={Cluster}
      position="relative"
      paddingTop="28"
      paddingBottom="10"
      style={{width: 244, height: 160}}
    >
      <Box as={Cluster.Item} position="absolute" style={{width: 244, height: 120}}>
        <Box backgroundColor="Background Subtle" borderRadius="Medium" height="100%" />
      </Box>

      <Box
        as={Cluster.Item}
        position="absolute"
        className={isDarkMode ? styles.cabinetDark : styles.cabinet}
      >
        <EmptyFileCabinetSpot style={{width: 244, height: 160}} />
      </Box>
    </Box>
  );
};

const emptyStates = {
  inbox: {
    Image: NotificationEmptyInbox,
    title: 'notification_inbox_empty_title',
    body: 'notification_inbox_empty_body',
  },
  upcoming: {
    Image: NotificationEmptyUpcoming,
    title: 'notification_upcoming_empty_title',
    body: 'notification_upcoming_empty_body',
  },
  archive: {
    Image: NotificationEmptyArchive,
    title: 'notification_archive_empty_title',
    body: 'notification_archive_empty_body',
  },
};

export const NotificationEmptyState = ({type}: {type: 'inbox' | 'archive' | 'upcoming'}) => {
  const {Image, title, body} = emptyStates[type];
  return (
    <Box
      width="100%"
      alignItems="center"
      textAlign="center"
      flexDirection="column"
      display="flex"
      justifyContent="center"
      paddingX="68"
    >
      <Image />
      <Text size="large" isBold variant="paragraph">
        {t(title)}
      </Text>
      <Text color="faint" variant="paragraph">
        {t(body)}
      </Text>
    </Box>
  );
};
