import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box} from '@dropbox/dig-foundations';
import {useQuery} from '@tanstack/react-query';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {HomeService} from 'client';
import {Link} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import * as d3 from 'd3';
import {useEffect, useRef} from 'react';
import {getService} from 'utilities';

import {Section} from './Section';

export const StocksSection = () => {
  const {data, isLoading} = useQuery({
    queryKey: ['stocks'],
    queryFn: getService(HomeService).stockApiV1HomeStockGet,
  });

  const handleEventClick = () => analyticsLogger().logEvent('HOME_STOCK_CLICKED');

  return (
    <Link
      hasNoUnderline
      to="https://www.google.com/finance/quote/DBX:NASDAQ"
      onClick={handleEventClick}
      className={atoms({display: 'block'})}
    >
      <Section
        isLoading={isLoading}
        gap="0"
        content={
          <Box display="flex" justifyContent="space-between">
            <Text color="faint" size="small">
              Dropbox, Inc.
            </Text>
            {!isLoading && (data?.intraday?.length ?? 0) > 5 && (
              <Box
                position="absolute"
                style={{
                  top: 15,
                  right: 108,
                }}
              >
                <StockChart values={data.intraday} trendingUp={data.change_percent >= 0} />
              </Box>
            )}

            <Box>
              {isLoading ? (
                <Box as={Skeleton.Text} style={{width: 80}} size="small" />
              ) : (
                Boolean(data) && (
                  <Box
                    as={Text}
                    size="small"
                    color={data.change_percent < 0 ? 'Alert On Surface' : 'Success Base - State 2'}
                  >
                    {data.change_percent < 0 ? data.change : `+${data.change.toFixed(2)}`} (
                    {data.change_percent.toFixed(2)}
                    %)
                  </Box>
                )
              )}
            </Box>
          </Box>
        }
        withAccessoryEnd={
          isLoading ? (
            <Box as={Skeleton.Text} size="large" style={{width: 55}} />
          ) : (
            <Title size={16}>${data?.price ? data.price.toFixed(2) : '-'}</Title>
          )
        }
        title={
          <Title size={16}>
            DBX
            {Boolean(data) && (
              <Box
                isBold
                as={Text}
                size="large"
                color={data.change_percent < 0 ? 'Alert On Surface' : 'Success Base - State 2'}
                paddingLeft="4"
              >
                {data.change_percent < 0 ? '▼' : '▲'}
              </Box>
            )}
          </Title>
        }
      />
    </Link>
  );
};

const StockChart = ({
  values,
  width = 107,
  height = 41,
  trendingUp,
}: {
  values: number[]; // Array of stock values
  trendingUp: boolean; // Optional trending up
  width?: number; // Optional width
  height?: number; // Optional height
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  const color = trendingUp
    ? 'var(--dig-color__highlight__green--bolder)'
    : 'var(--dig-color__highlight__red--bolder)';

  useEffect(() => {
    if (!values || values.length === 0) return;

    const margin = {top: 1, right: 1, bottom: 1, left: 1};

    // Create scales
    const xScale = d3
      .scaleLinear()
      .domain([0, values.length - 1])
      .range([margin.left, width - margin.right]);

    const yScale = d3
      .scaleLinear()
      .domain([d3.min(values) ?? 0, d3.max(values) ?? 1])
      .range([height - margin.bottom, margin.top]);

    // Line generator
    const line = d3
      .line<number>()
      .x((_, i) => xScale(i))
      .y((d) => yScale(d))
      .curve(d3.curveMonotoneX); // Smooth line

    // Area generator
    const area = d3
      .area<number>()
      .x((_, i) => xScale(i))
      .y0(height - margin.bottom)
      .y1((d) => yScale(d))
      .curve(d3.curveMonotoneX);

    // Select the SVG element
    const svg = d3.select(svgRef.current);

    // Clear existing content
    svg.selectAll('*').remove();

    // Add gradient
    const gradient = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', 'gradient')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%');

    gradient
      .append('stop')
      .attr('offset', '0%')
      .attr('stop-color', color)
      .attr('stop-opacity', 0.5);

    gradient
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', color)
      .attr('stop-opacity', 0);

    // Append area
    svg.append('path').datum(values).attr('d', area).attr('fill', 'url(#gradient)');

    // Append line
    svg
      .append('path')
      .datum(values)
      .attr('d', line)
      .attr('fill', 'none')
      .attr('stroke', color)
      .attr('stroke-width', 1.5);
  }, [values, width, height, color]);

  return <svg ref={svgRef} width={width} height={height}></svg>;
};
