import {Table} from '@dropbox/dig-components/dist/table';
import {Text} from '@dropbox/dig-components/dist/typography';
import {useSuspenseQuery} from '@tanstack/react-query';
import {pulseUserAtom} from 'atoms/auth';
import {CeleryMetataskService} from 'client';
import {Title} from 'components/DSYS/Title';
import {isSuperUser} from 'helpers/utils';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {Suspense} from 'react';
import {redirect} from 'react-router-dom';
import {getService} from 'utilities';

export const CeleryTaskMetaAdmin = () => {
  const pulseUser = useAtomValue(pulseUserAtom);
  useDocumentTitle('Monitor tasks');

  if (!pulseUser) {
    return <></>;
  }

  if (!isSuperUser(pulseUser.email)) {
    redirect('/');
  }

  return (
    <>
      <Title size={24}>{t('tasks')}</Title>
      <Suspense fallback={null}>
        <TaskMetaTableView />
      </Suspense>
    </>
  );
};

const TaskMetaTableView = () => {
  const {data: tasks} = useSuspenseQuery({
    queryKey: ['celery_taskmeta'],
    queryFn: getService(CeleryMetataskService).getTasksApiV1CeleryMetataskGet,
  });

  return (
    <Suspense fallback={null}>
      <div>
        <Table hasDividers={true} verticalAlign="top">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('id')}</Table.HeaderCell>
              <Table.HeaderCell>{t('task_id')}</Table.HeaderCell>
              <Table.HeaderCell>{t('status')}</Table.HeaderCell>
              <Table.HeaderCell>{t('date_done')}</Table.HeaderCell>
              <Table.HeaderCell>{t('traceback')}</Table.HeaderCell>
              <Table.HeaderCell>{t('name')}</Table.HeaderCell>
              <Table.HeaderCell>{t('worker')}</Table.HeaderCell>
              <Table.HeaderCell>{t('retries')}</Table.HeaderCell>
              <Table.HeaderCell>{t('queue')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tasks &&
              tasks.map((task, index) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    <Text>{task.id}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{task.task_id}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{task.status}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{task.date_done}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{task.traceback}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{task.name}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{task.worker}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{task.retries}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{task.queue}</Text>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </div>
    </Suspense>
  );
};
