import {Button} from '@dropbox/dig-components/dist/buttons';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {List} from '@dropbox/dig-components/dist/list';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CheckmarkLine, ChevronRightLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {ButtonLink, Link} from 'components/DSYS/Link';
import {ReactNode} from 'react';
import {LinkProps} from 'react-router-dom';

import styles from './Notifications.module.css';
import {useNotificationArchiver} from './utils/useNotificationArchiver';
import {useNotificationContext} from './utils/useNotificationContext';

const NotificationIcon = ({
  accessory,
  upcoming,
  last,
}: {
  accessory: ReactNode;
  upcoming?: boolean;
  last?: boolean;
}) => {
  if (!upcoming) {
    return <>{accessory}</>;
  }
  return (
    <Box position="relative">
      <Box
        position="absolute"
        backgroundColor="Border Subtle"
        style={{width: 1, top: -12, height: 12, left: 16.5}}
      />
      <Box
        position="absolute"
        backgroundColor="Border Subtle"
        style={{width: 1, top: 34, height: last ? 14 : 22, left: 16.5}}
      />
      <Box
        borderRadius="Circular"
        borderWidth="1"
        borderStyle="Solid"
        borderColor="Border Subtle"
        padding="4"
        style={{height: 34}}
      >
        {accessory}
      </Box>
    </Box>
  );
};

export const NotificationCard = ({
  accessory,
  header,
  subtext,
  buttonLink,
  archived,
  truncate,
  variant = 'standard',
  to,
}: {
  accessory: ReactNode;
  header: ReactNode;
  subtext: ReactNode;
  archived: boolean;
  truncate?: boolean;
  variant?: 'alert' | 'standard'; // | 'attention' | 'success' | 'warning';
  to?: string;
  buttonLink?: {cta: string; to: string; state?: LinkProps['state']};
}) => {
  const {
    separator,
    onClick,
    archiveable,
    upcoming,
    notification: {id, type},
  } = useNotificationContext();
  const {archiveNotification} = useNotificationArchiver();
  const selectable = Boolean((to && !buttonLink) || (archived && archiveable)) && !upcoming;
  const markNotificationRead = (click: 'view' | 'archive') => {
    if (archived) {
      analyticsLogger().logEvent('NOTIFICATION_ARCHIVE_CLICKED', {type});
      onClick?.();
      return;
    }
    archiveNotification({id});
    analyticsLogger().logEvent('NOTIFICATION_INBOX_CLICKED', {type, click});
    if (click !== 'archive') {
      onClick?.();
    }
  };

  const handleCTA = () => {
    // Some action items can archive on CTA click
    if (['gratitude_received', 'okr_review', 'new_user'].includes(type)) {
      archiveNotification({id});
      analyticsLogger().logEvent('NOTIFICATION_INBOX_CLICKED', {type, click: 'cta'});
    }

    analyticsLogger().logEvent('NOTIFICATION_CTA_CLICKED', {type});
    onClick?.();
  };

  return (
    <Link
      isClickable={selectable}
      to={to || ''}
      hasNoUnderline
      onClick={() => markNotificationRead('view')}
      state={{source: 'notification'}}
    >
      <Box
        as={List.Item}
        paddingY="12"
        paddingX="16"
        gap={upcoming ? 'small' : 'medium'}
        borderBottom={upcoming ? undefined : 'Solid'}
        borderWidth={separator && variant !== 'alert' ? '1' : '0'}
        borderColor="Border Subtle"
        backgroundColor={variant === 'alert' ? 'Alert Surface' : 'Background Raised'}
        isSelectable={selectable}
        className={styles.notification}
      >
        <List.Accessory style={{alignSelf: 'flex-start'}}>
          <NotificationIcon accessory={accessory} upcoming={upcoming} last={separator} />
        </List.Accessory>
        <List.Content>
          <LabelGroup
            withText={
              !truncate ? (
                header
              ) : (
                <Box
                  style={{
                    WebkitLineClamp: 2,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    display: '-webkit-box',
                  }}
                >
                  {header}
                </Box>
              )
            }
            withSubtext={
              <Split alignY="center" gap="8">
                <Split.Item>{subtext}</Split.Item>
                <Split.Item>{archiveable && !archived ? '•' : null}</Split.Item>
                <Split.Item>
                  {archiveable && !archived ? (
                    <Button
                      variant="transparent"
                      size="small"
                      onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        markNotificationRead('archive');
                      }}
                    >
                      <Text size="small" color="faint">
                        Archive
                      </Text>
                    </Button>
                  ) : null}
                </Split.Item>
              </Split>
            }
          />
        </List.Content>
        {!archived ? (
          buttonLink ? (
            <List.Accessory>
              <ButtonLink
                to={buttonLink.to}
                state={{...buttonLink.state, source: 'notification'}}
                size="small"
                variant="outline"
                onClick={handleCTA}
              >
                {buttonLink.cta}
              </ButtonLink>
            </List.Accessory>
          ) : (
            <List.Accessory style={{marginLeft: 0}}>
              <Box
                visibility="hidden"
                color="Text Subtle"
                as={UIIcon}
                src={ChevronRightLine}
                className={upcoming ? undefined : styles.showChevron}
              />
            </List.Accessory>
          )
        ) : (
          buttonLink && (
            <List.Accessory style={{marginLeft: 0}}>
              <Box color="Text Subtle" as={UIIcon} src={CheckmarkLine} />
            </List.Accessory>
          )
        )}
      </Box>
    </Link>
  );
};
