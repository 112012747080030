import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {IconButtonGroup, LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {List} from '@dropbox/dig-components/dist/list';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/dist/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {CheckmarkLine, CloseLine, EditLine} from '@dropbox/dig-icons/assets';
import {useQuery} from '@tanstack/react-query';
import {snackbarAtom} from 'atoms/snackbar';
import {Badge} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {SelectedItem} from 'components/shared/ManagePage/SelectedItem';
import {PeopleSearchMenu} from 'components/shared/PeopleSearchMenu';
import {useSelectEmployees} from 'hooks/useSelectEmployee';
import {t} from 'i18next';
import {useSetAtom} from 'jotai';
import {FunctionComponent, useState} from 'react';
import {getBadgesService} from 'utilities';
import {queryClient} from 'views/QueryClientWrapper';

import {BadgeImage} from './BadgeImage';
import styles from './SelectedBadge.module.css';
import {UpdateBadgesForm} from './UpdateBadgeForm';

interface Props {
  badge?: Badge;
  isUserContentAdmin?: boolean;
}

export const SelectedBadge: FunctionComponent<Props> = ({
  badge: badgeInput,
  isUserContentAdmin,
}) => {
  const [editingOpen, setEditingOpen] = useState(false);
  const [selectedEmployees, selectEmployee, selectEmployees, removeEmployee, resetEmployees] =
    useSelectEmployees();

  const {data: badgeData} = useQuery({
    enabled: !!badgeInput,
    queryKey: ['badge', badgeInput?.id],
    queryFn: () => getBadgesService().getBadgeApiV1BadgesIdGet(badgeInput?.id ?? 0),
  });

  const badge: Badge | undefined = badgeData || badgeInput;

  const {data: nominations} = useQuery({
    enabled: !!badge,
    queryKey: ['nominations', badge?.id],
    queryFn: () => getBadgesService().getNominationsApiV1BadgesIdNominationsGet(badge?.id ?? 0),
  });

  const {data: owners} = useQuery({
    enabled: !!badge,
    queryKey: ['owners', badge?.id],
    queryFn: () => getBadgesService().getBadgeOwnersApiV1BadgesIdOwnersGet(badge?.id ?? 0),
  });

  const setSnackbarMessage = useSetAtom(snackbarAtom);

  if (!badge) {
    return <Skeleton.Box height={64} />;
  }
  return (
    <SelectedItem
      title={badge.name}
      accessory={<BadgeImage badge={badge.image_key} size={48} />}
      action={
        <>
          <IconButton variant="transparent" shape="circular" onClick={() => setEditingOpen(true)}>
            <UIIcon src={EditLine} />
          </IconButton>
          <Modal
            isCentered
            open={editingOpen}
            onRequestClose={() => setEditingOpen(false)}
            withCloseButton="edit-close"
          >
            <Modal.Header>
              <Modal.Title>{t('edit_badge')}</Modal.Title>
            </Modal.Header>
            {owners && (
              <UpdateBadgesForm
                badge={badge}
                owners={owners}
                onSubmit={() => {
                  queryClient.invalidateQueries({queryKey: ['badge', badge.id]});
                  queryClient.invalidateQueries({queryKey: ['owners', badge.id]});
                  queryClient.invalidateQueries({queryKey: ['ownedBadges']});
                  setEditingOpen(false);
                }}
                onCancel={() => {
                  setEditingOpen(false);
                }}
              />
            )}
          </Modal>
        </>
      }
    >
      <Text variant="paragraph" color="faint" className={styles.description} tagName="div">
        {badge.description}
      </Text>
      <Text isBold size="large" variant="paragraph" tagName="div">
        {t('review_nominees')}
      </Text>
      <List paddingBottom={24} verticalAlign="top">
        {nominations === undefined && (
          <>
            <List.Item paddingLeft={0}>
              <Skeleton.Box height={44} />
            </List.Item>
            <List.Item paddingLeft={0}>
              <Skeleton.Box height={44} />
            </List.Item>
            <List.Item paddingLeft={0}>
              <Skeleton.Box height={44} />
            </List.Item>
          </>
        )}
        {nominations && nominations.length === 0 && (
          <Text variant="label" isBold color="faint" className={styles.noNominations} tagName="div">
            {t('no_nominations')}
          </Text>
        )}
        {nominations &&
          nominations.map((nomination) => (
            <List.Item key={nomination.id} paddingLeft={0}>
              <List.Accessory>
                <Avatar user={nomination.nominee} />
              </List.Accessory>
              <List.Content>
                <LabelGroup
                  withText={<b>{nomination.nominee.name}</b>}
                  withSubtext={nomination.justification}
                />
              </List.Content>
              <List.Accessory className={styles.actions}>
                <IconButtonGroup>
                  {({getButtonProps}) => (
                    <>
                      <IconButton
                        variant="outline"
                        {...getButtonProps()}
                        shape="circular"
                        onClick={() =>
                          getBadgesService()
                            .updateNominationApiV1BadgesIdNominationsNominationIdPost(
                              badge.id,
                              nomination.id,
                              {...nomination, approved: true}
                            )
                            .then(() => {
                              queryClient.invalidateQueries({queryKey: ['nominations', badge.id]});
                              queryClient.invalidateQueries({queryKey: ['activeNominations']});
                              setSnackbarMessage({text: t('nomination_accepted')});
                            })
                        }
                      >
                        <UIIcon src={CheckmarkLine} />
                      </IconButton>
                      <IconButton
                        variant="outline"
                        {...getButtonProps()}
                        shape="circular"
                        onClick={() =>
                          getBadgesService()
                            .updateNominationApiV1BadgesIdNominationsNominationIdPost(
                              badge.id,
                              nomination.id,
                              {...nomination, approved: false}
                            )
                            .then(() => {
                              queryClient.invalidateQueries({queryKey: ['nominations', badge.id]});
                              queryClient.invalidateQueries({queryKey: ['activeNominations']});
                              setSnackbarMessage({text: t('nomination_declined')});
                            })
                        }
                      >
                        <UIIcon src={CloseLine} />
                      </IconButton>
                    </>
                  )}
                </IconButtonGroup>
              </List.Accessory>
            </List.Item>
          ))}
      </List>
      <div className={styles.award}>
        <Text isBold size="large" variant="paragraph" tagName="div" className={styles.awardBadges}>
          {isUserContentAdmin ? t('update_badge') : t('award_badges')}
        </Text>
        <PeopleSearchMenu
          allowSelf
          showReportingLine={false}
          className={styles.peopleSelect}
          onRemoveEmployee={removeEmployee}
          selectedEmployees={selectedEmployees}
          onSelectEmployee={selectEmployee}
          onSelectEmployees={selectEmployees}
        />
        <div className={styles.submit}>
          {isUserContentAdmin && (
            <Button
              disabled={selectedEmployees.length === 0}
              variant="primary"
              style={{marginRight: 8}}
              onClick={() => {
                getBadgesService()
                  .removeBadgeForRecipientApiV1BadgesIdRecipientsDelete(badge.id, {
                    recipients: selectedEmployees.map((e) => e.ldap),
                  })
                  .then(() => {
                    resetEmployees();
                    setSnackbarMessage({text: t('badge_removed')});
                    queryClient.invalidateQueries({queryKey: ['nominations', badge.id]});
                  });
              }}
            >
              {t('remove_badge')}
            </Button>
          )}
          <Button
            disabled={selectedEmployees.length === 0}
            variant="primary"
            onClick={() => {
              getBadgesService()
                .awardBadgeApiV1BadgesIdAwardPost(badge.id, {
                  recipients: selectedEmployees.map((e) => e.ldap),
                })
                .then(() => {
                  resetEmployees();
                  setSnackbarMessage({text: t('awarded')});
                  queryClient.invalidateQueries({queryKey: ['nominations', badge.id]});
                });
            }}
          >
            {isUserContentAdmin ? t('award_badges') : t('submit')}
          </Button>
        </div>
      </div>
    </SelectedItem>
  );
};
