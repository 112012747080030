import {Chip} from '@dropbox/dig-components/dist/chip';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {StarLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {EmployeeFull} from 'client';
import {ButtonLink} from 'components/DSYS/Link';
import {RichTextArea} from 'components/DSYS/RichTextArea';
import {Title} from 'components/DSYS/Title';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {Trans} from 'react-i18next';

import {AboutMeSection} from './AboutMe/AboutMeSection';
import {ProfileBadges} from './AboutMe/ProfileBadges';

const EnneagramData = {
  1: {name: t('enneagram_1_name'), strengths: t('enneagram_1_strenghts')},
  2: {name: t('enneagram_2_name'), strengths: t('enneagram_2_strenghts')},
  3: {name: t('enneagram_3_name'), strengths: t('enneagram_3_strenghts')},
  4: {name: t('enneagram_4_name'), strengths: t('enneagram_4_strenghts')},
  5: {name: t('enneagram_5_name'), strengths: t('enneagram_5_strenghts')},
  6: {name: t('enneagram_6_name'), strengths: t('enneagram_6_strenghts')},
  7: {name: t('enneagram_7_name'), strengths: t('enneagram_7_strenghts')},
  8: {name: t('enneagram_8_name'), strengths: t('enneagram_8_strenghts')},
  9: {name: t('enneagram_9_name'), strengths: t('enneagram_9_strenghts')},
};

const Enneagram = ({enneagram}: {enneagram: null | string}) => {
  if (!enneagram) {
    return null;
  }

  const enneagramNumber = parseInt(enneagram, 10) as keyof typeof EnneagramData;
  if (!Object.keys(EnneagramData).includes(enneagramNumber.toString())) {
    return null;
  }

  const {name, strengths} = EnneagramData[enneagramNumber];
  return (
    <AboutMeSection title={t('enneagram')}>
      <LabelGroup
        align="top"
        withText={
          <Text isBold size="small">
            {name}
          </Text>
        }
        withSubtext={strengths}
        withLeftAccessory={<Title size={20}>{enneagram}</Title>}
      />
    </AboutMeSection>
  );
};

const Ergs = ({ergs}: {ergs: string | null}) => {
  if (!ergs) {
    return null;
  }

  return (
    <AboutMeSection title={t('resource_groups')}>
      <Box display="flex" alignItems="center" flexWrap="wrap" style={{gap: 4}}>
        {ergs.split(',').map((erg) => (
          <Chip size="small" key={erg}>
            {erg}
          </Chip>
        ))}
      </Box>
    </AboutMeSection>
  );
};

const Languages = ({languages}: {languages: string | null}) => {
  if (!languages) {
    return null;
  }

  return (
    <AboutMeSection title={t('languages')}>
      <Box display="flex" alignItems="center" flexWrap="wrap" style={{gap: 4}}>
        {languages.split(',').map((language) => (
          <Chip size="small" key={language}>
            {language}
          </Chip>
        ))}
      </Box>
    </AboutMeSection>
  );
};

export const AboutMe = ({
  employee,
  workingWithMe,
}: {
  employee: EmployeeFull;
  workingWithMe: string | null;
}) => {
  const {employee: loggedInEmployee} = useAtomValue(loggedInEmployeeAtom);

  const showWorkingWithMe = loggedInEmployee.ldap === employee.ldap || workingWithMe;

  const showLeftCell =
    showWorkingWithMe || employee.languages || employee.enneagram || employee.ergs;

  return (
    <Stack
      className={atoms({
        paddingX: '20',
        paddingY: '16',
        borderWidth: '1',
        borderColor: 'Border Subtle',
        borderRadius: 'Medium',
        borderStyle: 'Solid',
      })}
    >
      <Title size={16} withAccessoryStart={<UIIcon src={StarLine} isOpticallyAligned />}>
        {t('settings_working_with_me')}
      </Title>
      <Box display="flex">
        {showLeftCell && (
          <Box
            flexGrow={1}
            flexShrink={1}
            flexBasis={0}
            marginRight="24"
            borderColor="Border Subtle"
            borderWidth="1"
            borderRight="Solid"
          >
            <Stack gap="20" paddingTop="12" paddingBottom="8" paddingRight="24">
              {showWorkingWithMe ? (
                workingWithMe ? (
                  <RichTextArea theme="small-faint" value={workingWithMe} />
                ) : (
                  <Box>
                    <Text color="faint">
                      <Trans
                        i18nKey="about_me_empty_working_with_me"
                        t={t}
                        components={{
                          Button: (
                            <ButtonLink
                              monochromatic
                              variant="transparent"
                              to="/settings"
                              className={atoms({color: 'Text Subtle'})}
                            />
                          ),
                        }}
                      />
                    </Text>
                  </Box>
                )
              ) : (
                <>
                  <Languages languages={employee.languages} />
                  <Enneagram enneagram={employee.enneagram} />
                  <Ergs ergs={employee.ergs} />
                </>
              )}
            </Stack>
          </Box>
        )}
        <Stack
          gap="20"
          paddingTop="12"
          paddingBottom="8"
          className={atoms({
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 0,
          })}
        >
          {showWorkingWithMe && (
            <>
              <Languages languages={employee.languages} />
              <Enneagram enneagram={employee.enneagram} />
              <Ergs ergs={employee.ergs} />
            </>
          )}
          <ProfileBadges ldap={employee.ldap} />
        </Stack>
      </Box>
    </Stack>
  );
};

// import {Chip} from '@dropbox/dig-components/dist/chip';
// import {Toggletip} from '@dropbox/dig-components/dist/tooltips';
// import {Text} from '@dropbox/dig-components/dist/typography';
// import {InfoLine} from '@dropbox/dig-icons/assets';
// import classNames from 'classnames';
// import {EmployeeFull} from 'client';
// import {Link} from 'components/DSYS/Link';
// import {RichTextArea} from 'components/DSYS/RichTextArea';
// import {t} from 'i18next';
// import {FunctionComponent, useCallback} from 'react';

// import styles from './ProfileWorkingWithMe.module.css';

// interface Props {
//   employee: EmployeeFull;
//   workingWithMe?: string | null;
// }

// export const ProfileWorkingWithMe: FunctionComponent<Props> = ({employee, workingWithMe}) => {
//   const mapEnneagramData = useCallback(
//     (value: string): {name: string; strengths: string} | undefined => {
//       switch (value) {
//         case '1': {
//           return {name: t('enneagram_1_name'), strengths: t('enneagram_1_strenghts')};
//         }
//         case '2': {
//           return {name: t('enneagram_2_name'), strengths: t('enneagram_2_strenghts')};
//         }
//         case '3': {
//           return {name: t('enneagram_3_name'), strengths: t('enneagram_3_strenghts')};
//         }
//         case '4': {
//           return {name: t('enneagram_4_name'), strengths: t('enneagram_4_strenghts')};
//         }
//         case '5': {
//           return {name: t('enneagram_5_name'), strengths: t('enneagram_5_strenghts')};
//         }
//         case '6': {
//           return {name: t('enneagram_6_name'), strengths: t('enneagram_6_strenghts')};
//         }
//         case '7': {
//           return {name: t('enneagram_7_name'), strengths: t('enneagram_7_strenghts')};
//         }
//         case '8': {
//           return {name: t('enneagram_8_name'), strengths: t('enneagram_8_strenghts')};
//         }
//         case '9': {
//           return {name: t('enneagram_9_name'), strengths: t('enneagram_9_strenghts')};
//         }
//         default:
//           return undefined;
//       }
//     },
//     []
//   );

//   if (
//     !workingWithMe &&
//     !employee.ergs &&
//     !(employee.enneagram && mapEnneagramData(employee.enneagram)) &&
//     !employee.languages
//   )
//     return <></>;

//   return (
//     <div className={styles.container}>
//       {workingWithMe && (
//         <>
//           <Text isBold size="xlarge">
//             {t('working_with_me')}
//           </Text>
//           <div className={classNames(styles.about, styles.item)}>
//             <Text isBold tagName="div">
//               About {employee.name.split(' ')[0]}
//             </Text>
//             <RichTextArea theme="small-faint" value={workingWithMe} />
//           </div>
//         </>
//       )}
//       <div className={styles.items}>
//         {employee.ergs && (
//           <div className={styles.item}>
//             <Text isBold>{t('resource_groups')}</Text>
//             <div className={styles.chips}>
//               {employee.ergs.split(',').map((erg) => {
//                 return (
//                   <Chip size="small" key={erg}>
//                     {erg}
//                   </Chip>
//                 );
//               })}
//             </div>
//           </div>
//         )}
//         {employee.languages && (
//           <div className={styles.item}>
//             <Text isBold>{t('languages')}</Text>
//             <div className={styles.chips}>
//               {employee.languages.split(',').map((language) => {
//                 return (
//                   <Chip size="small" key={language}>
//                     {language}
//                   </Chip>
//                 );
//               })}
//             </div>
//           </div>
//         )}
//         {employee.enneagram && mapEnneagramData(employee.enneagram) && (
//           <div className={styles.item}>
//             <div className={styles.enneagramTitle}>
//               <Text isBold>{t('enneagram')}</Text>
//               <Toggletip
//                 src={InfoLine}
//                 title={
//                   <>
//                     {t('enneagram_tooltip')}{' '}
//                     <Link
//                       monochromatic
//                       target="_blank"
//                       to="https://www.crystalknows.com/enneagram-test"
//                     >
//                       {t('enneagram_tooltip_link')}
//                     </Link>
//                   </>
//                 }
//               />
//             </div>
//             <div className={styles.enneagram}>
//               <Text size="xlarge" isBold>
//                 {employee.enneagram}
//               </Text>
//               <div>
//                 <Text size="small">{mapEnneagramData(employee.enneagram)?.name}</Text>
//                 <Text size="small" color="faint" tagName="div">
//                   {mapEnneagramData(employee.enneagram)?.strengths}
//                 </Text>
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };
