import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$insertNodeToNearestRoot} from '@lexical/utils';
import {$createCaptureNode, CaptureNode} from 'components/DSYS/editor/nodes/CaptureNode';
import {COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand} from 'lexical';
import {useEffect} from 'react';

export const INSERT_CAPTURE_COMMAND: LexicalCommand<string> =
  createCommand('INSERT_CAPTURE_COMMAND');

export const CapturePlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([CaptureNode])) {
      throw new Error('CapturePlugin: CaptureNode not registered on editor');
    }

    return editor.registerCommand<string>(
      INSERT_CAPTURE_COMMAND,
      (payload) => {
        const youTubeNode = $createCaptureNode(payload);
        $insertNodeToNearestRoot(youTubeNode);

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
};
