/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { KeyResult } from '../models/KeyResult';
import type { KeyResultCreate } from '../models/KeyResultCreate';
import type { KeyResultUpdate } from '../models/KeyResultUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class KeyResultService {

    /**
     * Create Key Result
     * Create new key_result.
     * @param goalId
     * @param userId
     * @param requestBody
     * @returns KeyResult Successful Response
     * @throws ApiError
     */
    public static createKeyResultApiV1GoalsGoalIdKeyResultsPost(
        goalId: number,
        userId: string,
        requestBody: KeyResultCreate,
    ): CancelablePromise<KeyResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/goals/{goal_id}/key_results',
            path: {
                'goal_id': goalId,
            },
            query: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Key Result
     * Get key_result by ID.
     * @param keyResultId
     * @returns KeyResult Successful Response
     * @throws ApiError
     */
    public static readKeyResultApiV1KeyResultsKeyResultIdGet(
        keyResultId: number,
    ): CancelablePromise<KeyResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/key_results/{key_result_id}',
            path: {
                'key_result_id': keyResultId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Key Result
     * Update a key_result.
     * @param keyResultId
     * @param userId
     * @param requestBody
     * @returns KeyResult Successful Response
     * @throws ApiError
     */
    public static updateKeyResultApiV1KeyResultsKeyResultIdPut(
        keyResultId: number,
        userId: string,
        requestBody: KeyResultUpdate,
    ): CancelablePromise<KeyResult> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/key_results/{key_result_id}',
            path: {
                'key_result_id': keyResultId,
            },
            query: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Key Result
     * Delete a key_result.
     * Note: This is a soft delete.
     * @param keyResultId
     * @param userId
     * @param isDryRun
     * @returns KeyResult Successful Response
     * @throws ApiError
     */
    public static deleteKeyResultApiV1KeyResultsKeyResultIdDelete(
        keyResultId: number,
        userId: string,
        isDryRun: boolean = false,
    ): CancelablePromise<KeyResult> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/key_results/{key_result_id}',
            path: {
                'key_result_id': keyResultId,
            },
            query: {
                'user_id': userId,
                'is_dry_run': isDryRun,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Contribute
     * Join a key_result.
     * @param keyResultId
     * @param ownerId
     * @param employeeId
     * @param join
     * @returns KeyResult Successful Response
     * @throws ApiError
     */
    public static contributeApiV1KeyResultsKeyResultIdContributePost(
        keyResultId: number,
        ownerId: string,
        employeeId: string,
        join: boolean = false,
    ): CancelablePromise<KeyResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/key_results/{key_result_id}/contribute',
            path: {
                'key_result_id': keyResultId,
            },
            query: {
                'owner_id': ownerId,
                'join': join,
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Continue Key Result
     * Continue a key_result.
     * @param keyResultId
     * @param ownerId
     * @param timeframe
     * @param createdAt
     * @param employeeId
     * @returns KeyResult Successful Response
     * @throws ApiError
     */
    public static continueKeyResultApiV1KeyResultsKeyResultIdContinuePost(
        keyResultId: number,
        ownerId: string,
        timeframe: string,
        createdAt: string,
        employeeId?: (string | null),
    ): CancelablePromise<KeyResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/key_results/{key_result_id}/continue',
            path: {
                'key_result_id': keyResultId,
            },
            query: {
                'owner_id': ownerId,
                'timeframe': timeframe,
                'created_at': createdAt,
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
