import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Box, withShade} from '@dropbox/dig-foundations';
import {Badge} from 'client';
import {BadgeImage} from 'components/badges/BadgeImage';
import {useState} from 'react';

import {BadgeModal} from './BadgeModal';

export const BadgeItem = ({badge, collected}: {badge: Badge; collected?: number[]}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Tooltip title={badge.name} shouldCloseOnClick>
        <Box
          padding="8"
          borderRadius="Medium"
          onClick={() => setOpen(true)}
          cursor="pointer"
          {...withShade({})}
        >
          <BadgeImage size={32} badge={badge.image_key} />
        </Box>
      </Tooltip>
      {open && (
        <BadgeModal
          badge={badge}
          onRequestClose={() => setOpen(false)}
          collected={collected}
          closeModal={() => setOpen(false)}
        />
      )}
    </>
  );
};
