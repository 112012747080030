import {FormRow} from '@dropbox/dig-components/dist/form_row';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Typeahead} from '@dropbox/dig-components/dist/typeahead';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {SearchLine, TeamLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {Employee, Team, TeamWithCounts} from 'client';
import {useGlobalSearch} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useState} from 'react';

import {Avatar, AvatarIcon} from './Avatar';

const isEmployee = (employeeOrTeam: Employee | Team): employeeOrTeam is Employee =>
  (employeeOrTeam as Employee).ldap !== undefined;

const getUid = (employeeOrTeam: Employee | Team) =>
  isEmployee(employeeOrTeam) ? employeeOrTeam.ldap : (employeeOrTeam.slug ?? '');

export const EmployeeTeamTypeahead = ({
  autoFocus,
  selection,
  onSelect,
}: {
  autoFocus?: boolean;
  selection: string;
  onSelect?: (name: string, type: string, id: string) => void;
}) => {
  const [userInputValue, setUserInputValue] = useState('');
  const {data: searchResult} = useGlobalSearch({
    input: userInputValue,
    filter: 'teams,managers' as any,
    showMore: true,
  });

  const searchedResults = searchResult
    .map((result) => result as Employee | TeamWithCounts)
    .filter(Boolean);
  const employeeHints = searchedResults.length ? searchedResults : [];
  const filteredEmployeeHints = employeeHints.filter(
    (node) => getUid(node) !== selection && ((node as any).team_id || (node as any).user_id)
  );

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInputValue(event.currentTarget.value);
  };

  const handleSelection = (node: string) => {
    const selected = employeeHints.find((e) => getUid(e) === node);
    if (selected) {
      onSelect?.(selected.name ?? '', isEmployee(selected) ? 'employee' : 'team', getUid(selected));
      setUserInputValue('');
    }
  };

  const renderTypeaheadRow = (node: Employee | TeamWithCounts) =>
    isEmployee(node) ? (
      <Typeahead.Row
        key={node.user_id}
        value={node.ldap}
        withTitle={<Text isBold>{node.name}</Text>}
        withSubtitle={node.role}
        withLeftAccessory={<Avatar user={node} />}
      />
    ) : (
      <Typeahead.Row
        key={node.slug}
        value={node.slug}
        withTitle={<Text isBold>{node.name}</Text>}
        withSubtitle={
          t('member', {
            count: node.members_count ?? 0,
            countString: node.members_count ?? 0,
          }) +
          (node.subteams_count
            ? ` • ${t('sub_team', {
                count: node.subteams_count ?? 0,
                countString: node.subteams_count ?? 0,
              })}`
            : '')
        }
        withLeftAccessory={<AvatarIcon src={TeamLine} />}
      />
    );

  return (
    <Typeahead.Wrapper onSelection={handleSelection}>
      {({getTriggerProps, getContentProps}) => (
        <FormRow>
          <TextInput
            autoFocus={autoFocus}
            size="large"
            placeholder={t('search_individuals_or_groups')}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck={false}
            value={userInputValue}
            withLeftAccessory={<Box as={UIIcon} src={SearchLine} color="Text Subtle" />}
            {...getTriggerProps({onChange: onInputChange, onKeyDown: (e) => e.stopPropagation()})}
          />

          <Typeahead.Container {...getContentProps()}>
            <Typeahead.Results
              maxResults={8}
              results={filteredEmployeeHints}
              renderRow={renderTypeaheadRow}
            />
          </Typeahead.Container>
        </FormRow>
      )}
    </Typeahead.Wrapper>
  );
};
