import {Button} from '@dropbox/dig-components/buttons';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Spinner} from '@dropbox/dig-components/dist/progress_indicators';
import {Tabs} from '@dropbox/dig-components/dist/tabs';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {LocationLine} from '@dropbox/dig-icons/assets';
import {useQuery} from '@tanstack/react-query';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {EmployeeLocation, EmployeeService} from 'client';
import {Link} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import ProfileCard from 'components/shared/ProfileCard';
import {t} from 'i18next';
import {useCallback, useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import {getService} from 'utilities';

import styles from './EmployeeLocations.module.css';

const MAX_EMPLOYEES_DISPLAYED = 48;

const capitalizeWords = (wordsSring: string) => {
  const words = wordsSring.split(' ');
  const formattedWords = words.map((word) => {
    if (word.toLowerCase().startsWith('non-')) return word; // exclude "non-"
    return word.toLowerCase().charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  return formattedWords.filter((word) => !!word).join(' ');
};

export const formatLocation = (location?: string | null): string => {
  if (!location) {
    return location ?? '';
  }
  if (location.indexOf(' - ') >= 0) {
    return location;
  }
  const words = location.split(',');
  if (!words.length) {
    return location;
  }
  if (words.length == 1) {
    return capitalizeWords(words[0]);
  }
  if (words.length == 2) {
    const formattedWords = capitalizeWords(words[0]);
    const formattedState = words[1].toUpperCase();
    return [formattedWords, formattedState].join(',');
  }
  return location;
};

export const toMetro = (location: string) => {
  const metroSplit = location?.split(' - ');
  const metro = metroSplit?.[metroSplit.length - 1];

  return formatLocation(metro);
};

const toMap = (location: string) => {
  const metroSplit = location?.split(' - ');
  const metro = metroSplit?.[metroSplit.length - 1];

  if (metro?.startsWith('non-')) {
    try {
      return metroSplit?.[metroSplit.length - 2];
    } catch (e) {
      return metro;
    }
  }
  return metro;
};

const ModalSpinner = () => {
  return (
    <Box width="100%" display="flex" justifyContent="center" paddingTop="132" paddingBottom="132">
      <Spinner monochromatic />
    </Box>
  );
};

const LocationLink = ({
  location,
  showMetroOnly = false,
}: {
  location: string;
  showMetroOnly?: boolean;
}) => {
  return (
    <Link
      monochromatic
      to={'https://www.google.com/maps/place/' + toMap(location).replace(/Metro$/, '')}
      target="_blank"
    >
      {showMetroOnly ? toMetro(location) : location}
    </Link>
  );
};

const LocationBody = ({
  selectedTab,
  employees,
  location,
  handleClose,
  showAll,
  setShowAll,
  showMetroOnly,
  showNearbyText,
  showEmployeeCity,
}: {
  selectedTab: string;
  employees: EmployeeLocation[];
  location: string;
  handleClose: () => void;
  showAll: boolean;
  setShowAll: () => void;
  showMetroOnly?: boolean;
  showNearbyText?: boolean;
  showEmployeeCity?: boolean;
}) => {
  useEffect(() => {
    if (employees) {
      analyticsLogger().logEvent('PROFILE_EMPLOYEE_LOCATION_DISPLAYED', {
        view: selectedTab,
        location,
        resultsCount: employees.length,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowAll = useCallback(() => {
    if (employees.length) {
      analyticsLogger().logEvent('PROFILE_EMPLOYEE_LOCATION_VIEW_ALL_CLICKED', {
        view: selectedTab,
        location,
        resultsCount: employees.length,
      });
      setShowAll();
    }
  }, [selectedTab, location, employees, setShowAll]);

  const employeesToDisplay = showAll ? employees : employees.slice(0, MAX_EMPLOYEES_DISPLAYED);
  return (
    <>
      <Box paddingY="16">
        <Text color="faint">
          <Trans
            i18nKey={showNearbyText ? 'people_nearby' : 'people_in'}
            t={t}
            values={{count: employees.length}}
            components={{
              Text: <Text isBold color="faint" />,
              Location: (
                <LocationLink location={formatLocation(location)} showMetroOnly={showMetroOnly} />
              ),
            }}
          />
        </Text>
      </Box>

      <Box padding="16" paddingTop="0" style={{maxHeight: 600, overflow: 'auto'}}>
        <Box style={{display: 'grid', gridTemplateColumns: '1fr 1fr', width: '100%', gap: 8}}>
          {employeesToDisplay?.map((employee) => {
            return (
              <ProfileCard
                key={employee.user_id}
                source="locations"
                profile_username={employee?.ldap}
                profile_photo_url={employee?.profile_photo_url}
                display_name={employee?.name}
                profile_info={employee?.role ?? ''}
                isDeactivated={employee?.is_deactivated}
                onClick={handleClose}
                city={showEmployeeCity ? formatLocation(employee?.city) : null}
                showEmployeeCity={showEmployeeCity}
              />
            );
          })}
        </Box>
        {!showAll && employees.length > MAX_EMPLOYEES_DISPLAYED && (
          <Button
            className={styles.showAllButton}
            variant="transparent"
            size="small"
            onClick={handleShowAll}
          >
            {t('view_all')}
          </Button>
        )}
      </Box>
    </>
  );
};

const EmployeeCity = ({
  selectedTab,
  city,
  handleClose,
}: {
  selectedTab: string;
  city: string;
  handleClose: () => void;
}) => {
  const [showAll, setShowAll] = useState(false);
  const {data} = useQuery({
    queryKey: ['city', city],
    queryFn: () => getService(EmployeeService).cityApiV1PeopleCityCityGet(city),
  });

  if (!data) {
    return <ModalSpinner />;
  }

  return (
    <LocationBody
      selectedTab={selectedTab}
      employees={data}
      location={city}
      handleClose={handleClose}
      showAll={showAll}
      setShowAll={() => setShowAll(true)}
      showEmployeeCity
    />
  );
};

const usePeopleNearbyCities = (city: string) => {
  return useQuery({
    queryKey: ['nearby-cities', city],
    queryFn: () => getService(EmployeeService).nearbyCitiesApiV1PeopleNearbyCitiesCityGet(city),
    enabled: !!city,
  });
};

const EmployeeNearbyCities = ({
  selectedTab,
  city,
  handleClose,
}: {
  selectedTab: string;
  city: string;
  handleClose: () => void;
}) => {
  const [showAll, setShowAll] = useState(false);
  const {data} = usePeopleNearbyCities(city);

  if (!data) {
    return <ModalSpinner />;
  }

  return (
    <LocationBody
      selectedTab={selectedTab}
      employees={data}
      location={city}
      handleClose={handleClose}
      showAll={showAll}
      setShowAll={() => setShowAll(true)}
      showNearbyText
      showEmployeeCity
    />
  );
};

const EmployeeGreaterArea = ({
  selectedTab,
  location,
  handleClose,
}: {
  selectedTab: string;
  location: string;
  handleClose: () => void;
}) => {
  const [showAll, setShowAll] = useState(false);
  const {data} = useQuery({
    queryKey: ['location', location],
    queryFn: () => getService(EmployeeService).locationApiV1PeopleLocationLocationGet(location),
  });

  if (!data) {
    return <ModalSpinner />;
  }

  return (
    <LocationBody
      selectedTab={selectedTab}
      employees={data}
      location={location}
      handleClose={handleClose}
      showAll={showAll}
      setShowAll={() => setShowAll(true)}
      showMetroOnly
      showEmployeeCity
    />
  );
};

export const EmployeeLocations = ({
  city,
  location,
  handleClose,
}: {
  city?: string | null;
  location?: string | null;
  handleClose: () => void;
}) => {
  const [selectedTab, setSelectedTab] = useState(city ? 'city' : 'location');

  useEffect(() => {
    analyticsLogger().logEvent('PROFILE_EMPLOYEE_LOCATION_OPENED', {view: selectedTab});
  }, [selectedTab]);

  const {data: nearbyCitiesData = []} = usePeopleNearbyCities(city ?? '');

  return (
    <Modal open onRequestClose={handleClose} withCloseButton="Close" width="large">
      <Modal.Header>
        <Title
          withAccessoryStart={
            <Box
              as={UIIcon}
              size="medium"
              isOpticallyAligned
              src={LocationLine}
              style={{marginLeft: '-5px'}}
            />
          }
        >
          {t('people_nearby')}
        </Title>
      </Modal.Header>

      <Modal.Body style={{marginTop: -16}}>
        <Tabs selectedTab={selectedTab} onSelection={setSelectedTab}>
          {city && (
            <Tabs.Group hasHorizontalPadding={false} hasDivider={false}>
              <Tabs.Tab id="city">{city}</Tabs.Tab>

              {!!nearbyCitiesData.length && (
                <Tabs.Tab id="nearbyCities">{t('nearby_cities')}</Tabs.Tab>
              )}

              <Tabs.Tab id="location">{t('greater_area')}</Tabs.Tab>
            </Tabs.Group>
          )}

          {city && (
            <Tabs.Panel tabId="city">
              <EmployeeCity selectedTab={selectedTab} city={city} handleClose={handleClose} />
            </Tabs.Panel>
          )}
          {city && !!nearbyCitiesData.length && (
            <Tabs.Panel tabId="nearbyCities">
              <EmployeeNearbyCities
                selectedTab={selectedTab}
                city={city}
                handleClose={handleClose}
              />
            </Tabs.Panel>
          )}
          {location && (
            <Tabs.Panel tabId="location">
              <EmployeeGreaterArea
                selectedTab={selectedTab}
                location={location}
                handleClose={handleClose}
              />
            </Tabs.Panel>
          )}
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};
