import {useTeamCreate} from './hooks';
import {TeamModify} from './TeamModify';

export const TeamCreate = () => {
  const {createTeam, isPending} = useTeamCreate();

  return (
    <TeamModify team={undefined} isPending={isPending} onSubmit={({data}) => createTeam({data})} />
  );
};
