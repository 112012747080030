import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  MoreHorizontalLine,
  PersonMultipleLine,
  TargetLine,
  TeamLine,
} from '@dropbox/dig-icons/assets';
import {t} from 'i18next';
import {To, useNavigate} from 'react-router-dom';

export const DashboardsActionMenu = ({
  isVisible,
  viewGoalsPath,
  viewProfilePath,
  viewTeamPath,
  style = {},
}: {
  isVisible: boolean;
  viewGoalsPath: To;
  viewProfilePath?: To;
  viewTeamPath?: To;
  style?: React.CSSProperties;
}) => {
  const navigate = useNavigate();

  return (
    <Box style={{...style, visibility: isVisible ? 'visible' : 'hidden'}}>
      <Menu.Wrapper
        onClick={(e: React.SyntheticEvent) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onMouseLeave={(e: React.SyntheticEvent) => {
          e.stopPropagation();
        }}
      >
        {({getContentProps, getTriggerProps}) => (
          <>
            <IconButton {...getTriggerProps()} variant="borderless" shape="circular" size="small">
              <UIIcon src={MoreHorizontalLine} />
            </IconButton>
            <Menu.Content {...getContentProps()} minWidth="214px">
              <Menu.Segment>
                <Menu.ActionItem
                  key="view_goals"
                  value="view_goals"
                  withLeftAccessory={
                    <UIIcon src={TargetLine} color="var(--dig-color__text__subtle)" />
                  }
                  onClick={() => navigate(viewGoalsPath)}
                >
                  {t('view_goals')}
                </Menu.ActionItem>
                {viewProfilePath && (
                  <Menu.ActionItem
                    key="view_profile"
                    value="view_profile"
                    withLeftAccessory={
                      <UIIcon src={PersonMultipleLine} color="var(--dig-color__text__subtle)" />
                    }
                    onClick={() => navigate(viewProfilePath)}
                  >
                    {t('view_profile')}
                  </Menu.ActionItem>
                )}
                {viewTeamPath && (
                  <Menu.ActionItem
                    key="view_team"
                    value="view_team"
                    withLeftAccessory={
                      <UIIcon src={TeamLine} color="var(--dig-color__text__subtle)" />
                    }
                    onClick={() => navigate(viewTeamPath)}
                  >
                    {t('view_team')}
                  </Menu.ActionItem>
                )}
              </Menu.Segment>
            </Menu.Content>
          </>
        )}
      </Menu.Wrapper>
    </Box>
  );
};
