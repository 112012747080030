// Team -> area mapping

import {Project, TeamWithHierarchy} from 'client';

function createParentMap(
  node: TeamWithHierarchy,
  parentMap: {[key: string]: string | null},
  parent: TeamWithHierarchy | null = null
): void {
  if (!node) return;

  // eslint-disable-next-line no-param-reassign
  parentMap[node.team_id] = parent ? parent.team_id : null;

  for (const child of node.children ?? []) {
    createParentMap(child, parentMap, node);
  }
}

export const teamToArea = (
  teamStructure: TeamWithHierarchy,
  teamId: string
): TeamWithHierarchy | null => {
  const parentMap: {[key: string]: string | null} = {};
  createParentMap(teamStructure, parentMap);

  let currentTeamId = teamId;

  // Traverse up to find the highest ancestor below the root
  while (parentMap[currentTeamId] !== teamStructure.team_id && parentMap[currentTeamId]) {
    currentTeamId = parentMap[currentTeamId]!;
  }

  if (currentTeamId === teamStructure.team_id) {
    return teamStructure;
  } else {
    return teamStructure.children?.find((child) => child.team_id === currentTeamId) || null;
  }
};

const toArea = (
  teamStructure: TeamWithHierarchy,
  parentMap: {[key: string]: string | null},
  teamId: string
): TeamWithHierarchy | null => {
  let currentTeamId = teamId;

  // Traverse up to find the highest ancestor below the root
  while (parentMap[currentTeamId] !== teamStructure.team_id && parentMap[currentTeamId]) {
    currentTeamId = parentMap[currentTeamId]!;
  }

  if (currentTeamId === teamStructure.team_id) {
    return teamStructure;
  } else {
    return teamStructure.children?.find((child) => child.team_id === currentTeamId) || null;
  }
};

export const teamToFullChainToRoot = (
  teamStructure: TeamWithHierarchy,
  teamId: string
): TeamWithHierarchy[] | null => {
  const parentMap: {[key: string]: string | null} = {};
  createParentMap(teamStructure, parentMap);

  let currentTeamId = teamId;
  const chain: TeamWithHierarchy[] = [];

  while (parentMap[currentTeamId]) {
    const team = teamStructure.children?.find((child) => child.team_id === currentTeamId);
    if (!team) return null;
    chain.push(team);
    currentTeamId = team.team_id;
  }

  return chain;
};

// Group projects by area:
export const groupByArea = (area: TeamWithHierarchy, projects: Project[]) => {
  const groupedProjects: {[teamSlug: string]: {team: TeamWithHierarchy; projects: Project[]}} = {};

  const parentMap: {[key: string]: string | null} = {};
  createParentMap(area, parentMap);

  for (const project of projects) {
    if (project?.team) {
      const teamArea = toArea(area, parentMap, project.team.team_id);
      if (teamArea?.slug) {
        if (!groupedProjects[teamArea.slug]) {
          groupedProjects[teamArea.slug] = {team: teamArea, projects: []};
        }
        groupedProjects[teamArea.slug].projects.push(project);
      }
    }
  }

  const sortedGroups = Object.values(groupedProjects).sort((a, b) =>
    (a.team.name ?? '').localeCompare(b.team.name ?? '')
  );

  return sortedGroups;
};
