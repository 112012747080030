import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  ConfluenceExternalLogo,
  DropboxCaptureLine,
  DropboxPaperLine,
  FigmaExternalLogo,
  JiraExternalLogo,
  LinkLine,
  SlackExternalLogo,
} from '@dropbox/dig-icons/assets';

export const linkToIcon = (link: string) => {
  try {
    const url = new URL(link);
    const host = url.host;
    const pathname = url.pathname;

    if (host === 'www.dropbox.com') {
      if (pathname.endsWith('.paper')) {
        return 'paper';
      } else {
        return 'folder';
      }
    } else if (host.endsWith('slack.com')) {
      return 'slack';
    } else if (host.endsWith('atlassian.net')) {
      return 'confluence';
    } else if (host === 'jira.dropboxer.net') {
      return 'jira';
    } else if (host === 'www.figma.com') {
      return 'figma';
    } else if (host === 'capture.dropbox.com') {
      return 'capture';
    }

    return 'link';
  } catch (e) {
    return 'link';
  }
};

const linkIconMap = {
  slack: SlackExternalLogo,
  paper: DropboxPaperLine,
  folder: DropboxPaperLine,
  capture: DropboxCaptureLine,
  figma: FigmaExternalLogo,
  confluence: ConfluenceExternalLogo,
  jira: JiraExternalLogo,
  link: LinkLine,
};

export const LinkIcon = ({icon}: {icon?: string}) => (
  <Box
    as={UIIcon}
    color="Text Subtle"
    src={linkIconMap[(icon ?? 'link') as keyof typeof linkIconMap]}
  />
);
