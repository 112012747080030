/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Comment } from '../models/Comment';
import type { CommentCreate } from '../models/CommentCreate';
import type { CommentUpdate } from '../models/CommentUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CommentService {

    /**
     * Create Comment Reply
     * Create a new comment reply.
     * @param requestBody
     * @returns Comment Successful Response
     * @throws ApiError
     */
    public static createCommentReplyApiV1CommentReplyPost(
        requestBody: CommentCreate,
    ): CancelablePromise<Comment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/comment/reply',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Comment
     * Update a comment.
     * @param commentId
     * @param requestBody
     * @returns Comment Successful Response
     * @throws ApiError
     */
    public static updateCommentApiV1CommentCommentIdPut(
        commentId: string,
        requestBody: CommentUpdate,
    ): CancelablePromise<Comment> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/comment/{comment_id}',
            path: {
                'comment_id': commentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Comment
     * Delete a comment.
     * @param commentId
     * @returns Comment Successful Response
     * @throws ApiError
     */
    public static deleteCommentApiV1CommentCommentIdDelete(
        commentId: string,
    ): CancelablePromise<Comment> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/comment/{comment_id}',
            path: {
                'comment_id': commentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
