export function getTodayUTC9am() {
  const now = new Date();
  return new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 9));
}

export function toEODISOString(isoString?: string | null) {
  if (!isoString) {
    return undefined;
  }
  const date = new Date(isoString);
  date.setUTCHours(23, 59, 59, 999);
  return date.toISOString().slice(0, -1);
}

export function toCalendarDate(isoString?: string | null) {
  if (!isoString) {
    return getTodayUTC9am();
  }
  const datePart = isoString.split('T')[0];
  const [year, month, day] = datePart.split('-').map((num) => parseInt(num, 10));

  return new Date(year, month - 1, day);
}

export function fromServerDate(isoString?: string) {
  if (!isoString) {
    return getTodayUTC9am();
  }
  const isoStringWithUtc = isoString.endsWith('Z') ? isoString : `${isoString}Z`;
  return new Date(isoStringWithUtc);
}

export const toServerDate = (sendAt: string, value: Date | string) => {
  if (typeof value === 'string') {
    const updated = toCalendarDate(sendAt);
    updated.setUTCHours(parseInt(value, 10));
    return updated.toISOString().slice(0, -1);
  }

  const updated = toCalendarDate(value.toISOString());
  updated.setUTCHours(new Date(sendAt).getHours());
  return updated.toISOString().slice(0, -1);
};
