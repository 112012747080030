type LexicalNode = {
  type: string;
  text?: string;
  children?: LexicalNode[];
};

type LexicalJson = {
  root: LexicalNode;
};

const extractTextFromNode = (node: LexicalNode): string => {
  let text = '';

  if (node.type === 'text' && node.text) {
    text += node.text;
  } else if (node.children) {
    node.children.forEach((child) => {
      text += extractTextFromNode(child);
    });

    if (node.type === 'paragraph' || node.type === 'listitem') {
      text += '\n';
    }
  }

  return text;
};

const extractTextFromLexicalJson = (json: LexicalJson): string => {
  const {root} = json;
  return extractTextFromNode(root).trim(); // Remove trailing newline
};

export const parseUpdateComment = (comment: string) => {
  try {
    return extractTextFromLexicalJson(JSON.parse(comment));
  } catch (e) {
    return '';
  }
};

const getLines = (text: string, maxLineLength: number) => {
  const words = text.split(' ');
  const lines: string[] = [];
  let currentLine = '';

  words.forEach((word) => {
    if ((currentLine + word).length > maxLineLength) {
      lines.push(currentLine.trim());
      currentLine = word + ' ';
    } else {
      currentLine += word + ' ';
    }
  });

  if (currentLine) {
    lines.push(currentLine.trim());
  }

  return lines;
};

export const getHeight = (json?: string) => {
  if (!json) {
    return '56px';
  }
  try {
    const text = extractTextFromLexicalJson(JSON.parse(json));
    const maxLineLength = 25;
    const lines = getLines(text, maxLineLength);
    if (lines.length == 1) {
      return '76px';
    }
    if (lines.length == 2) {
      return '88px';
    }
    return '96px';
  } catch (e) {
    return '96px';
  }
};
