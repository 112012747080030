import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ChevronLeftLine, ChevronRightLine} from '@dropbox/dig-icons/assets';
import {useSuspenseQuery} from '@tanstack/react-query';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {Pulse, PulseGrouping, PulseService} from 'client';
import {Title} from 'components/DSYS/Title';
import ProfileCard from 'components/shared/ProfileCard';
import {sortEmployees} from 'helpers/utils';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {Suspense, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {getService} from 'utilities';

import {PulseBreadcrumbs} from './PulseBreadcrumbs';
import {toPulseGroup} from './utils';

const EmployeeBody = ({
  grouping,
  handleClose,
}: {
  grouping?: PulseGrouping;
  handleClose: () => void;
}) => {
  const {
    employee: {team, ldap},
  } = useAtomValue(loggedInEmployeeAtom);
  const {type, slug} = grouping
    ? toPulseGroup(grouping)
    : {type: 'pillar', slug: team?.split(' ').join('-').toLocaleLowerCase() ?? '—'};
  const {data} = useSuspenseQuery({
    queryKey: ['pulse', 'employee', type, slug],
    queryFn: () => getService(PulseService).getEmployeesApiV1PulseGroupSlugEmployeeGet(type, slug),
  });

  return (
    <Modal.Body>
      <Box padding="24" paddingTop="0" style={{maxHeight: 600, overflow: 'auto'}}>
        <Box style={{display: 'grid', gridTemplateColumns: '1fr 1fr', width: '100%', gap: 8}}>
          {data
            .sort(sortEmployees)
            ?.filter((employee) => employee.ldap === ldap)
            .map((employee) => (
              <ProfileCard
                key={employee.user_id}
                source="pulse"
                profile_username={employee?.ldap}
                profile_photo_url={employee?.profile_photo_url}
                display_name={employee?.name}
                profile_info={employee?.role ?? ''}
                isDeactivated={employee?.is_deactivated}
                onClick={handleClose}
              />
            ))}
        </Box>
      </Box>
    </Modal.Body>
  );
};

const EmployeeListModal = (props: {
  count: number;
  team: string;
  grouping?: PulseGrouping;
  handleClose: () => void;
}) => (
  <Modal open onRequestClose={props.handleClose} withCloseButton="Close" width="large">
    <Modal.Title weightVariant="emphasized">
      <Box paddingX="24" paddingTop="20" paddingBottom="16">
        {props.grouping?.manager ? t('reports', {count: props.count}) : `Members of ${props.team}`}
      </Box>
    </Modal.Title>
    <Suspense
      fallback={
        <Box as={Skeleton.Box} marginX="24" marginY="16" style={{height: 200, width: 'auto'}} />
      }
    >
      <EmployeeBody {...props} />
    </Suspense>
  </Modal>
);

export const PulseHeader = ({
  pulse,
  grouping,
  employeeCount,
  responsesCount,
  pillar,
  selected,
  inProgress,
  previousId,
  nextId,
}: {
  pulse?: Pulse;
  grouping?: PulseGrouping;
  employeeCount: number;
  responsesCount: number;
  pillar: string;
  selected: string;
  inProgress: boolean;
  previousId?: number;
  nextId?: number;
}) => {
  const navigate = useNavigate();
  const [open, setModalOpen] = useState(false);
  const date = new Date(pulse?.survey_end_date ?? new Date()).toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  });

  return (
    <Stack gap="24">
      <PulseBreadcrumbs
        root="/pulse"
        team={{name: pillar, slug: pillar.toLowerCase().split(' ').join('-')}}
        grouping={grouping}
      />
      <Split alignY="center">
        <Split.Item width="fill">
          <Stack>
            <Title size={24}>{selected}</Title>
            <Split alignY="center">
              <Box
                as={Button}
                display="inline"
                size="small"
                variant="transparent"
                marginRight="4"
                onClick={() => setModalOpen(true)}
              >
                <Text color="faint" size="small" tagName="div">
                  {t(grouping?.manager ? 'reports' : 'member', {
                    count: employeeCount,
                    countString: employeeCount.toLocaleString(),
                  })}
                </Text>
              </Box>
              <Text color="faint" size="small" tagName="div">
                {inProgress ? '• In progress • ' : '• '}
                {Math.round((responsesCount / employeeCount) * 100)}% participation
              </Text>
            </Split>
          </Stack>
        </Split.Item>
        <Split.Item>
          <Split alignY="center" gap="12">
            <Split.Item>
              <IconButton
                shape="circular"
                variant="outline"
                size="small"
                disabled={!previousId}
                onClick={() => navigate(`/pulse/${previousId}`)}
              >
                <UIIcon src={ChevronLeftLine} />
              </IconButton>
            </Split.Item>
            <Split.Item>
              <Text size="large">{date}</Text>
            </Split.Item>
            <Split.Item>
              <IconButton
                shape="circular"
                variant="outline"
                size="small"
                disabled={!nextId}
                onClick={() => navigate(`/pulse/${nextId}`)}
              >
                <UIIcon src={ChevronRightLine} />
              </IconButton>
            </Split.Item>
          </Split>
        </Split.Item>
      </Split>
      {open && (
        <EmployeeListModal
          count={employeeCount}
          team={selected}
          grouping={grouping}
          handleClose={() => setModalOpen(false)}
        />
      )}
    </Stack>
  );
};
