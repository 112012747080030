import {Link} from 'components/DSYS/Link';

// Convert JIRA tags and links to clickable links
export const ConvertLinks = ({
  text,
  isBold = true,
  hasNoUnderline = true,
}: {
  text?: string;
  isBold?: boolean;
  hasNoUnderline?: boolean;
}) => {
  const jiraRegex = /[A-Z]+-\d+/g;
  const linkRegex = /https?:\/\/[^\s]+/g;
  const combinedRegex = new RegExp(`(${jiraRegex.source})|(${linkRegex.source})`, 'g');

  return (
    <>
      {text?.split(combinedRegex)?.map((part, index) => {
        const isJira = jiraRegex.test(part);
        jiraRegex.lastIndex = 0; // Reset the state

        if (linkRegex.test(part) || isJira) {
          linkRegex.lastIndex = 0; // Reset the state
          return (
            <Link
              key={index}
              isBold={isBold}
              hasNoUnderline={hasNoUnderline}
              monochromatic
              target="_blank"
              to={isJira ? `https://jira.dropboxer.net/browse/${part}` : part}
            >
              {part}
            </Link>
          );
        }
        return part;
      })}
    </>
  );
};
