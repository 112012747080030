import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {atoms, Box, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {AddLine, DeleteLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {useCallback, useEffect, useRef} from 'react';

import styles from './RowEditor.module.css';

export const RowEditor = ({
  placeholder,
  values = [''],
  isInvalid,
  isValueValid,
  setValues,
}: {
  placeholder: string;
  values?: string[];
  isInvalid: boolean;
  isValueValid: (value: string) => boolean;
  setValues: (values: string[]) => void;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const addLink = useCallback(() => setValues([...values, '']), [setValues, values]);
  const removeLink = (index: number) => setValues(values?.filter((_, i) => i !== index) ?? []);

  const handleChange = (value: string, index: number) =>
    setValues([...values.slice(0, index), value, ...values.slice(index + 1)]);

  useEffect(() => {
    const handleEnter = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        addLink();

        // Focus new link
        setTimeout(() => {
          const inputs = containerRef.current?.querySelectorAll('input');
          const lastInput = inputs?.[inputs.length - 1];
          lastInput?.focus();
        }, 0);
      }
    };

    containerRef.current?.addEventListener('keydown', handleEnter);
    return () => containerRef.current?.removeEventListener('keydown', handleEnter);
  }, [addLink]);

  return (
    <div ref={containerRef}>
      <Stack gap="8">
        {values.map((value, index) => (
          <Box key={index} className={values.length > 1 ? styles.row : undefined}>
            <TextInput
              size="large"
              placeholder={placeholder}
              isInvalid={isInvalid && !isValueValid(value)}
              withRightAccessory={
                <IconButton
                  className={styles.delete}
                  shape="circular"
                  variant="borderless"
                  onClick={() => removeLink(index)}
                >
                  <UIIcon
                    src={DeleteLine}
                    size="medium"
                    className={atoms({color: 'Border Base'})}
                  />
                </IconButton>
              }
              value={value}
              onChange={(e) => handleChange(e.target.value, index)}
            />
          </Box>
        ))}
        <IconButton onClick={addLink} variant="outline" size="small">
          <UIIcon src={AddLine} className={atoms({color: 'Border Base'})} />
        </IconButton>
      </Stack>
    </div>
  );
};
