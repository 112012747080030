import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split} from '@dropbox/dig-foundations';
import {Employee} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {ButtonLink} from 'components/DSYS/Link';
import {OptionalTooltip} from 'components/DSYS/OptionalTooltip';

export const ProfileAvatarName = ({
  user,
  detail,
  tooltip,
  size = 'medium',
  excludeName,
  source,
}: {
  user: Employee;
  detail: string | null;
  source: string;
  tooltip?: string;
  size?: 'small' | 'medium';
  excludeName?: boolean;
}) => (
  <ButtonLink
    to={`/people/${user.ldap}`}
    variant="borderless"
    style={{marginLeft: excludeName ? '2px' : '-4px'}}
    state={{source}}
  >
    <Split alignY="center" gap="4">
      <Split.Item
        style={{
          marginLeft: excludeName ? '-8px' : '2px',
          marginRight: excludeName ? '-12px' : '0',
        }}
      >
        <Avatar size="small" user={user} />
      </Split.Item>
      {excludeName ? (
        <></>
      ) : (
        <Split.Item>
          <Text isBold size={size}>
            {user.name}
          </Text>
          {detail && (
            <OptionalTooltip enabled={Boolean(tooltip)} title={tooltip}>
              <Box as={Text} marginLeft="4" size="small" color="Text Subtle">
                ({detail})
              </Box>
            </OptionalTooltip>
          )}
        </Split.Item>
      )}
    </Split>
  </ButtonLink>
);
