/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_create_gratitude_api_v1_gratitudes_create_post } from '../models/Body_create_gratitude_api_v1_gratitudes_create_post';
import type { Gratitude } from '../models/Gratitude';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GratitudeService {

    /**
     * Get Gratitudes Sent By User
     * @returns Gratitude Successful Response
     * @throws ApiError
     */
    public static getGratitudesSentByUserApiV1GratitudesSentUserIdGet(): CancelablePromise<Array<Gratitude>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/gratitudes/sent/{user_id}',
        });
    }

    /**
     * Get Gratitudes Sent To User
     * @returns Gratitude Successful Response
     * @throws ApiError
     */
    public static getGratitudesSentToUserApiV1GratitudesReceivedUserIdGet(): CancelablePromise<Array<Gratitude>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/gratitudes/received/{user_id}',
        });
    }

    /**
     * Create Gratitude
     * @param requestBody
     * @param sender
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createGratitudeApiV1GratitudesCreatePost(
        requestBody: Body_create_gratitude_api_v1_gratitudes_create_post,
        sender?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/gratitudes/create',
            query: {
                'sender': sender,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
