import {pulseTokenAtom} from 'atoms/auth';
import {PageSpinner} from 'components/shared/PageSpinner';
import {reportAndLogError} from 'helpers/logging';
import {useSetAtom} from 'jotai';
import {useEffect} from 'react';
import {getLoginService, getLogoutUrl} from 'utilities';

const Logout = () => {
  const setPulseToken = useSetAtom(pulseTokenAtom);

  useEffect(() => {
    getLoginService()
      .routeLogoutAndRemoveCookieApiV1AuthLogoutGet()
      .then(() => {})
      .catch((error) => {
        reportAndLogError(error, 'Failed to logout');
      })
      .finally(() => {
        setPulseToken('');
        window.location.href = getLogoutUrl();
      });
  }, [setPulseToken]);

  return <PageSpinner />;
};

export {Logout};
