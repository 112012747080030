import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {DeleteLine, MoreHorizontalLine, TeamLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {BadgeMini} from '@dropbox/dig-illustrations';
import {EmployeeWithReportTeams, TeamAssociation, TeamWithCounts} from 'client';
import {t} from 'i18next';
import {useEffect, useState} from 'react';
import {getCheckupError} from 'views/tools/util';

import {Avatar, AvatarIcon} from './Avatar';
import styles from './EmployeeListTypeahead.module.css';
import {EmptyState} from './EmptyState';
import {OptionalTooltip} from './OptionalTooltip';
import {TeamTypeahead} from './TeamTypeahead';

// type TeamAllocation = TeamAssociation & {allocation: number};

export const SavableTeamListTypeahead = ({
  isPending,
  employee,
  memberSort,
  withOverflowMenuItems,
  withRightAccessory,
  onSave,
  onCancel,
  onChange,
}: {
  isPending?: boolean;
  employee: Omit<EmployeeWithReportTeams, 'reports_error'>;
  memberSort?: (a: TeamAssociation, b: TeamAssociation) => number;
  withOverflowMenuItems?: (employee: TeamAssociation) => React.ReactNode;
  withRightAccessory?: (employee: TeamAssociation) => React.ReactNode;
  onSave: (selections: TeamAssociation[]) => void;
  onCancel: () => void;
  onChange?: (selections: TeamAssociation[]) => void;
}) => {
  const [selections, setSelections] = useState(
    employee.team_associations?.map(({team, allocation}) => ({
      team,
      allocation:
        allocation ?? parseInt(((1 / employee.team_associations.length) * 100).toFixed(), 10),
    }))
  );

  useEffect(() => {
    const hasAllocation = employee.team_associations?.some((assoc) => assoc.allocation !== null);
    setSelections(
      employee.team_associations?.map(({team, allocation}) => ({
        team,
        allocation:
          allocation ??
          (hasAllocation
            ? 0
            : parseInt(((1 / employee.team_associations.length) * 100).toFixed(), 10)),
      }))
    );
  }, [employee.ldap, employee.team_associations]);

  const validationError = getCheckupError(
    {team_associations: selections} as EmployeeWithReportTeams,
    true
  );

  const handleCancel = () => {
    setSelections(
      employee.team_associations?.map(({team, allocation}) => ({
        team,
        allocation:
          allocation ?? parseInt(((1 / employee.team_associations.length) * 100).toFixed(), 10),
      }))
    );
    onCancel();
  };

  return (
    <Stack gap="16">
      <Box
        paddingX="16"
        paddingY="12"
        borderRadius="Medium"
        {...withShade({
          state: 'active',
        })}
      >
        <LabelGroup
          withText={<Text isBold>{employee.name}</Text>}
          withSubtext={employee.role}
          withLeftAccessory={<Avatar user={employee} />}
        />
      </Box>
      <TeamListTypeahead
        editable
        memberSort={memberSort}
        selections={selections ?? []}
        setSelections={(updated) => {
          const hasAllocation = updated.some((assoc) => assoc.allocation !== null);

          const updatedSelections = updated.map(({team, allocation}) => ({
            team,
            allocation:
              allocation ??
              (hasAllocation
                ? 0
                : parseInt(((1 / (employee.team_associations?.length ?? 0)) * 100).toFixed()),
              10),
          }));

          setSelections(updatedSelections);
          onChange?.(updatedSelections);
        }}
        withOverflowMenuItems={withOverflowMenuItems}
        withReactNode={(assoc) => (
          <Box style={{width: 68}}>
            <TextInput
              withRightAccessory={'%'}
              isInvalid={validationError === 'checkup_allocation'}
              style={{textAlign: 'right'}}
              value={assoc.allocation?.toString()}
              onChange={(e) => {
                const value = Math.max(
                  Math.min(parseInt(e.currentTarget.value || '0', 10), 100),
                  0
                );

                setSelections(
                  selections.map((s) =>
                    s.team.slug === assoc.team.slug
                      ? {...s, allocation: isNaN(value) ? (assoc.allocation ?? 0) : value}
                      : s
                  )
                );
              }}
            />
          </Box>
        )}
        withRightAccessory={withRightAccessory}
      />

      <Box display="flex" justifyContent="flex-end" className={atoms({paddingTop: '44'})}>
        <Stack gap="8">
          <Box textAlign="right">
            <Button
              variant="opacity"
              onClick={handleCancel}
              className={atoms({marginRight: '8'})}
              disabled={isPending}
            >
              {t('cancel')}
            </Button>
            <OptionalTooltip
              enabled={Boolean(validationError === 'checkup_allocation')}
              title={t(validationError ?? '')}
              placement="top-end"
            >
              <Button
                variant="primary"
                onClick={() => onSave(selections)}
                isLoading={isPending}
                disabled={
                  JSON.stringify(selections) === JSON.stringify(employee.team_associations) ||
                  Boolean(validationError === 'checkup_allocation')
                }
              >
                {t('save')}
              </Button>
            </OptionalTooltip>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};

export const TeamListTypeahead = ({
  editable,
  memberSort,
  withReactNode,
  withOverflowMenuItems,
  withRightAccessory,
  selections,
  setSelections,
}: {
  editable?: boolean;
  memberSort?: (a: TeamAssociation, b: TeamAssociation) => number;
  withReactNode?: (employee: TeamAssociation) => React.ReactNode;
  withOverflowMenuItems?: (employee: TeamAssociation) => React.ReactNode;
  withRightAccessory?: (employee: TeamAssociation) => React.ReactNode;
  selections?: TeamAssociation[];
  setSelections: (employee: TeamAssociation[]) => void;
}) => {
  const removeEmployee = (remove: TeamAssociation) => {
    const newSelections = (selections ?? []).filter((e) => e.team.slug !== remove.team.slug);

    const uniqueAllocations = new Set(newSelections);

    if (uniqueAllocations.size <= 1) {
      const allocation = parseInt(((1 / newSelections.length) * 100).toFixed(), 10);
      return setSelections(newSelections.map((selection) => ({team: selection.team, allocation})));
    }

    return setSelections(newSelections);
  };

  if (!selections?.length) {
    return (
      <Stack>
        {editable && (
          <TeamTypeahead
            placeholder={[]}
            selections={selections?.map(({team}) => team.slug ?? '')}
            onMultiSelect={(teams) => {
              const uniqueAllocations = new Set(
                selections?.map((selection) => selection.allocation)
              );

              if (uniqueAllocations.size <= 1) {
                const allocation = parseInt(
                  ((1 / ((selections?.length ?? 0) + teams.length)) * 100).toFixed(),
                  10
                );
                return setSelections([
                  ...(selections?.map((selection) => ({team: selection.team, allocation})) ?? []),
                  ...teams.map((team) => ({team, allocation})),
                ]);
              }

              return setSelections([
                ...(selections ?? []),
                ...teams.map((team) => ({team, allocation: 0})),
              ]);
            }}
          />
        )}
        <EmptyState
          title={t('no_teams_title')}
          body={t('no_teams_subtitle')}
          image={<BadgeMini width={64} altText={''} />}
        />
      </Stack>
    );
  }

  return (
    <Stack>
      {editable && (
        <TeamTypeahead
          placeholder={[]}
          selections={selections.map(({team}) => team.slug ?? '')}
          onMultiSelect={(teams) => {
            const uniqueAllocations = new Set(selections.map((selection) => selection.allocation));

            if (uniqueAllocations.size <= 1) {
              const allocation = parseInt(
                ((1 / (selections.length + teams.length)) * 100).toFixed(),
                10
              );
              return setSelections([
                ...selections.map((selection) => ({team: selection.team, allocation})),
                ...teams.map((team) => ({team, allocation})),
              ]);
            }

            return setSelections([...selections, ...teams.map((team) => ({team, allocation: 0}))]);
          }}
        />
      )}

      <Text size="large" isBold className={atoms({paddingBottom: '8', display: 'block'})}>
        {t('team_count', {
          count: selections.length,
          countString: selections.length.toLocaleString(),
        })}
      </Text>

      <Box style={{marginLeft: -8, marginRight: -8}}>
        {selections.sort(memberSort).map((member) => (
          <Box
            key={member.team.team_id}
            paddingY="8"
            paddingX="12"
            borderRadius="Medium"
            width="100%"
            overflow="hidden"
            // backgroundColor={hasError(member) ? 'Alert Surface' : undefined}
            {...withShade({
              duration: 'None',
              // bgColors: {
              //   base: !hasError(member) ? 'Opacity Surface' : 'Alert Surface - State 1',
              //   active: !hasError(member) ? 'Opacity Surface' : 'Alert Surface - State 1',
              // },
              className: styles.row,
            })}
          >
            <Box
              display="inline"
              style={{
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              <Box display="flex" alignItems="center">
                <AvatarIcon src={TeamLine} className={atoms({marginRight: '8'})} />
                <Box className={atoms({paddingRight: '116', overflow: 'hidden'})}>
                  <Text isBold className={atoms({display: 'block'})}>
                    {member.team.name}
                  </Text>
                  <Text
                    color="subtle"
                    size="small"
                    style={{
                      display: 'block',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t('member', {
                      count:
                        (member.team as unknown as TeamWithCounts).members_count ??
                        member.team.employee_count ??
                        0,
                      countString:
                        (member.team as unknown as TeamWithCounts).members_count ??
                        member.team.employee_count ??
                        0,
                    })}
                  </Text>
                </Box>
              </Box>

              {editable && withOverflowMenuItems && (
                <Box
                  position="absolute"
                  className={styles.button}
                  style={{
                    top: 12,
                    right: selections.length <= 1 ? 8 : 44,
                  }}
                >
                  <Menu.Wrapper shouldPropagateClickOutsideMouseEvents>
                    {({getContentProps, getTriggerProps}) => (
                      <>
                        <IconButton
                          {...getTriggerProps()}
                          variant="transparent"
                          className={atoms({marginRight: '4'})}
                        >
                          <UIIcon
                            src={MoreHorizontalLine}
                            className={atoms({color: 'Border Base'})}
                          />
                        </IconButton>
                        <Menu.Content {...getContentProps()} minWidth="200px" placement="bottom">
                          <Menu.Segment>{withOverflowMenuItems(member)}</Menu.Segment>
                        </Menu.Content>
                      </>
                    )}
                  </Menu.Wrapper>
                </Box>
              )}
              {editable && (
                <Box
                  position="absolute"
                  className={styles.button}
                  style={{
                    top: 12,
                    right: 78,
                    display: selections.length <= 1 ? 'none' : 'block',
                  }}
                >
                  <IconButton variant="transparent" onClick={() => removeEmployee(member)}>
                    <UIIcon src={DeleteLine} className={atoms({color: 'Border Base'})} />
                  </IconButton>
                </Box>
              )}

              {editable && withReactNode && (
                <Box
                  position="absolute"
                  className={styles.button}
                  style={{
                    top: 12,
                    right: 8,
                  }}
                >
                  {withReactNode(member)}
                </Box>
              )}

              {withRightAccessory && (
                <Box
                  position="absolute"
                  className={styles.chip}
                  style={{
                    top: 17,
                    right: 20,
                    // display: editable ? 'none' : 'block',
                  }}
                >
                  {withRightAccessory(member)}
                </Box>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Stack>
  );
};
