import {Text} from '@dropbox/dig-components/dist/typography';
import {Atoms, Box, Stack} from '@dropbox/dig-foundations';
import {ReactNode} from 'react';

import {ButtonLink} from './Link';
import {Title} from './Title';

export const EmptyState = ({
  title,
  body,
  cta,
  image,
  hideBorder,
  paddingY = '68',
  marginBottom = '44',
}: {
  title: ReactNode;
  body: ReactNode;
  cta?: {to: string; text: ReactNode};
  image: ReactNode;
  hideBorder?: boolean;
  paddingY?: Atoms['paddingY'];
  marginBottom?: Atoms['marginBottom'];
}) => {
  return (
    <Box
      width="100%"
      paddingY={paddingY}
      display="flex"
      borderRadius="Medium"
      borderStyle={hideBorder ? undefined : 'Solid'}
      borderColor="Border Subtle"
      textAlign="center"
      placeItems="center"
      marginTop="12"
      marginBottom={marginBottom}
    >
      <Box as={Stack} gap="8" style={{maxWidth: 460}}>
        <>
          {image}
          <Title size={16} className="ignore">
            {title}
          </Title>
          <Box as={Text} display="block" color="Text Subtle">
            {body}
          </Box>
          {cta && (
            <Box as={ButtonLink} variant="opacity" to={cta.to} marginTop="16">
              {cta.text}
            </Box>
          )}
        </>
      </Box>
    </Box>
  );
};
