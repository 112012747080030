import {Table} from '@dropbox/dig-components/dist/table';
import {StatusButtonIcon} from 'components/DSYS/StatusButtonIcon';
import {
  ProjectStatusButton,
  ProjectStatusButtonReadOnly,
} from 'components/projects/ProjectStatusButton';
import {JIRA_STATUS_MAP} from 'views/projects/utils';

export const StatusCell = ({
  width,
  status,
  projectId,
  size = 'small',
  onClick,
}: {
  width: number;
  status?: string; // only specific to tickets
  projectId?: number;
  size?: 'small';
  onClick: () => void;
}) => {
  if (projectId) {
    return (
      <Table.Cell>
        <ProjectStatusButton
          projectId={projectId}
          size={width < 100 ? 'icon' : size}
          onClick={onClick}
        />
      </Table.Cell>
    );
  }

  if (width < 100) {
    return (
      <Table.Cell>
        <StatusButtonIcon status={JIRA_STATUS_MAP[status as keyof typeof JIRA_STATUS_MAP]} />
      </Table.Cell>
    );
  }

  return (
    <Table.Cell>
      <ProjectStatusButtonReadOnly
        showIcon
        status={JIRA_STATUS_MAP[status as keyof typeof JIRA_STATUS_MAP]}
        size={size}
      />
    </Table.Cell>
  );
};
