import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {UIIcon} from '@dropbox/dig-icons';
import {
  CalendarLine,
  DeleteLine,
  EditLine,
  LinkLine,
  MoreHorizontalLine,
  ShareLine,
} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {snackbarAtom} from 'atoms/snackbar';
import {EmployeeFull, Goal, GoalRead, GoalUser, KeyResult, Update} from 'client';
import {KeyResultEditModal} from 'components/goals/edit/KeyResultEditModal';
import {useGoalService, useKeyResultService} from 'components/goals/hooks';
import {DelegationBanner} from 'components/shared/DelegationBanner';
import {getTimeframeOptions} from 'components/shared/TimeAgo';
import {ROUTE_PATHS} from 'constant';
import {reportAndLogError} from 'helpers/logging';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {ObjectiveLine} from 'views/goals_v2/icons';
import {StatusModal} from 'views/goals_v2/StatusModal';
import {TeamInfo} from 'views/goals_v2/types';
import {createGoalCopyLink, hasOpenStatusKeyResult} from 'views/goals_v2/utils';

interface GoalStatusMenuProps {
  user: GoalUser;
  source: string;
  goal?: Goal | GoalRead;
  keyResult?: KeyResult;
  canDelete: boolean;
  teamInfo?: TeamInfo;
  parentGoalUser?: GoalUser;
  ownerLdap?: string;
  latestUpdate?: Update;
  isDraftRow: boolean;
  timeframe: string;
  nextTimeframe: string;
  setNextTimeframe: (timeframe: string) => void;
  setIsStatusModalOpen?: (isOpen: boolean) => void;
  variant: 'outline' | 'borderless';
  onDeleteCallback?: () => void;
  onContributorCallback: () => void;
  onContinueGoalCallback: (selectedStatus: string) => void;
}

const getContributorsButtonText = (
  employee: EmployeeFull,
  keyResult: KeyResult,
  canEdit: boolean = false,
  hasDelegates: boolean = false
) => {
  if (canEdit || hasDelegates) {
    return t('edit_co-owners');
  }
  const isContributor = !!keyResult.contributors?.some(({ldap}) => ldap === employee.ldap);
  if (isContributor) {
    return t('leave_co-owner');
  }
  return t('join_co-owner');
};

export const GoalStatusMenu = ({
  user,
  source,
  goal,
  keyResult,
  canDelete,
  teamInfo,
  parentGoalUser,
  ownerLdap,
  latestUpdate,
  isDraftRow,
  timeframe,
  nextTimeframe,
  setNextTimeframe,
  setIsStatusModalOpen,
  variant,
  onDeleteCallback,
  onContributorCallback,
  onContinueGoalCallback,
}: GoalStatusMenuProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {employee, delegatedBy} = useAtomValue(loggedInEmployeeAtom);
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const [editModal, setEditModal] = useState({open: false, action: ''});
  const [statusModalState, setStatusModalState] = useState({
    open: false,
    action: 'enforce-enable-continue-goal-toggle',
  });
  const [isConfirmDeletionModalOpen, setConfirmDeletionModalOpen] = useState(false);
  const {deleteGoal} = useGoalService({
    timeframe: goal?.timeframe ?? timeframe,
    teamInfo: teamInfo,
    parentGoalUserLdap: parentGoalUser?.email.split('@')[0],
  });
  const {deleteKeyResult} = useKeyResultService({
    timeframe: goal?.timeframe ?? timeframe,
    teamInfo: teamInfo,
  });
  const goalId = goal?.id || keyResult?.goal_id;

  const row = keyResult ?? goal;

  const canEdit =
    employee.email === user.email || delegatedBy?.some(({email}) => email === user.email);
  const canContinueGoal =
    goalId && canEdit && !isDraftRow && getTimeframeOptions(timeframe).length > 0;
  const hasDelegates = !!delegatedBy?.length;

  const handleDelete = () => {
    setConfirmDeletionModalOpen(true);
  };

  const handleCopyLink = async () => {
    if (goalId) {
      await navigator.clipboard.writeText(createGoalCopyLink(goalId, keyResult?.id));
      setSnackbarMessage({text: t('copied')});
    }
  };

  // TODO Manage error states here
  const performDelete = async () => {
    if (onDeleteCallback) {
      onDeleteCallback();
    }
    if (goal) {
      if ('children' in goal && goal.children && goal.children.length > 0) {
        setSnackbarMessage({text: t('delete_goal_with_children_error')});
        return;
      }
      try {
        await deleteGoal({goalId: goal.id, userId: user.user_id});
        setSnackbarMessage({text: t('deleted')});
        const urlParams = new URLSearchParams(location.search);
        navigate(
          {pathname: ROUTE_PATHS.GOALS, search: urlParams.toString()},
          {preventScrollReset: true}
        );
      } catch (error: any) {
        if (error.name === 'ApiError' && error.message == 'Bad Request') {
          setSnackbarMessage({text: t('delete_goal_with_children_error')});
        } else {
          reportAndLogError(error, 'Error deleting goal or key result');
          setSnackbarMessage({text: t('couldnt_delete')});
        }
      }
    } else if (keyResult) {
      try {
        await deleteKeyResult({
          keyResultId: keyResult.id,
          userId: user.user_id,
        });
        setSnackbarMessage({text: t('deleted')});
      } catch (error: any) {
        if (error.name === 'ApiError' && error.message == 'Bad Request') {
          setSnackbarMessage({text: t('delete_goal_with_children_error')});
        } else {
          reportAndLogError(error, 'Error deleting goal or key result');
          setSnackbarMessage({text: t('couldnt_delete')});
        }
      }
    }
  };

  const handleEdit = () => {
    if (!goal) {
      navigate(`/goals/${keyResult?.goal_id}/edit`);
      return;
    }
    navigate(`/goals/${goal?.id}/edit`);
  };

  const handleSelection = (action: string, event: React.SyntheticEvent) => {
    analyticsLogger().logEvent('GOAL_OVERFLOW_MENU_CLICK', {
      type: keyResult ? 'kr' : 'objective',
      action,
      source,
    });
    if (action === 'join') {
      onContributorCallback();
    } else if (action === 'delete') {
      handleDelete();
    } else if (action === 'edit') {
      handleEdit();
    } else if (action === 'copy') {
      handleCopyLink();
    } else if (action === 'continue') {
      if (goal && hasOpenStatusKeyResult(goal, user)) {
        setSnackbarMessage({text: t('cannot_submit_goal_status_message')});
      } else {
        setStatusModalState({open: true, action: 'enforce-enable-continue-goal-toggle'});
        setIsStatusModalOpen?.(true);
      }
    } else if (action === 'add_subgoal') {
      const keyResultContributors = keyResult?.contributors ?? [];
      navigate(
        {pathname: ROUTE_PATHS.NEW_GOAL},
        {
          state: {
            selectedParentGoal: goal,
            selectedParentKeyResult: keyResult
              ? {
                  ...keyResult,
                  contributors: parentGoalUser
                    ? [{ldap: parentGoalUser.email.split('@')[0]}, ...keyResultContributors]
                    : keyResultContributors,
                }
              : undefined,
          },
        }
      );
    }
    event.stopPropagation();
  };

  const menuActionItemStyle = {width: '232px'};
  return (
    <>
      {(canEdit || canContinueGoal || canDelete || goalId || keyResult) && (
        <Menu.Wrapper
          onSelection={(action: string, event: React.SyntheticEvent) =>
            handleSelection(action, event)
          }
          closeOnBlur={true}
          closeOnBlurWhenDefaultPrevented={true}
          onClick={(e: React.SyntheticEvent) => {
            e.stopPropagation();
          }}
          onMouseLeave={(e: React.SyntheticEvent) => {
            e.stopPropagation();
          }}
        >
          {({getContentProps, getTriggerProps}) => (
            <>
              <IconButton
                {...getTriggerProps()}
                onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                  const onClick = getTriggerProps().onClick;
                  if (onClick) {
                    onClick(e);
                  }
                  e.stopPropagation();
                }}
                onMouseDown={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                  const onMouseDown = getTriggerProps().onMouseDown;
                  if (onMouseDown) {
                    onMouseDown(e);
                  }
                  e.stopPropagation();
                }}
                onMouseUp={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                  const onMouseUp = getTriggerProps().onMouseUp;
                  if (onMouseUp) {
                    onMouseUp(e);
                  }
                  e.stopPropagation();
                }}
                variant={getContentProps().open ? 'borderless' : variant}
                shape="circular"
              >
                <UIIcon src={MoreHorizontalLine} />
              </IconButton>
              <Menu.Content {...getContentProps()}>
                {(canEdit || canContinueGoal || canDelete) && (
                  <Menu.Segment>
                    {canEdit && (
                      <Menu.ActionItem
                        key="edit"
                        value="edit"
                        withLeftAccessory={<UIIcon src={EditLine} />}
                        style={menuActionItemStyle}
                      >
                        {t('edit')}
                      </Menu.ActionItem>
                    )}
                    {canContinueGoal && (
                      <Menu.ActionItem
                        key="continue"
                        value="continue"
                        withLeftAccessory={<UIIcon src={CalendarLine} />}
                        style={menuActionItemStyle}
                      >
                        {t('continue_goal')}
                      </Menu.ActionItem>
                    )}
                    {canDelete && (
                      <Menu.ActionItem
                        key="delete"
                        value="delete"
                        withLeftAccessory={<UIIcon src={DeleteLine} />}
                        style={menuActionItemStyle}
                      >
                        {t('delete')}
                      </Menu.ActionItem>
                    )}
                  </Menu.Segment>
                )}
                {goalId && (
                  <Menu.Segment>
                    <Menu.ActionItem
                      key="add_subgoal"
                      value="add_subgoal"
                      withLeftAccessory={<UIIcon src={ObjectiveLine} width={20} height={21} />}
                      style={menuActionItemStyle}
                    >
                      {t('add_subgoal')}
                    </Menu.ActionItem>
                  </Menu.Segment>
                )}
                {goalId && (
                  <Menu.Segment>
                    <Menu.ActionItem
                      key="copy"
                      value="copy"
                      withLeftAccessory={<UIIcon src={LinkLine} />}
                      style={menuActionItemStyle}
                    >
                      {t('copy_link')}
                    </Menu.ActionItem>
                  </Menu.Segment>
                )}
                {keyResult && (
                  <Menu.Segment>
                    <Menu.ActionItem
                      key="join"
                      value="join"
                      withLeftAccessory={<UIIcon src={ShareLine} />}
                      style={menuActionItemStyle}
                    >
                      {getContributorsButtonText(employee, keyResult, canEdit, hasDelegates)}
                    </Menu.ActionItem>
                  </Menu.Segment>
                )}
              </Menu.Content>
            </>
          )}
        </Menu.Wrapper>
      )}
      {editModal.open && keyResult && (
        <KeyResultEditModal
          state={editModal}
          setState={setEditModal}
          keyResult={keyResult}
          user={user}
          timeframe={goal?.timeframe ?? ''}
        />
      )}
      {row && goalId && statusModalState.open && (
        <StatusModal
          title={row.title}
          user={user}
          state={statusModalState}
          setState={setStatusModalState}
          setIsStatusModalOpen={setIsStatusModalOpen}
          goalId={goalId}
          timeframe={timeframe}
          nextTimeframe={nextTimeframe}
          setNextTimeframe={setNextTimeframe}
          keyResultId={keyResult?.id}
          previousUpdate={latestUpdate}
          teamInfo={teamInfo}
          parentGoalUser={parentGoalUser}
          ownerEmployeeLdap={ownerLdap}
          onContinueGoalClick={onContinueGoalCallback}
        />
      )}
      <Modal
        open={isConfirmDeletionModalOpen}
        isCentered
        width="small"
        withCloseButton="Close"
        aria-label="Are you sure you want to delete?"
        onRequestClose={(e: React.SyntheticEvent) => {
          setConfirmDeletionModalOpen(false);
          e.stopPropagation();
        }}
      >
        <Modal.Header hasBottomSpacing="title-standard">
          <Modal.Title>
            {t('delete_this')} {goal ? t('goal') : t('key_result_alone')}?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DelegationBanner delegatingAs={user} padding />
          {t('delete_confirm')}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="opacity"
            onClick={(e: React.SyntheticEvent) => {
              e.stopPropagation();
              setConfirmDeletionModalOpen(false);
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={(e: React.SyntheticEvent) => {
              e.stopPropagation();
              performDelete();
              setConfirmDeletionModalOpen(false);
            }}
          >
            {t('delete')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
