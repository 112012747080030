import {WrapperRefObject} from '@dropbox/dig-components/dist/menu';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Box, BoxProps} from '@dropbox/dig-foundations';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {Title} from 'components/DSYS/Title';
import {useGoals} from 'components/goals/hooks';
import {t} from 'i18next';
import {LegacyRef} from 'react';
import {TimeframeSelector} from 'views/goals_v2/TimeframeSelector';
import {GoalsHeaderData, GoalsTableFilter} from 'views/goals_v2/types';
import {filterTable, MAX_NUM_GOALS_TO_SHOW} from 'views/goals_v2/utils';

import {FilterChips, JoinTableFilter} from './JoinTableFilter';

export const GoalsHeaderSkeleton = (boxProps: BoxProps<'div'>) => {
  const width = 100;
  return (
    <Box {...boxProps} style={{width}}>
      <Skeleton.Box width={width} height={24} />
    </Box>
  );
};

export const GoalsJoinTableHeader = ({
  timeframe,
  setTimeframe,
  filter,
  setFilter,
  filterButtonRef,
  delegateLdap,
}: {
  timeframe: string;
  setTimeframe: (timeframe: string) => void;
  filter: GoalsTableFilter;
  setFilter?: (filter: GoalsTableFilter, shouldNotUpdateUrl?: boolean) => void;
  filterButtonRef?: LegacyRef<WrapperRefObject>;
  goalsHeaderData?: GoalsHeaderData;
  delegateLdap?: string;
}) => {
  const filterWithTeamInfo = filter;
  const {goals} = useGoals({ldaps: filter.people, timeframe: timeframe, throwOnError: false});

  const numTotalGoals = goals.length;
  const filteredCount = goals.filter(filterTable(filter)).length;
  let title = 'table_goals';
  if (filteredCount !== numTotalGoals) {
    title = 'table_goals_filter';
  }
  const showFilter = filterWithTeamInfo && setFilter;

  const renderCountHeader = () => {
    if (filterWithTeamInfo && filterWithTeamInfo.people.length == 0) {
      return (
        <Title size={18} style={{width: 200}}>
          {t('more_goals', {count: MAX_NUM_GOALS_TO_SHOW})}
        </Title>
      );
    }
    return (
      <Title size={18} style={{width: 200}}>
        {t(title, {count: numTotalGoals, filteredCount})}
      </Title>
    );
  };

  return (
    <>
      <Box
        position="sticky"
        backgroundColor="Background Base"
        marginTop="4"
        paddingTop="12"
        paddingBottom="10"
        width="100%"
        style={{top: 52, zIndex: 2}}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{maxWidth: 1240}}
        >
          {renderCountHeader()}
          <Box display="flex">
            {showFilter && (
              <>
                <FilterChips filter={filterWithTeamInfo} setFilter={setFilter} />
                <JoinTableFilter
                  filter={filterWithTeamInfo}
                  setFilter={setFilter}
                  filterButtonRef={filterButtonRef}
                  delegateLdap={delegateLdap}
                />
              </>
            )}
            <Box
              paddingLeft="16"
              marginLeft={showFilter ? '8' : undefined}
              marginTop="4"
              borderLeft={showFilter ? 'Solid' : undefined}
              borderColor={showFilter ? 'Border Subtle' : undefined}
              height="fit-content"
              style={{height: '24px'}}
            >
              <TimeframeSelector
                selectedTimeframe={timeframe}
                setSelectedTimeframe={(next) => {
                  analyticsLogger().logEvent('GOAL_TABLE_TIMEFRAME', {timeframe: next});
                  setTimeframe(next);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
