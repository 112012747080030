import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Table} from '@dropbox/dig-components/dist/table';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {EmptyCell} from 'components/shared/table/EmptyCell';
import {toLocaleStringConditionalYear, toQuarter} from 'components/shared/TimeAgo';
import {useMemo} from 'react';

export const QuarterCell = ({width, timeframe}: {width: number; timeframe?: string}) => {
  const quarter = useMemo(() => timeframe && toQuarter(timeframe), [timeframe]);

  const localeString = useMemo(
    () => timeframe && toLocaleStringConditionalYear(timeframe),
    [timeframe]
  );

  const [date, year] = (localeString ?? '').split(', ');

  if (!timeframe) {
    return <EmptyCell />;
  }

  return (
    <Table.Cell>
      <LabelGroup
        withText={
          <Box as={Text} size="small">
            {width < 20 ? quarter : date}
          </Box>
        }
        withSubtext={
          width < 20 ? null : (
            <Box as={Text} color="Text Subtle" size="xsmall">
              {quarter} {year ? `FY${parseInt(year, 10) - 2000}` : ''}
            </Box>
          )
        }
      />
    </Table.Cell>
  );
};
