import {Text} from '@dropbox/dig-components/dist/typography';
import {Avatar} from 'components/DSYS/Avatar';
import {NotificationCard} from 'components/notifications/NotificationCard';
import {useNotificationContext} from 'components/notifications/utils/useNotificationContext';
import {TimeAgo} from 'components/shared/TimeAgo';
import {useEmployee} from 'hooks/useEmployee';
import {t} from 'i18next';
import {Trans} from 'react-i18next';

export const AvatarNotification = () => {
  const {
    notification: {archived, data, updated_at},
  } = useNotificationContext();
  const {content, to, cta, data: jsonData} = data;
  const {ldap, data: timeframe} = JSON.parse(jsonData);
  const {employee} = useEmployee({ldap});

  return (
    <NotificationCard
      accessory={employee && <Avatar size="small" user={employee} />}
      header={
        <Trans
          i18nKey={content}
          t={t}
          values={{name: employee?.name ?? '-', data: timeframe ?? '-'}}
          components={{b: <Text isBold />}}
        />
      }
      subtext={<TimeAgo timestamp={updated_at} />}
      buttonLink={{cta, to, state: {timeframe}}}
      archived={archived}
    />
  );
};
