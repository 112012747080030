import {Button} from '@dropbox/dig-components/dist/buttons';
import {List} from '@dropbox/dig-components/dist/list';
import {Box} from '@dropbox/dig-foundations';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {NewsPostWithRead} from 'client';
import {Link} from 'components/DSYS/Link';
import {NewsRow} from 'components/news/NewsRow';
import {t} from 'i18next';
import {useEffect, useState} from 'react';
import {useLoaderData} from 'react-router-dom';
import {useDraftsSuspense, useFeedSuspense} from 'views/news/hooks';

interface NewsListProps {
  isEditorView?: boolean;
  viewCategory?: string;
  selected?: number;
}

export const NewsList = (props: NewsListProps) => {
  const [startingPosts] = useLoaderData() as [NewsPostWithRead[]];
  const [selectedNewsPostId, setSelectedNewsPostId] = useState<number | undefined>();
  const [showViewMore, setShowViewMore] = useState<boolean>(true);
  const drafts = useDraftsSuspense();

  const [posts, setPosts] = useState<NewsPostWithRead[]>([]);
  const [skip, setSkip] = useState(25);
  const limit = 25;
  const data = useFeedSuspense(skip, limit, props.viewCategory);

  const handleViewMore = () => {
    analyticsLogger().logEvent('NEWS_VIEW_MORE_CLICKED');
    if (data?.length) {
      setPosts([...posts, ...data]);
      setSkip(skip + limit);
    } else {
      setShowViewMore(false);
    }
  };

  useEffect(() => {
    setPosts([]);
  }, [startingPosts]);

  useEffect(() => {
    setSelectedNewsPostId(props.selected);
  }, [props.selected]);

  const feedPosts = props.isEditorView ? [...drafts, ...startingPosts] : startingPosts;

  return (
    <Box as={List} isSelectable borderRadius="XLarge" height="0">
      {[...feedPosts, ...posts].map((newspost) => (
        <Link
          to={`/news/${newspost.id}`}
          state={{source: 'news-list'}}
          key={newspost.id}
          hasNoUnderline
          style={{display: 'block', marginLeft: 3, marginRight: 3}}
        >
          <Box
            as={List.Item}
            key={newspost.is_draft ? `draft_${newspost.id}` : `listitem_${newspost.id}`}
            isSelected={selectedNewsPostId === newspost.id}
            onClick={() => {
              setSelectedNewsPostId(newspost.id);
            }}
            padding="12"
            borderRadius="Medium"
          >
            <NewsRow
              id={newspost.id}
              title={newspost.title}
              category={newspost.category}
              creationDate={newspost.created_at}
              is_read={
                selectedNewsPostId === newspost.id ||
                ('read' in newspost ? (newspost.read as boolean) : undefined)
              }
              is_draft={newspost.is_draft ?? false}
              isCategoryView={!!props.viewCategory}
            />
          </Box>
        </Link>
      ))}
      {feedPosts.length >= 25 && showViewMore && (
        <Button
          style={{
            marginTop: '8px',
            marginBottom: '16px',
            marginLeft: '12px',
          }}
          variant="transparent"
          size="medium"
          onClick={handleViewMore}
        >
          {t('view_more')}
        </Button>
      )}
    </Box>
  );
};
