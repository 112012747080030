import {Box} from '@dropbox/dig-foundations';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {Employee} from 'client';
import ProfileCard from 'components/shared/ProfileCard';
import {ReactNode} from 'react';
import styles from 'views/dashboards/Dashboards.module.css';
import {DashboardsChartContainer} from 'views/dashboards/DashboardsChartContainer';
import {DashboardsInfoMessage} from 'views/dashboards/DashboardsInfoMessage';
import {
  DashboardsWidgetShowAllButton,
  DashboardsWidgetSpinner,
} from 'views/dashboards/widgets/DashboardsWidget';

export interface DashboardsPeopleWidgetProps {
  teamName: string;
  teamSlug: string;
  timeframe: string;
}

type DashboardsPeopleWidgetType = 'participation';

export type DashboardsPeopleWidgetComponentProps = DashboardsPeopleWidgetProps & {
  type: DashboardsPeopleWidgetType;
  title: string;
  subtitle: string;
  icon: React.ComponentType<React.SVGAttributes<SVGElement>>;
  source: string;
  employees: Employee[] | null;
  peopleWidgetSelectorRef?: React.RefObject<HTMLDivElement>;
  isShowAllLoading?: boolean;
  showAll?: boolean;
  showAllCount?: number;
  setShowAll?: () => void;
  infoMessage?: ReactNode | string;
  emptyState?: ReactNode;
};

export const DashboardsPeopleWidgetComponent = (props: DashboardsPeopleWidgetComponentProps) => {
  const {
    type,
    title,
    subtitle,
    icon,
    source,
    employees,
    peopleWidgetSelectorRef,
    showAll,
    showAllCount,
    setShowAll,
    isShowAllLoading,
    infoMessage,
    emptyState,
  } = props;

  const cssClasses = [styles.dashboardsPeopleList];
  if (showAll) {
    cssClasses.push(styles.dashboardsPeopleListScroll);
  }
  return (
    <DashboardsChartContainer title={title} subtitle={subtitle} icon={icon}>
      <Box marginTop="12" marginBottom="16" borderColor="Border Subtle" borderBottom="Solid" />
      {!!(employees?.length && infoMessage) && (
        <Box marginBottom="12">
          <DashboardsInfoMessage message={infoMessage} />
        </Box>
      )}
      {employees ? (
        <>
          {employees.length ? (
            <>
              <Box className={cssClasses.join(' ')} ref={peopleWidgetSelectorRef}>
                {employees.map((employee) => {
                  return (
                    <ProfileCard
                      key={employee.user_id}
                      source={source}
                      profile_username={employee.ldap}
                      profile_photo_url={employee.profile_photo_url}
                      display_name={employee.name}
                      profile_info={employee.role ?? ''}
                      isDeactivated={employee.is_deactivated}
                      onClick={() => {
                        analyticsLogger().logEvent('DASHBOARD_WIDGET_CLICKED', {
                          type,
                          event: 'row',
                        });
                      }}
                      target="_blank"
                    />
                  );
                })}
              </Box>
              {isShowAllLoading ? (
                <DashboardsWidgetSpinner marginTop="8" width="75px" />
              ) : (
                !!showAllCount &&
                setShowAll && (
                  <DashboardsWidgetShowAllButton
                    showAllCount={showAllCount}
                    setShowAll={setShowAll}
                  />
                )
              )}
            </>
          ) : (
            emptyState
          )}
        </>
      ) : (
        <DashboardsWidgetSpinner marginTop="16" />
      )}
    </DashboardsChartContainer>
  );
};
