import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Table} from '@dropbox/dig-components/dist/table';
import {Box} from '@dropbox/dig-foundations';

import {Header} from './Header';
import {ColumnConfig} from './useColumnResize';

export const TableSkeleton = ({columnConfigs}: {columnConfigs: ColumnConfig[]}) => (
  <Box
    as="div"
    paddingX="24"
    paddingY="20"
    borderRadius="Medium"
    borderColor="Border Subtle"
    borderStyle="Solid"
    borderWidth="1"
    maxWidth="100%"
    marginX="auto"
    style={{width: 350 + columnConfigs.reduce((acc, {width}) => acc + width, 0), overflowX: 'auto'}}
  >
    <Skeleton.Box width={200} height={24} style={{marginBottom: 4}} />
    <Table hasDividers={false} spacing="small" verticalAlign="center">
      <Header
        columnConfigs={columnConfigs.map((config) => ({
          ...config,
          type: '',
          short: '',
        }))}
        sort={{type: 'available', asc: true}}
        setSort={() => {}}
        getMouseDownHandler={() => () => {}}
      />
      <Table.Body>
        <Box as="div" style={{height: 8}} />
        <Box style={{height: '100vh'}} />
      </Table.Body>
    </Table>
  </Box>
);
