import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {isMobileAtom} from 'atoms/layout';
import {useAtomValue} from 'jotai';
import {useEffect, useState} from 'react';

import {Link} from './Link';

/**
 * Scroller
 *
 */

type Node = {label: string; element: HTMLElement};
type NodeData = {parent: Node; children: Node[]};

export const Scroller = ({
  data,
  width = 150,
  withChildren = true,
  onScrollClick,
}: {
  data: NodeData[];
  width?: number;
  withChildren?: boolean;
  onScrollClick?: (id: string) => void;
}) => {
  const [activeId, setActiveId] = useState<string | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useAtomValue(isMobileAtom);

  useEffect(() => {
    const scrollHandler = () => {
      const scrollPosition = document.documentElement.scrollTop;

      const positions = data
        ?.map(({parent, children}: NodeData) => {
          const parentTop = parent.element.getBoundingClientRect().top ?? 0;
          const childrenTops = children.map((child: Node) => {
            return {
              id: child.element.id,
              offset: (child.element.getBoundingClientRect().top ?? 0) - 111,
            };
          });
          return [{id: parent.element.id, offset: parentTop - 111}, ...childrenTops];
        })
        .flat();

      if (positions.length) {
        const closestId = positions.reduce((prev: any, curr: any) =>
          Math.abs(curr.offset) < Math.abs(prev.offset) ? curr : prev
        );

        setActiveId(scrollPosition > 50 ? closestId.id ?? undefined : undefined);
      }
    };

    document.addEventListener('scroll', scrollHandler);
    return () => document.removeEventListener('scroll', scrollHandler);
  }, [data]);

  if (isMobile) {
    return null;
  }

  return (
    <Box
      id="scroller"
      position="fixed"
      alignItems="center"
      display="flex"
      height="100%"
      style={{marginTop: -150, right: 60, zIndex: 3}}
    >
      <Box
        position="absolute"
        paddingRight="12"
        visibility={!isOpen ? 'visible' : 'hidden'}
        opacity={!isOpen ? 1 : 0}
        style={{
          marginLeft: width,
          transition: 'transform 0.2s ease-in-out, opacity 0.7s',
          transform: !isOpen ? `translateX(-${width}px` : 'translateX(0)',
        }}
        onMouseEnter={() => setIsOpen(true)}
      >
        {data.map(({parent, children}: NodeData) => (
          <>
            <Box
              key={parent.element.id}
              borderWidth="2"
              borderBottom="Solid"
              borderColor={activeId === parent.element.id ? 'Primary Base' : 'Border Base'}
              style={{width: 28, height: 27}}
            />
            {withChildren &&
              children.map((child: Node) => (
                <Box
                  key={child.element.id}
                  borderWidth="2"
                  borderBottom="Solid"
                  borderColor={activeId === child.element.id ? 'Primary Base' : 'Border Base'}
                  style={{width: 14, height: 27}}
                />
              ))}
          </>
        ))}
      </Box>
      <Box
        position="absolute"
        padding="24"
        boxShadow="Raised"
        borderRadius="Medium"
        backgroundColor="Background Raised"
        visibility={isOpen ? 'visible' : 'hidden'}
        opacity={isOpen ? 1 : 0}
        style={{
          transform: isOpen ? `translateX(-${width}px` : 'translateX(0)',
          transition: 'transform 0.2s ease-in-out, opacity 0.7s',
          paddingRight: 100 + width,
        }}
        onMouseLeave={() => setIsOpen(false)}
        width="max-content"
      >
        {data.map(({parent, children}: NodeData) => (
          <>
            <Box
              as={Text}
              display="block"
              key={parent.element.id}
              size="small"
              paddingY="4"
              color={{
                default: activeId === parent.element.id ? 'Primary Base' : 'Text Subtle',
                hover: 'Primary Base',
              }}
            >
              <Link
                hasNoUnderline
                monochromatic
                to={`#${parent.element.id}`}
                replace={true}
                onClick={() => onScrollClick?.(parent.element.id)}
              >
                {parent.label}
              </Link>
            </Box>
            {withChildren &&
              children.map((child: Node) => (
                <Box
                  as={Text}
                  display="block"
                  key={child.element.id}
                  size="small"
                  paddingLeft="32"
                  paddingY="4"
                  color={{
                    default: activeId === child.element.id ? 'Primary Base' : 'Text Subtle',
                    hover: 'Primary Base',
                  }}
                >
                  <Link
                    hasNoUnderline
                    monochromatic
                    to={`#${child.element.id}`}
                    replace={true}
                    onClick={() => onScrollClick?.(child.element.id)}
                  >
                    {child.label}
                  </Link>
                </Box>
              ))}
          </>
        ))}
      </Box>
    </Box>
  );
};
