import {LinkNode} from '@lexical/link';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {useEffect} from 'react';

export const LinkNodePlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor) return;
    const removeNodeListener = editor.registerNodeTransform(LinkNode, (node) => {
      if (!node) return;

      const dom = editor.getElementByKey(node.__key);
      if (!dom) return;
      dom.setAttribute('target', '_blank');
    });
    return () => removeNodeListener();
  }, [editor]);
  return null;
};
