import {OpenAIApiKey} from 'helpers/api';
import {
  keyResultPromptTemplate1,
  objectivePromptTemplate3,
} from 'helpers/template/objectivePromptTemplate';
import {OpenAI} from 'langchain/llms/openai';
import {StructuredOutputParser} from 'langchain/output_parsers';
import {PromptTemplate} from 'langchain/prompts';

export const generate = async (objective_input?: string) => {
  // With a `StructuredOutputParser` we can define a schema for the output.

  const parser = StructuredOutputParser.fromNamesAndDescriptions({
    result:
      'Boolean result that determined if the user input follow the OKR objective rule. If it is a good objective, return true. Otherwise, return false.',
  });

  const prompt = new PromptTemplate({
    template: objectivePromptTemplate3,
    inputVariables: ['input'],
    // partialVariables: {format_instructions: formatInstructions},
  });

  const model = new OpenAI({
    openAIApiKey: OpenAIApiKey,
    temperature: 0,
    // modelName: 'gpt-3.5-turbo',
    maxTokens: 1500,
  });

  const input = await prompt.format({
    input: objective_input,
  });
  const response = await model.call(input);

  return await parser.parse(response);
};

export const generateKR = async (keyResultInput?: string) => {
  // With a `StructuredOutputParser` we can define a schema for the output.

  const parser = StructuredOutputParser.fromNamesAndDescriptions({
    result:
      'Boolean result that determined if the user input follow the OKR key result rule. If it is a good key result, return true. Otherwise, return false.',
  });
  const formatInstructions = parser.getFormatInstructions();

  const prompt = new PromptTemplate({
    template: keyResultPromptTemplate1,
    inputVariables: ['input'],
    partialVariables: {format_instructions: formatInstructions},
  });

  const model = new OpenAI({
    openAIApiKey: OpenAIApiKey,
    temperature: 0,
    maxTokens: 1500,
  });

  const input = await prompt.format({
    input: keyResultInput,
  });
  const response = await model.call(input);

  return await parser.parse(response);
};
