import {useMutation, useQuery, useSuspenseQuery} from '@tanstack/react-query';
import {NewsCategoryUpdate, NewsPostCreate, NewsPostUpdate, NewsService} from 'client';
import {getBackendUrl, getService} from 'utilities';
import {queryClient} from 'views/QueryClientWrapper';

export const usePostSuspense = (id: number) => {
  const {data} = useSuspenseQuery({
    queryKey: ['news', id],
    queryFn: () => getService(NewsService).getPostApiV1NewsIdGet(id),
  });

  return data;
};

export const useAddViewer = () => {
  const {mutateAsync: addViewer, isPending} = useMutation({
    mutationFn: ({id}: {id: number}) => getService(NewsService).addViewerApiV1NewsViewsIdPost(id),
  });
  return {addViewer, isPending};
};

export const useFeedSuspense = (skip: number, limit: number, category?: string) => {
  const {data} = useQuery({
    queryKey: ['news', 'latest', skip, limit, category],
    queryFn: () => getService(NewsService).getPostsApiV1NewsGet(skip, limit, category),
  });

  return data;
};

export const useThumbnailSuspense = (id: number) => {
  const {data} = useSuspenseQuery({
    queryKey: ['news', id, 'thumbnail'],
    queryFn: () => getBackendUrl() + `/api/v1/news/thumbnail/${id}`,
  });

  return data;
};

export const useThumbnailCreate = () => {
  const {mutateAsync: uploadThumbnail, isPending} = useMutation({
    mutationFn: ({id, file}: {id: number; file: File}) =>
      getService(NewsService).uploadImageApiV1NewsThumbnailPost(id, {file}),
    onSettled: (response) => {
      if (!response) {
        return;
      }
      queryClient.invalidateQueries({queryKey: ['news', response, 'thumbnail']});
    },
  });

  return {uploadThumbnail, isPending};
};

export const useMediaCreate = () => {
  const {mutateAsync: uploadMedia, isPending} = useMutation({
    mutationFn: ({path, file}: {path: string; file: File}) =>
      getService(NewsService).uploadMediaApiV1NewsMediaPost(path, {file}),
  });

  return {uploadMedia, isPending};
};

export const useThumbnailDelete = () => {
  const {mutateAsync: deleteThumbnail, isPending} = useMutation({
    mutationFn: ({id}: {id: number}) =>
      getService(NewsService).deleteImageApiV1NewsThumbnailIdDelete(id),
    onSettled: (response) => {
      if (!response) {
        return;
      }
    },
  });

  return {deleteThumbnail, isPending};
};

export const useCategoriesSuspense = () => {
  const {data} = useSuspenseQuery({
    queryKey: ['news', 'categories'],
    queryFn: () => getService(NewsService).getCategoriesApiV1NewsCategoriesGet(),
  });

  return data;
};

export const useDraftsSuspense = () => {
  const {data} = useSuspenseQuery({
    queryKey: ['news', 'drafts'],
    queryFn: () => getService(NewsService).getDraftsApiV1NewsDraftsGet(),
  });

  return data;
};

export const useNewsCreate = () => {
  const {mutateAsync: createNewsPost, isPending} = useMutation({
    mutationFn: ({data}: {data: NewsPostCreate}) =>
      getService(NewsService).createPostApiV1NewsPost(data),
    onSettled: (response) => {
      if (!response) {
        return;
      }

      queryClient.invalidateQueries({queryKey: ['news', response.id]});
      queryClient.invalidateQueries({queryKey: ['news', 'latest']});
      queryClient.invalidateQueries({queryKey: ['news', 'drafts']});
    },
  });

  return {createNewsPost, isPending};
};

export const useNewsUpdate = () => {
  const {mutateAsync: updateNewsPost, isPending} = useMutation({
    mutationFn: ({newspostId, data}: {newspostId: number; data: NewsPostUpdate}) =>
      getService(NewsService).updatePostApiV1NewsIdPost(newspostId, data),
    onSettled: (response) => {
      queryClient.invalidateQueries({queryKey: ['news', 'latest']});
      queryClient.invalidateQueries({queryKey: ['news', 'drafts']});

      if (!response) {
        return;
      }

      queryClient.invalidateQueries({queryKey: ['news', response.id]});
    },
  });

  return {updateNewsPost, isPending};
};

export const useDeleteNewsPost = () => {
  const {mutateAsync: deleteNewsPost, isPending} = useMutation({
    mutationFn: ({newspostId}: {newspostId: number}) =>
      getService(NewsService).deletePostApiV1NewsIdDelete(newspostId),
    onSettled: (response) => {
      if (!response) {
        return;
      }

      queryClient.invalidateQueries({queryKey: ['news', 'latest']});
      queryClient.invalidateQueries({queryKey: ['news', 'drafts']});
    },
  });

  return {deleteNewsPost, isPending};
};

export const useNewsCategoryUpdate = (id: number | undefined) => {
  const {mutateAsync: updateNewsCategory, isPending} = useMutation({
    mutationFn: ({data}: {data: NewsCategoryUpdate[]}) =>
      getService(NewsService).editCategoriesApiV1NewsCategoriesEditPost(data),
    onSettled: (response) => {
      if (!response) {
        return;
      }

      queryClient.invalidateQueries({queryKey: ['news', 'categories']});
      queryClient.invalidateQueries({queryKey: ['news', 'latest']});
      queryClient.invalidateQueries({queryKey: ['news', 'drafts']});
      if (id) {
        queryClient.invalidateQueries({queryKey: ['news', id]});
      }
    },
  });

  return {updateNewsCategory, isPending};
};
