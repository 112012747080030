import {Button} from '@dropbox/dig-components/dist/buttons';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Box, Split, ThemeContainer, ThemeProvider} from '@dropbox/dig-foundations';
import {
  AutoEmbedOption,
  EmbedConfig,
  EmbedMatchResult,
  LexicalAutoEmbedPlugin,
  URL_MATCHER,
} from '@lexical/react/LexicalAutoEmbedPlugin';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {derivedThemeAtom} from 'atoms/layout';
import {INSERT_CAPTURE_COMMAND} from 'components/DSYS/editor/plugins/CapturePlugin';
import {INSERT_KALTURA_COMMAND} from 'components/DSYS/editor/plugins/KalturaPlugin';
import {INSERT_YOUTUBE_COMMAND} from 'components/DSYS/editor/plugins/YouTubePlugin';
import {useAtomValue} from 'jotai';
import type {LexicalEditor} from 'lexical';
import {ReactNode, useMemo, useState} from 'react';
import * as ReactDOM from 'react-dom';

import {INSERT_VIMEO_COMMAND} from './VimeoPlugin';

interface PlaygroundEmbedConfig extends EmbedConfig {
  // Human readable name of the embeded content e.g. Tweet or Google Map.
  contentName: string;

  // Icon for display.
  icon?: ReactNode;

  // An example of a matching url https://twitter.com/jack/status/20
  exampleUrl: string;

  // For extra searching.
  keywords: Array<string>;

  // Embed a Figma Project.
  description?: string;
}

const YoutubeEmbedConfig: PlaygroundEmbedConfig = {
  contentName: 'Youtube Video',

  exampleUrl: 'https://www.youtube.com/watch?v=jNQXAC9IVRw',

  // Icon for display.
  icon: <i className="icon youtube" />,

  insertNode: (editor: LexicalEditor, result: EmbedMatchResult) => {
    editor.dispatchCommand(INSERT_YOUTUBE_COMMAND, result.id);
  },

  keywords: ['youtube', 'video'],

  // Determine if a given URL is a match and return url data.
  parseUrl: async (url: string) => {
    const match = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/.exec(url);

    const id = match ? (match?.[2].length === 11 ? match[2] : null) : null;

    if (id != null) {
      return {
        id,
        url,
      };
    }

    return null;
  },

  type: 'youtube-video',
};

const CaptureEmbedConfig: PlaygroundEmbedConfig = {
  contentName: 'Capture',

  exampleUrl: 'https://capture.dropbox.com/61XPWw2g0bp1vxuv',

  // Icon for display.
  icon: <i className="icon capture" />,

  insertNode: (editor: LexicalEditor, result: EmbedMatchResult) => {
    editor.dispatchCommand(INSERT_CAPTURE_COMMAND, result.id);
  },

  keywords: ['capture', 'video'],

  // Determine if a given URL is a match and return url data.
  parseUrl: async (url: string) => {
    const match = /^.*capture.dropbox.com\/(\w+).*/.exec(url);

    const id = match ? match[1] : null;

    if (id != null) {
      return {
        id,
        url,
      };
    }

    return null;
  },

  type: 'capture-video',
};

const VimeoEmbedConfig: PlaygroundEmbedConfig = {
  contentName: 'Vimeo',

  exampleUrl: 'https://vimeo.com/445394992',

  // Icon for display.
  icon: <i className="icon vimeo" />,

  insertNode: (editor: LexicalEditor, result: EmbedMatchResult) => {
    editor.dispatchCommand(INSERT_VIMEO_COMMAND, result.id);
  },

  keywords: ['vimeo', 'video'],

  // Determine if a given URL is a match and return url data.
  parseUrl: async (url: string) => {
    const match = /^.*vimeo.com\/(video\/)?(\d+).*/.exec(url);

    const id = match ? match[2] : null;

    if (id != null) {
      return {
        id,
        url,
      };
    }

    return null;
  },

  type: 'vimeo-video',
};

const KalturaEmbedConfig: PlaygroundEmbedConfig = {
  contentName: 'Kaltura Video',

  exampleUrl:
    'https://dropbox.mediaspace.kaltura.com/media/All+HandsA+Top+5+Deep+Dive+%26+AMA/1_ou078jol/188606883',

  // Icon for display.
  icon: <i className="icon kaltura" />,

  insertNode: (editor: LexicalEditor, result: EmbedMatchResult) => {
    editor.dispatchCommand(INSERT_KALTURA_COMMAND, result.id);
  },

  keywords: ['kaltura', 'video'],

  // Determine if a given URL is a match and return url data.
  parseUrl: async (url: string) => {
    const match = /^.*dropbox.mediaspace.kaltura.com\/media\/.*\/(\d*).*/.exec(url);

    const id = match ? match[1] : null;

    if (id != null) {
      return {
        id,
        url,
      };
    }

    return null;
  },

  type: 'kaltura-video',
};

const EmbedConfigs = [YoutubeEmbedConfig, CaptureEmbedConfig, VimeoEmbedConfig, KalturaEmbedConfig];

const AutoEmbedMenuItem = ({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
}: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: AutoEmbedOption;
}) => {
  return (
    <Button
      size="small"
      variant={option.title.toLocaleLowerCase() === 'dismiss' ? 'outline' : 'primary'}
      key={option.key}
      tabIndex={-1}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={'typeahead-item-' + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      {option.title}
    </Button>
  );
};

const AutoEmbedMenu = ({
  options,
  selectedItemIndex,
  onOptionClick,
  onOptionMouseEnter,
}: {
  selectedItemIndex: number | null;
  onOptionClick: (option: AutoEmbedOption, index: number) => void;
  onOptionMouseEnter: (index: number) => void;
  options: Array<AutoEmbedOption>;
}) => {
  return (
    <ThemeProvider mode={useAtomValue(derivedThemeAtom) === 'bright' ? 'dark' : 'bright'}>
      <ThemeContainer>
        <Box backgroundColor="Background Raised" padding="16" borderRadius="Medium">
          <Split gap="8">
            {options.map((option: AutoEmbedOption, i: number) => (
              <Split.Item key={option.key}>
                <AutoEmbedMenuItem
                  index={i}
                  isSelected={selectedItemIndex === i}
                  onClick={() => onOptionClick(option, i)}
                  onMouseEnter={() => onOptionMouseEnter(i)}
                  option={option}
                />
              </Split.Item>
            ))}
          </Split>
        </Box>
      </ThemeContainer>
    </ThemeProvider>
  );
};

const debounce = (callback: (text: string) => void, delay: number) => {
  let timeoutId: number;
  return (text: string) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(text);
    }, delay);
  };
};

const AutoEmbedDialog = ({
  embedConfig,
  onClose,
}: {
  embedConfig: PlaygroundEmbedConfig;
  onClose: () => void;
}) => {
  const [text, setText] = useState('');
  const [editor] = useLexicalComposerContext();
  const [embedResult, setEmbedResult] = useState<EmbedMatchResult | null>(null);

  const validateText = useMemo(
    () =>
      debounce((inputText: string) => {
        const urlMatch = URL_MATCHER.exec(inputText);
        if (embedConfig != null && inputText != null && urlMatch != null) {
          Promise.resolve(embedConfig.parseUrl(inputText)).then((parseResult) => {
            setEmbedResult(parseResult);
          });
        } else if (embedResult != null) {
          setEmbedResult(null);
        }
      }, 200),
    [embedConfig, embedResult]
  );

  const onClick = () => {
    if (embedResult != null) {
      embedConfig.insertNode(editor, embedResult);
      onClose();
    }
  };

  return (
    <div style={{width: '600px'}}>
      <div className="Input__wrapper">
        <input
          type="text"
          className="Input__input"
          placeholder={embedConfig.exampleUrl}
          value={text}
          data-test-id={`${embedConfig.type}-embed-modal-url`}
          onChange={(e) => {
            const {value} = e.target;
            setText(value);
            validateText(value);
          }}
        />
      </div>

      <Button
        variant="primary"
        disabled={!embedResult}
        onClick={onClick}
        data-test-id={`${embedConfig.type}-embed-modal-submit-btn`}
      >
        Embed
      </Button>
    </div>
  );
};

export const AutoEmbedPlugin = () => {
  const [showModal, setShowModal] = useState<PlaygroundEmbedConfig | undefined>(undefined);
  const openEmbedModal = (embedConfig: PlaygroundEmbedConfig) => {
    setShowModal(embedConfig);
  };

  const getMenuOptions = (
    activeEmbedConfig: PlaygroundEmbedConfig,
    embedFn: () => void,
    dismissFn: () => void
  ) => {
    return [
      new AutoEmbedOption('Dismiss', {
        onSelect: dismissFn,
      }),
      new AutoEmbedOption(`Embed ${activeEmbedConfig.contentName}`, {
        onSelect: embedFn,
      }),
    ];
  };

  return (
    <>
      <Modal
        open={Boolean(showModal)}
        isCentered
        width="small"
        withCloseButton="Close"
        aria-label="Are you sure you want to delete?"
        onRequestClose={() => setShowModal(undefined)}
      >
        <Modal.Header>
          <Modal.Title>Embed?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AutoEmbedDialog embedConfig={showModal!} onClose={() => setShowModal(undefined)} />
        </Modal.Body>
      </Modal>
      <LexicalAutoEmbedPlugin<PlaygroundEmbedConfig>
        embedConfigs={EmbedConfigs}
        onOpenEmbedModalForConfig={openEmbedModal}
        getMenuOptions={getMenuOptions}
        menuRenderFn={(
          anchorElementRef,
          {selectedIndex, options, selectOptionAndCleanUp, setHighlightedIndex}
        ) =>
          anchorElementRef.current
            ? ReactDOM.createPortal(
                <div
                  style={{
                    marginLeft: `${Math.max(
                      parseFloat(anchorElementRef.current.style.width) - 200,
                      0
                    )}px`,
                    // marginTop: 100,
                  }}
                >
                  <AutoEmbedMenu
                    options={options}
                    selectedItemIndex={selectedIndex}
                    onOptionClick={(option: AutoEmbedOption, index: number) => {
                      setHighlightedIndex(index);
                      selectOptionAndCleanUp(option);
                    }}
                    onOptionMouseEnter={(index: number) => {
                      setHighlightedIndex(index);
                    }}
                  />
                </div>,
                anchorElementRef.current
              )
            : null
        }
      />
    </>
  );
};
