import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {IconButtonGroup, LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {List} from '@dropbox/dig-components/dist/list';
import {UIIcon} from '@dropbox/dig-icons';
import {ChevronRightLine, DeleteLine, TeamLine} from '@dropbox/dig-icons/assets';
import {Employee, EmployeeFull, Team, TeamWithCounts} from 'client';
import {Avatar, AvatarIcon} from 'components/DSYS/Avatar';
import {TeamMembersAndSubTeamsCounts} from 'components/shared/TeamMembersAndSubTeamsCounts';
import {useTeam} from 'components/teams/hooks';
import {sortEmployees, sortTeams} from 'helpers/utils';
import {isEmployee, isTeam} from 'hooks/useEmployee';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {CustomListItem} from './CustomListItem';

export const GoalPrivacyList = ({
  canDelete = false,
  selectedEmployeesOrTeams,
  onRemoveEmployeeOrTeam,
  goalOwner,
  reportingLine,
  delegates,
  contributors,
  padding,
  maxHeight,
}: {
  canDelete?: boolean;
  selectedEmployeesOrTeams: (Employee | Team)[];
  onRemoveEmployeeOrTeam?: (employee: Employee | Team) => void;
  goalOwner?: EmployeeFull;
  delegates?: Employee[] | null;
  reportingLine?: Employee[];
  contributors?: Employee[];
  padding?: string;
  maxHeight?: string;
}) => {
  return (
    <List
      isSelectable={!canDelete}
      spacing="xsmall"
      style={{
        maxHeight: canDelete ? (maxHeight ? maxHeight : '260px') : '100%',
        overflow: 'auto',
        marginTop: '8px',
        padding: '0',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {selectedEmployeesOrTeams
        .filter(isTeam)
        .sort(sortTeams)
        .map((item: Team) => (
          <TeamPrivacyItem
            key={item.dbx_team_id}
            item={item}
            onRemoveEmployeeOrTeam={onRemoveEmployeeOrTeam!}
            canDelete={canDelete}
            padding={padding}
          />
        ))}
      {selectedEmployeesOrTeams
        .filter(isEmployee)
        .sort(sortEmployees)
        .map((item: Employee) => (
          <EmployeePrivacyItem
            key={item.user_id}
            item={item}
            onRemoveEmployeeOrTeam={onRemoveEmployeeOrTeam!}
            canDelete={canDelete}
            padding={padding}
          />
        ))}
      {delegates?.map((delegate, index) => (
        <CustomListItem
          key={index + delegate.user_id}
          itemInfo={{type: 'delegate', delegate: delegate}}
          canDelete={canDelete}
          padding={padding}
        />
      ))}
      {contributors?.map((contributor, index) => (
        <CustomListItem
          key={index + contributor.user_id}
          itemInfo={{type: 'contributor', contributor}}
          canDelete={canDelete}
          padding={padding}
        />
      ))}
      <CustomListItem
        itemInfo={{type: 'reportingLine', reportingLine: reportingLine!}}
        canDelete={canDelete}
        padding={padding}
      />
      <CustomListItem
        itemInfo={{type: 'owner', goalOwner: goalOwner!}}
        canDelete={canDelete}
        padding={padding}
      />
    </List>
  );
};

const TeamPrivacyItem = ({
  canDelete = false,
  item,
  onRemoveEmployeeOrTeam,
  padding,
}: {
  canDelete?: boolean;
  item: TeamWithCounts;
  onRemoveEmployeeOrTeam: (item: Employee | Team) => void;
  padding?: string;
}) => {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();

  const team = useTeam({slug: item.slug ?? ''});

  const membersCount: number | null =
    (team ? team.total_employee_count : item.members_count) ?? null;
  const subTeamsCount: number | null = (team ? team.children?.length : item.subteams_count) ?? null;

  const onNavigate = () => {
    navigate(`/teams/${item.slug}`);
  };

  return (
    <List.Item
      gap="small"
      style={{
        padding: padding ?? '4px 8px',
        alignItems: 'center',
        display: 'flex',
        gap: '8px',
        borderRadius: '8px',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={canDelete ? () => {} : onNavigate}
    >
      <AvatarIcon src={TeamLine} size="medium" />
      <List.Content>
        <LabelGroup
          withText={item.name}
          withSubtext={
            membersCount !== null &&
            subTeamsCount !== null && (
              <TeamMembersAndSubTeamsCounts
                membersCount={membersCount}
                subTeamsCount={subTeamsCount}
              />
            )
          }
        />
      </List.Content>
      <List.Accessory>
        {hover && (
          <IconButtonGroup>
            {({getButtonProps}) => (
              <>
                <IconButton
                  variant="transparent"
                  {...getButtonProps()}
                  shape="circular"
                  onClick={canDelete ? () => {} : onNavigate}
                >
                  {canDelete ? (
                    <UIIcon
                      src={DeleteLine}
                      style={{color: 'var(--dig-color__border__base)'}}
                      onClick={() => onRemoveEmployeeOrTeam(item)}
                    />
                  ) : (
                    <UIIcon
                      src={ChevronRightLine}
                      style={{color: 'var(--dig-color__border__base)'}}
                      onClick={onNavigate}
                    />
                  )}
                </IconButton>
              </>
            )}
          </IconButtonGroup>
        )}
      </List.Accessory>
    </List.Item>
  );
};

const EmployeePrivacyItem = ({
  canDelete = false,
  item,
  onRemoveEmployeeOrTeam,
  padding,
}: {
  canDelete?: boolean;
  item: Employee;
  onRemoveEmployeeOrTeam: (item: Employee | Team) => void;
  padding?: string;
}) => {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();

  const onNavigate = () => {
    navigate(`/people/${item.ldap}`);
  };

  return (
    <List.Item
      gap="small"
      style={{
        padding: padding ?? '4px 8px',
        alignItems: 'center',
        display: 'flex',
        gap: '4px',
        borderRadius: '8px',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={canDelete ? () => {} : onNavigate}
    >
      <List.Accessory style={{width: '32px', height: '32px'}}>
        <Avatar user={item} isInactive={item.is_deactivated ?? false} />
      </List.Accessory>
      <List.Content>
        <LabelGroup withText={item.name} />
      </List.Content>
      <List.Accessory>
        {hover && (
          <IconButtonGroup>
            {({getButtonProps}) => (
              <>
                <IconButton
                  variant="transparent"
                  {...getButtonProps()}
                  shape="circular"
                  onClick={canDelete ? () => {} : onNavigate}
                >
                  {canDelete ? (
                    <UIIcon
                      src={DeleteLine}
                      style={{color: 'var(--dig-color__border__base)'}}
                      onClick={() => onRemoveEmployeeOrTeam(item)}
                    />
                  ) : (
                    <UIIcon
                      src={ChevronRightLine}
                      style={{color: 'var(--dig-color__border__base)'}}
                      onClick={onNavigate}
                    />
                  )}
                </IconButton>
              </>
            )}
          </IconButtonGroup>
        )}
      </List.Accessory>
    </List.Item>
  );
};
