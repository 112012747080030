import {FormRow} from '@dropbox/dig-components/dist/form_row';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Typeahead} from '@dropbox/dig-components/dist/typeahead';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {SearchLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {Employee} from 'client';
import {useSearches} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useEffect, useState} from 'react';

import {Avatar} from './Avatar';

export const EmployeeTypeahead = ({
  autoFocus,
  selection,
  selections,
  placeholder,
  onSingleSelect,
  onMultiSelect,
}: {
  autoFocus?: boolean;
  selection?: string;
  selections?: string[];
  placeholder: Employee[];
  onSingleSelect?: (employee: Employee) => void;
  onMultiSelect?: (employees: Employee[]) => void;
}) => {
  if ((selection && !onSingleSelect) || (selections && !onMultiSelect)) {
    throw new Error(
      'Selections must be used with onMultiSelect and selection must be used with onSingleSelect'
    );
  }

  const [userInputValue, setUserInputValue] = useState('');
  const searchResult = useSearches({
    ldaps: userInputValue.split(',').map((e) => (!e.includes('@') ? e : e.trim().split('@')[0])),
  });

  const employeeHints = searchResult.length ? searchResult : placeholder;

  const filteredEmployeeHints = employeeHints.filter(
    (employee) =>
      !selections?.find((email) => email === employee.email) && employee.email !== selection
  );

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInputValue(event.currentTarget.value);
  };

  const handleSelection = (ldap: string) => {
    const selectedEmployee = employeeHints.find((e) => e.ldap === ldap);
    if (selectedEmployee) {
      onMultiSelect?.([selectedEmployee]);
      onSingleSelect?.(selectedEmployee);
      setUserInputValue('');
    }
  };

  useEffect(() => {
    if (userInputValue.length > 0 && userInputValue.includes(',') && searchResult.length) {
      let updatedInputValue = userInputValue;
      const foundEmployees = userInputValue.split(',').reduce((acc, value) => {
        const normalizedValue = value.trim().toLowerCase();
        const found = employeeHints.find(
          (e) =>
            e.name.toLowerCase() === normalizedValue || e.ldap === normalizedValue.split('@')[0]
        );
        if (found) {
          updatedInputValue = updatedInputValue.replace(value, '');
          acc.push(found);
        }
        return acc;
      }, [] as Employee[]);

      const newSelections = foundEmployees.filter(
        (found) => !selections?.find((e) => e === found.email)
      );

      if (newSelections.length) {
        onMultiSelect?.(newSelections);
      }

      setUserInputValue(
        updatedInputValue
          .split(',')
          .filter((e) => e.length)
          .join(',')
      );
    }
  }, [employeeHints, onMultiSelect, searchResult, selections, userInputValue]);

  const renderTypeaheadRow = (employee: Employee) => (
    <Typeahead.Row
      key={employee.user_id}
      value={employee.ldap}
      withTitle={<Text isBold>{employee.name}</Text>}
      withSubtitle={employee.role}
      withLeftAccessory={<Avatar user={employee} />}
    />
  );

  return (
    <Typeahead.Wrapper onSelection={handleSelection}>
      {({getTriggerProps, getContentProps}) => (
        <FormRow>
          <TextInput
            autoFocus={autoFocus}
            size="large"
            placeholder={t('search_dropboxers')}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck={false}
            value={userInputValue}
            withRightAccessory={<Box as={UIIcon} src={SearchLine} color="Text Subtle" />}
            {...getTriggerProps({onChange: onInputChange})}
          />

          <Typeahead.Container {...getContentProps()}>
            <Typeahead.Results
              maxResults={8}
              results={filteredEmployeeHints}
              renderRow={renderTypeaheadRow}
            />
          </Typeahead.Container>
        </FormRow>
      )}
    </Typeahead.Wrapper>
  );
};
