import {SVGProps} from 'react';

export const OrgLine = (props: SVGProps<SVGSVGElement>): React.ReactElement => {
  const iconFill = 'currentColor';
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.48438 5H16.4844V8.25H7.48438V5ZM5.98438 9.75V3.5H17.9844V9.75H12.7344V11.75H15.9844H17.2031H18.9844C19.5367 11.75 19.9844 12.1977 19.9844 12.75V15.3115C21.5639 15.5805 22.4844 16.729 22.4844 18.5C22.4844 20.535 21.2694 21.75 19.2344 21.75C17.1994 21.75 15.9844 20.535 15.9844 18.5C15.9844 16.729 16.9049 15.5805 18.4844 15.3115V13.25H17.2031H15.9844H12.7344L12.7344 15.3115C14.3139 15.5805 15.2344 16.729 15.2344 18.5C15.2344 20.535 14.0194 21.75 11.9844 21.75C9.94938 21.75 8.73438 20.535 8.73438 18.5C8.73438 16.729 9.65487 15.5805 11.2344 15.3115L11.2344 13.25H8.73438H8.01562L7.35938 13.25L5.51562 13.25L5.51562 15.3115C7.09513 15.5805 8.01562 16.729 8.01562 18.5C8.01562 20.535 6.80062 21.75 4.76563 21.75C2.73063 21.75 1.51562 20.535 1.51562 18.5C1.51562 16.729 2.43612 15.5805 4.01562 15.3115L4.01562 12.75C4.01562 12.1977 4.46334 11.75 5.01562 11.75L7.35938 11.75L8.01562 11.75H8.73438H11.2344V9.75H5.98438ZM4.76562 20.25C5.97612 20.25 6.51562 19.71 6.51562 18.5C6.51562 17.29 5.97612 16.75 4.76562 16.75C3.55512 16.75 3.01562 17.29 3.01562 18.5C3.01562 19.71 3.55512 20.25 4.76562 20.25ZM17.4844 18.5C17.4844 19.71 18.0239 20.25 19.2344 20.25C20.4449 20.25 20.9844 19.71 20.9844 18.5C20.9844 17.29 20.4449 16.75 19.2344 16.75C18.0239 16.75 17.4844 17.29 17.4844 18.5ZM11.9844 20.25C10.7739 20.25 10.2344 19.71 10.2344 18.5C10.2344 17.29 10.7739 16.75 11.9844 16.75C13.1949 16.75 13.7344 17.29 13.7344 18.5C13.7344 19.71 13.1949 20.25 11.9844 20.25Z"
        fill={iconFill}
      />
    </svg>
  );
};
