import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {PersonMultipleLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {Employee} from 'client';
import {Title} from 'components/DSYS/Title';
import ProfileCard from 'components/shared/ProfileCard';

import styles from './ProfileEmployees.module.css';

interface ProfileEmployeesProps {
  sectionTitle: string;
  employees: Employee[];
  employee: Employee;
  source: string;
}

export const ProfileEmployees = (props: ProfileEmployeesProps) => {
  return (
    <Box
      paddingX="20"
      paddingY="16"
      borderWidth="1"
      borderColor="Border Subtle"
      borderRadius="Medium"
      borderStyle="Solid"
      className={styles.profileReportsSection}
    >
      <Text isBold size="xlarge"></Text>
      <Title size={16} withAccessoryStart={<UIIcon src={PersonMultipleLine} isOpticallyAligned />}>
        {props.sectionTitle}
      </Title>
      <div className={styles.profileReportsList}>
        {props.employees
          .filter(
            // TODO: Move to server
            (person) => person.ldap !== 'donotuse' && person.ldap !== props.employee.ldap
          )
          .map((person, index) => (
            <ProfileCard
              key={index + person.ldap}
              source={props.source}
              display_name={person.name}
              profile_info={person.role || ''}
              profile_photo_url={person.profile_photo_url}
              profile_username={person.ldap}
              isDeactivated={person.is_deactivated}
              directReportsCount={person.direct_reports_count}
              totalReportsCount={person.total_report_count}
              onClick={() =>
                analyticsLogger().logEvent('PROFILE_INTERACTION', {action: props.source})
              }
            />
          ))}
      </div>
    </Box>
  );
};
