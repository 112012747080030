import {sortEmployees} from 'helpers/utils';
import {useEffect} from 'react';
import {useProfileSuspense} from 'views/goals_v2/hooks';
import {GoalsTablesComponent} from 'views/goals_v2/TableComponents/GoalsTablesComponent';
import {GoalsTablePropsWithoutData} from 'views/goals_v2/types';
import {isDirectReportsSelected} from 'views/goals_v2/utils';

/*
 * Table view that loads employee goals for direct reports as their own tables
 */
export const DirectReportsGoalsTableView = (
  tableProps: GoalsTablePropsWithoutData & {userLdap: string}
) => {
  const {filter, userLdap, goalsHeaderData, setGoalsHeaderData, quickFilterSelected} = tableProps;
  const {directReports = []} = useProfileSuspense({ldap: userLdap});
  const sortedDirectReports = directReports.sort(sortEmployees);
  const directReportLdaps = sortedDirectReports.map(({ldap}) => ldap);
  const hasDirectReportSelected = filter && isDirectReportsSelected(filter, quickFilterSelected);
  const ldaps = hasDirectReportSelected ? directReportLdaps : [];

  useEffect(() => {
    if (goalsHeaderData && setGoalsHeaderData && directReportLdaps) {
      setGoalsHeaderData({
        ...goalsHeaderData,
        directReportLdaps,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(goalsHeaderData), setGoalsHeaderData, JSON.stringify(directReportLdaps)]);

  const keyPrefix = 'direct_reports_goals';
  return <GoalsTablesComponent keyPrefix={keyPrefix} tableProps={tableProps} ldaps={ldaps} />;
};
