import {AutoLinkPlugin as LexicalAutoLinkPlugin} from '@lexical/react/LexicalAutoLinkPlugin';

export const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const EMAIL_MATCHER =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

const JIRA_MATCHER = /[A-Z]+-\d+/;

const MATCHERS = [
  (text: string) => {
    const match = URL_MATCHER.exec(text);
    return (
      match && {
        index: match.index,
        length: match[0].length,
        text: match[0],
        url: match[0],
      }
    );
  },
  (text: string) => {
    const match = EMAIL_MATCHER.exec(text);
    return (
      match && {
        index: match.index,
        length: match[0].length,
        text: match[0],
        url: `mailto:${match[0]}`,
      }
    );
  },
  (text: string) => {
    const match = JIRA_MATCHER.exec(text);
    return (
      match && {
        index: match.index,
        length: match[0].length,
        text: match[0],
        url: `https://jira.dropboxer.net/browse/${match[0]}`,
      }
    );
  },
  // // ldap links to profile
  // (text: string) => {
  //   const match = /@([a-zA-Z0-9-]+)/.exec(text);
  //   return (
  //     match && {
  //       index: match.index,
  //       length: match[0].length,
  //       text: match[0],
  //       url: `${getFrontendUrl()}people/${match[1]}`,
  //     }
  //   );
  // },
  // // Slack #channels
  // (text: string) => {
  //   const match = /#([a-zA-Z0-9-]+)/.exec(text);
  //   return (
  //     match && {
  //       index: match.index,
  //       length: match[0].length,
  //       text: match[0],
  //       url: `https://dropbox.slack.com/archives/${match[1]}`,
  //     }
  //   );
  // },
];

export const AutoLinkPlugin = () => {
  return <LexicalAutoLinkPlugin matchers={MATCHERS} />;
};
