import {Chip} from '@dropbox/dig-components/dist/chip';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Box} from '@dropbox/dig-foundations';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {Avatar} from 'components/DSYS/Avatar';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {FunctionComponent, useState} from 'react';

import styles from './DelegateSelectMenu.module.css';

interface Props {
  disabled?: boolean;
  delegateId?: string;
  filterLdaps?: string[];
  setDelegateId?: (userId?: string) => void;
  delegateLdap?: string;
  setDelegateLdap?: (ldap?: string) => void;
  showDisabledDelegateChip?: boolean;
}

export const DelegateSelectMenu: FunctionComponent<Props> = ({
  disabled = false,
  delegateId,
  filterLdaps = [],
  setDelegateId,
  showDisabledDelegateChip,
  setDelegateLdap,
}) => {
  const [selected, setSelected] = useState<boolean>(false);

  const {employee, delegatedBy} = useAtomValue(loggedInEmployeeAtom);
  const delegateList = (delegatedBy ? [employee, ...delegatedBy] : [employee]).filter(
    ({ldap}) => !filterLdaps.includes(ldap)
  );

  const selectedId = delegateId || employee.user_id;
  const selectedDelegate = delegateList?.find((d) => d.user_id === selectedId) ?? delegateList?.[0];

  const handleDelegateSelection = (userId: string) => {
    setDelegateId?.(userId);
    setSelected(false);
    setDelegateLdap?.(delegateList.find((d) => d.user_id === userId)?.ldap);
  };

  if (showDisabledDelegateChip && selectedDelegate) {
    return (
      <Chip isSelected={true} disabled={true} className={styles.delegateButton}>
        <Chip.AvatarAccessory>
          <Avatar user={selectedDelegate} />
        </Chip.AvatarAccessory>
        <Box marginLeft="4">{selectedDelegate?.name}</Box>
      </Chip>
    );
  }

  if (
    !delegateList.length ||
    (delegateList.length === 1 && delegateList[0].user_id === employee.user_id)
  ) {
    return null;
  }

  return (
    <Menu.Wrapper
      className={styles.delegationMenu}
      onSelection={handleDelegateSelection}
      onToggle={({isOpen}) => setSelected(isOpen)}
    >
      {({getTriggerProps, getContentProps}) => (
        <>
          <Chip
            {...getTriggerProps()}
            disabled={disabled}
            withDropdownIcon
            isSelected={selected}
            className={styles.delegateButton}
          >
            {selectedDelegate && (
              <Chip.AvatarAccessory>
                <Avatar user={selectedDelegate} />
              </Chip.AvatarAccessory>
            )}
            <Box marginLeft="4">{selectedDelegate?.name}</Box>
          </Chip>

          <Menu.Content {...getContentProps()}>
            <Menu.Segment withLabel={t('on_behalf_of')}>
              {delegateList.map((user) => (
                <Menu.ActionItem
                  key={user.user_id}
                  value={user.user_id}
                  withLeftAccessory={<Avatar user={user} size="small" />}
                >
                  {user.name}
                </Menu.ActionItem>
              ))}
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};
