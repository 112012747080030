import {Text, TextProps} from '@dropbox/dig-components/dist/typography';
import classNames from 'classnames';
import {FunctionComponent} from 'react';

import styles from './LabelText.module.css';

export const LabelText: FunctionComponent<
  Pick<TextProps, 'onClick' | 'children' | 'htmlFor' | 'tagName' | 'className'>
> = ({className, ...rest}) => (
  <Text {...rest} className={classNames(styles.labelText, className)} />
);
