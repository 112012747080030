import {BlockWithAlignableContents} from '@lexical/react/LexicalBlockWithAlignableContents';
import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode,
} from '@lexical/react/LexicalDecoratorBlockNode';
import type {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  ElementFormatType,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  Spread,
} from 'lexical';

type CaptureComponentProps = Readonly<{
  className: Readonly<{
    base: string;
    focus: string;
  }>;
  format: ElementFormatType | null;
  nodeKey: NodeKey;
  videoID: string;
}>;

const CaptureComponent = ({className, format, nodeKey, videoID}: CaptureComponentProps) => {
  return (
    <BlockWithAlignableContents className={className} format={format} nodeKey={nodeKey}>
      <iframe
        src={`https://capture.dropbox.com/embed/${videoID}?source=companyOS`}
        width="100%"
        height="390"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </BlockWithAlignableContents>
  );
};

export type SerializedCaptureNode = Spread<
  {
    videoID: string;
  },
  SerializedDecoratorBlockNode
>;

function convertCaptureElement(domNode: HTMLElement): null | DOMConversionOutput {
  const videoID = domNode.getAttribute('data-lexical-capture');
  if (videoID) {
    const node = $createCaptureNode(videoID);
    return {node};
  }
  return null;
}

export class CaptureNode extends DecoratorBlockNode {
  __id: string;

  static getType(): string {
    return 'capture';
  }

  static clone(node: CaptureNode): CaptureNode {
    return new CaptureNode(node.__id, node.__format, node.__key);
  }

  static importJSON(serializedNode: SerializedCaptureNode): CaptureNode {
    const node = $createCaptureNode(serializedNode.videoID);
    node.setFormat(serializedNode.format);
    return node;
  }

  exportJSON(): SerializedCaptureNode {
    return {
      ...super.exportJSON(),
      type: 'capture',
      version: 1,
      videoID: this.__id,
    };
  }

  constructor(id: string, format?: ElementFormatType, key?: NodeKey) {
    super(format, key);
    this.__id = id;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('iframe');
    element.setAttribute('data-lexical-capture', this.__id);
    element.setAttribute('width', '100%');
    element.setAttribute('height', '390');
    element.setAttribute('src', `https://capture.dropbox.com/embed/${this.__id}}?source=companyOS`);
    element.setAttribute('frameborder', '0');
    element.setAttribute(
      'allow',
      'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
    );
    element.setAttribute('allowfullscreen', 'true');
    element.setAttribute('title', 'Capture');
    return {element};
  }

  static importDOM(): DOMConversionMap | null {
    return {
      iframe: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-lexical-capture')) {
          return null;
        }
        return {
          conversion: convertCaptureElement,
          priority: 1,
        };
      },
    };
  }

  updateDOM(): false {
    return false;
  }

  getId(): string {
    return this.__id;
  }

  getTextContent(): string {
    return `https://capture.dropbox.com/${this.__id}`;
  }

  decorate(_editor: LexicalEditor, config: EditorConfig) {
    const embedBlockTheme = config.theme.embedBlock || {};
    const className = {
      base: embedBlockTheme.base || '',
      focus: embedBlockTheme.focus || '',
    };
    return (
      <CaptureComponent
        className={className}
        format={this.__format}
        nodeKey={this.getKey()}
        videoID={this.__id}
      />
    );
  }
}

export function $createCaptureNode(videoID: string): CaptureNode {
  return new CaptureNode(videoID);
}

export function $isCaptureNode(
  node: CaptureNode | LexicalNode | null | undefined
): node is CaptureNode {
  return node instanceof CaptureNode;
}
