import {useSuspenseQuery} from '@tanstack/react-query';
import {getEmployeeService} from 'utilities';

export const useProfileSuspense = ({ldap}: {ldap: string}) => {
  const {data} = useSuspenseQuery({
    queryKey: ['userProfile', ldap],
    queryFn: () => getEmployeeService().getProfileApiV1PeopleUserIdGet(ldap),
  });

  return data;
};
