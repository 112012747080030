import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {NewGoalView} from 'views/goals/NewGoalView';

const NewGoal = () => {
  useDocumentTitle(t('create_goal'));
  return <NewGoalView />;
};

export {NewGoal};
