import {EmployeeWithReportTeams} from 'client';

const maxTeamCount = 5;

export const getCheckupError = (
  {team_associations, reports_error}: EmployeeWithReportTeams,
  strictAllocation?: boolean
) => {
  if (!team_associations?.length) {
    return 'checkup_no_teams';
  }

  if (reports_error && !strictAllocation) {
    return 'checkup_reports_error';
  }

  const only_member = team_associations.find(({team}) => team.employee_count === 1)?.team;
  if (only_member) {
    return 'checkup_only_member';
  }

  if (
    team_associations.length &&
    team_associations.reduce((acc, {allocation}) => acc + (allocation ?? 0), 0) !== 100
  ) {
    if (!strictAllocation) {
      if (!team_associations.some(({allocation}) => !allocation)) {
        return 'checkup_allocation';
      }
    } else {
      return 'checkup_allocation';
    }
  }

  if (team_associations.length >= maxTeamCount) {
    return 'checkup_too_many_teams';
  }

  return undefined;
};

export const getErrorCounts = (employees: EmployeeWithReportTeams[]) => {
  let noTeams = 0;
  let tooManyTeams = 0;
  let onlyMember = 0;
  let reportErrors = 0;
  let allocationErrors = 0;

  for (const {team_associations, reports_error} of employees) {
    if (!team_associations.length) {
      noTeams++;
    }

    if (reports_error) {
      reportErrors++;
    }

    const only_member = team_associations.find(({team}) => team.employee_count === 1)?.team;

    if (only_member) {
      onlyMember++;
    }

    if (
      team_associations.length &&
      !team_associations.some(({allocation}) => !allocation) &&
      team_associations.reduce((acc, {allocation}) => acc + (allocation ?? 0), 0) !== 100
    ) {
      allocationErrors++;
    }

    if (team_associations.length >= maxTeamCount) {
      tooManyTeams++;
    }
  }
  return {noTeams, reportErrors, tooManyTeams, onlyMember, allocationErrors};
};
