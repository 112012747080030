import {Interstitial} from '@dropbox/dig-components/progress_indicators';

import styles from './PageSpinner.module.css';

const PageSpinner = () => (
  <div className={styles.pageSpinner}>
    <Interstitial onComplete={() => {}} in isIndeterminate />
  </div>
);

export {PageSpinner};
