import {Button} from '@dropbox/dig-components/dist/buttons';
import {Text} from '@dropbox/dig-components/dist/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {CheckmarkLine, FailLine, MagicWandLine} from '@dropbox/dig-icons/assets';
import {OKREntry, OKREntryStatus} from 'helpers/okr/types';
import {KeyboardEvent, useEffect, useRef} from 'react';

import styles from './OKRComponent.module.css';

interface OKRComponentProps {
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleFocus: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  handleCheckObjective: () => void;
  inputText: string;
  objectiveEntry: OKREntry;
}

const OKRComponent = ({
  onChange,
  inputText,
  handleFocus,
  handleCheckObjective,
  objectiveEntry,
}: OKRComponentProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const adjustTextareaHeight = () => {
    const element = textareaRef.current;
    if (element) {
      element.style.height = 'auto';
      element.style.height = element.scrollHeight + 'px';
    }
  };

  return (
    <>
      <div className={styles.okrContainer}>
        <div className={styles.okrHeader}>
          <Text size="large" className={styles.objectiveHeader}>
            Objective
          </Text>
        </div>
        <textarea
          autoFocus
          placeholder="What do you want to achieve?"
          className={styles.objectiveInputTextArea}
          onChange={onChange}
          value={inputText}
          onFocus={handleFocus}
          readOnly={objectiveEntry.status === OKREntryStatus.SUCCESS}
          ref={textareaRef}
          onInput={adjustTextareaHeight}
        />
        {inputText.length > 0 && objectiveEntry.status !== OKREntryStatus.SUCCESS && (
          <Button
            variant="opacity"
            onClick={handleCheckObjective}
            withIconLeft={
              <UIIcon src={MagicWandLine} className={styles.checkObjectiveButtonIcon} />
            }
            isLoading={objectiveEntry.status === OKREntryStatus.IN_PROGRESS}
            disabled={objectiveEntry.status === OKREntryStatus.FAILED}
          >
            Check objective
          </Button>
        )}
      </div>
    </>
  );
};

interface KeyResultsComponentProps {
  // inputs: {id: number; value: string}[];
  okrEntries: {id: number; value: string; entry: OKREntry}[];
  handleAddInput: () => void;
  handleInputChange: (id: number, value: string) => void;
  handleRemoveInput: (id: number) => void;
  handleKeyDown: (event: KeyboardEvent<HTMLInputElement>, index: number) => void;
  handleFocus: (id: number) => void;
  handleCheckKeyResult: () => void;
  completed: boolean;
}

const KeyResultsComponent = ({
  okrEntries,
  handleInputChange,
  handleKeyDown,
  handleFocus,
  handleCheckKeyResult,
  completed,
}: KeyResultsComponentProps) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    setTimeout(() => {
      if (inputRefs.current[inputRefs.current.length - 1]) {
        inputRefs.current[inputRefs.current.length - 1].focus();
      }
    }, 0);
  }, [okrEntries]);

  const renderInputs = () => {
    return okrEntries.map((input, index) => (
      <div key={input.id} className={styles.keyResultContainer}>
        <Text size="large" className={styles.keyResultTitle}>{`KR${input.id}`}</Text>
        <input
          id={`${input.id}`}
          ref={(ref) => (inputRefs.current[index] = ref as HTMLInputElement)}
          placeholder="How will I know I succeeded?"
          className={styles.keyResultInput}
          value={input.value}
          onChange={(e) => handleInputChange(input.id, e.currentTarget.value)}
          onKeyDown={(e) => handleKeyDown(e, input.id)}
          onFocus={() => handleFocus(index)}
          readOnly={input.entry.status === OKREntryStatus.SUCCESS}
        />

        {input.entry.status === OKREntryStatus.FAILED && <UIIcon src={FailLine} color="#BBB5AE" />}
        {input.entry.status === OKREntryStatus.SUCCESS && (
          <UIIcon src={CheckmarkLine} color="#BBB5AE" />
        )}
      </div>
    ));
  };

  return (
    <div className={styles.keyResultsContainer}>
      {renderInputs()}
      {okrEntries[okrEntries.length - 1].value !== '' && !completed && (
        <div className={styles.keyResultButtons}>
          <Button
            variant="opacity"
            onClick={handleCheckKeyResult}
            withIconLeft={
              <UIIcon src={MagicWandLine} className={styles.checkObjectiveButtonIcon} />
            }
            isLoading={
              okrEntries[okrEntries.length - 1].entry.status === OKREntryStatus.IN_PROGRESS
            }
            disabled={okrEntries[okrEntries.length - 1].entry.status === OKREntryStatus.FAILED}
          >
            Check key result
          </Button>
        </div>
      )}
    </div>
  );
};

export {KeyResultsComponent, OKRComponent};
