import {Tabs} from '@dropbox/dig-components/dist/tabs';
import {Select} from '@dropbox/dig-components/dist/text_fields';
import {Box, Split, Stack} from '@dropbox/dig-foundations';
import {themePreferenceAtom} from 'atoms/settings';
import {Layout} from 'components/DSYS/Layout';
import {Scroller} from 'components/DSYS/Scroller';
import {t} from 'i18next';
import {useAtom} from 'jotai';
import {useEffect, useState} from 'react';

import {
  AccordionExample,
  EditorExample,
  EmptyStateExample,
  GridLayoutExample,
  LightboxExample,
  OptionalTooltipExample,
  StatusExample,
  TableExample,
  TypeaheadExample,
  UpdatesExample,
} from './DSYSCompanyOS';
import {
  AvatarExample,
  ButtonExample,
  FormExample,
  ModalExample,
  TypographyExample,
} from './DSYSDIGOverride';

export const DSYS = () => {
  const [themePreference, setThemePreference] = useAtom(themePreferenceAtom);
  const [selectedTab, setSelectedTab] = useState('DIG');
  const [data, setData] = useState<any>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setTimeout(() => {
        setLoaded(true);
      }, 1000);
    }
  }, [loaded]);

  useEffect(() => {
    if (loaded) {
      const headers = Array.from(document.body.getElementsByTagName('h2'));

      const headerData: {parent: HTMLElement; children: HTMLElement[]}[] = [];
      while (headers.length > 0) {
        const parentHeader = headers.shift();
        const childHeaders = [];

        // Iterate headers to find children
        for (let i = 0; i < headers.length; i++) {
          const potentialChildHeader = headers[i];

          if (potentialChildHeader?.classList.contains('ignore')) {
            headers.splice(i, 1);
            i--;
            continue;
          }

          if (parentHeader && parentHeader.parentElement?.contains(potentialChildHeader)) {
            childHeaders.push(potentialChildHeader);
            headers.splice(i, 1);
            i--;
          }
        }

        headerData.push({
          parent: parentHeader!,
          children: childHeaders,
        });
      }

      setData(
        headerData.map(({parent, children}) => ({
          parent: {
            label: parent.innerText.split(/\s/)[0].replace(/DSYS\//, ''),
            element: parent,
          },
          children:
            children.length === 1
              ? []
              : children.map((child) => ({
                  label: child.innerText.split(/\s/)[0].replace(/DSYS\//, ''),
                  element: child,
                })),
        }))
      );
    }
  }, [loaded, selectedTab]);

  return (
    <Layout.Container>
      <Scroller data={data} />
      <Tabs selectedTab={selectedTab} onSelection={setSelectedTab}>
        <Box position="sticky" style={{top: 52, zIndex: 3}} backgroundColor="Background Base">
          <Split alignY="bottom">
            <Split.Item width="fill">
              <Tabs.Group hasHorizontalPadding={false}>
                <Tabs.Tab id="DIG">DIG overrides</Tabs.Tab>
                <Tabs.Tab id="CompanyOS">CompanyOS</Tabs.Tab>
              </Tabs.Group>
            </Split.Item>
            <Split.Item>
              <Box
                borderBottom="Solid"
                paddingBottom="6"
                borderColor="Border Subtle"
                paddingRight="12"
              >
                <Select
                  id="theme-select"
                  defaultValue={themePreference}
                  onChange={(value) =>
                    setThemePreference(value as Parameters<typeof setThemePreference>[0])
                  }
                >
                  <Select.Option value="auto">
                    {t('settings_theme_same_as_system') as string}
                  </Select.Option>
                  <Select.Option value="dark">{t('settings_theme_dark') as string}</Select.Option>
                  <Select.Option value="bright">
                    {t('settings_theme_bright') as string}
                  </Select.Option>
                </Select>
              </Box>
            </Split.Item>
          </Split>
        </Box>

        <Tabs.Panel tabId="DIG">
          <Stack gap="76" paddingTop="32">
            <TypographyExample />
            <ButtonExample />
            <AvatarExample />
            <FormExample />
            <ModalExample />
          </Stack>
        </Tabs.Panel>

        <Tabs.Panel tabId="CompanyOS">
          <Stack gap="76" paddingTop="32">
            <GridLayoutExample />
            <StatusExample />
            <UpdatesExample />
            <EmptyStateExample />
            <AccordionExample />
            <TypeaheadExample />
            <OptionalTooltipExample />
            <EditorExample />
            <TableExample />
            <LightboxExample />
          </Stack>
        </Tabs.Panel>
      </Tabs>
    </Layout.Container>
  );
};
