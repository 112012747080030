import {Text} from '@dropbox/dig-components/dist/typography';
import {Split} from '@dropbox/dig-foundations';
import {useQuery} from '@tanstack/react-query';
import {pulseUserAtom} from 'atoms/auth';
import {Badge, BadgeService, Nomination} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {ManagePageNav} from 'components/shared/ManagePage/Nav';
import {isContentAdmin} from 'helpers/utils';
import {useAtomValue} from 'jotai';
import {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {getService} from 'utilities';

import {BadgeImage} from './BadgeImage';
import styles from './ManageBadgeList.module.css';
import {SelectedBadge} from './SelectedBadge';

export const ManageBadgesList = ({
  badges,
  nominations,
  isUserContentAdmin,
}: {
  badges?: Badge[];
  nominations?: Record<string, Nomination[]>;
  isUserContentAdmin?: boolean;
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [selected, setSelected] = useState<Badge>();

  useEffect(() => {
    if (badges && !badges.length) {
      navigate('/');
    }
  }, [badges, navigate]);

  useEffect(() => {
    if (!badges) {
      return;
    }

    const found = badges.find((badge) => badge.id === parseInt(searchParams.get('badge') || ''));
    if (found) {
      setSelected(found);
      searchParams.delete('badge');
      return;
    }
    setSelected(badges[0]);
  }, [badges, searchParams]);

  return (
    <Layout.Container>
      <Layout.Nav>
        <ManagePageNav
          items={badges && nominations ? badges : undefined}
          onSelect={setSelected}
          selected={selected}
          renderItem={(item: Badge) => (
            <BadgeNavItem
              badge={item}
              selected={item === selected}
              nominations={nominations ? nominations[item.id] || [] : []}
            />
          )}
        />
      </Layout.Nav>
      <Layout.Body>
        <SelectedBadge badge={selected} isUserContentAdmin={isUserContentAdmin} />
      </Layout.Body>
    </Layout.Container>
  );
};

const BadgeNavItem = ({
  badge,
  selected,
  nominations,
}: {
  badge: Badge;
  selected: boolean;
  nominations: Nomination[];
}) => {
  return (
    <Split gap="8">
      <Split.Item>
        <BadgeImage badge={badge.image_key} size={24} />
      </Split.Item>
      <Split.Item>
        <Text variant="paragraph" isBold={selected}>
          {badge.name}
        </Text>
      </Split.Item>
      <Split.Item>{nominations.length > 0 && <div className={styles.notification} />}</Split.Item>
    </Split>
  );
};

export const LoadedManageBadgesList = () => {
  const pulseUser = useAtomValue(pulseUserAtom);
  const {data: ownedBadges} = useQuery({
    queryKey: ['ownedBadges'],
    queryFn: getService(BadgeService).getOwnedBadgesApiV1BadgesOwnedGet,
  });
  const {data: activeNominations} = useQuery({
    queryKey: ['activeNominations'],
    queryFn: getService(BadgeService).getActiveNominationsApiV1BadgesNominationsGet,
  });
  return (
    <ManageBadgesList
      badges={ownedBadges}
      nominations={activeNominations}
      isUserContentAdmin={isContentAdmin(pulseUser?.email)}
    />
  );
};
