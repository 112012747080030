import {NotificationWithContent} from 'client';
import {createContext, PropsWithChildren, useContext} from 'react';

export interface NotificationCardContext {
  notification: NotificationWithContent;
  separator: boolean;
  upcoming?: boolean;
  onClick?: () => void;
  archiveable?: boolean;
}

export const NotificationProvider = ({
  children,
  ...props
}: PropsWithChildren<NotificationCardContext>) => (
  <NotificationContext.Provider value={{...props}}>{children}</NotificationContext.Provider>
);

const NotificationContext = createContext<NotificationCardContext | undefined>(undefined);

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotificationContext must be used within a NotificationProvider');
  }
  return context;
};
