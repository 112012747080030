// import {toQuarter} from 'components/shared/TimeAgo';
import {t} from 'i18next';

const now = new Date();
const currentMonthYear = `${now.getMonth() + 1}-${now.getFullYear()}`;

export function dateToGroup(createdAt?: string, useCurrentYear?: boolean) {
  if (!createdAt) {
    return t('activity_this_month');
  }

  const date = new Date(createdAt);
  let year = date.getFullYear();
  if (useCurrentYear) {
    year = now.getFullYear();
    if (
      (date.getMonth() === now.getMonth() && date.getDate() < now.getDate()) ||
      date.getMonth() < now.getMonth()
    ) {
      year = now.getFullYear() + 1;
    }
  }
  const key = `${date.getMonth() + 1}-${year}`;
  const monthName = date.toLocaleString('default', {month: 'long'});

  return key === currentMonthYear ? t('activity_this_month') : `${monthName} ${year}`;
}

// by quarter
//   const key = toQuarter(createdAt); //`${date.getMonth() + 1}-${year}`;
//   // const monthName = date.toLocaleString('default', {month: 'long'});

//   return key === currentMonthYear ? t('activity_this_month') : `${key} ${year}`;
// }
