import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Chip} from '@dropbox/dig-components/dist/chip';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {FormLabel, FormRow} from '@dropbox/dig-components/dist/form_row';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Select, TextArea, TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Toggletip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Cluster, Split, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ArrowLeftLine, ChevronRightLine, PersonMultipleLine} from '@dropbox/dig-icons/assets';
import {useQuery} from '@tanstack/react-query';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {snackbarAtom} from 'atoms/snackbar';
import {
  Employee,
  EmployeeLdap,
  ProjectLink,
  TeamEdit,
  TeamInfo,
  TeamInfoFull,
  TeamLink,
  TeamService,
} from 'client';
import {SavableEmployeeListTypeahead} from 'components/DSYS/EmployeeListTypeahead';
import {Facepile} from 'components/DSYS/Facepile';
import {Layout} from 'components/DSYS/Layout';
import {Title} from 'components/DSYS/Title';
import {ProjectLinkEditor} from 'components/projects/ProjectLinkEditor';
import {EditSaveButtons} from 'components/shared/EditSaveButtons';
import {sortEmployees} from 'helpers/utils';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {ChangeEvent, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {getService} from 'utilities';

import {RowEditor} from './RowEditor';
import {TeamSearch} from './TeamSearch';

export const sortEmployeesPrimaryFirst = (primaryLdap?: string) => (a: Employee, b: Employee) => {
  return a.ldap === primaryLdap ? -1 : b.ldap === primaryLdap ? 1 : sortEmployees(a, b);
};

export const TeamMemberTypeahead = ({
  initialSelections,
  initialPrimaryContact,
  isPending,
  onBeforeSave,
  onSave,
  onCancel,
}: {
  initialSelections: Employee[];
  initialPrimaryContact?: EmployeeLdap;
  isPending?: boolean;
  onBeforeSave?: (selections: Employee[], primaryContact?: EmployeeLdap) => Promise<void>;
  onSave: (selections: Employee[], primaryContact?: EmployeeLdap) => void;
  onCancel: () => void;
}) => {
  const [primaryContact, setPrimaryContact] = useState<EmployeeLdap | undefined>(
    initialPrimaryContact
  );
  const [hasChanges, setHasChanges] = useState(false);

  return (
    <SavableEmployeeListTypeahead
      isPending={isPending}
      hasChanges={hasChanges}
      initialSelections={initialSelections.sort(sortEmployeesPrimaryFirst(primaryContact?.ldap))}
      memberSort={sortEmployeesPrimaryFirst(primaryContact?.ldap)}
      onSave={async (selectedMembers) => {
        await onBeforeSave?.(selectedMembers, primaryContact);
        onSave(selectedMembers, primaryContact);
      }}
      onCancel={onCancel}
      onChange={(selections) => {
        setHasChanges(
          JSON.stringify(selections) !== JSON.stringify(initialSelections) ||
            primaryContact !== initialPrimaryContact
        );
        if (selections.length === 0) {
          setPrimaryContact(undefined);
        } else if (!primaryContact || !selections.find(({ldap}) => ldap === primaryContact.ldap)) {
          setPrimaryContact(selections[0]);
        }
      }}
      withOverflowMenuItems={(employee) => (
        <>
          {primaryContact?.ldap !== employee.ldap && (
            <Menu.ActionItem
              onClick={() => {
                setPrimaryContact(employee);
                setHasChanges(true);
              }}
            >
              {t('team_edit_primary_add')}
            </Menu.ActionItem>
          )}
          <Menu.LinkItem target="_blank" href={`/people/${employee.ldap}`}>
            {t('view_profile')}
          </Menu.LinkItem>
        </>
      )}
      withRightAccessory={(employee) =>
        employee.ldap === primaryContact?.ldap ? (
          <Chip size="small">{t('primary_contact')}</Chip>
        ) : null
      }
    />
  );
};

export const TeamModify = ({
  team,
  onSubmit,
  isEditing,
  isPending,
}: {
  onSubmit: (data: {teamId: string; data: TeamEdit; resetTree?: boolean}) => Promise<string>;
  team?: TeamInfoFull;
  isPending: boolean;
  isEditing?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const autofocus = location.state?.autofocus;
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const {employee: loggedInEmployee} = useAtomValue(loggedInEmployeeAtom);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [primaryContact, setPrimaryContact] = useState<EmployeeLdap | undefined>(
    team?.primary_contact ?? undefined
  );
  const [selectedTeam, setSelectedTeam] = useState<TeamInfo | undefined>(team?.parent ?? undefined);
  const [members, setMembers] = useState<Employee[]>(
    (team?.employees ?? ([] as Employee[])).sort(
      sortEmployeesPrimaryFirst(team?.primary_contact?.ldap)
    )
  );

  useDocumentTitle(isEditing ? t('edit_team') : t('add_team'));

  const parentSlug = new URLSearchParams(location.search).get('parent');

  const {data: parentTeam} = useQuery({
    queryKey: ['team', parentSlug],
    queryFn: () => getService(TeamService).getTeamBySlugApiV1TeamsSlugSlugGet(parentSlug!),
    enabled: location.pathname === '/teams/new' && Boolean(parentSlug),
  });

  useEffect(() => {
    if (parentTeam) {
      setSelectedTeam(parentTeam);
      setTeamUpdate((p) => ({...p, parent_team: parentTeam.team_id}));
    }
  }, [parentTeam]);

  useEffect(() => {
    analyticsLogger().logEvent('PROJECT_MODIFY_VIEW', {edit: Boolean(isEditing), autofocus});
  }, [isEditing, autofocus]);

  const [teamUpdate, setTeamUpdate] = useState<TeamEdit>(
    team
      ? {
          name: team.name,
          description: team.description ?? '',
          parent_team: team.parent?.team_id ?? '',
          slack_channels:
            team.slack_channels?.map((channel) =>
              channel?.startsWith('#') ? channel : '#' + channel
            ) ?? [],
          emails: team.emails ?? [],
          links: team.links ?? [],
        }
      : ({
          name: new URLSearchParams(location.search).get('name') ?? undefined,
        } as unknown as TeamEdit)
  );

  const hasError = useMemo(() => {
    const {name, description, links, emails, slack_channels, parent_team} = teamUpdate;

    if (
      slack_channels?.some(
        (channel) => channel.length && channel !== '#' && !/^#?[\w-]+$/.test(channel)
      )
    ) {
      return true;
    }

    if (
      emails?.some((email) => email.length && !/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/.test(email))
    ) {
      return true;
    }

    // Validate links
    let invalidLink = false;

    links?.forEach(({url}) => {
      if (!url) {
        return;
      }
      if (
        !/^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w.-]*)*(\?[\w=&]*)?$/i.test(url) &&
        !url.startsWith('drl/') &&
        !url.startsWith('http://drl/')
      ) {
        invalidLink = true;
        return;
      }
      try {
        new URL(
          url.startsWith('drl/')
            ? `http://${url}`
            : !url.startsWith('http')
              ? `https://${url}`
              : url
        );
      } catch {
        invalidLink = true;
        return;
      }
    });

    if (invalidLink || links?.some(({url, text}) => (url && !text) || (!url && text))) {
      return true;
    }

    if (!name) {
      return true;
    }

    if (!parent_team) {
      return true;
    }

    if (!members?.length) {
      return true;
    }

    if (description?.length > 2000) {
      return true;
    }

    return false;
  }, [members?.length, teamUpdate]);

  const handleSubmit = async () => {
    const {name, description, parent_team, links, emails, slack_channels} = teamUpdate;

    // Validate links
    let invalidLink = false;
    links?.forEach(({url}) => {
      if (!url) {
        return;
      }
      try {
        new URL(
          url.startsWith('drl/')
            ? `http://${url}`
            : !url.startsWith('http')
              ? `https://${url}`
              : url
        );
      } catch {
        invalidLink = true;
        return;
      }
    });

    if (!parent_team) {
      setHasSubmitError(true);
      setSnackbarMessage({text: t('missing_parent_team')});
      return;
    }

    if (!name) {
      setHasSubmitError(true);
      setSnackbarMessage({text: t('missing_fields')});
      return;
    }

    if (!members?.length) {
      setHasSubmitError(true);
      setSnackbarMessage({text: t('missing_members')});
      return;
    }

    if (description?.length > 2000) {
      setSnackbarMessage({text: t('description_too_long')});
      return;
    }

    if (
      slack_channels?.some(
        (channel) => channel.length && channel !== '#' && !/^#?[\w-]+$/.test(channel)
      )
    ) {
      setSnackbarMessage({text: t('invalid_slack_channel')});
      return;
    }

    if (
      emails?.some((email) => email.length && !/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/.test(email))
    ) {
      setSnackbarMessage({text: t('invalid_email')});
      return;
    }

    if (invalidLink || links?.some(({url, text}) => (url && !text) || (!url && text))) {
      setSnackbarMessage({text: t('invalid_link_urls')});
      return;
    }

    try {
      const response = await onSubmit({
        teamId: team?.slug ?? '',
        resetTree: parent_team !== team?.parent?.team_id || team.name !== name,
        data: {
          name,
          parent_team,
          description: description ?? '',
          primary_contact: primaryContact?.ldap,
          employees: members.map(({user_id}) => user_id),
          emails: emails?.filter((email) => email.length),
          slack_channels: slack_channels
            ?.map((channel) => (channel?.startsWith('#') ? channel : '#' + channel))
            .filter((channel) => channel !== '#'),
          links: links
            ?.map(({text, url}) => ({
              text,
              url: url.startsWith('drl/')
                ? `http://${url}`
                : url.startsWith('http')
                  ? url
                  : `https://${url}`,
            }))
            .filter(({text, url}) => text && url),
        },
      });

      setSnackbarMessage({text: t('saved')});

      navigate(`/teams/${response}`, {
        state: {source: isEditing ? 'modify' : 'create'},
      });
    } catch (e: any) {
      setSnackbarMessage({text: e.body.detail});
    }
  };

  const setLinks = (links: TeamLink[]) => {
    return setTeamUpdate((p) => ({...p, links}));
  };

  if (!loggedInEmployee?.email) {
    return null;
  }

  return (
    <Layout.InlineDrawerContainer
      open={isDrawerOpen}
      size="condensed"
      drawerHeader={<Title size={18}>{t('members_title')}</Title>}
      drawerIcon={PersonMultipleLine}
      drawerBody={
        <TeamMemberTypeahead
          initialSelections={members.sort(sortEmployeesPrimaryFirst(primaryContact?.ldap))}
          initialPrimaryContact={primaryContact}
          onSave={(selections, primary) => {
            setPrimaryContact(primary);
            setMembers(selections);
            setDrawerOpen(false);
          }}
          onCancel={() => setDrawerOpen(false)}
        />
      }
      onClose={() => setDrawerOpen(false)}
    >
      <Stack align="start">
        <ProjectHeader isEditing={isEditing} />

        <FormRow>
          <FormLabel
            withInput={
              <>
                <TextInput
                  required
                  autoFocus={!autofocus}
                  isInvalid={hasSubmitError && !teamUpdate.name}
                  size="large"
                  width="100%"
                  placeholder={t('team_edit_name_placeholder')}
                  value={teamUpdate.name}
                  onChange={(e) => setTeamUpdate((p) => ({...p, name: e.target.value}))}
                />

                {/* <Text monospace className={atoms({margin: '16', paddingY: '8'})}>
                    https://os.dropbox.com/{teamUpdate.name.toLowerCase().replace(' ', '-')}
                  </Text> */}
              </>
            }
          >
            {t('name')}
          </FormLabel>
        </FormRow>

        <FormRow>
          <FormLabel>{t('parent_team')}</FormLabel>
          <TeamSearch
            team={team}
            isInvalid={hasSubmitError && !teamUpdate.parent_team}
            selectedTeam={selectedTeam}
            handleTeamSelected={(newTeam) => {
              setSelectedTeam(newTeam);
              setTeamUpdate((p) => ({...p, parent_team: newTeam.team_id}));
            }}
          />
        </FormRow>

        <FormRow>
          <FormLabel>{t('members_title')}</FormLabel>

          <Box
            padding="12"
            borderRadius="Medium"
            cursor="pointer"
            backgroundColor={members.length ? 'Opacity Surface - State 1' : undefined}
            borderStyle={!members.length ? 'Solid' : undefined}
            borderColor={hasSubmitError && members.length === 0 ? 'Alert Base' : 'Border Subtle'}
            borderWidth="1"
            {...withShade({})}
            onClick={() => setDrawerOpen(true)}
          >
            <Box as={Split} gap="8" alignY="center">
              <Split.Item width="fill">
                <LabelGroup
                  align="top"
                  withLeftAccessory={
                    <UIIcon src={PersonMultipleLine} className={atoms({color: 'Text Subtle'})} />
                  }
                  withText={
                    <Text size="large" isBold>
                      {members.length
                        ? t('member', {
                            count: members.length,
                            countString: members.length.toLocaleString(),
                          })
                        : t('find_people')}
                    </Text>
                  }
                  withSubtext={
                    <Text color="subtle" size="small">
                      {t('team_edit_members_subtext')}
                    </Text>
                  }
                />
              </Split.Item>
              <Split.Item>
                <Facepile
                  showSelf={false}
                  ldaps={members
                    .sort(sortEmployeesPrimaryFirst(primaryContact?.ldap))
                    .map(({ldap}) => ldap)}
                  ownerIsFirst
                  overflow={4}
                />
              </Split.Item>
              <Split.Item>
                <UIIcon src={ChevronRightLine} className={atoms({color: 'Border Base'})} />
              </Split.Item>
            </Box>
          </Box>
        </FormRow>

        <FormRow>
          <Cluster>
            <FormLabel>{t('description')}</FormLabel>
            <Text color="faint" size="small" className={atoms({marginLeft: 'auto'})}>
              {t('optional')}
            </Text>
          </Cluster>
          <TextArea
            isInvalid={hasError && teamUpdate.description?.length > 2000}
            placeholder={t('team_edit_description_placeholder')}
            size="large"
            value={teamUpdate.description}
            resizable="auto"
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setTeamUpdate((p) => ({...p, description: e.target.value}))
            }
          />
        </FormRow>

        <FormRow>
          <Cluster>
            <FormLabel>{t('email')}</FormLabel>{' '}
            <Text color="faint" size="small" className={atoms({marginLeft: 'auto'})}>
              {t('optional')}
            </Text>
          </Cluster>

          <RowEditor
            placeholder={t('team_edit_email_placeholder')}
            isInvalid={hasError}
            values={teamUpdate.emails?.length ? teamUpdate.emails : undefined}
            isValueValid={(value) =>
              value.length === 0 || /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/.test(value)
            }
            setValues={(emails) => setTeamUpdate((p) => ({...p, emails}))}
          />
        </FormRow>

        <FormRow>
          <Cluster>
            <FormLabel>{t('slack')}</FormLabel>
            <Text color="faint" size="small" className={atoms({marginLeft: 'auto'})}>
              {t('optional')}
            </Text>
          </Cluster>
          <RowEditor
            placeholder={t('team_edit_slack_placeholder')}
            isInvalid={hasError}
            values={teamUpdate.slack_channels?.length ? teamUpdate.slack_channels : undefined}
            isValueValid={(value) => value.length <= 1 || /^#?[\w-]+$/.test(value)}
            setValues={(slack_channels) =>
              setTeamUpdate((p) => ({
                ...p,
                slack_channels: slack_channels.map((channel) =>
                  channel.startsWith('#') ? channel : `#${channel}`
                ),
              }))
            }
          />
        </FormRow>

        <FormRow>
          <Cluster>
            <FormLabel subtext={<Toggletip title={t('project_resources_tooltip')} />}>
              {t('resources')}
            </FormLabel>
            <Text color="faint" size="small" className={atoms({marginLeft: 'auto'})}>
              {t('optional')}
            </Text>
          </Cluster>
          <ProjectLinkEditor
            isInvalid={hasError}
            autoFocus={autofocus === 'resources'}
            links={
              teamUpdate.links?.length
                ? (teamUpdate.links as ProjectLink[])
                : ([{url: '', text: ''}] as ProjectLink[])
            }
            updateLinks={setLinks}
          />
        </FormRow>

        <FormRow>
          <EditSaveButtons
            cta={'Save team'}
            disableSave={hasError || hasSubmitError}
            isLoading={isPending}
            handleCancelClick={() =>
              /* eslint-disable-next-line*/
              /* @ts-ignore */
              navigate(-1, {
                state: {source: isEditing ? 'modify' : 'create'},
              })
            }
            handleSaveClick={handleSubmit}
          />
        </FormRow>
      </Stack>
    </Layout.InlineDrawerContainer>
  );
};

export const ProjectTeamSelector = ({
  isInvalid,
  teamId,
  setTeamId,
}: {
  isInvalid?: boolean;
  teamId: string;
  setTeamId: (teamId: string) => void;
}) => {
  const {data} = useQuery({
    queryKey: ['all', 'teams'],
    queryFn: () => getService(TeamService).getTeamsAreaApiV1TeamAreaGet(),
  });

  return (
    <FormRow>
      <FormLabel
        withInput={
          <Select
            required
            isInvalid={isInvalid}
            id="team"
            size="large"
            placeholder="Select a team"
            defaultValue={teamId}
            onChange={(val) => setTeamId(val)}
            value={teamId}
          >
            {data
              ?.sort((a, b) => a.name!.localeCompare(b.name!))
              .map(({team_id, name}) => (
                <Select.Option key={team_id} value={team_id}>
                  {name!}
                </Select.Option>
              ))}
          </Select>
        }
      >
        {t('team')}
      </FormLabel>
    </FormRow>
  );
};

const ProjectHeader = ({isEditing}: {isEditing?: boolean}) => {
  const navigate = useNavigate();
  return (
    <>
      <IconButton
        variant="transparent"
        style={{position: 'absolute', marginTop: 4, marginLeft: -40}}
        onClick={() =>
          /* eslint-disable-next-line*/
          /* @ts-ignore */
          navigate(-1, {
            state: {source: isEditing ? 'modify' : 'create'},
          })
        }
      >
        <UIIcon src={ArrowLeftLine} />
      </IconButton>
      <Split alignY="center" paddingBottom="16" gap="6">
        <Split.Item width="fill">
          <Title size={18}>{isEditing ? t('edit_team') : t('add_team')}</Title>
        </Split.Item>
      </Split>
    </>
  );
};
