import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {t} from 'i18next';

export const TeamMembersAndSubTeamsCounts = ({
  membersCount,
  subTeamsCount,
  marginTop,
  hideZeroCounts,
}: {
  membersCount: number;
  subTeamsCount: number;
  marginTop?: string;
  hideZeroCounts?: boolean;
}) => {
  return (
    <Box style={{minHeight: '20px', marginTop}}>
      <Text size="small" color="faint">
        {hideZeroCounts && membersCount === 0
          ? null
          : t('member', {count: membersCount, countString: membersCount.toLocaleString()})}
        {hideZeroCounts && (membersCount == 0 || subTeamsCount === 0) ? null : ' • '}
        {hideZeroCounts && subTeamsCount === 0 ? null : t('sub_team', {count: subTeamsCount})}
      </Text>
    </Box>
  );
};
