import {Text} from '@dropbox/dig-components/typography';
import {TargetLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {Employee} from 'client';
import {usePeopleWidget} from 'hooks/useDashboards';
import {t} from 'i18next';
import {useEffect, useRef, useState} from 'react';
import {Trans} from 'react-i18next';
import {
  DashboardsPeopleWidgetComponent,
  DashboardsPeopleWidgetComponentProps,
  DashboardsPeopleWidgetProps,
} from 'views/dashboards/widgets/DashboardsPeopleWidgetComponent';
import {DashboardsWidgetEmptyState} from 'views/dashboards/widgets/DashboardsWidget';

const MAX_PEOPLE_TO_DISPLAY = 12;
const PEOPLE_LIST_OFFSET = 275;

export const DashboardsParticipationWidget = (props: DashboardsPeopleWidgetProps) => {
  const {teamName, teamSlug, timeframe} = props;

  const [employees, setEmployees] = useState<Employee[] | null>(null);
  const [showAll, setShowAll] = useState<boolean>(false);

  const type = 'participation';
  const peopleWidgetSelectorRef = useRef<HTMLDivElement>(null);
  const {isFetched, people, remainingCount, totalPeopleCount, totalEmployeesCount} =
    usePeopleWidget({
      type,
      teamSlug,
      timeframe,
      limit: MAX_PEOPLE_TO_DISPLAY,
      enabled: true,
    });

  const {
    isLoading: isShowAllLoading,
    isFetched: isShowAllFetched,
    people: showAllPeople,
  } = usePeopleWidget({
    type,
    teamSlug,
    timeframe,
    limit: totalPeopleCount,
    enabled: remainingCount > 0,
  });

  useEffect(() => {
    // Update employees to display after fetching next batch of people
    if (isFetched) {
      setEmployees(people);
      setShowAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, teamSlug, timeframe]);

  useEffect(() => {
    if (showAll && isShowAllFetched) {
      setEmployees(showAllPeople);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAll]);

  useEffect(() => {
    // Scroll to bottom when people list is fully rendered
    const element = peopleWidgetSelectorRef.current;
    if (element && showAll && employees?.length == totalPeopleCount) {
      const rect = element.getBoundingClientRect();
      window.scrollBy({top: rect.top - PEOPLE_LIST_OFFSET, behavior: 'smooth'});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees?.length]);

  const widgetProps: DashboardsPeopleWidgetComponentProps = {
    ...props,
    type,
    title: t('participation'),
    subtitle: t('participation_subtitle'),
    icon: TargetLine,
    source: 'dashboards',
    employees,
    peopleWidgetSelectorRef,
    isShowAllLoading,
    showAll,
    showAllCount: !showAll && isShowAllFetched ? remainingCount : undefined,
    setShowAll: () => {
      analyticsLogger().logEvent('DASHBOARD_WIDGET_CLICKED', {type, event: 'show_more'});
      setShowAll(true);
    },
    infoMessage: isFetched && !!totalEmployeesCount && (
      <Trans
        i18nKey="people_with_no_goals_info_message"
        t={t}
        values={{
          count: totalPeopleCount,
          countString: totalPeopleCount.toLocaleString(),
          perc: Math.ceil((totalPeopleCount / totalEmployeesCount) * 100),
          team: teamName,
        }}
        components={{
          Text: <Text size="small" isBold />,
        }}
      />
    ),
    emptyState: (
      <DashboardsWidgetEmptyState
        title={t('participation_no_results', {team: teamName})}
        subtitle={t('participation_no_results_subtitle')}
      />
    ),
  };
  return <DashboardsPeopleWidgetComponent {...widgetProps} />;
};
