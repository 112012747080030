import {GrowthBook} from '@growthbook/growthbook-react';
import {analyticsLogger} from 'analytics/analyticsLogger';

export const growthbook = new GrowthBook({
  apiHost: 'https://cdn.dropboxexperiment.com',
  clientKey: 'sdk-YOmIDiSx55qBdoW',
  enableDevMode: true,
  subscribeToChanges: true,
  trackingCallback: (experiment, result) =>
    analyticsLogger().logEvent('LOG_EXPERIMENT', {
      experimentId: experiment.key,
      variationId: result.key,
    }),
});
