import {Checkbox} from '@dropbox/dig-components/controls';
import {BACKSPACE_KEY} from '@dropbox/dig-components/dist/menu';
import {TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Typeahead} from '@dropbox/dig-components/typeahead';
import {atoms, Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {SearchLine} from '@dropbox/dig-icons/assets';
import {t} from 'i18next';
import {useRef, useState} from 'react';

import styles from './StrategyConnectSearch.module.css';

export const TeamConnectSearch = ({
  teams,
  teamIds = [],
  handleTeamSelected,
}: {
  teams: string[];
  teamIds?: string[];
  handleTeamSelected: (teamIds: string[]) => void;
}) => {
  const [filteredOptions, setFilteredOptions] = useState(teams);

  const inputContainerRef = useRef<HTMLElement | null>(null);
  const [inputValue, setInputValue] = useState('');

  const handleSelection = (value: string) => {
    const isChecked = teamIds.indexOf(value) === -1;
    const newSelected = isChecked
      ? teamIds.concat(value)
      : teamIds.filter((item: string) => item !== value);
    handleTeamSelected(newSelected);
    setInputValue('');
    setFilteredOptions(teams);
  };

  const renderTypeaheadRow = (name: string) => {
    const isChecked = teamIds.indexOf(name) !== -1;
    return (
      <Typeahead.Row
        key={name}
        value={name}
        withTitle={
          <Box whiteSpace="nowrap" overflow="hidden" style={{textOverflow: 'ellipsis'}}>
            {name}
          </Box>
        }
        role="menuitemcheckbox"
        aria-label={`Option ${name}`}
        aria-checked={isChecked}
        withLeftAccessory={<Checkbox checked={isChecked} readOnly tabIndex={-1} />}
      />
    );
  };

  const renderNoResultsRow = () => (
    <Typeahead.Row disabled>
      <Box paddingLeft="Micro Medium">
        <Text>{t('nothing_found')}</Text>
      </Box>
    </Typeahead.Row>
  );

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
    if (!e.currentTarget.value) {
      setFilteredOptions(teams);
    } else {
      setFilteredOptions(
        teams.filter((team) => team.toLowerCase().includes(e.currentTarget.value.toLowerCase()))
      );
    }
  };

  const handleKeyDown: React.KeyboardEventHandler = (e) => {
    e.stopPropagation();
    if (e.key === BACKSPACE_KEY && inputValue === '') {
      handleTeamSelected(teamIds.slice(0, -1));
    }
  };

  return (
    <div className={styles.objectiveActionContainer}>
      <Typeahead.Wrapper
        isPortaled={false}
        onSelection={handleSelection}
        closeOnSelection={false}
        className={styles.strategySelectOptions}
      >
        {({getTriggerProps, getContentProps}) => (
          <>
            <TextInput.Container ref={inputContainerRef} size="large">
              <TextInput.Accessory>
                <UIIcon src={SearchLine} className={atoms({marginTop: '8'})} />
              </TextInput.Accessory>
              <TextInput.Input
                placeholder={
                  teamIds.length
                    ? teamIds.length === 1
                      ? '1 squad selected'
                      : `${teamIds.length} squads selected`
                    : t('select_teams').toString()
                }
                value={inputValue}
                {...getTriggerProps({
                  onChange: onInputChange,
                  onKeyDown: handleKeyDown,
                })}
              />
            </TextInput.Container>
            <Typeahead.Container
              {...getContentProps()}
              className={styles.typeaheadResultsContainer}
              triggerRef={inputContainerRef}
            >
              {filteredOptions.length ? (
                <Typeahead.Results
                  // title={t('teams')}
                  results={filteredOptions}
                  renderRow={renderTypeaheadRow}
                  initialResults={500}
                />
              ) : inputValue.length ? (
                <Typeahead.Results
                  // title={t('teams')}
                  results={[{}]}
                  renderRow={renderNoResultsRow}
                />
              ) : null}
            </Typeahead.Container>
          </>
        )}
      </Typeahead.Wrapper>
    </div>
  );
};
