import {Box} from '@dropbox/dig-foundations';
import {totalNotificationsAtom} from 'atoms/notifications';
import {useGoal} from 'components/goals/hooks';
import {isGoalPrivate} from 'helpers/utils';
import {useAtomValue} from 'jotai';
import {useEffect} from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {ContributorsDrawer, ContributorsDrawerContainer} from './ContributorsDrawer';
import styles from './Drawer.module.css';
import {DrawerOverlay} from './DrawerOverlay';
import {GoalDetailsDrawerContainer} from './GoalDetailsDrawer';
import {PrivacyDetailsDrawerContainer} from './PrivacyDetailsDrawer';
import {GoalsV2DrawerProps} from './types';

export const GoalsV2Drawer = (props: GoalsV2DrawerProps) => {
  const {t} = useTranslation();
  const notificationCount: number = useAtomValue(totalNotificationsAtom);

  const {drawerType, goalData, onClose, showBackButton} = props;
  const {goalId, keyResultId} = goalData;

  const goal = useGoal({goalId});
  const keyResult = goal?.key_results.find(({id}) => id === keyResultId);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => event.key === 'Escape' && onClose();

    document.addEventListener('keydown', handleEsc);
    return () => document.removeEventListener('keydown', handleEsc);
  }, [onClose]);

  useEffect(() => {
    const title = keyResult?.title ?? goal?.title ?? t('goals');
    document.title =
      notificationCount > 0
        ? `(${notificationCount}) ${title} - ${t('dropbox_os')}`
        : title + ' - ' + t('dropbox_os');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationCount, t, goalId, goalData.keyResultId]);

  if (drawerType === 'contributor' && showBackButton === false) {
    return <ContributorsDrawer {...props} />;
  }
  return <GoalsV2DrawerWithSlider {...props} />;
};

export const GoalsV2DrawerWithSlider = (props: GoalsV2DrawerProps) => {
  const {drawerType, isOpen, goalData} = props;
  const {goalId, keyResultId} = goalData;

  const goal = useGoal({goalId});

  const [currentSlide, setCurrentSlide] = useState(drawerType === 'goal' ? 0 : 1);

  useEffect(() => {
    if (drawerType === 'goal') {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(1);
    }
  }, [drawerType]);

  const slideAnimationClass =
    currentSlide === 0 ? styles.drawerContainerSlide0 : styles.drawerContainerSlide1;
  return (
    <DrawerOverlay isOpen={isOpen}>
      <Box className={styles.drawerContainerSlider}>
        <Box className={`${styles.drawerContainerSlides} ${slideAnimationClass}`}>
          <Box as="div" className={styles.drawerContainerSlide}>
            <GoalDetailsDrawerContainer {...props} isOpen />
          </Box>
          <Box className={styles.drawerContainerSlide}>
            {keyResultId ? (
              <ContributorsDrawerContainer
                {...props}
                isOpen={drawerType === 'contributor'}
                shouldToggleVisibility
              />
            ) : (
              isGoalPrivate(goal) && (
                <PrivacyDetailsDrawerContainer
                  {...props}
                  isOpen={drawerType === 'privacy'}
                  shouldToggleVisibility
                />
              )
            )}
          </Box>
        </Box>
      </Box>
    </DrawerOverlay>
  );
};
