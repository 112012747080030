import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {LockLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {GoalData} from 'client';
import {useGoal} from 'components/goals/hooks';
import {GoalPrivacyList} from 'components/goals/privacy/GoalPrivacyList';
import {
  countUniqueEmployee,
  dedupeEmployeesOrTeams,
  sortEmployeesOrTeamsByName,
} from 'components/goals/privacy/utils';
import {isEmployee, isTeam, useEmployees} from 'hooks/useEmployee';
import {useEffect} from 'react';
import {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {emailToLdap} from 'utilities';
import {useProfileSuspense} from 'views/goals_v2/hooks';

import {DrawerHeader} from './DrawerHeader';
import {DrawerContainer, DrawerOverlay} from './DrawerOverlay';
import {GoalsV2DrawerProps} from './types';

export const PrivacyDetailsDrawer = (props: GoalsV2DrawerProps) => {
  return (
    <DrawerOverlay isOpen={props.isOpen}>
      <PrivacyDetailsDrawerContainer {...props} />
    </DrawerOverlay>
  );
};

export const PrivacyDetailsDrawerContainer = ({
  isOpen,
  goalData,
  onClose,
  setGoalsV2DrawerData,
  shouldToggleVisibility = false,
}: GoalsV2DrawerProps) => {
  useEffect(() => {
    if (isOpen) {
      analyticsLogger().logEvent('GOAL_PRIVACY_DRAWER_OPENED');
    }
  }, [isOpen]);

  const {goalId} = goalData;
  const goal = useGoal({goalId});

  return (
    <DrawerContainer
      isOpen={isOpen}
      shouldToggleVisibility={shouldToggleVisibility}
      header={
        <PrivacyDetailsDrawerHeader
          onClose={onClose}
          onBack={() => setGoalsV2DrawerData({drawerType: 'goal', isOpen: true, source: 'drawer'})}
        />
      }
      body={<PrivacyDetailsDrawerBody goal={goal} />}
    />
  );
};

const PrivacyDetailsDrawerHeader = ({
  onBack,
  onClose,
}: {
  onBack: () => void;
  onClose: () => void;
}) => {
  const {t} = useTranslation();
  return (
    <DrawerHeader
      headerTitle={t('private')}
      headerIcon={<Box as={UIIcon} src={LockLine} style={{marginLeft: '-3px'}} />}
      onBack={onBack}
      onClose={onClose}
    />
  );
};

const PrivacyDetailsDrawerBody = ({goal}: {goal: GoalData}) => {
  const {t} = useTranslation();

  const contributors = goal.key_results.flatMap((kr) => kr.contributors.map(({ldap}) => ldap));
  const employees = useEmployees({ldaps: contributors});
  const selectedEmployeesOrTeams = sortEmployeesOrTeamsByName(
    Array.from(new Set([...(goal.individual_privacies ?? []), ...employees])),
    goal.team_privacies
  );
  const data = useProfileSuspense({ldap: emailToLdap(goal.users![0]!.email)});

  const teamCount = selectedEmployeesOrTeams.filter(isTeam).length;
  return (
    <Box as={Stack} gap="8">
      <Box display="flex" alignItems="center">
        <Box marginTop="8">
          <Box as={Text} isBold>
            {t('people_count', {
              count: countUniqueEmployee(
                selectedEmployeesOrTeams.filter(isEmployee),
                data?.reportingLine || [],
                data?.delegates || []
              ),
            })}
          </Box>
          {teamCount > 0 && (
            <>
              <Box as={Text}> {t('and')} </Box>
              <Box as={Text} isBold>
                {t('team', {count: teamCount})}
              </Box>
            </>
          )}
          <Box as={Text}> {t('may_view_and_align_to_this_goal')}</Box>
        </Box>
      </Box>
      <Suspense fallback={<Box />}>
        <GoalPrivacySection goal={goal} />
      </Suspense>
    </Box>
  );
};

const GoalPrivacySection = ({goal}: {goal: GoalData}) => {
  const selectedEmployeesOrTeams = sortEmployeesOrTeamsByName(
    goal.individual_privacies,
    goal.team_privacies
  );

  const contributors = useEmployees({
    ldaps: goal.key_results.flatMap((kr) => kr.contributors.map(({ldap}) => ldap)),
  });

  const data = useProfileSuspense({ldap: emailToLdap(goal.users![0]!.email)});

  return (
    <GoalPrivacyList
      goalOwner={data.employee}
      reportingLine={data.reportingLine}
      delegates={data.delegates}
      contributors={contributors}
      canDelete={false}
      selectedEmployeesOrTeams={dedupeEmployeesOrTeams(selectedEmployeesOrTeams)}
      onRemoveEmployeeOrTeam={() => {}}
      padding={'12px 12px'}
    />
  );
};
