import {Chip} from '@dropbox/dig-components/dist/chip';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {Gratitude} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {ButtonLink} from 'components/DSYS/Link';
import {LabelText} from 'components/gratitiude/LabelText';
import {t} from 'i18next';
import {FunctionComponent} from 'react';

import styles from './ToAndFrom.module.css';

interface Props {
  gratitude: Gratitude;
  hideReply?: boolean;
}

export const ToAndFrom: FunctionComponent<Props> = ({gratitude, hideReply = false}) => {
  const {sender, recipient} = gratitude;

  return (
    <Box className={styles.container}>
      <Text className={styles.message}>{gratitude.message}</Text>
      <Box borderTop="Solid" borderColor="Border Subtle" borderWidth="1" className={styles.people}>
        {recipient && (
          <div>
            <LabelText tagName="span">{t('to').toUpperCase()}:</LabelText>
            <Box>
              <Chip>
                <Chip.AvatarAccessory>
                  <Avatar user={recipient} />
                </Chip.AvatarAccessory>
                <Chip.Content>{recipient.name}</Chip.Content>
              </Chip>
            </Box>
          </div>
        )}
        <div>
          <LabelText tagName="span">{t('from').toUpperCase()}:</LabelText>
          <Box marginTop="4">
            <Chip>
              <Chip.AvatarAccessory>
                <Avatar user={sender} />
              </Chip.AvatarAccessory>
              <Chip.Content>{sender.name}</Chip.Content>
            </Chip>
          </Box>
        </div>
        {!hideReply && (
          <ButtonLink
            to={`/gratitude?to=${sender.ldap}`}
            variant="primary"
            className={styles.button}
          >
            {t('reply')}
          </ButtonLink>
        )}
      </Box>
    </Box>
  );
};
