import {Chip} from '@dropbox/dig-components/dist/chip';
import {Table} from '@dropbox/dig-components/dist/table';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ArrowDownLine, ArrowUpLine, HideLine, ZoomOutLine} from '@dropbox/dig-icons/assets';

import styles from './Pulse.module.css';

export const MetricCell = ({
  value,
  change,
  responses,
  minimum,
}: {
  value: number;
  change?: number;
  responses?: number;
  minimum?: number;
}) => {
  if (responses !== undefined && minimum !== undefined && responses < minimum) {
    return (
      <Table.Cell>
        <Tooltip title={`Requires at least ${minimum} responses`}>
          <Box width="min-content">
            <Box as={UIIcon} color="Text Subtle" src={HideLine} />
          </Box>
        </Tooltip>
      </Table.Cell>
    );
  }

  return (
    <Table.Cell>
      <Split alignY="center" gap="8">
        <Split.Item>
          <Text isBold>{value}</Text>
        </Split.Item>
        {change !== undefined && (
          <Split.Item>
            <Box
              as={Chip}
              visibility="hidden"
              className={styles.metricChip}
              size="small"
              variant={change === 0 ? 'standard' : change > 0 ? 'success' : 'alert'}
              paddingX="8"
              paddingY="4"
            >
              <UIIcon
                size="small"
                src={change === 0 ? ZoomOutLine : change > 0 ? ArrowUpLine : ArrowDownLine}
              />
              <Box marginTop="2">{change}%</Box>
            </Box>
          </Split.Item>
        )}
      </Split>
    </Table.Cell>
  );
};
