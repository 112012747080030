import {Button} from '@dropbox/dig-components/dist/buttons';
import {Snackbar} from '@dropbox/dig-components/snackbar';
import {snackbarAtom} from 'atoms/snackbar';
import {useAtom} from 'jotai';

import styles from './GlobalSnackbar.module.css';

export const GlobalSnackbar = () => {
  const [snackbarMessage, setSnackbarMessage] = useAtom(snackbarAtom);

  return snackbarMessage ? (
    <>
      <div className={styles.wrapper}>
        <Snackbar
          open={!!snackbarMessage}
          preferComposition
          className={styles.snackbar}
          onRequestClose={() => {
            setSnackbarMessage(null);
          }}
          style={{zIndex: 999999}}
        >
          <Snackbar.Content>
            <Snackbar.Message>{snackbarMessage.text}</Snackbar.Message>
          </Snackbar.Content>
          {snackbarMessage.action && (
            <Snackbar.Actions>
              <Button variant="transparent" onClick={snackbarMessage.action.onClick}>
                {snackbarMessage.action.text}
              </Button>
            </Snackbar.Actions>
          )}
        </Snackbar>
      </div>
    </>
  ) : null;
};
