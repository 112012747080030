import {Text} from '@dropbox/dig-components/dist/typography';
import {analyticsLogger} from 'analytics/analyticsLogger';
import classNames from 'classnames';
import {Card, Gratitude} from 'client';
import {findNotification} from 'components/notifications/utils/findNotification';
import {useNotificationArchiver} from 'components/notifications/utils/useNotificationArchiver';
import {t} from 'i18next';
import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import {GratitudeOrCard, isGratitude} from '.';
import styles from './CardGallery.module.css';
import {CardModal} from './CardModal';
import {ImageComponent} from './ImageComponent';

export const CardGallery = ({
  mailbox,
  cards,
  hideCard,
}: {
  cards: GratitudeOrCard[]; // TODO rename to gratitudes
  mailbox?: 'inbox' | 'outbox';
  hideCard?: (gratitude: Gratitude) => boolean;
}) => {
  const {archiveNotification} = useNotificationArchiver();
  const [searchParams] = useSearchParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [selected, setSelected] = useState<GratitudeOrCard>();

  useEffect(() => {
    const selectedId = searchParams.get('selected');
    if (selectedId) {
      const found = cards.find((gratitude) =>
        isGratitude(gratitude) ? gratitude.id === parseInt(selectedId) : false
      );
      if (found) {
        setSelected(found);
        setModalOpen(true);
      }
    }
  }, [searchParams, cards]);

  useEffect(() => {
    const notification = findNotification({
      type: 'gratitude_received',
      data: isGratitude(selected) ? selected?.id.toString() : undefined,
    });

    if (notification && !searchParams.get('selected')) {
      archiveNotification({id: notification.id});
      analyticsLogger().logEvent('NOTIFICATION_INBOX_CLICKED', {
        type: notification.type,
        click: 'organic',
      });
    }
  }, [archiveNotification, searchParams, selected]);

  return (
    <>
      <div className={styles.rows}>
        {cards &&
          cards
            .filter((gratitude) => {
              if (!gratitude.card) return false;
              if (mailbox === undefined) {
                return !gratitude.card.hidden_from_library;
              }
              return true;
            })
            .filter(
              (gratitude): gratitude is Gratitude & {card: Card} => gratitude.card !== undefined
            )
            .map((gratitude, index) => (
              <div
                key={gratitude.id}
                className={classNames(styles.item, {
                  [styles.filtered]: hideCard ? hideCard(gratitude) : false,
                })}
              >
                <ImageComponent
                  loadLazy={index > 12}
                  card={gratitude.card}
                  onClick={() => {
                    setModalOpen(true);
                    setSelected(gratitude);
                  }}
                />
                {mailbox === 'inbox' && gratitude.sender && (
                  <Text variant="paragraph">
                    {t('from')} {gratitude.sender.name}
                  </Text>
                )}
                {mailbox === 'outbox' && gratitude.sender && (
                  <Text variant="paragraph">
                    {t('to')} {gratitude.recipient.name}
                  </Text>
                )}
              </div>
            ))}
      </div>
      <CardModal
        gratitude={selected}
        onClose={() => setModalOpen(false)}
        open={modalOpen}
        hideReply={mailbox === 'outbox'}
      />
    </>
  );
};
