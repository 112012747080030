// export const objectivePromptTemplate =
//   'Context: User is writing his own Objective & Key Results(OKRs) to set goals for next quarter.' +
//   'An objective will be provided from user. And an objective has three parts: 1. The outcome-oriented verb, or the how of your objective' +
//   '2. What outcome you are trying to accomplish. 3.Why it is important, or the impact of achieving the objective. \nInstruction: ' +
//   'User will provide an objective and you should extract the three parts from user input. If any part is not found, replace it with an empty string and suggest one.' +
//   'Make sure the suggested part still make the objective a valid sentence which follow the above rule. \n{format_instructions}\nObjective: {input}';

// export const keyResultPromptTemplate =
//   'Context: User is writing his own Objective & Key Results(OKRs) to set goals.' +
//   'A key result will be provided from user. And a key result should include three parts: 1. A progress-oriented verb. ' +
//   '2. A concrete milestone and/or deliverable. 3. A metric or data point. A good key result usually is metric driven.' +
//   '\nInstruction: User will provide a key result and you should extract the three critical parts from the user input. If any part is not found, replace it with an empty string and suggest one.' +
//   'Make sure the newly suggested part still make the key result a valid sentence which follow the above rule. If no metric found from user input, suggest a fake one with format like x% or x times' +
//   ' \n{format_instructions}\n Key Result: {input}';

// export const objectivePromptTemplate1 =
//   'Context: User is writing his own Objective & Key Results(OKRs) to set goals for next quarter.' +
//   'An objective will be provided from user. And an objective has three parts: 1. The outcome-oriented verb, or the how of your objective' +
//   '2. What outcome you are trying to accomplish. 3.Why it is important, or the impact of achieving the objective. \nInstruction: ' +
//   'Check if the objective below is following the above rule. If it follows the rule, return true, otherwise return false. Make sure the response to contain only the JSON-formatted result and nothing else' +
//   '\nObjective: {input}';

// export const objectivePromptTemplate2 =
//   'You are a machine that takes in description of an objective and check if it is a good objective.' +
//   '\nUser is writing his own Objective & Key Results(OKRs) to set goals for next quarter. A good objective has three parts: 1. The outcome-oriented verb, or the how of your objective\n' +
//   '2. What outcome you are trying to accomplish.\n 3.Why it is important, or the impact of achieving the objective.\n You are going to tell if this is good objective or not based on the above rule. ' +
//   'You are only allowed to answer yes or no in JSON format. You are only allowed to return yes/no and nothing also. The user provided objective is: {input}.';

export const objectivePromptTemplate3 =
  "Check if it is a good objective or not in the following text. If you don't know the answer, answer 'no' in JSON format. Only answer 'yes' or 'no' in JSON format, whose key is result. A good objective consists of three parts: 1. It should have an outcome-oriented verb, or the how of your objective." +
  '2. It should contain what outcome you are trying to accomplish. 3. It should include why it is important, or the impact of achieving the objective.\n ' +
  " \nThe user provided objective input: '{input}'";

export const keyResultPromptTemplate1 =
  "Check if it is a good key result or not in the following text. If you don't know the answer, answer 'no' in JSON format. Only answer 'yes' or 'no' in JSON format, whose key is result. And a good key result should consist of three parts: 1. A progress-oriented verb. " +
  "2. A concrete milestone and/or deliverable. 3. metrics. Date or timeline can be treated as metrics as well here. \nThe user provided Key Result: '{input}'.";
