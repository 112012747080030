import {Button} from '@dropbox/dig-components/dist/buttons';
import {IconButtonGroup} from '@dropbox/dig-components/dist/combinations';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CommentLine} from '@dropbox/dig-icons/assets';
import {pulseUserAtom} from 'atoms/auth';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {GoalData, KeyResult} from 'client';
import {Eyebrow} from 'components/DSYS/Eyebrow';
import {Facepile} from 'components/DSYS/Facepile';
import {useGoalService, useGoalUpdateData} from 'components/goals/hooks';
import {getSubstatus, GoalStatusButton} from 'components/shared/GoalStatusButton';
import {isGoal} from 'helpers/utils';
import {useAtomValue} from 'jotai';
import {useTranslation} from 'react-i18next';
import {emailToLdap} from 'utilities';
import {GoalHeaderDetails} from 'views/goals_v2/GoalHeaderDetails';
import {ActionMenuBox} from 'views/goals_v2/Table/ActionMenuCell';
import {DrawerGoalData, TeamInfo} from 'views/goals_v2/types';
import {getRowData} from 'views/goals_v2/utils';

import {DrawerCloseButton} from './DrawerButtons';

export const GoalDetailsDrawerHeader = ({
  title,
  icon,
  onClose,
  goal,
  keyResult,
  teamInfo,
  timeframe,
  nextTimeframe,
  totalCommentsCount,
  showComments,
  setShowComments,
  setNextTimeframe,
  setShowConfidentialDetails,
  handleContributorsDrawerOpen,
  handleContinueGoalClick,
}: {
  title: string;
  icon: React.ComponentType<React.SVGAttributes<SVGElement>>;
  onClose: () => void;
  goal: GoalData;
  keyResult?: KeyResult;
  teamInfo?: TeamInfo;
  timeframe: string;
  nextTimeframe: string;
  totalCommentsCount: number;
  showComments: boolean;
  setShowComments: (show: boolean) => void;
  setNextTimeframe: (timeframe: string) => void;
  setShowConfidentialDetails: (show: boolean) => void;
  handleContributorsDrawerOpen: (open: boolean, source: string, autoFocus: boolean) => void;
  handleContinueGoalClick: (goalData: DrawerGoalData, source: string) => void;
}) => {
  return (
    <>
      <Box display="flex" alignItems="flex-start" justifyContent="space-between" marginBottom="8">
        <Box as="div" alignItems="center" display="flex" style={{gap: '4px'}}>
          <Box as={UIIcon} src={icon} color="Text Subtle" />
          <Box as={Eyebrow} color="Text Subtle" size="xsmall">
            {title}
          </Box>
        </Box>
        <IconButtonGroup>
          {({getButtonProps}) => (
            <DrawerCloseButton onClose={onClose} iconButtonProps={getButtonProps()} />
          )}
        </IconButtonGroup>
      </Box>
      <GoalDetailsDrawerHeaderBody
        goal={goal}
        keyResult={keyResult}
        teamInfo={teamInfo}
        timeframe={timeframe}
        nextTimeframe={nextTimeframe}
        totalCommentsCount={totalCommentsCount}
        showComments={showComments}
        setShowComments={setShowComments}
        setNextTimeframe={setNextTimeframe}
        onClose={onClose}
        setShowConfidentialDetails={setShowConfidentialDetails}
        handleContributorsDrawerOpen={handleContributorsDrawerOpen}
        handleContinueGoalClick={handleContinueGoalClick}
      />
    </>
  );
};

const GoalDetailsDrawerHeaderBody = ({
  goal,
  keyResult,
  teamInfo,
  timeframe,
  nextTimeframe,
  setNextTimeframe,
  totalCommentsCount,
  showComments,
  setShowComments,
  onClose,
  setShowConfidentialDetails,
  handleContributorsDrawerOpen,
  handleContinueGoalClick,
}: {
  goal: GoalData;
  keyResult?: KeyResult;
  teamInfo?: TeamInfo;
  timeframe: string;
  nextTimeframe: string;
  totalCommentsCount: number;
  showComments: boolean;
  setShowComments: (show: boolean) => void;
  setNextTimeframe: (timeframe: string) => void;
  onClose?: () => void;
  setShowConfidentialDetails: (show: boolean) => void;
  handleContributorsDrawerOpen: (open: boolean, source: string, autoFocus: boolean) => void;
  handleContinueGoalClick: (goalData: DrawerGoalData, source: string) => void;
}) => {
  const row = keyResult ? keyResult : goal;
  const isGoalRow = isGoal(row);

  const {t} = useTranslation();
  const {delegatedBy} = useAtomValue(loggedInEmployeeAtom);
  const pulseUser = useAtomValue(pulseUserAtom);
  const goalOwner = goal.users![0];
  const {
    currentRowGoalOwner,
    canUpdate,
    canEdit,
    latestUpdate,
    goalId,
    keyResultId,
    numKeyResults,
    isReadOnlyDueToOpenKRs,
  } = getRowData(row, goalOwner, pulseUser?.email, delegatedBy);
  const parentGoalUser = isGoalRow ? row.parent?.users?.[0] : undefined;
  const currentRowTimeframe = row.timeframe ?? goal.timeframe;
  const subGoals = keyResult
    ? goal.key_results_aligned_goals?.filter(
        (alignedGoal) => alignedGoal.key_result_parent_id === keyResult.id
      )
    : goal.children;
  const numSubGoals = subGoals?.length ?? 0;

  const {updateGoal} = useGoalService({timeframe: goal.timeframe, teamInfo: teamInfo});
  const updatedGoal = useGoalUpdateData(goal);

  const isOwner = goalOwner.email === pulseUser?.email;
  const isDelegatedBy = delegatedBy?.some(({email}) => email === goalOwner.email);

  const publishGoal = () => {
    updateGoal({
      goalId: goalId,
      userId: goal!.users![0].user_id,
      data: {
        ...updatedGoal,
        updated_at: new Date().toISOString(),
        is_draft: false,
      },
    });
  };

  return (
    <Box>
      <Box>
        {currentRowGoalOwner && (
          <Box marginBottom="6">
            <Facepile
              ownerIsFirst
              ldaps={[
                emailToLdap(currentRowGoalOwner.email),
                ...(!isGoalRow ? row.contributors.map(({ldap}) => ldap) : []),
              ]}
              onClick={
                keyResultId
                  ? () => handleContributorsDrawerOpen?.(true, 'drawer', false)
                  : undefined
              }
            />
          </Box>
        )}
        <Text size="large" isBold>
          {row.title}
        </Text>
      </Box>
      <Box marginTop="4" display="flex" alignItems="center">
        <GoalHeaderDetails
          isGoalRow={isGoalRow}
          goal={goal}
          currentRowTimeframe={currentRowTimeframe}
          numKeyResults={numKeyResults}
          numSubGoals={numSubGoals}
          setShowConfidentialDetails={setShowConfidentialDetails}
        />
      </Box>

      <Box marginTop="16" display="flex" alignItems="center" style={{gap: '8px'}}>
        {goal.is_draft ? (
          <>
            {(isOwner || isDelegatedBy) && keyResult === undefined && (
              <Button variant="primary" onClick={() => publishGoal()}>
                {t('publish')}
              </Button>
            )}

            {!((isOwner || isDelegatedBy) && keyResult === undefined) && (
              <GoalStatusButton
                title={row.title}
                latestUpdate={latestUpdate}
                substatus={isGoalRow ? getSubstatus(row, latestUpdate) : undefined}
                goalId={goalId}
                keyResultId={keyResultId}
                timeframe={timeframe}
                nextTimeframe={nextTimeframe}
                setNextTimeframe={setNextTimeframe}
                user={currentRowGoalOwner}
                canEdit={canEdit ?? false}
                isLabel={!canUpdate}
                isDraft={goal.is_draft}
                teamInfo={teamInfo}
                parentGoalUser={goalOwner}
                disableAction={true}
                onContinueGoalClick={(selectedStatus: string) =>
                  handleContinueGoalClick(
                    {
                      goalId,
                      keyResultId,
                      goalOwnerId: currentRowGoalOwner.user_id,
                      goalOwnerEmail: currentRowGoalOwner.email,
                      latestUpdateStatus: selectedStatus,
                    },
                    'modal'
                  )
                }
              />
            )}
            <Box
              as={Button}
              variant="outline"
              value="comments"
              aria-label="comment"
              withIconStart={<Box as={UIIcon} src={CommentLine} color="Text Subtle" />}
              onClick={() => setShowComments(!showComments)}
              style={{
                border: showComments
                  ? '1px solid transparent'
                  : '1px solid var(--dig-color__border__subtle)',
                backgroundColor: showComments
                  ? 'var(--dig-color__opacity__surface--state-1)'
                  : 'transparent',
              }}
            >
              <Text size="small" isBold variant="label">
                {totalCommentsCount == 0
                  ? t('comment')
                  : t('comments', {count: totalCommentsCount})}
              </Text>
            </Box>
          </>
        ) : (
          <GoalStatusButton
            title={row.title}
            latestUpdate={latestUpdate}
            substatus={isGoalRow ? getSubstatus(row, latestUpdate) : undefined}
            goalId={goalId}
            keyResultId={keyResultId}
            timeframe={timeframe}
            nextTimeframe={nextTimeframe}
            setNextTimeframe={setNextTimeframe}
            user={currentRowGoalOwner}
            canEdit={canUpdate}
            isLabel={!canUpdate}
            isDisabled={!canUpdate || isReadOnlyDueToOpenKRs}
            isReadOnlyDueToOpenKRs={isReadOnlyDueToOpenKRs}
            isDraft={goal.is_draft}
            teamInfo={teamInfo}
            parentGoalUser={goalOwner}
            onContinueGoalClick={(selectedStatus: string) =>
              handleContinueGoalClick(
                {
                  goalId,
                  keyResultId,
                  goalOwnerId: currentRowGoalOwner.user_id,
                  goalOwnerEmail: currentRowGoalOwner.email,
                  latestUpdateStatus: selectedStatus,
                },
                'modal'
              )
            }
          />
        )}

        <ActionMenuBox
          user={currentRowGoalOwner}
          goal={isGoalRow ? goal : undefined}
          keyResult={isGoalRow ? undefined : keyResult}
          canDelete={Boolean(canEdit)}
          teamInfo={teamInfo}
          parentGoalUser={parentGoalUser ?? goalOwner}
          latestUpdate={latestUpdate}
          isDraftRow={!!goal.is_draft}
          timeframe={timeframe}
          nextTimeframe={nextTimeframe}
          setNextTimeframe={setNextTimeframe}
          variant="outline"
          source="menu"
          marginLeft="8"
          onDeleteCallback={onClose}
          onContributorCallback={() => {
            !isGoalRow && handleContributorsDrawerOpen?.(true, 'menu', true);
          }}
          onContinueGoalCallback={(selectedStatus: string) =>
            handleContinueGoalClick(
              {
                goalId,
                keyResultId,
                goalOwnerId: currentRowGoalOwner.user_id,
                goalOwnerEmail: currentRowGoalOwner.email,
                latestUpdateStatus: selectedStatus,
              },
              'menu'
            )
          }
        />
      </Box>
    </Box>
  );
};
