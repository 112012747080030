import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {Suspense} from 'react';
import {DashboardsView} from 'views/dashboards/DashboardsView';

export const Dashboards = () => {
  useDocumentTitle(t('dashboards'));
  return (
    <Suspense fallback={null}>
      <DashboardsView />
    </Suspense>
  );
};
