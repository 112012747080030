import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {UIIcon} from '@dropbox/dig-icons';
import {CloseLine, RotateLeftLine} from '@dropbox/dig-icons/assets';
import {snackbarAtom} from 'atoms/snackbar';
import classNames from 'classnames';
import {t} from 'i18next';
import {useSetAtom} from 'jotai';
import {FunctionComponent, useState} from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {getBackendUrl} from 'utilities';

import {GratitudeOrCard, isGratitude} from '..';
import {CardModalForm} from './Form';
import styles from './index.module.css';
import {ToAndFrom} from './ToAndFrom';

interface Props {
  gratitude?: GratitudeOrCard;
  open: boolean;
  onClose: () => void;
  hideReply?: boolean;
}

export const CardModal: FunctionComponent<Props> = ({
  open,
  onClose,
  gratitude,
  hideReply = false,
}) => {
  const card = gratitude?.card;
  const setSnackbarMessage = useSetAtom(snackbarAtom);

  const [flipped, setFlipped] = useState(false);

  return (
    <Modal
      className={classNames(
        styles.modalOverrides,
        card?.landscape ? styles.modalOverridesLandscape : styles.modalOverridesPortrait
      )}
      open={open}
      shouldCloseOnOverlayClick
      onRequestClose={() => {
        onClose();
      }}
      isCentered
    >
      <IconButton
        variant="filled"
        shape="circular"
        size="xlarge"
        className={styles.close}
        onClick={() => onClose()}
      >
        <UIIcon src={CloseLine} />
      </IconButton>
      <div className={styles.desktopContent}>
        {card && (
          <LazyLoadImage
            placeholder={<Skeleton.Box height={158} width={158} />}
            className={classNames(
              styles.image,
              card.landscape ? styles.landscape : styles.portrait,
              {[styles.flipped]: flipped}
            )}
            src={getBackendUrl() + `/api/v1/cards/images/${card.image_key}`}
            alt={card.image_key}
          />
        )}
        <div
          className={classNames(styles.message, {
            [styles.flipped]: flipped,
            [styles.landscape]: card?.landscape === true,
            [styles.portrait]: card?.landscape === false,
          })}
        >
          {isGratitude(gratitude) ? (
            <ToAndFrom gratitude={gratitude} hideReply={hideReply} />
          ) : (
            <CardModalForm
              card={gratitude?.card}
              onSubmit={() => {
                onClose();
                setSnackbarMessage({text: t('sent_snackbar')});
              }}
              open={open}
            />
          )}
        </div>
      </div>
      <div className={styles.flipButton}>
        <Button
          size="large"
          variant="primary"
          onClick={() => setFlipped(!flipped)}
          withIconStart={<UIIcon src={RotateLeftLine} />}
        >
          {t('flip_over')}
        </Button>
      </div>
    </Modal>
  );
};
