import {TextArea} from '@dropbox/dig-components/dist/text_fields';
import {t} from 'i18next';
import {useEffect, useRef} from 'react';

import styles from './TextSelector.module.css';

export const AutoResizingTextArea = ({
  content,
  onContentChange,
  onBlurHandler,
}: {
  content: string;
  onBlurHandler: (value: string | null) => void;
  onContentChange: (value: string) => void;
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const handleFocus = () => {
    if (textareaRef.current) {
      const length = textareaRef.current.value.length;
      textareaRef.current.setSelectionRange(length, length);
    }
  };

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;
    // Reset height to ensure shrinking on delete
    textarea.style.height = '24px';
    // Adjust height based on scrollHeight
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  // Adjust height on input
  useEffect(() => {
    adjustHeight();
    handleFocus();
  }, []); // Re-adjust if value changes externally

  return (
    <TextArea
      placeholder={t('add_a_comment').toString()}
      id="startWriting"
      value={content}
      onChange={(e) => onContentChange(e.currentTarget.value)}
      className={styles.commentEditTextArea}
      spellCheck="true"
      autoFocus={true}
      onBlur={() => onBlurHandler(null)}
      onFocus={() => handleFocus()}
      ref={textareaRef}
      onInput={adjustHeight}
    />
  );
};
