import {
  Button,
  ButtonBar,
  IconButton,
  SplitButton,
  StylelessButton,
} from '@dropbox/dig-components/dist/buttons';
import {FormHelperText, FormLabel, FormRow} from '@dropbox/dig-components/dist/form_row';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Select, TextArea, TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {AiLine, CloseLine, EmojiSmileLine} from '@dropbox/dig-icons/assets';
import {ChoiceHero} from '@dropbox/dig-illustrations';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {Avatar, AvatarIcon} from 'components/DSYS/Avatar';
import {Eyebrow} from 'components/DSYS/Eyebrow';
import {Facepile} from 'components/DSYS/Facepile';
import {ButtonLink, Link} from 'components/DSYS/Link';
import {StatusButtonIcon} from 'components/DSYS/StatusButtonIcon';
import {Title} from 'components/DSYS/Title';
import {useAtomValue} from 'jotai';
import {useState} from 'react';

import {Note, Path} from './Note';

/**
 * This file demos most overrides for DIG components.
 */

export const TypographyExample = () => (
  <Stack gap="16">
    <Title
      id="typography"
      withAccessoryEnd={<Path path="src/dig-overrides/typography.module.css" />}
    >
      Typography
    </Title>

    <Note
      type="DSYS/Title"
      path="src/components/DSYS/Title.tsx"
      title={`<Title
  size={28 (large) | 24 | 22 (standard/default) | 20 | 18 | 16 (small)}
>
  Text
</Title>
<Title withAccessoryStart={<UIIcon src={AiLine} />} withAccessoryEnd={IconButton}  />`}
      info="Titles are only Atlas Grotesk, always bold, and have different sizes from DIG. For convenience, the companyOS version also some helpful props like withAccessoryStart, Toggletip, and withAccessoryEnd"
    >
      <Stack gap="8">
        {['28/36,large', '24/28', '22/28,standard/default', '20/28', '18/26', '16/26,small'].map(
          (size) => (
            <Title key={size} size={parseInt(size.split('/')[0], 10) as any} className="ignore">
              Title Atlas Grotesk {size.split(',')[0]}{' '}
              <Text color="subtle" size="small">
                {size.split(',')?.[1] && ` (${size.split(',')?.[1]})`}
              </Text>
            </Title>
          )
        )}

        <Box width="100%" borderTop="Solid" borderColor="Opacity Surface" />

        <Title
          className="ignore"
          withAccessoryStart={<UIIcon src={AiLine} />}
          withAccessoryEnd={
            <IconButton variant="borderless">
              <UIIcon src={CloseLine} />
            </IconButton>
          }
        >
          Title
          <Text color="subtle" size="small">
            {' '}
            withAccessoryStart, Toggletip, and withAccessoryEnd
          </Text>
        </Title>
      </Stack>
    </Note>

    <Note
      type="DSYS/Eyebrow"
      title="<Eyebrow />"
      path="src/components/DSYS/Eyebrow.tsx"
      info="Eyebrows are always bold, caps, and inherit props from `Text`."
    >
      <Eyebrow>Eyebrow</Eyebrow>
    </Note>

    <Note
      type="DSYS/Link"
      title={`<Link to={absolute | relative url} />
<Title color="error">This is an error Title <Link to={to} monochromatic>Link</Link>.</Title>
<Text color="faint" size="small">This is a small faint<Link to={to} monochromatic>link</Link>.</Text>`}
      info="DSYS Links use Text Base instead of Primary Base. `isBold` makes the text bold. The `monochromatic` prop allows the link to inherit styles from its parent. Links take in a `to` prop that can be absolute or relative. Under the hood they're react-router-dom links or an anchor link, depending on the link type."
      path="src/components/DSYS/Link.tsx"
    >
      <Stack gap="8">
        <Box>
          <Text>
            <Link to="/dsys">Relative link (/settings)</Link>
          </Text>
        </Box>

        <Box>
          <Text>
            <Link to="/dsys">Absolute link (https://dropbox.com)</Link>
          </Text>
        </Box>

        <Box>
          <Text>
            <Link to="/dsys" hasNoUnderline showUnderlineOnHover>
              showUnderlineOnHover
            </Link>
          </Text>
        </Box>

        <Box>
          <Text>
            <Link to="/dsys" hasNoUnderline>
              hasNoUnderline
            </Link>
          </Text>
        </Box>

        <Box>
          <Text>
            <Link to="/dsys" hasNoUnderline showUnderlineOnHover>
              hasNoUnderline & showUnderlineOnHover
            </Link>
          </Text>
        </Box>

        <Box>
          <Title color="error" className="ignore">
            This is an error Title <Link to="/dsys">Link</Link>.
          </Title>
        </Box>

        <Box>
          <Title color="error" className="ignore">
            This is an error Title{' '}
            <Link to="/dsys" monochromatic>
              Monochromatic Link
            </Link>
            .
          </Title>
        </Box>

        <Box>
          <Text color="faint" size="small">
            This is a small faint{' '}
            <Link to="/dsys" monochromatic>
              monochromatic link
            </Link>
            .
          </Text>
        </Box>

        <Box>
          <Text color="faint" size="small">
            This is a small faint{' '}
            <Link to="/dsys" monochromatic isBold>
              monochromatic and isBold link
            </Link>
            .
          </Text>
        </Box>
      </Stack>
    </Note>
  </Stack>
);

export const ButtonExample = () => (
  <Stack gap="16">
    <Title id="buttons" withAccessoryEnd={<Path path="src/dig-overrides/button.module.css" />}>
      Buttons
    </Title>
    <Note
      type="Button"
      title={`<Button
  variant={primary | outline | opacity | borderless | transparent} 
  size={small | standard | large | xlarge}
>
  Button
</Button>`}
      info="Buttons are only Atlas Grotesk, always bold, and have no letter spacing. Outline border is subtle."
    >
      <Stack gap="8">
        {['small', 'standard', 'large', 'xlarge'].map((size) => (
          <Split key={size} gap="8" alignY="center">
            {['primary', 'outline', 'opacity', 'borderless', 'transparent'].map((variant) => (
              <Split.Item key={`${size}-${variant}`}>
                <Button variant={variant as any} size={size as any}>
                  {variant.charAt(0).toUpperCase() + variant.substring(1)}
                </Button>
              </Split.Item>
            ))}
          </Split>
        ))}
        <SplitButton
          variant="primary"
          renderMenu={({buttonProps}) => (
            <Menu.Wrapper>
              {({getTriggerProps, getContentProps}) => (
                <>
                  <Button
                    // buttonProps are required for props to match the SplitButton styles
                    {...buttonProps}
                    // triggerProps are required for <Menu>
                    {...getTriggerProps()}
                  />
                  <Menu.Content {...getContentProps()} placement="bottom-end">
                    <Menu.Segment>
                      <Menu.ActionItem>Optional 1st</Menu.ActionItem>
                      <Menu.ActionItem>Optional 2nd</Menu.ActionItem>
                    </Menu.Segment>
                  </Menu.Content>
                </>
              )}
            </Menu.Wrapper>
          )}
        >
          SplitButton
        </SplitButton>
        <ButtonBar
          variant="primary"
          size="small"
          buttonGroupProps={[
            {
              id: 'button-1',
              children: 'Label',
            },
            {
              id: 'button-2',
              children: 'Label',
            },
            {
              id: 'button-3',
              children: 'Label',
              withIconStart: (
                <UIIcon src={EmojiSmileLine} aria-label="DO_NOT_COPY-ButtonBar example" />
              ),
            },
            {
              id: 'button-4',
              children: 'Label',
            },
          ]}
        />
      </Stack>
    </Note>

    <Note
      type="DSYS/ButtonLink"
      title={`<ButtonLink 
  variant={primary | outline | opacity | borderless | transparent} 
  to={absolute | relative url} 
/>`}
      info="Button links inherit from buttons, but can take in a `to` prop"
      path="src/components/DSYS/Link.tsx"
    >
      <Split gap="8">
        {['primary', 'outline', 'opacity', 'borderless', 'transparent'].map((variant) => (
          <Split.Item key={variant}>
            <ButtonLink variant={variant as any} to="/dsys">
              {variant.charAt(0).toUpperCase() + variant.substring(1)}
            </ButtonLink>
          </Split.Item>
        ))}
      </Split>
    </Note>

    <Note
      type="IconButton"
      title={`<IconButton variant={primary | outline | opacity | borderless | transparent}>
  <UIIcon src={AiLine} />
</IconButton>`}
      info="These buttons will default to always be circular and the icon is Text Subtle."
    >
      <Split gap="8">
        {['filled', 'outline', 'opacity', 'borderless', 'transparent'].map((variant) => (
          <Split.Item key={variant}>
            <Tooltip
              title={`${variant.charAt(0).toUpperCase()}${variant.substring(1)}`}
              placement="bottom"
            >
              <IconButton variant={variant as any}>
                <Box as={UIIcon} src={AiLine} color="Text Subtle" />
              </IconButton>
            </Tooltip>
          </Split.Item>
        ))}
      </Split>
    </Note>

    <Note
      type="DSYS/Custom Buttons using withShade"
      title={`<Box as="button" {...withShade({ state: 'animated'})}>Anything Here!</Box>`}
      info="You can style anything as a button using the withShade util from dig-foundations. Use state=animated to force active state."
    >
      <Split gap="8">
        {['auto', 'animated'].map((type) => (
          <Split.Item key={type}>
            <Box
              as={StylelessButton}
              cursor="pointer"
              padding="10"
              borderRadius="Medium"
              {...withShade({
                state: type as any,
              })}
            >
              <Stack gap="8">
                <Box>
                  {type === 'animated' ? 'state=animated (force active state)' : 'Click me!'}
                </Box>
                <Box>!!</Box>
              </Stack>
            </Box>
          </Split.Item>
        ))}
      </Split>
    </Note>
  </Stack>
);

export const AvatarExample = () => {
  const {employee} = useAtomValue(loggedInEmployeeAtom);
  if (!employee?.name) {
    return null;
  }

  return (
    <Stack gap="16">
      <Title id="avatars" withAccessoryEnd={<Path path="src/dig-overrides/avatar.module.css" />}>
        Avatars
      </Title>

      <Note
        type="DSYS/Avatar"
        path="src/components/DSYS/Avatar.tsx"
        title={`<Avatar 
  user={Employee | GoalUser | ldap}
  size={xsmall | small | medium (default if undefined) | large | xlarge | profile} 
/>
<Avatar user={{name: 'Drop Box'}} />
<Avatar user="kshay" onLeave size="profile" />
`}
        info="Avatars sizes match DIG, but we have a couple overrides. If an image isn't found, we fallback to the users initials. We also support xlarge and profile sizes. `Avatar` takes in a user prop. User can be a string (ldap), Employee, GoalUser, or PulseUser"
      >
        <Stack gap="8">
          <Split gap="8">
            {['xsmall,16', 'small,24', 'medium,32', 'large,40', 'xlarge,64', 'profile,128'].map(
              (size) => (
                <Split.Item key={size}>
                  <Stack gap="6">
                    <Text color="faint" size="small">
                      {size.split(',')[0]}{' '}
                      <Text color="faint" size="xsmall">
                        ({size.split(',')[1]}px)
                      </Text>
                    </Text>
                    <Avatar user={employee} size={size.split(',')[0] as any} />
                  </Stack>
                </Split.Item>
              )
            )}
          </Split>
          <Split gap="8">
            {['xsmall', 'small', 'medium', 'large', 'xlarge', 'profile'].map((size) => (
              <Split.Item key={size}>
                <Avatar user={{name: 'Drop Box'}} size={size as any} />
              </Split.Item>
            ))}
          </Split>
          <Box
            as={Avatar}
            user={{ldap: 'kshay', name: 'Kyle Shay'}}
            onLeave
            size="profile"
            marginX="100"
          />
        </Stack>
      </Note>

      <Note
        type="DSYS/AvatarIcon"
        path="src/components/DSYS/Avatar.tsx"
        title={`<AvatarIcon src={Icon} size={small | medium (default if undefined) | large} />`}
        info="AvatarIcons are a way to show an icon in the shape of an avatar. They inherit the same sizes as Icons."
      >
        <Stack gap="8">
          <Split gap="8">
            {['small,24', 'medium,32', 'large,40'].map((size) => (
              <Split.Item key={size}>
                <Stack gap="6">
                  <Text color="faint" size="small">
                    {size.split(',')[0]}{' '}
                    <Text color="faint" size="xsmall">
                      ({size.split(',')[1]}px)
                    </Text>
                  </Text>
                  <AvatarIcon src={AiLine} size={size.split(',')[0] as any} />
                </Stack>
              </Split.Item>
            ))}
          </Split>
        </Stack>
      </Note>

      <Note
        type="DSYS/Facepile"
        path="src/components/DSYS/Facepile.tsx"
        title={`<Facepile
  ldaps={ldaps}
  size={xsmall | small | medium (default if undefined) | large} 
  overflow={number (default 3)}
  hideTooltip={boolean (default false)}
  onClick={function}
  ownerIsFirst={boolean (default false)}
/>
<Avatar ownerIsFirst />`}
        info="Facepiles have a couple rules. By default, we show a max of 3 circles. First is the owner (if ownerIsFirst), then we sort using the `sortEmployeesSelfFirst` function. If there are more than 3, we show a +N circle"
      >
        <Stack gap="8">
          <Split gap="8">
            {['xsmall,16', 'small,24', 'medium,32', 'large,40'].map((size) => (
              <Split.Item key={size}>
                <Stack gap="6">
                  <Text color="faint" size="small">
                    {size.split(',')[0]}{' '}
                    <Text color="faint" size="xsmall">
                      ({size.split(',')[1]}px)
                    </Text>
                  </Text>
                  <Facepile
                    ldaps={['kshay', 'baochenh', 'youcef', 'drew']}
                    size={size.split(',')[0] as any}
                  />
                </Stack>
              </Split.Item>
            ))}
          </Split>
          <Split gap="8">
            <Split.Item>
              <Stack gap="6">
                <Text color="faint" size="small">
                  !ownerIsFirst
                </Text>
                <Facepile ldaps={['kshay', employee.ldap, 'drew']} />
              </Stack>
            </Split.Item>
            <Split.Item>
              <Stack gap="6">
                <Text color="faint" size="small">
                  ownerIsFirst (owner is first circle, then sort using `sortEmployeesSelfFirst`)
                </Text>
                <Facepile ldaps={['kshay', employee.ldap, 'drew']} ownerIsFirst />
              </Stack>
            </Split.Item>
          </Split>
        </Stack>
      </Note>
    </Stack>
  );
};

export const FormExample = () => (
  <Stack gap="16">
    <Title id="forms" withAccessoryEnd={<Path path="src/dig-overrides/forms.module.css" />}>
      Forms
    </Title>
    <Note
      type="Without/FormRow"
      title={`<TextInput placeholder="Text Input" />`}
      info="If you don't use the <FormRow> component, all form components will be 1:1 with DIG (besides the border-color)"
    >
      <Stack gap="8">
        <TextInput size="small" placeholder="Small Placeholder" />
        <Select size="small" id="select-default" placeholder="Small Select an example">
          <Select.Option aria-disabled disabled value="1">
            Example option
          </Select.Option>
          <Select.Option value="2">Example option 2</Select.Option>
        </Select>
        <TextInput placeholder="Medium Placeholder" />
        <TextArea placeholder="Medium Placeholder" />
        <Select id="select-default" placeholder="Medium Select an example">
          <Select.Option value="1">Example option</Select.Option>
          <Select.Option value="2">Example option 2</Select.Option>
        </Select>
        <TextInput size="large" placeholder="Large Placeholder" />
        <TextArea size="large" placeholder="Large Placeholder" />
        <Select size="large" id="select-default" placeholder="Large Select an example">
          <Select.Option value="1">Example option</Select.Option>
          <Select.Option value="2">Example option 2</Select.Option>
        </Select>
      </Stack>
    </Note>

    <Note
      type="With/FormRow"
      title={`<FormRow>
  <FormLabel htmlFor="unique-id">Text Input</FormLabel>
  <TextInput id="unique-id" placeholder="placeholder" />
</FormRow>
<FormRow variant="button" isFooter>
  <Button variant="opacity">Cancel</Button>
  <Button variant="primary">Submit</Button>
</FormRow>`}
      info="If you include <FormRow> the contents will have a larger font-size, padding, and spacing."
    >
      <Stack gap="8">
        <FormRow>
          <FormLabel htmlFor="input">Text Input</FormLabel>
          <TextInput placeholder="Placeholder text..." id="input" />
        </FormRow>

        <FormRow>
          <FormLabel htmlFor="area">Text Area</FormLabel>
          <TextArea placeholder="Placeholder text..." id="area" rows={5} />
          <FormHelperText>
            <UIIcon src={AiLine} size="small" />
            Helper info can be places here with FormHelperComponent
          </FormHelperText>
        </FormRow>

        <FormRow>
          <FormLabel htmlFor="sel">Select</FormLabel>
          <Select
            id="sel"
            defaultValue="1"
            placeholder="Select a value"
            withLeftAccessory={<UIIcon src={AiLine} />}
          >
            <Select.OptGroup withLabel="Group 1">
              <Select.Option withAccessory={<StatusButtonIcon status="on_track" />} value="1">
                Example option 1
              </Select.Option>
              <Select.Option value="2">Example option 2</Select.Option>
            </Select.OptGroup>
            <Select.OptGroup withLabel="Group 2">
              <Select.Option value="3">Example option 3</Select.Option>
              <Select.Option value="4">Example option 4</Select.Option>
            </Select.OptGroup>
          </Select>
        </FormRow>

        <FormRow variant="button" isFooter>
          <Button variant="opacity">Cancel</Button>
          <Button variant="primary">Submit</Button>
        </FormRow>
      </Stack>
    </Note>
  </Stack>
);

export const ModalExample = () => {
  const [open, setOpen] = useState<undefined | 'basic' | 'scroll' | 'full'>(undefined);
  const close = () => setOpen(undefined);

  return (
    <Stack gap="16">
      <Title id="modals" withAccessoryEnd={<Path path="src/dig-overrides/modal.module.css" />}>
        Modals
      </Title>
      <Note
        type="Modal"
        title={`<Modal>
  <Modal.Header>
    <Modal.Title>Basic Modal</Modal.Title>
    - or -
    <Title withAccessoryStart={Icon}>DSYS/Title Modal</Title>
  </Modal.Header>
  <Modal.Body>Body</Modal.Body>
  <Modal.Footer>
    <Button variant="primary">CTA</Button>
  </Modal.Footer>
</Modal>`}
        info="Modals overrides include updated paddings & a border bottom on the header, and are always centered by default"
      >
        <Stack gap="8">
          <Box>
            <Button variant="primary" onClick={() => setOpen('basic')}>
              Open basic modal
            </Button>
          </Box>

          <Box>
            <Button variant="primary" onClick={() => setOpen('scroll')}>
              Open scrolling modal
            </Button>
          </Box>

          <Box>
            <Button variant="primary" onClick={() => setOpen('full')}>
              Open Modal.FullBleed
            </Button>
          </Box>
        </Stack>

        {/* Basic */}
        <Modal open={open === 'basic'} withCloseButton="close" onRequestClose={close}>
          <Modal.Header>
            {/* It's not nessecary, but you can use <DSYS/Title> in place of <Modal.Title> */}
            <Title withAccessoryStart={<UIIcon src={AiLine} />}>Basic Modal</Title>
          </Modal.Header>

          <Modal.Body>
            Has padding / spacing overrides and includes a border-bottom on the
            <Text monospace> Modal.Header</Text>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="transparent" onClick={close}>
              Action 3
            </Button>
            <Button variant="opacity" onClick={close}>
              Close
            </Button>
            <Button variant="primary" onClick={close}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Scroll */}
        <Modal open={open === 'scroll'} withCloseButton="close" onRequestClose={close}>
          <Modal.Header>
            <Modal.Title>Scrolling modal</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {Array.from({length: 200}).map((_, i) => (
              <Box key={`modal-${i}`}>{`Line ${i + 1}`}</Box>
            ))}
          </Modal.Body>

          <Modal.Footer>
            <Button variant="transparent" onClick={close}>
              Action 3
            </Button>
            <Button variant="opacity" onClick={close}>
              Close
            </Button>
            <Button variant="primary" onClick={close}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal.FullBleed  */}
        <Modal open={open === 'full'} withCloseButton="close" onRequestClose={close}>
          <Modal.FullBleed>
            <ChoiceHero
              style={{
                backgroundColor: 'var(--dig-color__opacity__surface)',
              }}
              aspectRatio={'16:9'}
            />
          </Modal.FullBleed>

          <Modal.Body>
            This example demononstates a FullBleed. Instead of using
            <Text monospace> Modal.Header</Text>, use
            <Text monospace> Modal.FullBleed</Text>. This example also demonstates the{' '}
            <Text monospace>isCentered</Text> prop.
          </Modal.Body>
        </Modal>
      </Note>
    </Stack>
  );
};
