// OKR Grading Components Strings
export const OKR_GRADING_NO_INPUT_TITLE = "Let's make smart goals";
export const OKR_GRADING_NO_INPUT_DESCRIPTION =
  'Start writing your objective to get instant feedback in crafting the best OKR.';
export const OKR_GRADING_NO_INPUT_START_WRITING = 'Start writing';
export const OKR_GRADING_NO_INPUT_OR = 'or';
export const OKR_GRADING_NO_INPUT_LEARN_MORE = 'learn more';

// OKR Grading Components Overrall Success/Fail Strings
export const OKR_GRADING_OVERALL_SUCCESS_TITLE = 'You set a goal!';
export const OKR_GRADING_OVERALL_SUCCESS_DESCRIPTION =
  "Now that you've set your OKRs, It's time to get after it.";
export const OKR_GRADING_OVERALL_FAIL_TITLE = 'Almost there!';
export const OKR_GRADING_OVERALL_FAIL_DESCRIPTION =
  "You've making progress, but there's still room to fine-tune your goal.";

// OKR Grading Components Objective Footer Strings
export const OKR_GRADING_OBJECTIVE_FOOTER_CONTINUE = 'Continue writing...';

// OKR Grading Component Overall Result Strings
export const OKR_GRADING_OVERALL_RESULT_COPY = 'Copy';
export const OKR_GRADING_OVERALL_RESULT_CLIPBOARD = 'OKRs to clipboard';
export const OKR_GRADING_OVERALL_RESULT_RESTART = 'Restart';
export const OKR_GRADING_OVERALL_RESULT_RESTART_WITH_NEW_OBJECTIVE = 'with a new objective';
export const OKR_GRADING_OVERALL_RESULT_SELECT_KEY_RESULT = 'Select key results';
export const OKR_GRADING_OVERALL_RESULT_SELECT_KEY_RESULT_DESCRIPTION =
  'for suggestions to refine and edit your KR';
export const OKR_GRADING_OVERALL_RESULT_COMPLETED = 'Finish my goal';

export const OKR_GRADING_IN_PROGRESS_TITLE = "Looking at what you've done...";

// Path: src/helpers/parsing.tsx
// Objective Parsing Strings
export const OBJECTIVE_VALID_TITLE = "You've set an objective!";
export const OBJECTIVE_VALID_DESCRIPTION = 'Now craft your first key result to continue.';
export const OBJECTIVE_INVALID_TITLE = 'Keep working on your objective';
export const OBJECTIVE_INVALID_DESCRIPTION = 'Continue refining considering the tips below.';
export const OBJECTIVE_VALID_ACTION_TITLE = "It's actionable";
export const OBJECTIVE_VALID_ACTION_DESCRIPTION = 'This objective has an action oriented verb';
export const OBJECTIVE_INVALID_ACTION_TITLE = 'Make it actionable?';
export const OBJECTIVE_INVALID_ACTION_DESCRIPTION =
  'Use action verbs around outcomes clearly direct towards specific goals';
export const OBJECTIVE_VALID_OUTCOME_TITLE = "It's outcome oriented";
export const OBJECTIVE_VALID_OUTCOME_DESCRIPTION = 'Your objective has a clear outcome';
export const OBJECTIVE_INVALID_OUTCOME_TITLE = 'Include an outcome?';
export const OBJECTIVE_INVALID_OUTCOME_DESCRIPTION =
  'Highlight a clear outcome to guide progress and gauge success';
export const OBJECTIVE_VALID_IMPORTANCE_TITLE = 'It has importance';
export const OBJECTIVE_VALID_IMPORTANCE_DESCRIPTION = 'The objective shows its impact';
export const OBJECTIVE_INVALID_IMPORTANCE_TITLE = 'Is it important?';
export const OBJECTIVE_INVALID_IMPORTANCE_DESCRIPTION =
  "Clarify 'why it matters' to enhance commitment to the goal";

// Path: src/helpers/parsing.tsx
// Key Result Parsing Strings
export const KEY_RESULT_VALID_TITLE = "You've made a key result";
export const KEY_RESULT_VALID_DESCRIPTION = 'Continue to add another one.';
export const KEY_RESULT_INVALID_TITLE = 'Keep working on your key result';
export const KEY_RESULT_INVALID_DESCRIPTION =
  'Keep refining or consider the suggestions below to continue.';
export const KEY_RESULT_VALID_ACHIEVABLE_TITLE = "It's achievable";
export const KEY_RESULT_VALID_ACHIEVABLE_DESCRIPTION = 'The key result is achievable';
export const KEY_RESULT_INVALID_ACHIEVABLE_TITLE = 'Is it achievable?';
export const KEY_RESULT_INVALID_ACHIEVABLE_DESCRIPTION =
  'Key result should be tactical and feasible within one quarter';
export const KEY_RESULT_VALID_CONCRETE_TITLE = "It's concrete";
export const KEY_RESULT_VALID_CONCRETE_DESCRIPTION = 'The key result has a clear deliverable';
export const KEY_RESULT_INVALID_CONCRETE_TITLE = 'Does it have a concrete milestone?';
export const KEY_RESULT_INVALID_CONCRETE_DESCRIPTION =
  'Focus on deliverables for the end user and include time-bound outputs';
export const KEY_RESULT_VALID_MEASURABLE_TITLE = "It's measurable";
export const KEY_RESULT_VALID_MEASURABLE_DESCRIPTION = 'The key result has a clear metric';
export const KEY_RESULT_INVALID_MEASURABLE_TITLE = 'Is it measurable?';
export const KEY_RESULT_INVALID_MEASURABLE_DESCRIPTION =
  'Include a trackable metric so it’s easy to mark progress';

// Path: src/constants.tsx
// OKR Initial State Strings
export const OKR_INITIAL_STATE_OBJECTIVE = 'Tips to write an objective';
export const OKR_INITIAL_STATE_OBJECTIVE_DESCRIPTION =
  'The best objectives detail what outcomes you want to accomplish.';
export const OKR_INITIAL_STATE_OBJECTIVE_ACTION_TITLE = 'Make it actionable';
export const OKR_INITIAL_STATE_OBJECTIVE_ACTION_DESCRIPTION =
  'Use action verbs around outcomes clearly direct towards specific goals';
export const OKR_INITIAL_STATE_OBJECTIVE_OUTCOME_TITLE = 'Include an outcome';
export const OKR_INITIAL_STATE_OBJECTIVE_OUTCOME_DESCRIPTION =
  'Highlight a clear outcome to guide progress and gauge success';
export const OKR_INITIAL_STATE_OBJECTIVE_IMPORTANCE_TITLE = 'Keep it important';
export const OKR_INITIAL_STATE_OBJECTIVE_IMPORTANCE_DESCRIPTION =
  "Clarify 'why it matters' to enhance commitment to the goal";

// Path: src/constants.tsx
// Key Result Initial State Strings
export const OKR_INITIAL_STATE_KEY_RESULT = 'Tips to write a key result';
export const OKR_INITIAL_STATE_KEY_RESULT_DESCRIPTION =
  'A good key result demonstrates success in achieving your objective.';
export const OKR_INITIAL_STATE_KEY_RESULT_ACHIEVABLE_TITLE = 'Make it achievable';
export const OKR_INITIAL_STATE_KEY_RESULT_ACHIEVABLE_DESCRIPTION =
  'Key results should be tactical and feasible within one quarter';
export const OKR_INITIAL_STATE_KEY_RESULT_CONCRETE_TITLE = 'Concrete milestones';
export const OKR_INITIAL_STATE_KEY_RESULT_CONCRETE_DESCRIPTION =
  'Focus on deliverables for the end user and include time-bound outputs';
export const OKR_INITIAL_STATE_KEY_RESULT_MEASURABLE_TITLE = 'Make it measurable';
export const OKR_INITIAL_STATE_KEY_RESULT_MEASURABLE_DESCRIPTION =
  'Include a trackable metric so it’s easy to mark progress';
