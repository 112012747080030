/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LoginService {

    /**
     * Login
     * @param redirectUri
     * @returns any Successful Response
     * @throws ApiError
     */
    public static loginApiV1AuthLoginGet(
        redirectUri?: (string | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/auth/login',
            query: {
                'redirect_uri': redirectUri,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Auth Redirect
     * @returns any Successful Response
     * @throws ApiError
     */
    public static authRedirectApiV1AuthAuthRedirectGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/auth/auth_redirect',
        });
    }

    /**
     * Logout Endpoint
     * @param postLogoutRedirectUri
     * @returns any Successful Response
     * @throws ApiError
     */
    public static routeLogoutAndRemoveCookieApiV1AuthLogoutGet(
        postLogoutRedirectUri?: (string | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/auth/logout',
            query: {
                'post_logout_redirect_uri': postLogoutRedirectUri,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
