import {List} from '@dropbox/dig-components/dist/list';
import {Box, Stack} from '@dropbox/dig-foundations';
import {useQuery} from '@tanstack/react-query';
import {NotificationService} from 'client';
import {Eyebrow} from 'components/DSYS/Eyebrow';
import {t} from 'i18next';
import {getService} from 'utilities';

import {NotificationEmptyState} from './NotificationEmptyState';
import {NotificationRenderer} from './NotificationRenderer';

export const NotificationInbox = ({handleSelection}: {handleSelection: () => void}) => {
  const {data} = useQuery({
    queryKey: ['notifications'],
    queryFn: getService(NotificationService).checkApiV1NotificationsGet,
  });

  return (
    <Stack>
      {!data?.action_items.length ? null : (
        <Box paddingTop="12">
          <Box paddingX="16">
            <Eyebrow>{t('notification_action_items_header')}</Eyebrow>
          </Box>
          <List padding="0">
            {data.action_items.map((item, index) => (
              <NotificationRenderer
                key={item.id}
                notification={item}
                separator={index < data.action_items.length - 1}
                onClick={handleSelection}
              />
            ))}
          </List>
        </Box>
      )}
      {!data?.notifications.length ? null : (
        <Box paddingTop="12">
          <Box paddingX="16">
            <Eyebrow>{t('notification_notifications_header')}</Eyebrow>
          </Box>
          <List padding="0">
            {data.notifications.map((item, index) => (
              <NotificationRenderer
                key={item.id}
                notification={item}
                archiveable
                separator={index < data.notifications.length - 1}
                onClick={handleSelection}
              />
            ))}
          </List>
        </Box>
      )}

      {data?.action_items.length || data?.notifications.length ? null : (
        <NotificationEmptyState type="inbox" />
      )}
    </Stack>
  );
};
