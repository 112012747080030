/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UpdateCreate } from '../models/UpdateCreate';
import type { UpdateWithGoalStatus } from '../models/UpdateWithGoalStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UpdateService {

    /**
     * Create Update
     * Create new update.
     * @param userId
     * @param requestBody
     * @param employeeId
     * @param isDelegated
     * @returns UpdateWithGoalStatus Successful Response
     * @throws ApiError
     */
    public static createUpdateApiV1UpdatesPost(
        userId: string,
        requestBody: UpdateCreate,
        employeeId?: (string | null),
        isDelegated: boolean = false,
    ): CancelablePromise<UpdateWithGoalStatus> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/updates',
            query: {
                'user_id': userId,
                'employee_id': employeeId,
                'is_delegated': isDelegated,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
