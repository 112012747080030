import {List} from '@dropbox/dig-components/dist/list';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';

import styles from './NewsLoadingState.module.css';

export const NewsPostRightLoading = () => {
  const items = Array.from({length: 10}, (_, i) => i);

  return (
    <div className={styles.loadingPost}>
      <Skeleton.Box height={30} withBorderRadius="medium" />
      <Skeleton.Box width={240} height={10} withBorderRadius="xsmall" />
      <Skeleton.Box height={200} withBorderRadius="large" />
      {items.map((item) => (
        <Skeleton.Text key={item} />
      ))}
    </div>
  );
};

export const NewsFeedSkeleton = () => {
  const items = Array.from({length: 7}, (_, i) => i);

  return (
    <List>
      {items.map((item) => (
        <Skeleton.Box key={item} className={styles.loadingBox} />
      ))}
    </List>
  );
};
