import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {CodeHighlightNode, CodeNode} from '@lexical/code';
import {AutoLinkNode, LinkNode} from '@lexical/link';
import {ListItemNode, ListNode} from '@lexical/list';
import {TRANSFORMERS} from '@lexical/markdown';
import {AutoFocusPlugin} from '@lexical/react/LexicalAutoFocusPlugin';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {ListPlugin} from '@lexical/react/LexicalListPlugin';
import {MarkdownShortcutPlugin} from '@lexical/react/LexicalMarkdownShortcutPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {HeadingNode, QuoteNode} from '@lexical/rich-text';
import * as Sentry from '@sentry/react';
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $selectAll,
  EditorState,
  RootNode,
} from 'lexical';
import {useEffect} from 'react';

import styles from './editor/Editor.module.css';
import {CaptureNode} from './editor/nodes/CaptureNode';
import {ImageNode} from './editor/nodes/ImageNode';
import {KalturaNode} from './editor/nodes/KalturaNode';
import {LinkNodePlugin} from './editor/nodes/LinkNodePlugin';
import {MentionNode} from './editor/nodes/MentionNode';
import {VideoNode} from './editor/nodes/VideoNode';
import {VimeoNode} from './editor/nodes/VimeoNode';
import {YouTubeNode} from './editor/nodes/YouTubeNode';
import {AutoEmbedPlugin} from './editor/plugins/AutoEmbedPlugin';
import {AutoLinkPlugin} from './editor/plugins/AutoLinkPlugin';
import {CapturePlugin} from './editor/plugins/CapturePlugin';
import {DragDropPastePlugin} from './editor/plugins/DragDropPastePlugin';
import DraggableBlockPlugin from './editor/plugins/DraggableBlockPlugin';
import {FloatingLinkEditorPlugin} from './editor/plugins/FloatingLinkEditor';
import {ImagesPlugin} from './editor/plugins/ImagesPlugin';
import {KalturaPlugin} from './editor/plugins/KalturaPlugin';
import {LexicalClickableLinkPlugin} from './editor/plugins/LexicalClickableLinkPlugin';
import {LinkPlugin} from './editor/plugins/LinkPlugin';
import {NewMentionsPlugin} from './editor/plugins/MentionPlugin';
import {RichTextToolbar} from './editor/plugins/RichTextToolbarPlugin';
import {VideosPlugin} from './editor/plugins/VideosPlugin';
import {VimeoPlugin} from './editor/plugins/VimeoPlugin';
import {YouTubePlugin} from './editor/plugins/YouTubePlugin';
import getEditorTheme, {EditorTheme} from './editor/themes/EditorTheme';

const config = {
  namespace: 'Editor',
  onError(error: any) {
    throw error;
  },
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    AutoLinkNode,
    LinkNode,
    YouTubeNode,
    CaptureNode,
    VimeoNode,
    MentionNode,
    KalturaNode,
    ImageNode,
    VideoNode,
  ],
};

interface Props {
  source?: string;
  theme?: EditorTheme;
  autoFocus?: boolean;
  placeholder?: string;
  topOffset?: number;
  media?: boolean;
  minHeight?: number;
  mentions?: boolean;
  editable?: boolean;
  selectAll?: boolean;
  value?: string;
  onChange?: (state: EditorState) => void;
  onReady?: (editorNodes: ChildNode[]) => void;

  // Don't use this one
  onRootReady?: (root: RootNode) => void;
}

const EditorContent = ({
  source,
  placeholder,
  autoFocus = true,
  editable,
  minHeight = 150,
  media,
  topOffset = 48,
  mentions,
  selectAll,
  value,
  onChange,
  onReady,
  onRootReady,
}: Props) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.setEditable(editable ?? false);
  }, [editable, editor]);

  useEffect(() => {
    if (value) {
      editor.update(() => {
        const root = $getRoot();
        try {
          const json = JSON.parse(value);
          editor.setEditorState(editor.parseEditorState(json));
        } catch (e) {
          // Fallback just loading a string
          root.clear();

          const paragraphNode = $createParagraphNode();
          paragraphNode.append($createTextNode(value));
          root.append(paragraphNode);

          if (source) {
            Sentry.captureException(new Error(`[RichTextEditor] Invalid JSON in ${source}`));
          }
        }

        onRootReady?.(root);
        onReady?.([...(editor.getRootElement()?.childNodes ?? [])]);
      });

      if (selectAll) {
        editor.update(() => {
          $selectAll();
        });
      }
    }
  }, [editor, selectAll, value, onReady, onRootReady, source]);

  if (!editable && !value) {
    return null;
  }

  return (
    <Box
      borderWidth="1"
      borderStyle={editable ? 'Solid' : undefined}
      borderRadius="Small"
      borderColor="Border Subtle"
      display="block"
    >
      {editable && <RichTextToolbar top={topOffset} media={Boolean(media)} />}
      <Box position="relative" borderRadius="Small" paddingX={editable ? '4' : '0'}>
        <RichTextPlugin
          contentEditable={
            <Box paddingX={editable ? '10' : undefined}>
              <ContentEditable
                className={editable ? styles.input : undefined}
                style={editable ? {minHeight} : undefined}
              />
            </Box>
          }
          placeholder={<Text className={styles.placeholder}>{placeholder}</Text>}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        {autoFocus && <AutoFocusPlugin />}
        <ListPlugin />
        <LinkPlugin />
        <LinkNodePlugin />
        <AutoLinkPlugin />
        {!editable ? <LexicalClickableLinkPlugin /> : <FloatingLinkEditorPlugin />}
        {mentions && <NewMentionsPlugin />}
        {media && (
          <>
            <DraggableBlockPlugin />
            <AutoEmbedPlugin />
            <YouTubePlugin />
            <CapturePlugin />
            <VimeoPlugin />
            <KalturaPlugin />
            <ImagesPlugin />
            <VideosPlugin />
            <DragDropPastePlugin />
          </>
        )}
        <OnChangePlugin
          onChange={(state, editorInstance) => {
            const jsonState = state.toJSON();
            const {children} = jsonState.root;
            let lastChildrenNodeWithChildren = children.length - 1;
            for (let i = children.length - 1; i >= 0; i--) {
              const child = children[i] as any;
              if (child && child.children && child.children.length) {
                lastChildrenNodeWithChildren = i;
                break;
              }
            }

            jsonState.root.children = children.slice(0, lastChildrenNodeWithChildren + 1);

            onChange?.(
              Object.assign(state, {
                toString: () =>
                  !editorInstance.getRootElement()?.textContent?.length
                    ? ''
                    : JSON.stringify(jsonState),
              })
            );
          }}
        />
        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
      </Box>
    </Box>
  );
};

export const RichTextArea = (props: Props) => (
  <LexicalComposer
    initialConfig={{
      ...config,
      editable: props.editable ?? false,
      theme: getEditorTheme(props.theme),
    }}
  >
    <EditorContent {...props} />
  </LexicalComposer>
);
