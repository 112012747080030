import {useMutation} from '@tanstack/react-query';
import {NotificationService, Project} from 'client';
import {getService} from 'utilities';
import {queryClient} from 'views/QueryClientWrapper';

export const useNotificationArchiver = () => {
  const {mutateAsync: archiveNotification, isPending} = useMutation({
    mutationFn: ({id}: {id: number}) =>
      getService(NotificationService)
        .archiveApiV1NotificationsArchiveIdGet(id)
        .catch((error) => {
          console.log(error);
        }),
    onMutate: ({id}) => {
      queryClient.setQueryData(['notification', id], (old?: Project) => {
        if (!old) {
          queryClient.invalidateQueries({queryKey: ['notification', id]});
          return;
        }

        return {...old, archived: true};
      });
    },
    onSettled: (response) => {
      if (!response) {
        return;
      }
      queryClient.invalidateQueries({queryKey: ['notifications']});
    },
  });

  return {archiveNotification, isPending};
};
