import {Thread} from 'client';

export const shouldCloseAndResetDrawers = (targetEl: HTMLElement) => {
  const isClickInsideTableCell = !!targetEl.closest('.dig-Table-cell');
  const isClickInsideDrawer = !!targetEl.closest('.dig-Drawer-container');
  const isClickInsideMenu =
    !!targetEl.closest('.dig-Menu-Overlay') || !!targetEl.closest('.dig-Menu-backdrop');
  const isClickInsideOverlay = !!targetEl.closest('.dig-Overlay');
  const isClickInsideTypeahead =
    !!targetEl.closest('.dig-Typeahead-results') || !!targetEl.closest('.dig-Typeahead-row');
  const isButtonClick = targetEl.classList.contains('dig-IconButton') || targetEl.closest('button');
  // We should not close and reset drawers if click is inside table, drawer or menu,
  // or if it's a button click
  return (
    !isClickInsideTableCell &&
    !isClickInsideDrawer &&
    !isClickInsideMenu &&
    !isClickInsideOverlay &&
    !isClickInsideTypeahead &&
    !isButtonClick
  );
};

export const deactivateThread = (targetEl: HTMLElement) => {
  const isClickInsideMenu =
    !!targetEl.closest('.dig-Menu-Overlay') || !!targetEl.closest('.dig-Menu-backdrop');
  const isClickInsideOverlay = !!targetEl.closest('.dig-Overlay');
  const isButtonClick = targetEl.classList.contains('dig-IconButton') || targetEl.closest('button');
  // We should not close and reset drawers if click is inside table, drawer or menu,
  // or if it's a button click
  return !isClickInsideMenu && !isClickInsideOverlay && !isButtonClick;
};

export const getThreads = (goalId: number, threads: Thread[], keyResultId?: number) => {
  if (!keyResultId) {
    return threads.filter(
      ({goal_id, key_result_id, is_resolved}) =>
        goal_id === goalId && key_result_id === null && !is_resolved
    );
  }
  return threads.filter(
    ({goal_id, key_result_id, is_resolved}) =>
      goal_id === goalId && key_result_id === keyResultId && !is_resolved
  );
};

export const getTotalCommentsCount = (threads: Thread[]) =>
  threads.reduce((acc, {comments}) => acc + (comments ? comments.length : 0), 0);
