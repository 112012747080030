import {Select, TextInput} from '@dropbox/dig-components/dist/text_fields';
import {Split} from '@dropbox/dig-foundations';
import {NotificationContent} from 'client';

export const parseAction = (data: string) => {
  try {
    return JSON.parse(data) as NotificationContent;
  } catch (e) {
    return {content: data, to: '/', icon: 'generic', cta: 'View'} as NotificationContent;
  }
};
export const DataInput = ({
  type,
  value,
  error,
  onChange,
}: {
  type: string;
  value: string;
  error?: boolean;
  onChange: (value: string) => void;
}) => {
  const handleOKRChange = (val: string, key: string) => {
    const [quarter, year] = value.split(' ');
    const updated = `${key === 'quarter' ? val : quarter} ${key === 'year' ? val : year}`;
    onChange(updated);
  };

  const handleGenericChange = (val: string, key: 'content' | 'to') => {
    const existing = parseAction(value || '{}');
    const updated = JSON.stringify({...existing, [key]: val});
    onChange(updated);
  };

  if (type.startsWith('okr_')) {
    const [selectedQuarter, selectedYear] = value.split(' ');

    return (
      <Split gap="4">
        <Split.Item>
          <Select
            id="quarter"
            value={value.length ? selectedQuarter : undefined}
            placeholder="— Quarter —"
            onChange={(val) => handleOKRChange(val, 'quarter')}
          >
            {['Q1', 'Q2', 'Q3', 'Q4'].map((quarter) => (
              <Select.Option key={quarter} value={quarter}>
                {quarter}
              </Select.Option>
            ))}
          </Select>
        </Split.Item>
        <Split.Item>
          <Select
            id="year"
            value={value.length ? selectedYear : undefined}
            placeholder="— Fiscal Year —"
            onChange={(val) => handleOKRChange(val, 'year')}
          >
            {['FY24', 'FY25', 'FY26', 'FY27'].map((year) => (
              <Select.Option key={year} value={year}>
                {year}
              </Select.Option>
            ))}
          </Select>
        </Split.Item>
      </Split>
    );
  }

  if (type === 'generic') {
    const {to, content} = parseAction(value || '{}');
    return (
      <Split gap="4" direction="vertical">
        <Split.Item>
          <TextInput
            isInvalid={error && !content}
            value={content}
            onChange={(e) => handleGenericChange(e.currentTarget.value, 'content')}
            placeholder="Notification message content"
          />
        </Split.Item>
        <Split.Item>
          <TextInput
            isInvalid={error && !to}
            value={to}
            onChange={(e) => handleGenericChange(e.currentTarget.value, 'to')}
            placeholder="Link"
          />
        </Split.Item>
      </Split>
    );
  }

  return (
    <TextInput
      disabled
      isInvalid={error && value.length === 0}
      value={value}
      onChange={(e) => {
        // setData(e.currentTarget.value);
        onChange(e.currentTarget.value);
      }}
      placeholder="Notification message content"
    />
  );
};
