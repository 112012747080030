import {Button} from '@dropbox/dig-components/dist/buttons';
import {ProgressBar} from '@dropbox/dig-components/dist/progress_indicators';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Stack} from '@dropbox/dig-foundations';
import {AiDocumentMini} from '@dropbox/dig-illustrations';
import {useQuery} from '@tanstack/react-query';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {AiSpriteService} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {Title} from 'components/DSYS/Title';
import {useAtomValue} from 'jotai';
import {useEffect, useState} from 'react';
import {getService} from 'utilities';

const messages = [
  'Loading your goals...',
  'Loading your status updates...',
  'Pulling the career framework for your role and level...',
];

const convertLine = (line: string) => {
  if (line.match(/- /g)) {
    return <Box marginLeft="16">{line.replace('-', '•')}</Box>;
  } else {
    return line;
  }
};

const massageResponse = (response: string) => {
  return response.split('\n').map((line, idx) => (
    <Box key={idx} marginLeft={line.match(/- /g) ? '0' : '0'}>
      <Text tagName="div">
        {line.match(/\*\*/g) ? (
          <Text>
            {line
              .trim()
              .replace('- ', '')
              .replace(/:$/, '')
              .split('**')
              .map((text, idx2) =>
                idx2 % 2 === 0 ? (
                  text
                ) : (
                  <Text isBold key={`${idx}-${idx2}`}>
                    {text}
                  </Text>
                )
              )}
          </Text>
        ) : line.match(/####/g) ? (
          <Box as={Title} marginY="4">
            {convertLine(line.replace(/#/g, ''))}
          </Box>
        ) : line.match(/###/g) ? (
          <Box as={Title} marginY="4" size="large">
            {convertLine(line.replace(/#/g, ''))}
          </Box>
        ) : (
          convertLine(line)
        )}
      </Text>
    </Box>
  ));
};

export const Review = () => {
  const [message, setMessage] = useState(messages[0]);
  const {employee} = useAtomValue(loggedInEmployeeAtom);

  const [shouldFetch, setShouldFetch] = useState(false);

  const {data, isLoading} = useQuery({
    queryKey: ['review'],
    queryFn: getService(AiSpriteService).reviewApiV1UserReviewGet,
    enabled: shouldFetch,
  });

  useEffect(() => {
    let i = 0;
    const interval = setInterval(() => {
      setMessage(messages[i]);
      i = i + 1;
      if (i === messages.length) {
        clearInterval(interval);
        setMessage('Generating the review...');
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  if (!employee) {
    return null;
  }

  return (
    <Layout.Container>
      <Stack gap="12">
        <Title size={24}>Your review</Title>

        {isLoading || !shouldFetch ? (
          <Box
            width="100%"
            paddingTop="68"
            paddingBottom="68"
            borderRadius="Medium"
            borderStyle="Solid"
            borderColor="Border Subtle"
            textAlign="center"
            placeItems="center"
            marginTop="12"
            marginBottom="44"
          >
            <Stack gap="4">
              <AiDocumentMini altText="" width={64} />
              {!isLoading && !shouldFetch && (
                <>
                  <Text size="large" color="standard" tagName="p" isBold>
                    Generate your performance review with AI
                  </Text>
                  <Text color="faint" tagName="p">
                    It&apos;s based on your 2024 goals, level, and career framework.
                  </Text>
                  <Box paddingTop="4">
                    <Button variant="opacity" onClick={() => setShouldFetch(true)}>
                      Generate
                    </Button>
                  </Box>
                </>
              )}
              {isLoading && shouldFetch && (
                <>
                  <Text size="large" color="standard" tagName="p" isBold>
                    Sit tight, it will take a minute or two
                  </Text>
                  <Text color="faint" tagName="p">
                    {message}
                  </Text>
                  <Box paddingTop="12" marginLeft="132" marginRight="132">
                    <ProgressBar isIndeterminate in />
                  </Box>
                </>
              )}
            </Stack>
          </Box>
        ) : (
          <Box
            as={Stack}
            width="100%"
            padding="24"
            borderRadius="Medium"
            borderStyle="Solid"
            borderColor="Border Subtle"
            marginTop="12"
            marginBottom="44"
            gap="8"
          >
            {massageResponse(data.response)}
          </Box>
        )}
      </Stack>
    </Layout.Container>
  );
};
