import {SVGProps} from 'react';

export const ErrorLine = (props: SVGProps<SVGSVGElement>): React.ReactElement => {
  const iconFill = 'currentColor';
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <g clipPath="url(#clip0_1165_17555)">
        <path
          d="M5.60887 1.28736C5.91869 0.972079 6.34106 0.792792 6.78307 0.788935L13.0558 0.734194C13.4978 0.730336 13.9232 0.902225 14.2385 1.21205L18.7127 5.60882C19.028 5.91864 19.2073 6.34102 19.2111 6.78303L19.2659 13.0557C19.2697 13.4978 19.0978 13.9232 18.788 14.2385L14.3912 18.7127C14.0814 19.0279 13.659 19.2072 13.217 19.2111L6.94432 19.2658C6.50231 19.2697 6.07687 19.0978 5.76159 18.788L1.2874 14.3912C0.972127 14.0814 0.79284 13.659 0.788982 13.217L0.734241 6.94427C0.730384 6.50226 0.902272 6.07682 1.21209 5.76155L5.60887 1.28736Z"
          fill={iconFill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1165_17555">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CheckLine = (props: SVGProps<SVGSVGElement>): React.ReactElement => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" {...props}>
      <g clipPath="url(#clip0_1004_73099)">
        <path
          d="M9.35546 1.29765C9.99774 0.691209 11.0016 0.691209 11.6439 1.29764L12.798 2.38739C13.132 2.70272 13.5813 2.86624 14.0398 2.83936L15.6244 2.74644C16.5062 2.69473 17.2752 3.34001 17.3775 4.21741L17.5611 5.79408C17.6143 6.2503 17.8533 6.66437 18.2218 6.9385L19.4954 7.88589C20.2042 8.4131 20.3785 9.40172 19.8928 10.1395L19.0201 11.4654C18.7675 11.849 18.6845 12.3199 18.7906 12.7668L19.1573 14.3112C19.3613 15.1706 18.8594 16.04 18.013 16.293L16.4922 16.7477C16.0522 16.8792 15.6859 17.1866 15.4799 17.5971L14.7681 19.0159C14.372 19.8054 13.4286 20.1487 12.6177 19.7986L11.1604 19.1693C10.7387 18.9872 10.2606 18.9872 9.83894 19.1693L8.38168 19.7986C7.57072 20.1487 6.62739 19.8054 6.23126 19.0159L5.51943 17.5971C5.31345 17.1866 4.94719 16.8792 4.50712 16.7477L2.9863 16.293C2.13997 16.04 1.63804 15.1706 1.84209 14.3112L2.20876 12.7668C2.31486 12.3199 2.23183 11.849 1.97929 11.4654L1.10652 10.1395C0.620824 9.40172 0.795144 8.4131 1.5039 7.88589L2.7775 6.9385C3.14603 6.66437 3.38509 6.2503 3.43824 5.79408L3.62189 4.21741C3.7241 3.34001 4.49311 2.69473 5.37493 2.74644L6.95954 2.83936C7.41805 2.86624 7.86735 2.70272 8.20131 2.38739L9.35546 1.29765Z"
          fill="#0061FE"
        />
      </g>
      <path
        d="M6.66309 10.3388L9.31934 12.995L14.1891 8.12524"
        stroke="white"
        strokeWidth="1.66667"
        strokeMiterlimit="10"
      />
      <defs>
        <clipPath id="clip0_1004_73099">
          <rect width="20" height="20" fill="white" transform="translate(0.5 0.217285)" />
        </clipPath>
      </defs>
    </svg>
  );
};
