import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ZoomOutLine} from '@dropbox/dig-icons/assets';

export const MetricResult = ({value}: {value?: number}) => (
  <Stack gap={value ? '2' : '0'} align="end" display="flex">
    {value ? (
      <Box
        as={Text}
        isBold
        display="block"
        style={{
          fontSize: '32px',
          lineHeight: '32px',
          letterSpacing: '-2%',
        }}
      >
        {value}
      </Box>
    ) : (
      <Box
        as={UIIcon}
        color="Text Subtle"
        display="block"
        src={ZoomOutLine}
        style={{width: 48, height: 48}}
      />
    )}

    <Text color="subtle">{value ? 'eSAT' : 'Requires 4+ responses'}</Text>
  </Stack>
);
