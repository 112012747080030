import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {AiLine, ChevronRightLine} from '@dropbox/dig-icons/assets';
import {PulseSummary} from 'client';

export const PulseInsight = ({insight, onClick}: {insight: PulseSummary; onClick?: () => void}) => {
  return (
    <Box
      paddingX="4"
      paddingLeft="16"
      borderLeft="Solid"
      borderWidth="2"
      marginLeft="8"
      borderColor="Primary Surface"
      cursor={onClick ? 'pointer' : undefined}
      onClick={onClick}
    >
      <LabelGroup
        withText={
          <Box display="flex" alignItems="center">
            <Text isBold>{insight.theme}</Text>

            {onClick && (
              <Box
                marginLeft="6"
                color="Text Subtle"
                as={UIIcon}
                size="small"
                src={ChevronRightLine}
              />
            )}
          </Box>
        }
        withSubtext={
          <>
            <Box
              as={UIIcon}
              size="small"
              src={AiLine}
              color="Text Subtle"
              position="relative"
              style={{top: 4}}
            />
            <Text color="faint" size="small">
              {insight.summary}
            </Text>
          </>
        }
      />
    </Box>
  );
};
