import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import classNames from 'classnames';
import {Card} from 'client';
import {FunctionComponent, useState} from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {getBackendUrl} from 'utilities';

import styles from './ImageComponent.module.css';

interface Props {
  card: Card;
  onClick?: () => void;
  loadLazy?: boolean;
  size?: 'default' | 'small';
  emphasis?: boolean;
}

export const ImageComponent: FunctionComponent<Props> = ({
  card,
  onClick,
  size = 'default',
  emphasis = true,
}) => {
  const [loaded, setLoaded] = useState(false);

  const skeletonSize = card.landscape
    ? {height: '213px', width: '300px'}
    : {width: '213px', height: '300px'};
  return (
    <div
      className={classNames(styles.image, card.landscape ? styles.landscape : styles.portrait, {
        [styles.default]: size == 'default',
        [styles.small]: size == 'small',
        [styles.emphasis]: emphasis,
      })}
    >
      <LazyLoadImage
        style={{
          visibility: loaded ? 'visible' : 'hidden',
          opacity: loaded ? '1' : '0',
        }}
        placeholder={<Skeleton.Box height={158} width={158} />}
        afterLoad={() => {
          // Should not be necessary, but based on the following issue, it is a known problem
          // https://github.com/Aljullu/react-lazy-load-image-component/issues/95
          setLoaded(true);
        }}
        onClick={onClick}
        src={getBackendUrl() + `/api/v1/cards/images/${card.image_key}`}
      />
      <Skeleton.Box
        className={styles.skeleton}
        height={158}
        style={{
          ...skeletonSize,
          visibility: !loaded ? 'visible' : 'hidden',
          opacity: !loaded ? '1' : '0',
        }}
      />
    </div>
  );
};
