import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {Suspense} from 'react';
import {CardManagementView} from 'views/admin/CardManagement';

export const CardManagement = () => {
  useDocumentTitle(t('manage_cards'));
  return (
    <Suspense fallback={null}>
      <CardManagementView />
    </Suspense>
  );
};
