import {useSuspenseQuery} from '@tanstack/react-query';
import {CardService} from 'client';
import {getService} from 'utilities';

export const useCardsSuspense = () => {
  const {data} = useSuspenseQuery({
    queryKey: ['cards'],
    queryFn: getService(CardService).readCardsApiV1CardsGet,
  });

  return data;
};
