import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {IconButtonGroup, LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {List} from '@dropbox/dig-components/dist/list';
import {Text} from '@dropbox/dig-components/dist/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {ChevronRightLine, PersonMultipleLine} from '@dropbox/dig-icons/assets';
import {Employee, EmployeeFull} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

export const CustomListItem = ({
  itemInfo,
  canDelete,
  padding,
}: {
  itemInfo:
    | {
        type: 'contributor';
        contributor: Employee;
      }
    | {
        type: 'owner';
        goalOwner: EmployeeFull;
      }
    | {
        type: 'reportingLine';
        reportingLine: Employee[];
      }
    | {
        type: 'delegate';
        delegate: Employee;
      };
  canDelete: boolean;
  padding?: string;
}) => {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const {t} = useTranslation();

  const itemLdap =
    itemInfo.type === 'contributor'
      ? itemInfo.contributor.ldap
      : itemInfo.type === 'owner'
      ? itemInfo.goalOwner.ldap
      : itemInfo.type === 'reportingLine' && itemInfo.reportingLine.length > 1
      ? itemInfo.reportingLine?.[0]?.ldap
      : itemInfo.type === 'delegate'
      ? itemInfo.delegate.ldap
      : undefined;
  const itemLabel =
    itemInfo.type === 'contributor'
      ? t('co-owner')
      : itemInfo.type === 'owner'
      ? t('author')
      : itemInfo.type === 'reportingLine' && itemInfo.reportingLine.length > 1
      ? t('people_count', {count: itemInfo.reportingLine.length - 1})
      : itemInfo.type === 'delegate'
      ? t('delegate')
      : undefined;

  const enableNavigation = !canDelete && itemLdap;
  const onNavigate = enableNavigation ? () => navigate(`/people/${itemLdap}`) : undefined;

  return (
    <List.Item
      isSelectable={!!enableNavigation}
      gap="small"
      style={{
        padding: padding ?? '4px 8px',
        alignItems: 'center',
        display: 'flex',
        gap: '4px',
        borderRadius: '8px',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onNavigate}
    >
      <List.Accessory style={{height: '32px', width: '32px'}}>
        {itemInfo.type === 'owner' && (
          <Avatar
            user={itemInfo.goalOwner}
            isInactive={itemInfo.goalOwner.is_deactivated ?? false}
          />
        )}
        {itemInfo.type === 'reportingLine' && (
          <UIIcon
            src={PersonMultipleLine}
            size="large"
            style={{color: 'var(--dig-color__text__subtle)'}}
          />
        )}
        {itemInfo.type === 'delegate' && (
          <Avatar user={itemInfo.delegate} isInactive={itemInfo.delegate.is_deactivated ?? false} />
        )}
        {itemInfo.type === 'contributor' && (
          <Avatar
            user={itemInfo.contributor}
            isInactive={itemInfo.contributor.is_deactivated ?? false}
          />
        )}
      </List.Accessory>
      <List.Content>
        {itemInfo.type === 'owner' && <LabelGroup withText={itemInfo.goalOwner.name} />}
        {itemInfo.type === 'reportingLine' && (
          <LabelGroup withText={canDelete ? t('your_reporting_line') : t('owner_reporting_line')} />
        )}
        {itemInfo.type === 'delegate' && <LabelGroup withText={itemInfo.delegate.name} />}
        {itemInfo.type === 'contributor' && <LabelGroup withText={itemInfo.contributor.name} />}
      </List.Content>
      <List.Accessory>
        {hover && enableNavigation && (
          <IconButtonGroup>
            {({getButtonProps}) => (
              <>
                <IconButton
                  variant="transparent"
                  {...getButtonProps()}
                  shape="circular"
                  onClick={onNavigate}
                >
                  <UIIcon
                    src={ChevronRightLine}
                    style={{color: 'var(--dig-color__border__base)'}}
                    onClick={onNavigate}
                  />
                </IconButton>
              </>
            )}
          </IconButtonGroup>
        )}
        {(!hover || !enableNavigation) && itemLabel && (
          <Text color="faint" size="small" tagName="label">
            {itemLabel}
          </Text>
        )}
      </List.Accessory>
    </List.Item>
  );
};
