import {Drawer} from '@dropbox/dig-components/drawer';
import {Box} from '@dropbox/dig-foundations';
import {ReactNode} from 'react';

import styles from './Drawer.module.css';

type DrawerOverlayProps = {
  isOpen: boolean;
  header?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  children?: React.JSX.Element;
  shouldToggleVisibility?: boolean;
};

export const DrawerOverlay = (props: DrawerOverlayProps) => {
  const {isOpen, children} = props;
  return (
    <Drawer.OverlayDrawer isOpen={isOpen} width="475px" alignment="right">
      {children ? children : <DrawerContainer {...props} />}
    </Drawer.OverlayDrawer>
  );
};

export const DrawerContainer = ({
  isOpen,
  header,
  body,
  footer,
  shouldToggleVisibility = false,
}: DrawerOverlayProps) => {
  return (
    <Drawer.Container
      style={shouldToggleVisibility ? {visibility: isOpen ? 'visible' : 'hidden'} : {}}
    >
      <Drawer.Header className={styles.drawerHeader}>
        <Drawer.Layout>
          <Drawer.LayoutItem width="fill">{header}</Drawer.LayoutItem>
        </Drawer.Layout>
      </Drawer.Header>
      <Drawer.Body>
        {body}
        {footer && <Box marginTop="20">{footer}</Box>}
      </Drawer.Body>
    </Drawer.Container>
  );
};
