import {List} from '@dropbox/dig-components/dist/list';
import {Box} from '@dropbox/dig-foundations';
import {useQuery} from '@tanstack/react-query';
import {NotificationService, NotificationWithContent} from 'client';
import {Eyebrow} from 'components/DSYS/Eyebrow';
import {PageSpinner} from 'components/shared/PageSpinner';
import {dateToGroup} from 'helpers/groupByDate';
import {Fragment, useEffect} from 'react';
import {getService} from 'utilities';
import {queryClient} from 'views/QueryClientWrapper';

import {NotificationEmptyState} from './NotificationEmptyState';
import {NotificationRenderer} from './NotificationRenderer';

const groupedByMonth = (arr?: NotificationWithContent[]) =>
  arr?.reduce((acc: Record<string, NotificationWithContent[]>, item) => {
    const groupName = dateToGroup(item.updated_at);

    // eslint-disable-next-line no-param-reassign
    acc[groupName] = acc[groupName] || [];
    acc[groupName].push(item);

    return acc;
  }, {});

export const NotificationArchive = ({handleSelection}: {handleSelection: () => void}) => {
  const {data, isLoading} = useQuery({
    queryKey: ['notifications', 'archived'],
    queryFn: getService(NotificationService).archivedApiV1NotificationsArchivedGet,
  });
  const grouped = groupedByMonth(data);

  useEffect(() => {
    queryClient.invalidateQueries({queryKey: ['notifications', 'archived']});
  }, []);

  return (
    <>
      {!grouped ? null : (
        <Box paddingTop="12">
          {Object.entries(grouped).map(([title, notifications]) => (
            <Fragment key={title}>
              <Box paddingX="16">
                <Eyebrow>{title}</Eyebrow>
              </Box>
              <List padding="0">
                {notifications.map((item, index) => (
                  <NotificationRenderer
                    key={item.id}
                    notification={item}
                    separator={index < notifications.length - 1}
                    onClick={handleSelection}
                  />
                ))}
              </List>
            </Fragment>
          ))}
        </Box>
      )}

      {isLoading && <PageSpinner />}
      {!data?.length && !isLoading && <NotificationEmptyState type="archive" />}
    </>
  );
};
