import {Box} from '@dropbox/dig-foundations';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {pulseUserAtom} from 'atoms/auth';
import {Employee} from 'client';
import {GoalsList} from 'components/goals/GoalsList';
import {DEFAULT_TIMEFRAME} from 'constant';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {GoalsHeader as GoalsHeaderV2} from 'views/goals_v2/GoalsHeader';

import styles from './ProfileBody.module.css';

export const ProfileGoals = ({employee}: {employee: Employee}) => {
  const location = useLocation();
  const pulseUser = useAtomValue(pulseUserAtom);
  const navigate = useNavigate();
  const [timeframe, setTimeframe] = useState<string>(
    location.state?.timeframe ?? DEFAULT_TIMEFRAME
  );
  const handleMyTimeframeChange = (selectedQuarter: string) => setTimeframe(selectedQuarter);

  const profileActionLog = (action: string) =>
    analyticsLogger().logEvent('PROFILE_INTERACTION', {action});

  useEffect(() => {
    if (location.state?.timeframe !== timeframe) {
      navigate('.', {state: {...location.state, timeframe}, replace: true});
    }
  }, [timeframe, location, navigate]);

  return (
    <Box
      className={styles.goals}
      paddingX="20"
      paddingY="16"
      borderWidth="1"
      borderColor="Border Subtle"
      borderRadius="Medium"
      borderStyle="Solid"
    >
      <GoalsHeaderV2
        timeframe={timeframe}
        setTimeframe={handleMyTimeframeChange}
        containerStyleProps={{marginRight: '8px'}}
        titleOverride={t('goals')}
        disableCountHeader={true}
        disableStickyHeader={true}
      />
      <GoalsList
        type={employee.email === pulseUser?.email ? 'self' : 'employee'}
        ldaps={[employee.ldap]}
        timeframe={timeframe}
        onClick={profileActionLog}
      />
    </Box>
  );
};
