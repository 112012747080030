import {Button} from '@dropbox/dig-components/dist/buttons';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {generateQuarterData} from 'constant';
import {t} from 'i18next';

import styles from './TimeframeSelector.module.css';

interface Props {
  selectedTimeframe: string;
  setSelectedTimeframe: (value: string) => void;
}

export const TimeframeSelector = ({selectedTimeframe, setSelectedTimeframe}: Props) => {
  const quarterData = generateQuarterData();

  const formatSubTitle = (timeframe: string, dateRange: string, index: number) => {
    if (index === 0) {
      // return the year part of the date range
      return timeframe.split(' ')[1].replace('FY', '20');
    } else if (index === 1) {
      // return the second part of the dataRange
      return `Ends ${dateRange.split(' - ')[1]}`;
    } else {
      return '';
    }
  };

  const formatTimeframeLabel = (timeframe: string) => {
    const [quarter] = timeframe.split(' ');
    return `${quarter}`;
  };

  return (
    <Menu.Wrapper onSelection={(value) => setSelectedTimeframe(value)} slideSubmenu>
      {({getContentProps, getTriggerProps}) => (
        <>
          <Box
            as={Button}
            {...getTriggerProps()}
            variant="borderless"
            withDropdownIcon
            style={{marginLeft: -8, marginTop: -4}}
          >
            {selectedTimeframe.split(' ')[0]} {selectedTimeframe.split(' ')[1].replace('FY', '20')}
          </Box>
          <Menu.Content {...getContentProps()}>
            <Menu.Segment>
              {quarterData.currentQuarters.map(({timeframe, dateRange}: any, index: number) => (
                <Menu.SelectItem
                  key={timeframe}
                  value={timeframe}
                  withTitle={<Text>{formatTimeframeLabel(timeframe)}</Text>}
                  withRightAccessory={
                    <Text size="small" color="faint">
                      {formatSubTitle(timeframe, dateRange, index)}
                    </Text>
                  }
                  withLeftAccessory={undefined}
                >
                  <Text size="small" isBold></Text>
                </Menu.SelectItem>
              ))}
            </Menu.Segment>
            <Menu.Segment className={styles.menuWrapper}>
              <Menu.Submenu withTriggerContent={t('previous_quarters')}>
                <Menu.Segment className={styles.menuWrapper}>
                  {Object.values(quarterData.previousQuarters)
                    .flat()
                    .map(({timeframe}, index, array) => {
                      const isLast = index === array.length - 1;
                      return (
                        <Menu.SelectItem
                          key={timeframe}
                          value={timeframe}
                          withTitle={<Text>{formatTimeframeLabel(timeframe)}</Text>}
                          withRightAccessory={
                            <Text size="small" color="faint">
                              {!isLast ? '' : timeframe.split(' ')[1].replace('FY', '20')}
                            </Text>
                          }
                          withLeftAccessory={undefined}
                        ></Menu.SelectItem>
                      );
                    })}

                  {Object.entries(quarterData.archivedQuarters).map(([year, quarters]) => (
                    <Menu.Submenu key={year} withTriggerContent={year}>
                      <Menu.Segment>
                        {quarters.map(({timeframe, dateRange}) => (
                          <Menu.SelectItem
                            key={timeframe}
                            value={timeframe}
                            withTitle={<Text>{formatTimeframeLabel(timeframe)}</Text>}
                            withRightAccessory={
                              <Text size="small" color="faint">
                                {dateRange}
                              </Text>
                            }
                            withLeftAccessory={undefined}
                          />
                        ))}
                      </Menu.Segment>
                    </Menu.Submenu>
                  ))}
                </Menu.Segment>
              </Menu.Submenu>
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};
