import * as React from 'react';

import styles from './RightSideBar.module.css';

export type RightSideBarProps = {
  children?: React.ReactNode;
};

const RightSideBar = ({children}: RightSideBarProps) => (
  <div className={styles.container}>{children}</div>
);

export {RightSideBar};
