import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {MoreHorizontalLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {GoalData, GoalRead} from 'client';
import {t} from 'i18next';

export const ExpandGoalMenu = ({
  goal,
  parentGoal,
  showViewParentGoalAction,
  hasHiddenKeyResults,
  setShowAllKeyResults,
  handleSetExpandedParentGoals,
}: {
  goal: GoalData;
  parentGoal: GoalRead | null | undefined;
  showViewParentGoalAction: boolean;
  hasHiddenKeyResults: boolean;
  setShowAllKeyResults: (showAllKeyResults: boolean) => void;
  handleSetExpandedParentGoals: (row: GoalData, parentGoal: GoalRead) => void;
}) => {
  return (
    <Menu.Wrapper
      onSelection={(action: string, event: React.SyntheticEvent) => {
        if (action === 'show_all_key_results') {
          setShowAllKeyResults(true);
          analyticsLogger().logEvent('GOAL_TABLE_SHOW_ALL_KEY_RESULTS_CLICKED');
        } else if (action === 'view_parent_goal') {
          if (parentGoal) {
            handleSetExpandedParentGoals(goal, parentGoal);
            analyticsLogger().logEvent('GOAL_TABLE_VIEW_PARENT_GOAL_CLICKED');
          }
        }
        event.stopPropagation();
      }}
      closeOnBlur={true}
      closeOnBlurWhenDefaultPrevented={true}
      onClick={(e: React.SyntheticEvent) => {
        e.stopPropagation();
      }}
      onMouseLeave={(e: React.SyntheticEvent) => {
        e.stopPropagation();
      }}
    >
      {({getContentProps, getTriggerProps}) => (
        <Box marginLeft="4">
          <IconButton
            {...getTriggerProps()}
            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              const onClick = getTriggerProps().onClick;
              if (onClick) {
                onClick(e);
              }
              e.stopPropagation();
            }}
            onMouseDown={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              const onMouseDown = getTriggerProps().onMouseDown;
              if (onMouseDown) {
                onMouseDown(e);
              }
              e.stopPropagation();
            }}
            onMouseUp={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              const onMouseUp = getTriggerProps().onMouseUp;
              if (onMouseUp) {
                onMouseUp(e);
              }
              e.stopPropagation();
            }}
            variant="borderless"
            shape="circular"
            size="small"
          >
            <UIIcon src={MoreHorizontalLine} />
          </IconButton>
          <Menu.Content {...getContentProps()}>
            <Menu.Segment>
              {showViewParentGoalAction && (
                <Menu.ActionItem key="view_parent_goal" value="view_parent_goal">
                  {t('view_parent_goal')}
                </Menu.ActionItem>
              )}
              {hasHiddenKeyResults && (
                <Menu.ActionItem key="show_all_key_results" value="show_all_key_results">
                  {t('show_all_children')}
                </Menu.ActionItem>
              )}
            </Menu.Segment>
          </Menu.Content>
        </Box>
      )}
    </Menu.Wrapper>
  );
};
