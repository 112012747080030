import {Tooltip, TooltipProps} from '@dropbox/dig-components/dist/tooltips';
import {useMobile} from 'hooks/isMobile';
import {ReactNode, useRef, useState} from 'react';

// Helper component to optionally show a tooltip if title is specified
export const OptionalTooltip = ({
  enabled,
  openDelay,
  placement = 'top',
  title,
  children,
}: {
  enabled: boolean;
  openDelay?: number;
  placement?: TooltipProps['placement'];
  title?: ReactNode;
  children: ReactNode;
}) => {
  const triggerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMobile();

  return enabled && title && !isMobile ? (
    <>
      <Tooltip.Control
        open={Boolean(enabled && isOpen)}
        triggerRef={triggerRef}
        placement={placement}
        openDelay={openDelay}
      >
        {title}
      </Tooltip.Control>
      <span
        ref={triggerRef}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        {children}
      </span>
    </>
  ) : (
    <>{children}</>
  );
};
