import {Button} from '@dropbox/dig-components/dist/buttons';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {TextArea} from '@dropbox/dig-components/dist/text_fields';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {snackbarAtom} from 'atoms/snackbar';
import {Employee, EmployeeService} from 'client';
import {Link} from 'components/DSYS/Link';
import {t} from 'i18next';
import {useSetAtom} from 'jotai';
import {useCallback, useEffect, useState} from 'react';
import {getService} from 'utilities';

export const KudosModal = ({
  employee,
  open,
  closeModal,
}: {
  employee: Employee;
  open: boolean;
  closeModal: () => void;
}) => {
  const [kudosMessage, setKudosMessage] = useState('');
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const firstName = employee.name.split(' ')[0];

  const sendKudos = useCallback(() => {
    getService(EmployeeService).kudosApiV1PeopleKudosLdapPost(employee.ldap, kudosMessage);
    setSnackbarMessage({text: 'Posted on #kudos.'});
    closeModal();
  }, [closeModal, employee.ldap, kudosMessage, setSnackbarMessage]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (kudosMessage && (e.ctrlKey || e.metaKey) && e.key === 'Enter') {
        sendKudos();
      }
    };

    window.addEventListener('keydown', handleKeyDown, true);

    return () => window.removeEventListener('keydown', handleKeyDown, true);
  }, [kudosMessage, sendKudos]);

  return (
    <Modal
      open={open}
      isCentered
      width="small"
      withCloseButton="Close"
      aria-label="Send kudos!"
      onRequestClose={closeModal}
    >
      <Modal.Header>
        <Modal.Title>
          Give kudos to {firstName}
          <Box as={Text} size="small" color="Text Subtle" paddingTop="4" display="block">
            Your appreciation message for {firstName} will be posted on Slack on the{' '}
            <Box
              as={Link}
              color="Text Subtle"
              to="https://dropbox.slack.com/app_redirect?channel=C0DVA544F"
            >
              #kudos
            </Box>{' '}
            channel.
          </Box>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TextArea
          autoFocus
          placeholder="Write an appreciation message..."
          value={kudosMessage}
          onChange={(e) => setKudosMessage(e.currentTarget.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="opacity" onClick={closeModal}>
          {t('cancel')}
        </Button>
        <Button variant="primary" onClick={sendKudos} disabled={!kudosMessage}>
          {t('give_kudos')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
