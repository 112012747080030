import {Table} from '@dropbox/dig-components/dist/table';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack} from '@dropbox/dig-foundations';
import {
  DialLockMini,
  EndToEndStackMini,
  LaptopMini,
  PuzzlePiecesMini,
  RacingFlagMini,
} from '@dropbox/dig-illustrations';
import {ButtonLink} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import {Header} from 'components/shared/table/Header';
import {ColumnConfig} from 'components/shared/table/useColumnResize';
import {ROUTE_PATHS} from 'constant';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

import styles from './EmptyGoalsTable.module.css';

export const EmptyGoalsTable = ({
  withTitle,
  columnConfigs,
  containerProps = {},
  showHeader = true,
}: {
  withTitle?: string;
  columnConfigs: ColumnConfig[];
  showHeader?: boolean;
  containerProps?: {[key: string]: any};
}) => {
  const {t} = useTranslation();
  return (
    <Box
      as="div"
      paddingX="24"
      paddingY="20"
      borderRadius="Medium"
      borderColor="Border Subtle"
      borderStyle="Solid"
      borderWidth="1"
      maxWidth="100%"
      marginBottom="12"
      marginX="auto"
      style={{width: '100%', overflowX: 'auto', marginTop: 0}}
      {...containerProps}
    >
      {withTitle ? (
        <Box
          as={Title}
          id={withTitle ?? undefined}
          size={18}
          // cursor="pointer"
          paddingBottom="4"
          display="block"
        >
          {withTitle}
        </Box>
      ) : null}
      <Table hasDividers={false} spacing="small" verticalAlign="center">
        {showHeader && (
          <Header
            columnConfigs={columnConfigs}
            sort={undefined}
            setSort={() => {}}
            getMouseDownHandler={() => () => {}}
          />
        )}
        <Table.Body>
          {showHeader && <Box as="div" style={{height: 8}} />}
          <Table.Row>
            <Box paddingLeft="2" paddingRight="2" paddingTop="8" paddingBottom="8">
              <Box
                borderLeft="Solid"
                borderColor="Border Subtle"
                paddingTop="2"
                paddingBottom="2"
                paddingLeft="16"
                paddingRight="16"
                height="max-content"
              >
                <Box as={Text} display="block" isBold variant="paragraph">
                  {t('no_results_found')}
                </Box>
                <Box as={Text} color="Text Subtle" size="small" variant="paragraph">
                  {t('no_goals_generic')}
                </Box>
              </Box>
            </Box>
          </Table.Row>
        </Table.Body>
      </Table>
    </Box>
  );
};

export const EmptyErrorGoalsTable = ({
  containerProps,
  subtitleStyleProps,
}: {
  containerProps?: {[key: string]: any};
  subtitleStyleProps?: {[key: string]: any};
}) => {
  const {t} = useTranslation();
  return (
    <EmptyGoalsTableComponent
      icon={
        <Box className={styles.laptop}>
          <LaptopMini altText="laptop" width={64} height={64} />
        </Box>
      }
      title={t('no_goals_available_error')}
      subtitle={t('no_goals_available_error_subtitle')}
      containerProps={containerProps}
      subtitleStyleProps={subtitleStyleProps}
    />
  );
};

export const EmptyMyGoalsTable = ({
  timeframe,
  containerProps,
  subtitleStyleProps,
  delegateId,
}: {
  timeframe: string;
  containerProps?: {[key: string]: any};
  subtitleStyleProps?: {[key: string]: any};
  delegateId?: string;
}) => {
  const {t} = useTranslation();
  return (
    <EmptyGoalsTableComponent
      icon={
        <Box className={styles.racingFlag}>
          <RacingFlagMini altText="racing flag" width={64} height={64} />
        </Box>
      }
      title={t('ready_set_goal')}
      subtitle={t('no_my_goals_defined_subtitle')}
      buttonCta={{
        text: t('add_goal'),
        to: ROUTE_PATHS.GOALS_V2_ONBOARDING,
        state: {timeframe, source: 'table-my-empty', delegateId},
      }}
      containerProps={containerProps}
      subtitleStyleProps={subtitleStyleProps}
    />
  );
};

export const EmptyGoalsforPreviousQuarterTable = ({
  containerProps,
  subtitleStyleProps,
}: {
  containerProps?: {[key: string]: any};
  subtitleStyleProps?: {[key: string]: any};
}) => {
  const {t} = useTranslation();
  return (
    <EmptyGoalsTableComponent
      icon={
        <Box className={styles.laptop}>
          <LaptopMini altText="laptop" width={64} height={64} />
        </Box>
      }
      title={t('no_goals_defined_previous_quarter')}
      subtitle={t('no_goals_available_for_this_quarter')}
      containerProps={containerProps}
      subtitleStyleProps={subtitleStyleProps}
    />
  );
};

export const EmptyOthersGoalsTable = ({
  containerProps,
  subtitleStyleProps,
}: {
  containerProps?: {[key: string]: any};
  subtitleStyleProps?: {[key: string]: any};
}) => {
  const {t} = useTranslation();
  return (
    <EmptyGoalsTableComponent
      icon={
        <Box className={styles.puzzlePieces}>
          <PuzzlePiecesMini altText="puzzle pieces" width={64} height={64} />
        </Box>
      }
      title={t('no_others_goals_defined')}
      subtitle={t('no_others_goals_defined_subtitle')}
      containerProps={containerProps}
      subtitleStyleProps={subtitleStyleProps}
    />
  );
};

export const ConfidentialGoalsTable = ({
  containerProps,
  subtitleStyleProps,
}: {
  containerProps?: {[key: string]: any};
  subtitleStyleProps?: {[key: string]: any};
}) => {
  const {t} = useTranslation();
  return (
    <EmptyGoalsTableComponent
      icon={
        <Box className={styles.dialLock}>
          <DialLockMini altText="dial lock" width={64} height={64} />
        </Box>
      }
      title={t('goals_confidential')}
      subtitle={t('goals_confidential_subtitle')}
      containerProps={containerProps}
      subtitleStyleProps={subtitleStyleProps}
    />
  );
};

const EmptyGoalsTableComponent = ({
  icon,
  title,
  subtitle,
  buttonCta,
  containerProps,
  subtitleStyleProps,
}: {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  buttonCta?: {
    text: string;
    to?: string;
    state?: {[key: string]: any};
    onClick?: () => void;
  };
  containerProps?: {[key: string]: any};
  subtitleStyleProps?: {[key: string]: any};
}) => {
  const location = useLocation();
  const currentPath = `${location.pathname}${location.search}`;
  return (
    <Box
      as="div"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderColor="Border Subtle"
      borderStyle="Solid"
      borderWidth="1"
      borderRadius="Small"
      padding="24"
      {...containerProps}
    >
      <Split direction="vertical" alignX="center" alignY="center">
        <Split.Item>
          <Box display={'flex'} alignItems="center" justifyContent="center">
            {icon}
          </Box>
        </Split.Item>
        <Split.Item>
          <Stack display="flex" justify="center" align="center" gap="8" paddingBottom="8">
            <Box as={Text} isBold display="block" variant="paragraph" size="large">
              {title}
            </Box>
            <Box
              as={Text}
              display="block"
              textAlign="center"
              color="Text Subtle"
              variant="paragraph"
              style={subtitleStyleProps}
            >
              {subtitle}
            </Box>
          </Stack>
        </Split.Item>
        {buttonCta && (
          <Split.Item>
            <Box display={'flex'} alignItems="center" justifyContent="center">
              <ButtonLink
                variant="opacity"
                size="standard"
                to={buttonCta.to || currentPath}
                state={buttonCta.state}
                onClick={buttonCta.onClick}
              >
                {buttonCta.text}
              </ButtonLink>
            </Box>
          </Split.Item>
        )}
      </Split>
    </Box>
  );
};

export const NoFilterTable = ({onButtonClick}: {onButtonClick: () => void}) => {
  const {t} = useTranslation();
  return (
    <EmptyGoalsTableComponent
      icon={
        <Box className={styles.stackMini}>
          <EndToEndStackMini altText="end to end stack" width={64} height={64} />
        </Box>
      }
      title={t('no_filter_applied')}
      subtitle={t('no_filter_applied_subtitle')}
      buttonCta={onButtonClick ? {text: t('apply_filters'), onClick: onButtonClick} : undefined}
    />
  );
};

export const NoResultsTable = () => {
  const {t} = useTranslation();
  return (
    <EmptyGoalsTableComponent
      icon={
        <Box className={styles.stackMini}>
          <EndToEndStackMini altText="end to end stack" width={64} height={64} />
        </Box>
      }
      title={t('no_results_found')}
      subtitle={t('no_results_found_subtitle')}
    />
  );
};
