import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {Suspense} from 'react';
import {BrowseView} from 'views/gratitude/BrowseView';

const Gratitude = () => {
  useDocumentTitle(t('gratitude'));
  return (
    <>
      <Suspense fallback={null}>
        <BrowseView />
      </Suspense>
    </>
  );
};

export {Gratitude};
