import type {Dropbox} from '@dropbox/api-v2-client';

interface Group {
  title: string;
  items?: {
    icon?: string;
    icon_tag?: string;
  }[];
}

const fetchWaffleData = async (client: Dropbox) => {
  const response = await client.waffleGetWaffle({
    has_groups: true,
  });

  return response?.result || {};
};

const processWaffleMenuItems = (result: any) => {
  if (!Array.isArray(result?.items)) {
    return [];
  }

  return result.items;
};

const processWaffleGroupItems = (result: any) => {
  if (!Array.isArray(result?.groups)) {
    return [];
  }

  return result.groups
    .filter((group: Group) => group.items && group.items.length > 0)
    .map((group: Group) => {
      return {
        title: group.title,
        items: group.items?.map((item) => ({
          ...item,
          ...(item.icon ? {icon: item.icon} : {iconTag: item.icon_tag || ''}),
        })),
      };
    });
};

export {fetchWaffleData, processWaffleGroupItems, processWaffleMenuItems};
