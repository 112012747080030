import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split} from '@dropbox/dig-foundations';
import {PulseGrouping} from 'client';
import {Link} from 'components/DSYS/Link';

import {toPulseGroup} from './utils';

type CrumbData = {name: string | null; slug: string | null};

const hardcodedRoots = ['people-team', 'office-of-the-ceo-team'];

const Crumb = ({
  root,
  data,
  disabled,
  active,
}: {
  root: string;
  data?: CrumbData;
  disabled?: boolean;
  active?: boolean;
}) =>
  !data ? null : (
    <Box as={Split.Item} flexShrink={0} overflow="hidden" whiteSpace="nowrap">
      <Box as={Text} size="large" color="Border Subtle" marginRight="8">
        /
      </Box>
      {active ? (
        <Box as={Text} color="Text Base" isBold>
          {data.name}
        </Box>
      ) : (
        <Box as={Text} color="Border Bold">
          <Link
            monochromatic
            hasNoUnderline
            showUnderlineOnHover
            to={
              disabled || hardcodedRoots.includes(data.slug ?? '') ? root : `${root}/${data.slug}`
            }
            state={{source: 'breadcrumb'}}
          >
            {data.name}
          </Link>
        </Box>
      )}
    </Box>
  );

export const PulseBreadcrumbs = ({
  root,
  team,
  grouping,
}: {
  root: string;
  team: {name: string; slug: string};
  grouping?: PulseGrouping;
}) => (
  <Box as={Split} alignY="center" gap="8" wrap="wrap" overflow="hidden">
    <Split.Item>
      <Box as={Text} color="Border Bold">
        <Link
          to={root}
          hasNoUnderline
          showUnderlineOnHover
          monochromatic
          state={{source: 'breadcrumb'}}
        >
          Pulse
        </Link>
      </Box>
    </Split.Item>
    <Crumb root={root} data={team} active={!grouping} />
    {grouping && <Crumb root={root} data={toPulseGroup(grouping)} active disabled />}
  </Box>
);
