import {
  KEY_RESULT_INVALID_ACHIEVABLE_DESCRIPTION,
  KEY_RESULT_INVALID_ACHIEVABLE_TITLE,
  KEY_RESULT_INVALID_CONCRETE_DESCRIPTION,
  KEY_RESULT_INVALID_CONCRETE_TITLE,
  KEY_RESULT_INVALID_DESCRIPTION,
  KEY_RESULT_INVALID_MEASURABLE_DESCRIPTION,
  KEY_RESULT_INVALID_MEASURABLE_TITLE,
  KEY_RESULT_INVALID_TITLE,
  KEY_RESULT_VALID_ACHIEVABLE_DESCRIPTION,
  KEY_RESULT_VALID_ACHIEVABLE_TITLE,
  KEY_RESULT_VALID_CONCRETE_DESCRIPTION,
  KEY_RESULT_VALID_CONCRETE_TITLE,
  KEY_RESULT_VALID_DESCRIPTION,
  KEY_RESULT_VALID_MEASURABLE_DESCRIPTION,
  KEY_RESULT_VALID_MEASURABLE_TITLE,
  KEY_RESULT_VALID_TITLE,
  OBJECTIVE_INVALID_ACTION_DESCRIPTION,
  OBJECTIVE_INVALID_ACTION_TITLE,
  OBJECTIVE_INVALID_DESCRIPTION,
  OBJECTIVE_INVALID_IMPORTANCE_DESCRIPTION,
  OBJECTIVE_INVALID_IMPORTANCE_TITLE,
  OBJECTIVE_INVALID_OUTCOME_DESCRIPTION,
  OBJECTIVE_INVALID_OUTCOME_TITLE,
  OBJECTIVE_INVALID_TITLE,
  OBJECTIVE_VALID_ACTION_DESCRIPTION,
  OBJECTIVE_VALID_ACTION_TITLE,
  OBJECTIVE_VALID_DESCRIPTION,
  OBJECTIVE_VALID_IMPORTANCE_DESCRIPTION,
  OBJECTIVE_VALID_IMPORTANCE_TITLE,
  OBJECTIVE_VALID_OUTCOME_DESCRIPTION,
  OBJECTIVE_VALID_OUTCOME_TITLE,
  OBJECTIVE_VALID_TITLE,
} from 'strings';

import {GeneratedObjectiveResult, OKREntry, OKREntryDetailStatus, OKREntryStatus} from './types';

// Transfrom the generated objective response to OKREntry
export const transformObjectiveToOKREntry = (result: GeneratedObjectiveResult): OKREntry => {
  const isValidResult = result['result'] === 'yes' ? true : false;
  const status = isValidResult ? OKREntryStatus.SUCCESS : OKREntryStatus.FAILED;
  const title = isValidResult ? OBJECTIVE_VALID_TITLE : OBJECTIVE_INVALID_TITLE;
  const description = isValidResult ? OBJECTIVE_VALID_DESCRIPTION : OBJECTIVE_INVALID_DESCRIPTION;

  const details = [
    {
      title: isValidResult ? OBJECTIVE_VALID_ACTION_TITLE : OBJECTIVE_INVALID_ACTION_TITLE,
      description: isValidResult
        ? OBJECTIVE_VALID_ACTION_DESCRIPTION
        : OBJECTIVE_INVALID_ACTION_DESCRIPTION,
      iconColor: '#F77751',
      status: isValidResult ? OKREntryDetailStatus.SUCCESS : OKREntryDetailStatus.FAILED,
      imageName: 'action',
    },
    {
      title: isValidResult ? OBJECTIVE_VALID_OUTCOME_TITLE : OBJECTIVE_INVALID_OUTCOME_TITLE,
      description: isValidResult
        ? OBJECTIVE_VALID_OUTCOME_DESCRIPTION
        : OBJECTIVE_INVALID_OUTCOME_DESCRIPTION,
      iconColor: '#624894',
      status: isValidResult ? OKREntryDetailStatus.SUCCESS : OKREntryDetailStatus.FAILED,
      imageName: 'outcome',
    },
    {
      title: isValidResult ? OBJECTIVE_VALID_IMPORTANCE_TITLE : OBJECTIVE_INVALID_IMPORTANCE_TITLE,
      description: isValidResult
        ? OBJECTIVE_VALID_IMPORTANCE_DESCRIPTION
        : OBJECTIVE_INVALID_IMPORTANCE_DESCRIPTION,
      iconColor: '#9FC418',
      status: isValidResult ? OKREntryDetailStatus.SUCCESS : OKREntryDetailStatus.FAILED,
      imageName: 'importance',
    },
  ];

  return {
    status: status,
    details: details,
    title,
    description,
  };
};

// Transfrom the generated key result response to OKREntry
export const transformKeyResultToOKREntry = (result: GeneratedObjectiveResult): OKREntry => {
  const isValidResult = result['result'] === 'yes' ? true : false;
  const status = isValidResult ? OKREntryStatus.SUCCESS : OKREntryStatus.FAILED;
  const title = isValidResult ? KEY_RESULT_VALID_TITLE : KEY_RESULT_INVALID_TITLE;
  const description = isValidResult ? KEY_RESULT_VALID_DESCRIPTION : KEY_RESULT_INVALID_DESCRIPTION;

  const details = [
    {
      title: isValidResult
        ? KEY_RESULT_VALID_ACHIEVABLE_TITLE
        : KEY_RESULT_INVALID_ACHIEVABLE_TITLE,
      description: isValidResult
        ? KEY_RESULT_VALID_ACHIEVABLE_DESCRIPTION
        : KEY_RESULT_INVALID_ACHIEVABLE_DESCRIPTION,
      iconColor: '#0061FE',
      status: isValidResult ? OKREntryDetailStatus.SUCCESS : OKREntryDetailStatus.FAILED,
      imageName: 'progressiveVerb',
    },
    {
      title: isValidResult ? KEY_RESULT_VALID_CONCRETE_TITLE : KEY_RESULT_INVALID_CONCRETE_TITLE,
      description: isValidResult
        ? KEY_RESULT_VALID_CONCRETE_DESCRIPTION
        : KEY_RESULT_INVALID_CONCRETE_DESCRIPTION,
      iconColor: '#EECC5D',
      status: isValidResult ? OKREntryDetailStatus.SUCCESS : OKREntryDetailStatus.FAILED,
      imageName: 'achievable',
    },
    {
      title: isValidResult
        ? KEY_RESULT_VALID_MEASURABLE_TITLE
        : KEY_RESULT_INVALID_MEASURABLE_TITLE,
      description: isValidResult
        ? KEY_RESULT_VALID_MEASURABLE_DESCRIPTION
        : KEY_RESULT_INVALID_MEASURABLE_DESCRIPTION,
      iconColor: '#1B634B',
      status: isValidResult ? OKREntryDetailStatus.SUCCESS : OKREntryDetailStatus.FAILED,
      imageName: 'metric',
    },
  ];

  return {
    status: status,
    details: details,
    title,
    description,
  };
};
