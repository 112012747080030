import {isMimeType, mediaFileReader} from '@lexical/utils';
import {INSERT_IMAGE_COMMAND} from 'components/DSYS/editor/plugins/ImagesPlugin';
import {INSERT_VIDEO_COMMAND} from 'components/DSYS/editor/plugins/VideosPlugin';
import {LexicalEditor} from 'lexical';
import {getBackendUrl} from 'utilities';

import {$isImageNode} from './ImageNode';
import {$isVideoNode} from './VideoNode';

export const ACCEPTABLE_FILE_TYPES = [
  'image',
  'image/heic',
  'image/heif',
  'image/gif',
  'image/webp',
  'image/png',
  'image/jpeg',

  'video/mp4',
  'video/avi',
  'video/mov',
  'video/quicktime',
];

export const handleFiles = async (
  files: File[],
  uploadMedia: (data: {path: string; file: File}) => Promise<string>,
  editor: LexicalEditor
): Promise<void> => {
  const filesResult = await mediaFileReader(files, ACCEPTABLE_FILE_TYPES);

  for (const {file} of filesResult) {
    if (isMimeType(file, ACCEPTABLE_FILE_TYPES)) {
      const fileUrl = URL.createObjectURL(file);
      try {
        editor.dispatchCommand(
          file.type.startsWith('image/') ? INSERT_IMAGE_COMMAND : INSERT_VIDEO_COMMAND,
          {
            altText: file.name,
            src: fileUrl,
          }
        );

        // Upload the image file to the server
        const path = `${new Date().toISOString()}_${file.name}`;
        const url = await uploadMedia({path, file});

        // Force update to replace temporary URL with final URL
        editor.update(() => {
          const root = editor.getRootElement();
          if (root) {
            editor
              .getEditorState()
              .read(() => {
                const nodes = [];
                const nodeMap = editor.getEditorState()._nodeMap;
                for (const [, node] of nodeMap) {
                  if (($isImageNode(node) || $isVideoNode(node)) && node.getSrc() === fileUrl) {
                    nodes.push(node);
                  }
                }
                return nodes;
              })
              .forEach((imageNode) => {
                const writableNode = imageNode.getWritable();
                writableNode.__src = `${getBackendUrl()}/api/v1/news/media/${url}`;
              });
          }
        });
      } catch (error) {
        console.error('Image upload failed:', error);
        // Optionally handle the error, e.g., by removing the image node or showing an error message
      } finally {
        // Revoke the temporary URL to free up resources
        URL.revokeObjectURL(fileUrl);
      }
    } else {
      console.error('Unsupported file type:', file.type);
    }
  }
};
