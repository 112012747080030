import {useTheme} from '@dropbox/dig-foundations';
import {TargetLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {useGoalsWidget} from 'hooks/useDashboards';
import {DashboardsGoalCounts} from 'hooks/useDashboards';
import {t} from 'i18next';
import {useEffect, useRef, useState} from 'react';
import {DashboardsStatusSelectorWithInfoMessage} from 'views/dashboards/DashboardsStatusSelector';
import {getStatusGoalCountsData} from 'views/dashboards/util';
import {
  DashboardsGoalsWidgetComponent,
  DashboardsGoalsWidgetComponentProps,
  DashboardsGoalsWidgetProps,
} from 'views/dashboards/widgets/DashboardsGoalsWidgetComponent';
import {DashboardsWidgetEmptyState} from 'views/dashboards/widgets/DashboardsWidget';

const MAX_GOALS_TO_DISPLAY = 5;
const GOALS_LIST_OFFSET = 275;

const WIDGET_GOAL_STATUSES = ['missed', 'cancelled'];

export const DashboardsGoalsWidget = (
  props: DashboardsGoalsWidgetProps & {
    dashboardsGoalCounts?: DashboardsGoalCounts;
  }
) => {
  const {mode} = useTheme();
  const {teamName, teamSlug, timeframe, dashboardsGoalCounts} = props;

  const [status, setStatus] = useState<string>('missed');
  const [limit, setLimit] = useState<number>(MAX_GOALS_TO_DISPLAY);
  const [showAll, setShowAll] = useState<boolean>(false);

  const widgetStatusSelectorRef = useRef<HTMLDivElement>(null);

  const type = 'missed-and-cancelled';
  const goalsWidgetSelectorRef = useRef<HTMLDivElement>(null);
  const {isFetched, goalsByStatus, totalGoalsCount} = useGoalsWidget({
    type,
    teamSlug,
    timeframe,
    statuses: WIDGET_GOAL_STATUSES,
    enabled: true,
  });

  useEffect(() => {
    // Reset widget whenever team or timeframe changes
    setLimit(MAX_GOALS_TO_DISPLAY);
    setShowAll(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamSlug, timeframe]);

  const goals = goalsByStatus[status] ?? null;
  useEffect(() => {
    if (showAll && isFetched && goals?.length) {
      setLimit(goals.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAll]);

  const goalsToDisplay = goals?.slice(0, limit) ?? null;
  useEffect(() => {
    // Scroll to bottom when goals list is fully rendered
    const element = goalsWidgetSelectorRef.current;
    if (element && showAll) {
      const rect = element.getBoundingClientRect();
      window.scrollBy({top: rect.top - GOALS_LIST_OFFSET, behavior: 'smooth'});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalsToDisplay?.length]);

  const remainingCount = goals?.length && goals.length > limit ? goals.length - limit : 0;
  const totalGoalCountsData = getStatusGoalCountsData(
    status,
    mode,
    dashboardsGoalCounts?.total_counts
  );
  const widgetProps: DashboardsGoalsWidgetComponentProps = {
    ...props,
    type,
    title: t('missed_and_cancelled'),
    subtitle: t('missed_and_cancelled_subtitle'),
    icon: TargetLine,
    status,
    goals: goalsToDisplay,
    totalGoalsCount,
    goalsWidgetSelectorRef,
    showAll,
    showAllCount: !showAll ? remainingCount : undefined,
    setShowAll: () => {
      analyticsLogger().logEvent('DASHBOARD_WIDGET_CLICKED', {type, status, event: 'show_more'});
      setShowAll(true);
    },
    statusSelectorWithInfoMessage: !!totalGoalsCount && (
      <DashboardsStatusSelectorWithInfoMessage
        type={type}
        timeframe={timeframe}
        teamName={teamName}
        teamSlug={teamSlug ?? ''}
        statusSelectorRef={widgetStatusSelectorRef}
        selectedStatus={status}
        setSelectedStatus={(newStatus: string) => {
          analyticsLogger().logEvent('DASHBOARD_WIDGET_CLICKED', {
            type,
            status: newStatus,
            event: 'status',
          });
          setStatus(newStatus);
        }}
        source="widget"
        totalGoalCountsData={totalGoalCountsData}
        statusesOverride={WIDGET_GOAL_STATUSES}
      />
    ),
    emptyState: totalGoalsCount ? (
      <DashboardsWidgetEmptyState
        title={t('missed_and_cancelled_empty_state', {team: teamName})}
        subtitle={t('goals_widget_no_results_found_subtitle', {
          status: t(status).toLowerCase(),
        })}
      />
    ) : (
      <DashboardsWidgetEmptyState
        title={t('missed_and_cancelled_empty_state', {team: teamName})}
        subtitle={t('missed_and_cancelled_empty_state_subtitle')}
      />
    ),
  };
  return <DashboardsGoalsWidgetComponent {...widgetProps} />;
};
