import {Button} from '@dropbox/dig-components/dist/buttons';
import {LookMagnifyingGlassSpot} from '@dropbox/dig-illustrations';
import {NonIdealState} from 'components/shared/NonIdealState';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';

const NotFound = () => {
  useDocumentTitle(t('not_found'));
  return (
    <>
      <NonIdealState
        image={<LookMagnifyingGlassSpot />}
        title={t('we_cant_find_the_page_youre_looking_for')}
        description={t('check_the_url_and_try_again')}
        action={
          <Button href={window.location.origin} variant="primary">
            {t('go_back_home')}
          </Button>
        }
      />
    </>
  );
};

export {NotFound};
