import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';

import {useNewsCreate, useThumbnailCreate} from './hooks';
import {NewsCreate} from './NewsCreate';

export const NewsCreateView = () => {
  useDocumentTitle(t('new_post'));

  const {createNewsPost, isPending} = useNewsCreate();
  const {uploadThumbnail} = useThumbnailCreate();

  return (
    <NewsCreate
      onSubmit={({data}) => createNewsPost({data})}
      uploadThumbnail={(id: number, file: File) => uploadThumbnail({id: id, file: file})}
      isLoading={isPending}
    />
  );
};
