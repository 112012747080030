import {useSuspenseQuery} from '@tanstack/react-query';
import {EmployeeService} from 'client';
import {getService} from 'utilities';

export const usePeople = () => {
  const {data} = useSuspenseQuery({
    queryKey: ['people'],
    queryFn: () => getService(EmployeeService).getPeopleApiV1PeopleGet(new Date().toString()),
    staleTime: 1000 * 60 * 60 * 8, // 8 hours
  });

  if (!data) {
    return {birthdays: [], droplets: [], anniversaries: [], interns: []};
  }

  return data;
};
