import styles from './MainContent.module.css';

export type MainContentProps = {
  children?: React.ReactNode;
};

const MainContent = ({children}: MainContentProps) => (
  <>
    <div className={styles.container}>{children}</div>
  </>
);

export {MainContent};
