import {Button} from '@dropbox/dig-components/dist/buttons';

import styles from './EditSaveButtons.module.css';

export const EditSaveButtons = ({
  cta,
  disableSave,
  isLoading,
  handleCancelClick,
  handleSaveClick,
  containerStyleProps = {},
}: {
  cta: string;
  disableSave: boolean;
  isLoading: boolean;
  handleCancelClick: () => void;
  handleSaveClick: () => void;
  containerStyleProps?: {[key: string]: any};
}) => {
  return (
    <div className={styles.keyResultActionContainer} style={containerStyleProps}>
      <div className={styles.keyResultActionItemContainer}>
        <Button
          variant="opacity"
          size="medium"
          fullWidth={false}
          className={styles.cancelButton}
          onClick={handleCancelClick}
          disabled={isLoading}
        >
          {'Cancel'}
        </Button>
        <Button
          isLoading={isLoading}
          variant="primary"
          size="medium"
          fullWidth={false}
          className={styles.addKeyResultButton}
          onClick={handleSaveClick}
          disabled={disableSave}
        >
          {cta}
        </Button>
      </div>
    </div>
  );
};
