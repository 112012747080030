import {ProgressBar} from '@dropbox/dig-components/dist/progress_indicators';
import {Text} from '@dropbox/dig-components/dist/typography';
import cx from 'classnames';
import {isNumeric} from 'helpers/utils';

import styles from './MetricBar.module.css';

export interface MetricBarProps {
  currentValue?: string | null;
  targetValue?: string | null;
  accessoryRight?: React.ReactNode;
  className?: string;
}

export const MetricBar = ({
  currentValue,
  targetValue,
  accessoryRight,
  className,
}: MetricBarProps) => {
  const formattedValue = (value: string) => {
    let valueStr = value.toString();
    valueStr = valueStr.replace('%', '').replace(',', '.');
    return valueStr;
  };

  const formattedProgressBarValue = (value: number) => {
    return Number.isInteger(value) ? value.toString() : value.toFixed(2);
  };

  if (!currentValue?.length || !targetValue?.length) {
    return null;
  }

  const formattedCurrentValue = formattedValue(currentValue);
  const formattedTargetValue = formattedValue(targetValue);

  if (!isNumeric(formattedCurrentValue) || !isNumeric(formattedTargetValue)) {
    return (
      <Text color="faint" size="small" className={className}>
        {formattedCurrentValue}/{formattedTargetValue}
        {accessoryRight && <span className={styles.accessoryRight}>{accessoryRight}</span>}
      </Text>
    );
  }

  let progressBarValue = 0;

  if (
    !isNaN(Number(formattedCurrentValue)) &&
    !isNaN(Number(formattedTargetValue)) &&
    Number(formattedTargetValue) !== 0
  ) {
    progressBarValue = (Number(formattedCurrentValue) / Number(formattedTargetValue)) * 100;
  }

  return (
    <div className={cx(styles.progressWrapper, className)}>
      <ProgressBar
        className={cx(styles.progressBar, {
          [styles.complete]: progressBarValue >= 100,
        })}
        value={progressBarValue}
        isStatic
        title={formattedProgressBarValue(progressBarValue) + '%'}
      />
      <Text color="faint" size="small">
        {currentValue}/{targetValue}
        {accessoryRight && <span className={styles.accessoryRight}>{accessoryRight}</span>}
      </Text>
    </div>
  );
};
