import {Select} from '@dropbox/dig-components/dist/text_fields';
import {t} from 'i18next';

export const NotificationTypeSelect = ({
  error,
  value,
  onSelect,
}: {
  error?: boolean;
  value?: string;
  onSelect: (type: string) => void;
}) => {
  return (
    <Select
      id="type"
      value={value}
      placeholder="— Select —"
      onChange={onSelect}
      isInvalid={error && !value}
    >
      {['okr_write', 'okr_update', 'okr_grade', 'generic'].map((option) => (
        <Select.Option key={option} value={option}>
          {t(option)}
        </Select.Option>
      ))}
    </Select>
  );
};
