import {User} from 'client';
import {ROUTE_PATHS} from 'constant';
import {Atom, atom} from 'jotai';
import {atomWithStorage} from 'jotai/utils';
import {getLoginUrl} from 'utilities';

export type PulseUser = Pick<
  User,
  'display_name' | 'email' | 'abbreviated_name' | 'profile_photo_url'
> & {ldap: string};

export const pulseUserAtom = atom<PulseUser | undefined>(undefined);
export type PulseUserAtomValue = typeof pulseUserAtom extends Atom<infer T> ? T : never;

export const isPrivatePageAtom = atom(false);

const pulseTokenStorageAtom = atomWithStorage<string>('pulse_token', '');
export const pulseTokenAtom = atom(
  (get) => get(pulseTokenStorageAtom),
  (get, set, token: string, isUserDeactivated?: boolean) => {
    set(pulseTokenStorageAtom, token);
    if (!token && get(isPrivatePageAtom)) {
      if (window.location.pathname === ROUTE_PATHS.WAITLIST) {
        return;
      }

      if (isUserDeactivated) {
        window.location.href = ROUTE_PATHS.WAITLIST;
      } else {
        window.location.href =
          getLoginUrl() +
          (window.location.pathname.startsWith(ROUTE_PATHS.LOGOUT)
            ? '' // don't redirect to logout on login!
            : window.location.pathname.substring(1) +
              window.location.search +
              window.location.hash);
      }
    }
  }
);

export const pulseLoginFailedTimestampStorageAtom = atomWithStorage<Date | null>(
  'pulse_login_failed_timestamp',
  null,
  {
    getItem: (key) => {
      const storedValue = localStorage.getItem(key);
      return storedValue ? new Date(storedValue) : null;
    },
    setItem: (key, value) => {
      if (value) {
        localStorage.setItem(key, value.toISOString());
      } else {
        localStorage.removeItem(key);
      }
    },
    removeItem: (key) => {
      localStorage.removeItem(key);
    },
  }
);

export const analyticsStorageAtom = atomWithStorage<boolean | null>('disable_analytics', null);
