import {Box, Stack} from '@dropbox/dig-foundations';
import {PulseWithGrouping} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {useColumnResize} from 'components/shared/table/useColumnResize';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useLoaderData} from 'react-router-dom';

import {EmptyPulse} from './EmptyPulse';
import {PulseHeader} from './PulseHeader';
import {PulseQuestionBox} from './PulseQuestionBox';
import {getDefaultColumns, PulseTable} from './PulseTable';

export const PulsesView = () => {
  const {columnConfigs, dragging, getMouseDownHandler} = useColumnResize(getDefaultColumns());
  const response = useLoaderData() as PulseWithGrouping;
  useDocumentTitle(`${response?.pillar ?? ''} ${t('pulse')}`);

  return (
    <>
      <Layout.Container>
        <Stack gap="16">
          <PulseHeader
            pulse={response.pulse ?? undefined}
            employeeCount={response.employee_count}
            responsesCount={response.responses_count ?? undefined}
            pillar={response.pillar}
            selected={response.pillar}
            inProgress={response.in_progress}
            previousId={response.previous_id ?? undefined}
            nextId={response.next_id ?? undefined}
          />
          {response.in_progress || !response.pulse ? (
            <EmptyPulse inProgress={response.in_progress} />
          ) : (
            response.pulse?.questions.map((question, index) => (
              <PulseQuestionBox
                key={index}
                index={index}
                pillar={response.pillar}
                question={question}
                responseCounts={response.response_counts}
                metrics={response.metrics}
              />
            ))
          )}
        </Stack>
      </Layout.Container>

      {!response.in_progress && response.pulse && (
        <Box paddingTop="24">
          <PulseTable
            pulse={response.pulse}
            pillar={response.pillar}
            columnConfigs={columnConfigs}
            dragging={dragging}
            onColumnDrag={getMouseDownHandler}
          />
        </Box>
      )}
    </>
  );
};
