import {Button} from '@dropbox/dig-components/dist/buttons';
import {Table} from '@dropbox/dig-components/dist/table';
import {Split} from '@dropbox/dig-foundations';
import {ClockLine} from '@dropbox/dig-icons/assets';
import {useQuery} from '@tanstack/react-query';
import {NotificationService} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {Title} from 'components/shared/ManagePage/Title';
import {t} from 'i18next';
import {useState} from 'react';
import {getService} from 'utilities';

import {NewNotificationModal} from './notifications/NewNotificationModal';
import {NotificationRow} from './notifications/NotificationRow';

export const NotificationScheduler = () => {
  const {data} = useQuery({
    queryKey: ['notifications', 'scheduled'],
    queryFn: () => getService(NotificationService).scheduledApiV1NotificationsScheduledGet(),
  });

  const [notificationModal, addNotificationModal] = useState(false);
  const [sendNow, setSendNow] = useState(false);

  return (
    <Layout.Container>
      <Split gap="16">
        <Split.Item width="fill">
          <Title
            icon={ClockLine}
            text={t('manage_notifications')}
            addButtonOnClick={() => {
              setSendNow(false);
              addNotificationModal(true);
            }}
          />
        </Split.Item>
        <Split.Item>
          <Button
            variant="filled"
            onClick={() => {
              setSendNow(true);
              addNotificationModal(true);
            }}
          >
            Send now
          </Button>
        </Split.Item>
      </Split>
      <Table>
        <Table.Header>
          <Table.Row>
            {['Audience', 'Type', 'Data', 'Send', 'Due', ''].map((col, idx) => (
              <Table.HeaderCell key={idx}>{col}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data?.map((message) => <NotificationRow key={message.id} {...message} />)}
        </Table.Body>
      </Table>
      {notificationModal && (
        <NewNotificationModal sendNow={sendNow} onClose={() => addNotificationModal(false)} />
      )}
    </Layout.Container>
  );
};
