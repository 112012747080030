import {Modal} from '@dropbox/dig-components/dist/modal';
import {Tabs} from '@dropbox/dig-components/dist/tabs';
import {Box} from '@dropbox/dig-foundations';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {Badge} from 'client';
import {AllBadgesList} from 'components/badges/AllBadgesList';
import {BadgeInfo, LoadedBadgeInfo} from 'components/badges/BadgeInfo';
import {BadgeLeaderboard} from 'components/badges/BadgeLeaderboard';
import {t} from 'i18next';
import {Suspense, useEffect, useState} from 'react';

interface Props {
  badge: Badge | null;
  onRequestClose: () => void;
  collected?: number[];
  closeModal: () => void;
}

export const BadgeModal = ({badge, collected, onRequestClose, closeModal}: Props) => {
  const [selected, setSelected] = useState<Badge | null>(badge);
  const [selectedTab, setSelectedTab] = useState('badges');

  useEffect(() => {
    analyticsLogger().logEvent('BADGE_MODAL_OPENED', {type: badge ? 'badge' : selectedTab});
  }, [selectedTab, badge]);

  return (
    <Modal
      withCloseButton="close-badges"
      isCentered
      open
      onRequestClose={onRequestClose}
      width={selected ? 'small' : 'standard'}
    >
      <Box paddingTop={selected ? undefined : '16'}>
        {!selected && (
          <Tabs selectedTab={selectedTab} onSelection={setSelectedTab}>
            <Box paddingX="16">
              <Tabs.Group>
                <Tabs.Tab id="badges">{t('badges')}</Tabs.Tab>
                <Tabs.Tab id="leaderboard">{t('leaderboard')}</Tabs.Tab>
              </Tabs.Group>
            </Box>

            <Tabs.Panel tabId="badges">
              <AllBadgesList onSelect={setSelected} collected={collected} />
            </Tabs.Panel>
            <Tabs.Panel tabId="leaderboard">
              <BadgeLeaderboard closeModal={closeModal} />
            </Tabs.Panel>
          </Tabs>
        )}
        {selected && (
          <Suspense
            fallback={
              <BadgeInfo
                badge={selected}
                onBack={() => setSelected(null)}
                label="All badges"
                closeModal={closeModal}
                owners={[]}
                recipients={[]}
              />
            }
          >
            <LoadedBadgeInfo
              badge={selected}
              onBack={() => setSelected(null)}
              label="All badges"
              closeModal={closeModal}
            />
          </Suspense>
        )}
      </Box>
    </Modal>
  );
};
