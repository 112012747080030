import {Box} from '@dropbox/dig-foundations';
import styles from 'components/DSYS/animations/LottieAnimation.module.css';
import Lottie, {LottieRefCurrentProps} from 'lottie-react';
import {Suspense, useEffect, useRef, useState} from 'react';

export enum LottieAnimationType {
  HIGH_FIVE = 'high-five',
}

type LottieAnimationProps = {
  animationType?: LottieAnimationType;
  loop?: boolean;
  loopCount?: number;
  style?: React.CSSProperties;
  alt?: string;
};

export const LottieAnimation = ({
  animationType,
  loop,
  loopCount,
  style,
  alt,
}: LottieAnimationProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [autoplay, setAutoplay] = useState(false);
  const [animationData, setAnimationData] = useState({});
  const [loopNum, setLoopNumber] = useState<number>(1);

  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const animationContainerRef = useRef<HTMLDivElement>(null);

  const handleLoopComplete = () => {
    if (loopCount && loopNum < loopCount) {
      setLoopNumber(loopNum + 1);
    }
  };

  const isInViewport = () => {
    if (animationContainerRef.current) {
      const rect = animationContainerRef.current.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      );
    }
    return false;
  };

  useEffect(() => {
    (async () => {
      let animationJson;

      // Add new animation json files here
      switch (animationType) {
        case LottieAnimationType.HIGH_FIVE:
          animationJson = await import('components/DSYS/animations/assets/HighFive.json');
          break;
        default:
          break;
      }

      if (animationJson) {
        setAnimationData(animationJson);
        setIsLoaded(true);
      }
    })();
  }, [animationType]);

  const hasAnimationRefInitialized = !!animationContainerRef.current;
  useEffect(() => {
    if (!hasAnimationRefInitialized) {
      return;
    }

    const handleScroll = () => {
      if (!autoplay && isInViewport()) {
        // Start the animation when it's in view
        setAutoplay(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Check on mount in case it's already in view
    if (!autoplay && isInViewport()) {
      setAutoplay(true);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [autoplay, hasAnimationRefInitialized]);

  useEffect(() => {
    // Manually trigger play when autoplay becomes true
    if (autoplay && lottieRef.current) {
      lottieRef.current.play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoplay]);

  if (!isLoaded) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <Box ref={animationContainerRef}>
        <Lottie
          lottieRef={lottieRef}
          alt={alt}
          animationData={animationData}
          autoplay={autoplay}
          loop={loopCount ? loopNum < loopCount : loop}
          onLoopComplete={handleLoopComplete}
          className={styles.lottieAnimation}
          style={style}
        />
      </Box>
    </Suspense>
  );
};
