import {gratitudeRecipient} from 'atoms/gratitude';
import {snackbarAtom} from 'atoms/snackbar';
import classNames from 'classnames';
import {CardLabel, Employee} from 'client';
import {CardGallery} from 'components/gratitiude/CardGallery';
import {useCardsSuspense} from 'components/gratitiude/hooks';
import {LabelText} from 'components/gratitiude/LabelText';
import {useEmployee} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useSetAtom} from 'jotai';
import {useEffect, useMemo, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import styles from './BrowseView.module.css';
import {Gratitude} from './Gratitude';

const BrowseWithRecipient = ({ldap}: {ldap: string}) => {
  const setBrowseRecpient = useSetAtom(gratitudeRecipient);
  const {employee, isLoading} = useEmployee({ldap, throwOnError: false});
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!employee) {
      setSnackbarMessage({text: t('gratitude_recipient_not_found')});
      navigate('/gratitude');
    }
    setBrowseRecpient(employee);
  }, [employee, isLoading, navigate, setBrowseRecpient, setSnackbarMessage]);

  return <BrowseMailbox recipient={employee} />;
};

const BrowseMailbox = ({recipient}: {recipient?: Employee}) => {
  const cards = useCardsSuspense();
  const [selected, setSelected] = useState<CardLabel>();
  const labels = useMemo(() => {
    return cards.reduce((prev, current) => {
      return prev.concat(
        current.labels.filter((label) => !prev.map((l) => l.id).includes(label.id))
      );
    }, [] as CardLabel[]);
  }, [cards]);

  return (
    <>
      <Gratitude
        title={
          recipient
            ? t('pick_a_card_for', {
                name: recipient.name.split(' ')[0],
              })
            : t('pick_a_card')
        }
        flavor={
          <div className={styles.labels}>
            <LabelText
              className={classNames({
                [styles.selected]: selected === undefined,
              })}
              onClick={() => {
                setSelected(undefined);
              }}
              key="label-all"
            >
              {t('all')}
            </LabelText>
            {labels
              .sort(
                (a, b) =>
                  (a.order == null ? Number.MAX_SAFE_INTEGER : a.order) -
                  (b.order == null ? Number.MAX_SAFE_INTEGER : b.order)
              )
              .map((label) => (
                <LabelText
                  className={classNames({
                    [styles.selected]: selected === label,
                  })}
                  onClick={() => {
                    setSelected(label);
                  }}
                  key={`label-${label.name}`}
                >
                  {label.name}
                </LabelText>
              ))}
          </div>
        }
      >
        <CardGallery
          hideCard={(gratitude) => {
            if (!selected) return false;
            else return !gratitude?.card?.labels.find((label) => label.id === selected.id);
          }}
          cards={cards.map((card) => ({card}))}
        />
      </Gratitude>
    </>
  );
};

export const BrowseView = () => {
  const [searchParams] = useSearchParams();

  const ldap = searchParams.get('to');
  if (ldap) {
    return <BrowseWithRecipient ldap={ldap} />;
  }

  return <BrowseMailbox />;
};
