import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {Button} from '@dropbox/dig-components/dist/buttons';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ChevronRightLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {Employee} from 'client';
import {Eyebrow} from 'components/DSYS/Eyebrow';
import {Title} from 'components/DSYS/Title';
import ProfileCard from 'components/shared/ProfileCard';
import {t} from 'i18next';
import {useCallback, useState} from 'react';

const MAX_PEOPLE_TO_DISPLAY_IN_MODAL = 8;

export const NoGoalsRollup = ({
  teamName,
  employeesWithNoGoals,
}: {
  teamName: string;
  employeesWithNoGoals: Employee[];
}) => {
  const [isHovered, setHovered] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    analyticsLogger().logEvent('NO_GOALS_ROLLUP_MODAL_OPENED');
    setModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <>
      <Box display="flex" marginTop="8" marginBottom="8" marginLeft="8">
        <Box as={Eyebrow} color="Text Subtle" marginTop="8" marginBottom="4">
          {t('no_goals')}
        </Box>
      </Box>
      <Box
        as="div"
        backgroundColor={isHovered ? 'Opacity Surface' : 'Background Base'}
        borderRadius="Medium"
        marginBottom="4"
        marginTop="4"
        paddingLeft="12"
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: isHovered ? 'pointer' : undefined,
        }}
        onClick={handleOpenModal}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Box paddingLeft="2" paddingRight="2" paddingTop="8" paddingBottom="8">
          <Box
            borderLeft="Solid"
            borderColor="Border Subtle"
            paddingTop="2"
            paddingBottom="2"
            paddingLeft="16"
            paddingRight="16"
            height="max-content"
          >
            <Box as={Text} display="block" isBold variant="paragraph">
              {t('no_goals_rollup_title', {
                count: employeesWithNoGoals.length,
                team: teamName,
              })}
            </Box>
            <Box as={Text} color="Text Subtle" size="small" variant="paragraph">
              {t('no_goals_rollup_subtitle')}
            </Box>
          </Box>
        </Box>
        <Box paddingRight="16">
          <IconButton variant="borderless" tone="neutral">
            <UIIcon src={ChevronRightLine} />
          </IconButton>
        </Box>
      </Box>
      <NoGoalsRollupModal
        employees={employeesWithNoGoals}
        isOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};

export const NoGoalsRollupModal = ({
  employees,
  isOpen,
  handleCloseModal,
}: {
  employees: Employee[];
  isOpen: boolean;
  handleCloseModal: () => void;
}) => {
  const [showAll, setShowAll] = useState<boolean>(false);
  const showAllCount =
    employees.length > MAX_PEOPLE_TO_DISPLAY_IN_MODAL
      ? employees.length - MAX_PEOPLE_TO_DISPLAY_IN_MODAL
      : 0;
  const employeesToDisplay = showAll
    ? employees
    : employees.slice(0, MAX_PEOPLE_TO_DISPLAY_IN_MODAL);

  const handleShowAll = useCallback(() => {
    analyticsLogger().logEvent('NO_GOALS_ROLLUP_MODAL_CLICKED', {event: 'show_more'});
    setShowAll(true);
  }, []);

  return (
    <Modal open={isOpen} onRequestClose={handleCloseModal} withCloseButton="Close" width="large">
      <Modal.Header>
        <Title>{t('no_goals_rollup_modal_title')}</Title>
      </Modal.Header>

      <Modal.Body>
        <Box style={{display: 'grid', gridTemplateColumns: '1fr 1fr', width: '100%', gap: 8}}>
          {employeesToDisplay.map((employee) => {
            return (
              <ProfileCard
                key={employee.user_id}
                source="no_goals_rollup"
                profile_username={employee.ldap}
                profile_photo_url={employee.profile_photo_url}
                display_name={employee.name}
                profile_info={employee.role ?? ''}
                isDeactivated={employee.is_deactivated}
                onClick={() => {
                  analyticsLogger().logEvent('NO_GOALS_ROLLUP_MODAL_CLICKED', {event: 'row'});
                }}
                target="_blank"
              />
            );
          })}
        </Box>
        {!showAll && showAllCount > 0 && (
          <Box paddingTop="12">
            <Button variant="transparent" size="small" onClick={handleShowAll}>
              +{showAllCount.toLocaleString()} {t('more')}
            </Button>
          </Box>
        )}
      </Modal.Body>
    </Modal>
  );
};
