import {Button} from '@dropbox/dig-components/dist/buttons';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/typography';
import {Box, BoxProps, Split, Stack} from '@dropbox/dig-foundations';
import {LottieAnimation, LottieAnimationType} from 'components/DSYS/animations/LottieAnimation';
import {t} from 'i18next';

export const DashboardsWidgetEmptyState = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) => {
  return (
    <Box
      as="div"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      paddingTop="8"
    >
      <Split gap="8" direction="vertical" alignX="center" alignY="center">
        <Split.Item>
          <Box display="flex" alignItems="center" justifyContent="center">
            <LottieAnimation
              animationType={LottieAnimationType.HIGH_FIVE}
              loop={false}
              loopCount={2}
              alt={title}
              style={{width: '64px'}}
            />
          </Box>
        </Split.Item>
        <Split.Item>
          <Stack display="flex" justify="center" align="center" gap="8" paddingBottom="8">
            <Box as={Text} isBold display="block" variant="paragraph" size="large">
              {title}
            </Box>
            <Box
              as={Text}
              display="block"
              textAlign="center"
              color="Text Subtle"
              variant="paragraph"
            >
              {subtitle}
            </Box>
          </Stack>
        </Split.Item>
      </Split>
    </Box>
  );
};

export const DashboardsWidgetShowAllButton = ({
  showAllCount,
  setShowAll,
}: {
  showAllCount: number;
  setShowAll?: () => void;
}) => {
  return (
    <Box paddingTop="12">
      <Button variant="transparent" size="small" onClick={setShowAll}>
        +{showAllCount.toLocaleString()} {t('more')}
      </Button>
    </Box>
  );
};

export const DashboardsWidgetSpinner = ({
  marginTop,
  width = '200px',
}: {
  marginTop: BoxProps<'div'>['marginTop'];
  width?: string;
}) => {
  return (
    <Box marginTop={marginTop}>
      <Skeleton.Box height={20} style={{width}} />
    </Box>
  );
};
