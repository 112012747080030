import 'yet-another-react-lightbox/plugins/counter.css';
import 'yet-another-react-lightbox/styles.css';

import {UIIcon} from '@dropbox/dig-icons';
import {
  ArrowLeftLine,
  ArrowRightLine,
  CloseLine,
  FullscreenExitLine,
  FullscreenLine,
} from '@dropbox/dig-icons/assets';
import {getBackendUrl} from 'utilities';
import LB, {CaptureSlide, GenericSlide, Slide, useLightboxState} from 'yet-another-react-lightbox';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Video from 'yet-another-react-lightbox/plugins/video';

declare module 'yet-another-react-lightbox' {
  export interface CaptureSlide extends GenericSlide {
    type: 'capture';
    src: string;
  }

  interface SlideTypes {
    capture: CaptureSlide;
  }
}

function isCaptureSlide(slide: Slide): slide is CaptureSlide {
  return slide.type === 'capture';
}

const RenderCaptureSlide = ({slides, slide}: {slides?: Slide[]; slide: CaptureSlide}) => {
  const index = slides?.findIndex((el) => el === slide);

  const {currentIndex} = useLightboxState();

  const matches = slide.src.match(/capture\.dropbox\.com\/([A-z0-9]{16})/);

  return index === currentIndex && matches ? (
    <iframe
      key={index}
      src={`https://capture.dropbox.com/embed/${matches[1]}`}
      width="100%"
      height="100%"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  ) : null;
};

export const Lightbox = ({
  open,
  close,
  assets,
  index,
}: {
  open: boolean;
  index: number;
  close: () => void;
  assets: string[];
}) => {
  const slides: Slide[] = assets.map((asset) => {
    const matches = asset.match(/capture\.dropbox\.com\/([A-z0-9]{16})/);
    if (matches) {
      return {src: asset, type: 'capture'};
    }
    const src = asset.startsWith('http')
      ? asset
      : `${getBackendUrl()}/api/v1/projects/assets/${asset}`;
    if (src.endsWith('.mp4')) {
      return {
        poster: src.replace('.mp4', '.png'),
        sources: [{src, type: 'video/mp4'}],
        type: 'video',
      };
    }
    return {src};
  });

  return (
    <LB
      index={index}
      render={{
        iconPrev: () => <UIIcon src={ArrowLeftLine} />,
        iconNext: () => <UIIcon src={ArrowRightLine} />,
        iconClose: () => <UIIcon src={CloseLine} />,
        iconEnterFullscreen: () => <UIIcon src={FullscreenLine} />,
        iconExitFullscreen: () => <UIIcon src={FullscreenExitLine} />,
        slide: ({slide}) =>
          isCaptureSlide(slide) ? <RenderCaptureSlide slide={slide} slides={slides} /> : null,
      }}
      plugins={[Counter, Video, Fullscreen]}
      counter={{container: {style: {fontFamily: 'var(--type__body__standard--fontfamily)'}}}}
      open={open}
      close={close}
      slides={slides}
    />
  );
};
