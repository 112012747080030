import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Toggletip} from '@dropbox/dig-components/dist/tooltips';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {Project, ProjectDetails, TeamWithHierarchy} from 'client';
import {Link} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import {TableSearch} from 'components/shared/table/TableSearch';
import {seenUpdatesAtom} from 'components/shared/table/UpdateCell';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';

import {ProjectBreadcrumbs} from './ProjectBreadcrumbs';
import {ProjectCard} from './ProjectCard';
import {ProjectDetailRow} from './ProjectDetailRow';
import {TableFilter} from './Table/TableFilter';
// import {teamToFullChainToRoot} from './teamUtils';
import {filterTable, ProjectTableFilter} from './utils';

const isProject = (data: Project | Project[]): data is Project => {
  return !Array.isArray(data);
};

export const ProjectHeader = ({
  area,
  data,
  details,
  isLoading,
  filter,
  setFilter,
  onStatusClick,
}: {
  area: TeamWithHierarchy;
  data: Project[] | Project;
  isLoading: boolean;
  details?: ProjectDetails;
  filter: ProjectTableFilter;
  setFilter: (filter: ProjectTableFilter) => void;
  onStatusClick?: (id: number) => void;
}) => {
  const project = isProject(data);
  const seenUpdates = useAtomValue(seenUpdatesAtom);
  const headerData = (project ? data.epics : data) ?? [];
  const team = area?.hierarchy ? area.hierarchy[area.hierarchy.length - 1] : area;

  // const projectHeirarchy =
  //   project && data.team && team.children?.length
  //     ? teamToFullChainToRoot(team, data.team.team_id) ?? hierarchy
  //     : hierarchy;

  let title = 'table_projects';
  let filteredCount = 0;
  if (!project) {
    filteredCount = data.filter(filterTable(filter, seenUpdates)).length;
    if (filteredCount !== data.length) {
      title = 'table_projects_filter';
    }
  }

  return (
    <>
      <Box width="100%" marginBottom="12" marginX="auto">
        <ProjectBreadcrumbs
          area={area}
          title={project ? {name: project ? data.title : null, slug: null} : undefined}
        />
        {project ? (
          <>
            <ProjectCard
              key={data.id}
              title={data.title}
              subtitle={data.subtitle ?? (isLoading ? undefined : '')}
              projectId={data.id}
              onStatusClick={onStatusClick}
            />
            <ProjectDetailRow data={data} details={details} />
          </>
        ) : (
          <>
            <ProjectCard
              key={team.team_id}
              slug={team.slug ?? undefined}
              title={team?.name ?? (isLoading ? undefined : '')}
              subtitle={team?.description || (isLoading ? undefined : '')}
              onStatusClick={onStatusClick}
            />
            {/* <ProjectStatusBar
              isLoading={isLoading}
              projects={data}
              filter={filter}
              setFilter={setFilter}
            /> */}
          </>
        )}
      </Box>
      {!project && (
        <Box
          marginTop="20"
          position="sticky"
          backgroundColor="Background Base"
          paddingTop="12"
          paddingBottom="10"
          width="100%"
          style={{top: 52, zIndex: 2}}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            style={{maxWidth: 1240, margin: '0 auto'}}
          >
            {isLoading ? (
              <Skeleton.Box width={100} height={24} />
            ) : (
              <Title
                size={16}
                style={{width: 200}}
                withAccessoryEnd={
                  <Toggletip
                    title={
                      <>
                        <Text>
                          These projects are imported every 5 minutes from the Dropbox’s company
                          Roadmap on{' '}
                        </Text>
                        <Link
                          to="https://www.dropbox.com/scl/fi/g9nl3lpmn2us92ly1zjxj/Company-Roadmap-Executive-View.paper?rlkey=5bectemg81ixu95tw19xv3vcd&dl=0"
                          target="_blank"
                        >
                          <Text>Airtable</Text>
                        </Link>
                      </>
                    }
                  />
                }
              >
                {t(title, {count: headerData.length, filteredCount})}
              </Title>
            )}

            <Box display="flex">
              <TableFilter area={area} filter={filter} setFilter={setFilter} />
              <TableSearch placeholder="Project name..." filter={filter} setFilter={setFilter} />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
