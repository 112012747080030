import {loggedInEmployeeAtom} from 'atoms/employee';
import {useAtomValue} from 'jotai';
import {GoalsTablesComponent} from 'views/goals_v2/TableComponents/GoalsTablesComponent';
import {GoalsTablePropsWithoutData} from 'views/goals_v2/types';
import {isManagerGoalsSelected, isMyGoalsSelected} from 'views/goals_v2/utils';

/*
 * Table view that loads employee goals for your goals and your manager's goals (based on `filter.people`)
 */
export const EmployeesGoalsTableView = (tableProps: GoalsTablePropsWithoutData) => {
  const {filter} = tableProps;
  const {employee, reportingLine} = useAtomValue(loggedInEmployeeAtom);
  const managerLdap = reportingLine?.[1]?.ldap;

  const hasMyGoalsSelected = filter && !!employee?.ldap && isMyGoalsSelected(filter, employee.ldap);
  const hasManagerGoalsSelected =
    filter && !!managerLdap && isManagerGoalsSelected(filter, managerLdap);

  const ldaps = hasMyGoalsSelected ? [employee.ldap] : hasManagerGoalsSelected ? [managerLdap] : [];

  const keyPrefix = 'employees_goals';
  return <GoalsTablesComponent keyPrefix={keyPrefix} tableProps={tableProps} ldaps={ldaps} />;
};
