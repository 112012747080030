import {Button} from '@dropbox/dig-components/dist/buttons';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {List} from '@dropbox/dig-components/dist/list';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {ChevronRightLine} from '@dropbox/dig-icons/assets';
import {useQuery} from '@tanstack/react-query';
import {Badge, BadgeService} from 'client';
import {t} from 'i18next';
import {getService} from 'utilities';

import styles from './AllBadgesList.module.css';
import {BadgeImage} from './BadgeImage';

interface Props {
  onSelect?: (badge: Badge) => void;
  collected?: number[];
}

export const AllBadgesList = ({collected = [], onSelect}: Props) => {
  const {data: allBadges} = useQuery<Badge[]>({
    queryKey: ['badges'],
    queryFn: getService(BadgeService).getBadgesApiV1BadgesGet,
  });

  return (
    <div className={styles.container}>
      <Box marginX="32" paddingTop="16">
        <Text variant="label" size="small" color="faint" className={styles.supportingText}>
          {t('badge_list_supporting_text')}{' '}
        </Text>
        <Button
          variant="transparent"
          size="small"
          className={styles.requestBadge}
          target="_blank"
          href="https://forms.gle/ZNyV6EuyezMRVYWJ7"
        >
          {t('request_a_badge')}
        </Button>
      </Box>
      <List isSelectable paddingX="0" spacing="large">
        {allBadges
          ? allBadges
              .filter((badge) => !badge.hidden_from_library)
              .map((badge) => (
                <List.Item
                  className={styles.item}
                  padding={'10px var(--spacing__unit--4)'}
                  key={`all-${badge.id}`}
                  onClick={() => {
                    if (onSelect) onSelect(badge);
                  }}
                >
                  <List.Accessory>
                    <BadgeImage size={64} badge={badge.image_key} />
                  </List.Accessory>
                  <List.Content>
                    <LabelGroup
                      withText={
                        collected.includes(badge.id) ? (
                          <>
                            <span className={styles.collected}>{badge.name}</span> ✅
                          </>
                        ) : (
                          <span className={styles.collected}>{badge.name}</span>
                        )
                      }
                      withSubtext={<div className={styles.text}>{badge.description}</div>}
                    />
                  </List.Content>
                  <List.Accessory>
                    <UIIcon className={styles.chevron} src={ChevronRightLine} />
                  </List.Accessory>
                </List.Item>
              ))
          : Array.from({length: 50}).map((_, key) => <EmptyItem key={key} />)}
      </List>
    </div>
  );
};

const EmptyItem = () => (
  <List.Item className={styles.item} padding={'10px var(--spacing__unit--4)'}>
    <Skeleton.Box height={64} />
  </List.Item>
);
