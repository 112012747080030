import {List} from '@dropbox/dig-components/dist/list';
import {Box} from '@dropbox/dig-foundations';
import {useQuery} from '@tanstack/react-query';
import {NotificationService, NotificationWithContent} from 'client';
import {Eyebrow} from 'components/DSYS/Eyebrow';
import {PageSpinner} from 'components/shared/PageSpinner';
import {dateToGroup} from 'helpers/groupByDate';
import {t} from 'i18next';
import {Fragment} from 'react';
import {getService} from 'utilities';

import {NotificationEmptyState} from './NotificationEmptyState';
import {NotificationRenderer} from './NotificationRenderer';

const groupedByMonth = (arr?: NotificationWithContent[]) =>
  Object.entries(
    arr?.reduce((acc: Record<string, NotificationWithContent[]>, item) => {
      const groupName = dateToGroup(item.due_at || item.created_at, true);

      // eslint-disable-next-line no-param-reassign
      acc[groupName] = acc[groupName] || [];
      acc[groupName].push(item);

      return acc;
    }, {}) ?? {}
  )
    .map(([title, notifications]) => ({title, notifications}))
    .sort((a, b) => {
      if (a.title === t('activity_this_month')) {
        return -1;
      }
      if (b.title === t('activity_this_month')) {
        return 1;
      }
      return new Date(a.title).getTime() - new Date(b.title).getTime();
    });

export const NotificationUpcoming = () => {
  const {data, isLoading} = useQuery({
    queryKey: ['notifications', 'upcoming'],
    queryFn: () =>
      getService(NotificationService).upcomingApiV1NotificationsUpcomingGet(
        Intl.DateTimeFormat().resolvedOptions().timeZone
      ),
  });
  const grouped = groupedByMonth(data);

  return (
    <>
      {!grouped ? null : (
        <Box paddingTop="12">
          {grouped.map(({title, notifications}) => (
            <Fragment key={title}>
              <Box paddingX="16" marginBottom="4">
                <Eyebrow>{title}</Eyebrow>
              </Box>
              <List padding="0">
                {notifications
                  .sort(
                    (a, b) =>
                      new Date(a.due_at ?? a.created_at).getTime() -
                      new Date(b.due_at ?? b.created_at).getTime()
                  )
                  .map((item, index) => (
                    <NotificationRenderer
                      key={item.id}
                      separator={index === notifications.length - 1}
                      upcoming
                      notification={item}
                    />
                  ))}
              </List>
            </Fragment>
          ))}
        </Box>
      )}

      {isLoading && <PageSpinner />}
      {!data?.length && !isLoading && <NotificationEmptyState type="upcoming" />}
    </>
  );
};
