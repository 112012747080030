// eslint-disable-next-line no-restricted-imports
import {Avatar as DIGAvatar} from '@dropbox/dig-components/dist/avatar';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Text} from '@dropbox/dig-components/typography';
import {Box, Cluster, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {DropboxLine, PersonMultipleLine, SearchLine, TeamLine} from '@dropbox/dig-icons/assets';
import {PulseUser} from 'atoms/auth';
import {Employee} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {emailToLdap} from 'utilities';

import {useProfileSuspense} from './hooks';
import {GoalsTableFilter, SelectedKey} from './types';
import {
  getShortenedName,
  isCompanyGoalsSelected,
  isDirectReportsSelected,
  isManagerGoalsSelected,
  isMyGoalsSelected,
  isTeamGoalsSelected,
} from './utils';

export const QuickList = ({
  filter,
  loggedInUser,
  handleQuickFilterSelected,
}: {
  filter: GoalsTableFilter;
  loggedInUser?: PulseUser;
  handleQuickFilterSelected: (selectedItem: SelectedKey) => void;
}) => {
  const {employee, reportingLine, directReports, employeeTeams} = useProfileSuspense({
    ldap: emailToLdap(loggedInUser!.email),
  });
  const {t} = useTranslation();
  if (!employee || !reportingLine) {
    return <QuickListSkeleton />;
  }

  const hasMyGoalsSelected = isMyGoalsSelected(filter, employee.ldap);
  const managerLdap = reportingLine?.[1]?.ldap;
  const hasManagerGoalsSelected = isManagerGoalsSelected(filter, managerLdap);
  const hasDirectReportSelected = isDirectReportsSelected(filter);
  const employeeTeamSlugs = employeeTeams.map((team) => team.slug ?? '');
  const hasTeamGoalsSelected =
    !filter.people.length && isTeamGoalsSelected(filter, employeeTeamSlugs);
  const hasCompanyGoalsSelected = isCompanyGoalsSelected(filter);

  return (
    <Cluster gap="8" data-testid="goals-quick-list">
      <Cluster.Item key="myGoals" onClick={() => handleQuickFilterSelected('myGoals')}>
        <QuickListItem text={t('my_goals')} employee={employee} isSelected={hasMyGoalsSelected} />
      </Cluster.Item>
      {reportingLine.length > 1 && (
        <Cluster.Item
          key="managerGoals"
          onClick={() => handleQuickFilterSelected('managerGoals')}
          data-testid="goals-quick-list__manager-goals"
        >
          <QuickListItem
            text={t('your_manager')}
            employee={reportingLine[1]}
            isSelected={hasManagerGoalsSelected}
          />
        </Cluster.Item>
      )}
      {directReports && directReports.length > 0 && (
        <Cluster.Item
          key="directReportsGoals"
          onClick={() => handleQuickFilterSelected('directReportsGoals')}
        >
          <QuickListIconItem
            iconSrc={PersonMultipleLine}
            text={t('your_reports')}
            isSelected={hasDirectReportSelected}
          />
        </Cluster.Item>
      )}
      {!!employeeTeams.length && (
        <Cluster.Item
          key="teamsGoals"
          onClick={() => handleQuickFilterSelected('teamGoals')}
          data-testid="goals-quick-list__team-goals"
        >
          <QuickListIconItem
            iconSrc={TeamLine}
            text={employeeTeams && employeeTeams.length > 1 ? t('your_teams') : t('your_team')}
            isSelected={hasTeamGoalsSelected}
          />
        </Cluster.Item>
      )}
      <Cluster.Item
        key="companyGoals"
        onClick={() => handleQuickFilterSelected('companyGoals')}
        data-testid="goals-quick-list__company-goals"
      >
        <QuickListIconItem
          iconSrc={DropboxLine}
          text={t('company')}
          isSelected={hasCompanyGoalsSelected}
        />
      </Cluster.Item>
    </Cluster>
  );
};

export const QuickListSkeleton = () => {
  const items = Array.from({length: 5}, (_, i) => i);
  return (
    <Cluster gap="8">
      {items.map((item) => (
        <Cluster.Item key={item} style={{width: '94px', height: '80px'}}>
          <Skeleton.Box width={94} height={80} withBorderRadius="medium" key={item} />
        </Cluster.Item>
      ))}
    </Cluster>
  );
};

const QuickListItem = ({
  text,
  employee,
  isSelected,
}: {
  text: string;
  employee: Employee;
  isSelected: boolean;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Box
      as="div"
      padding="8"
      borderRadius="Medium"
      backgroundColor={isHovered || isSelected ? 'Opacity Surface' : 'Background Base'}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      cursor="pointer"
    >
      <Split direction="vertical" gap="4">
        <Split.Item>
          <Box display="flex" justifyContent="center">
            <Avatar user={employee} size="large" isInactive={employee.is_deactivated || false} />
          </Box>
        </Split.Item>
        <Split.Item>
          <Box display="flex" justifyContent="center">
            <Text size="small" variant="label" isBold={isSelected}>
              {text}
            </Text>
          </Box>
        </Split.Item>
      </Split>
    </Box>
  );
};

const QuickListIconItem = ({
  iconSrc,
  text,
  isSelected,
}: {
  text: string;
  iconSrc: React.ComponentType<React.SVGAttributes<SVGElement>>;
  isSelected: boolean;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      as="div"
      padding="8"
      borderRadius="Medium"
      backgroundColor={isHovered || isSelected ? 'Opacity Surface' : 'Background Base'}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      cursor="pointer"
    >
      <Split direction="vertical" gap="4" style={{maxWidth: '98px'}}>
        <Split.Item>
          <Box display="flex" justifyContent="center">
            <FilterIcon iconSrc={iconSrc} />
          </Box>
        </Split.Item>
        <Split.Item>
          <Box display="flex" justifyContent="center">
            <Text size="small" variant="label" isBold={isSelected}>
              {text}
            </Text>
          </Box>
        </Split.Item>
      </Split>
    </Box>
  );
};

const FilterIcon = ({iconSrc}: {iconSrc: React.ComponentType<React.SVGAttributes<SVGElement>>}) => {
  return (
    <Box
      as={DIGAvatar}
      colorPalette={{background: 'var(--dig-color__primary__surface--state-1)', foreground: ''}}
      size="large"
    >
      <UIIcon src={iconSrc} color="var(--dig-color__text__base)" />
    </Box>
  );
};

export const JoinQuickList = ({
  delegateLdap,
  loggedInUser,
  filter,
  isFindClicked,
  handleQuickFilterClick,
}: {
  delegateLdap?: string;
  loggedInUser?: PulseUser;
  filter: GoalsTableFilter;
  isFindClicked: boolean;
  handleQuickFilterClick: (ldap: string, isFindClicked: boolean) => void;
}) => {
  const {reportingLine} = useProfileSuspense({
    ldap: delegateLdap ? delegateLdap : emailToLdap(loggedInUser!.email),
  });
  const {t} = useTranslation();
  if (!reportingLine) {
    return <QuickListSkeleton />;
  }

  return (
    <Cluster gap="8">
      {reportingLine.length > 1 &&
        reportingLine.slice(1, 5).map((person: Employee) => {
          return (
            <Cluster.Item
              key={person.ldap}
              onClick={() => handleQuickFilterClick(person.ldap, false)}
            >
              <QuickListItem
                text={getShortenedName(person.name)}
                employee={person}
                isSelected={person.ldap === filter.people[0]}
              />
            </Cluster.Item>
          );
        })}
      <Cluster.Item key="findGoals" onClick={() => handleQuickFilterClick('', true)}>
        <QuickListIconItem iconSrc={SearchLine} text={t('find')} isSelected={isFindClicked} />
      </Cluster.Item>
    </Cluster>
  );
};
