import {Text, TextProps} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {forwardRef, ReactNode} from 'react';

export const Eyebrow = forwardRef<
  HTMLElement,
  TextProps & {
    children: ReactNode;
    id?: string;
  }
>(({children, id, ...props}, ref: any) => (
  <Box id={id} ref={ref} style={{letterSpacing: '1.2px', textTransform: 'uppercase'}}>
    <Text color="subtle" size="xsmall" isBold {...props}>
      {children}
    </Text>
  </Box>
));

Eyebrow.displayName = 'Eyebrow';
