import {Employee, GoalCreate, GoalUpdate, Team} from 'client';
import {isEmployee, isTeam} from 'hooks/useEmployee';

export const sortEmployeesOrTeamsByName = (employees?: Employee[], teams?: Team[]) => {
  // Combine the two arrays, defaulting to an empty array if they are undefined
  const combined = [...(employees || []), ...(teams || [])];

  // Sort the combined array by name, handling possible nulls in team names
  combined.sort((a, b) => {
    // Use an empty string for comparison if name is null
    const nameA = a.name || '';
    const nameB = b.name || '';

    return nameA.localeCompare(nameB);
  });

  return combined;
};

export const dedupeEmployeesOrTeams = (employeesOrTeams: (Employee | Team)[]) => {
  return employeesOrTeams.filter(
    (e, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          (isEmployee(e) && isEmployee(t) && e.user_id === t.user_id) ||
          (isTeam(e) && isTeam(t) && e.team_id === t.team_id)
      )
  );
};

export const countUniqueEmployee = (
  selectedEmployees: Employee[],
  reportingLine: Employee[],
  delegates: Employee[]
) => {
  const employeeUnion = [...selectedEmployees, ...reportingLine, ...delegates];
  // dedupe the employeeUnion array based on the employee id without using Set
  const dedupedEmployeeUnion = employeeUnion.filter((employee, index) => {
    const currentIndex = employeeUnion.findIndex((e) => e.user_id === employee.user_id);
    return currentIndex === index;
  });

  return dedupedEmployeeUnion.length;
};

export const excludeOwnerAndDelegate = (
  directReports: Employee[],
  delegates: Employee[],
  owner: Employee
) => {
  // filter out delegates and owner from directReports if their user_id is the same
  const filteredDirectReports = directReports.filter((employee) => {
    return (
      employee.user_id !== owner.user_id &&
      !delegates.some((delegate) => delegate.user_id === employee.user_id)
    );
  });

  return filteredDirectReports;
};

export const dedupeTeam = (employeeTeams: Team[], teamPrivacies?: Team[]) => {
  return employeeTeams.filter(
    (e) =>
      !teamPrivacies?.some((t) => {
        return t.team_id === e.team_id;
      })
  );
};

export const isEmployeeTeam = (team: Team, employeeTeams: Team[]) => {
  return employeeTeams.some((t) => t.team_id === team.team_id);
};

export const isDirectReport = (employee: Employee, directReports: Employee[]) => {
  return directReports.some((e) => e.user_id === employee.user_id);
};

export const hasPrivacyChanged = (
  goalCreateValues: GoalCreate | GoalUpdate,
  selectedEmployeesOrTeams: (Employee | Team)[]
) => {
  const selectedEmployees = selectedEmployeesOrTeams.filter(isEmployee);
  const selectedTeams = selectedEmployeesOrTeams.filter(isTeam);

  const goalIndividualPrivacies = goalCreateValues?.individual_privacies || [];
  const goalTeamPrivacies = goalCreateValues?.team_privacies || [];

  if (selectedEmployees.length !== goalIndividualPrivacies.length) {
    return true;
  }

  if (selectedTeams.length !== goalTeamPrivacies.length) {
    return true;
  }

  return (
    selectedEmployees.some(
      (e) =>
        !goalIndividualPrivacies.some((g) => {
          return g.user_id === e.user_id;
        })
    ) ||
    selectedTeams.some(
      (t) =>
        !goalTeamPrivacies.some((g) => {
          return g.team_id === t.team_id;
        })
    )
  );
};
