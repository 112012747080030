import {
  Button,
  SplitButton,
  SplitButtonMenuRenderFnOptions,
} from '@dropbox/dig-components/dist/buttons';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {UIIcon} from '@dropbox/dig-icons';
import {CheckmarkLine} from '@dropbox/dig-icons/assets';
import {getTimeframeButtonText, getTimeframeOptions} from 'components/shared/TimeAgo';
import {CURRENT_YEAR} from 'constant';
import {t} from 'i18next';

const renderContinueGoalSplitMenu = ({
  buttonProps: {buttonProps},
  currentTimeframe,
  nextTimeframe,
  setNextTimeframe,
}: {
  buttonProps: SplitButtonMenuRenderFnOptions;
  currentTimeframe: string;
  nextTimeframe: string;
  setNextTimeframe: (timeframe: string) => void;
}) => {
  const timeframeOptions: {value: string; quarter: string; displayName: string}[] =
    getTimeframeOptions(currentTimeframe).map((timeframe) => ({
      value: timeframe,
      quarter: timeframe.slice(0, 2),
      displayName: timeframe.replace('FY', '20'),
    }));
  return (
    <Menu.Wrapper
      onSelection={(option: string, event: React.SyntheticEvent) => {
        setNextTimeframe(option);
        event.stopPropagation();
      }}
    >
      {({getTriggerProps, getContentProps}) => (
        <>
          <Button
            aria-label="Show Continue Goal Timeframe Options"
            {...buttonProps}
            {...getTriggerProps()}
            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              const onClick = getTriggerProps().onClick;
              if (onClick) {
                onClick(e);
              }
              e.stopPropagation();
            }}
            onMouseDown={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              const onMouseDown = getTriggerProps().onMouseDown;
              if (onMouseDown) {
                onMouseDown(e);
              }
              e.stopPropagation();
            }}
            onMouseUp={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              const onMouseUp = getTriggerProps().onMouseUp;
              if (onMouseUp) {
                onMouseUp(e);
              }
              e.stopPropagation();
            }}
            withDropdownIcon
          />
          <Menu.Content {...getContentProps()} placement="bottom-end" minWidth={200}>
            <Menu.Segment withLabel={t('continue_in')}>
              {timeframeOptions.map((timeframe) => {
                return (
                  <Menu.ActionItem
                    key={`continue-goal-timeframe-option__${timeframe.value}`}
                    value={timeframe.value}
                    withRightAccessory={
                      nextTimeframe === timeframe.value && <UIIcon src={CheckmarkLine} />
                    }
                  >
                    {timeframe.displayName.indexOf(String(CURRENT_YEAR)) === -1
                      ? timeframe.displayName
                      : timeframe.quarter}
                  </Menu.ActionItem>
                );
              })}
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};

const ContinueGoalSplitButton = ({
  currentTimeframe,
  nextTimeframe,
  setNextTimeframe,
  handleContinueGoal,
  disabled,
}: ContinueGoalButtonProps) => {
  return (
    <SplitButton
      variant="primary"
      onClick={(e: React.SyntheticEvent) => {
        handleContinueGoal();
        e.stopPropagation();
      }}
      disabled={disabled}
      renderMenu={(buttonProps) =>
        renderContinueGoalSplitMenu({
          buttonProps,
          currentTimeframe,
          nextTimeframe,
          setNextTimeframe,
        })
      }
    >
      {t('continue_goal_button_submit', {nextTimeframe: getTimeframeButtonText(nextTimeframe)})}
    </SplitButton>
  );
};

type ContinueGoalButtonProps = {
  currentTimeframe: string;
  nextTimeframe: string;
  setNextTimeframe: (timeframe: string) => void;
  handleContinueGoal: () => void;
  disabled: boolean;
};

export const ContinueGoalButton = ({
  currentTimeframe,
  nextTimeframe,
  setNextTimeframe,
  handleContinueGoal,
  disabled,
}: ContinueGoalButtonProps) => {
  const timeframeOptions = getTimeframeOptions(currentTimeframe);
  return timeframeOptions.length > 1 ? (
    <ContinueGoalSplitButton
      currentTimeframe={currentTimeframe}
      nextTimeframe={nextTimeframe}
      setNextTimeframe={setNextTimeframe}
      handleContinueGoal={handleContinueGoal}
      disabled={disabled}
    />
  ) : (
    <Button
      variant="primary"
      onClick={(e: React.SyntheticEvent) => {
        handleContinueGoal();
        e.stopPropagation();
      }}
      disabled={disabled}
    >
      {t('continue_goal_button_submit', {nextTimeframe: getTimeframeButtonText(nextTimeframe)})}
    </Button>
  );
};
