import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {Box} from '@dropbox/dig-foundations';
import {useMobile} from 'hooks/isMobile';

import {PageSpinner} from './PageSpinner';
import styles from './SkeletonGoal.module.css';

export const SkeletonGoal = () => {
  const isMobile = useMobile();
  return !isMobile ? (
    <div className={styles.skeleton}>
      <Box display="flex" flexDirection="column" width="100%" marginRight="24">
        <Box marginBottom="24">
          <Skeleton.Box height={96} withBorderRadius="medium" />
        </Box>
        <Box marginBottom="24">
          <Skeleton.Box height={96} withBorderRadius="medium" />
        </Box>

        <Skeleton.Box height={96} withBorderRadius="medium" />
      </Box>
      <Skeleton.Box width={288} height={200} withBorderRadius="medium" />
    </div>
  ) : (
    <PageSpinner />
  );
};

export const SkeletonEditGoal = () => {
  const isMobile = useMobile();
  return !isMobile ? (
    <div className={styles.skeleton}>
      <Box display="flex" flexDirection="column" width="100%" marginRight="24">
        <Box marginBottom="24">
          <Skeleton.Box height={96} withBorderRadius="medium" />
        </Box>
        <Box marginBottom="24">
          <Skeleton.Box height={96} withBorderRadius="medium" />
        </Box>

        <Skeleton.Box height={96} withBorderRadius="medium" />
      </Box>
    </div>
  ) : (
    <PageSpinner />
  );
};
