import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {DatePickerInput} from '@dropbox/dig-components/dist/date_picker';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Table} from '@dropbox/dig-components/dist/table';
import {Select, TextArea} from '@dropbox/dig-components/dist/text_fields';
import {Box, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CheckmarkLine, DeleteLine} from '@dropbox/dig-icons/assets';
import {useMutation} from '@tanstack/react-query';
import {Pulse, PulseService, PulseUpdate, PulseWithGrouping} from 'client';
import {LabelGroupCell} from 'components/shared/table/LabelGroupCell';
import {useState} from 'react';
import {getService} from 'utilities';
import {
  fromServerDate,
  getTodayUTC9am,
  toCalendarDate,
  toEODISOString,
  toServerDate,
} from 'views/admin/utils';
import {queryClient} from 'views/QueryClientWrapper';

export const PulseRow = (props: PulseWithGrouping & {pulse: Pulse}) => {
  const [isHover, setIsHover] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [pulse, setPulse] = useState(props.pulse);

  const {mutateAsync: updatePulse} = useMutation({
    mutationFn: ({data}: {data: PulseUpdate}) =>
      getService(PulseService).updatePulseApiV1PulsePost(data),
    onSuccess: () => queryClient.invalidateQueries({queryKey: ['pulse', 'scheduled']}),
  });

  const handleSetStartAt = (value: Date | string) => {
    setHasChanges(true);
    setPulse({
      ...pulse,
      survey_start_date: toServerDate(props.pulse.survey_start_date, value),
    });
  };

  const handleSetValue = (key: string, value?: string) => {
    setHasChanges(true);
    setPulse({...pulse, [key]: value});
  };

  const handleSave = () => {
    setHasChanges(false);
    updatePulse({data: pulse});
  };

  return (
    <Table.Row onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      {pulse.questions.map((question, index) => (
        <Table.Cell key={`${pulse.id}—${question.id}`}>
          <Box padding="8">
            <TextArea
              value={question.prompt}
              onChange={(e) => {
                setHasChanges(true);
                setPulse({
                  ...pulse,
                  questions: pulse.questions.map((q, i) =>
                    i === index ? {...q, prompt: e.currentTarget.value} : q
                  ),
                });
              }}
            />
          </Box>
        </Table.Cell>
      ))}

      <LabelGroupCell isBold text={props.pillar} subText={`${props.employee_count} Employees`} />

      <Table.Cell>
        <Split gap="8" alignY="center">
          <Split.Item>
            <DatePickerInput
              id="start-at-date"
              value={toCalendarDate(pulse.survey_start_date)}
              onChange={(value) => handleSetStartAt(value ?? getTodayUTC9am())}
            />
          </Split.Item>
          <Split.Item>
            <Select
              id="start-at-time"
              value={fromServerDate(pulse.survey_start_date).getUTCHours().toString()}
              onChange={handleSetStartAt}
            >
              {Array.from({length: 24}, (_, i) => i).map((hour) => (
                <Select.Option key={hour} value={hour.toString()}>
                  {`${hour % 12 || 12}${hour < 12 ? 'am' : 'pm'}`}
                </Select.Option>
              ))}
            </Select>
          </Split.Item>
        </Split>
      </Table.Cell>
      <Table.Cell>
        <DatePickerInput
          id="end-at"
          value={toCalendarDate(pulse.survey_end_date)}
          onChange={(value) =>
            handleSetValue('survey_end_date', toEODISOString(value?.toISOString()))
          }
        />
      </Table.Cell>
      <Table.Cell>
        <Split gap="4">
          <Split.Item>
            <Box
              as={IconButton}
              visibility={hasChanges ? 'visible' : 'hidden'}
              variant="outline"
              shape="circular"
              disabled={!hasChanges}
              onClick={handleSave}
            >
              <Box as={UIIcon} src={CheckmarkLine} color="Text Subtle" />
            </Box>
          </Split.Item>
          <Split.Item>
            <IconButton
              variant="outline"
              shape="circular"
              style={{visibility: isHover ? 'visible' : 'hidden'}}
              onClick={() => setShowDelete(true)}
            >
              <Box as={UIIcon} src={DeleteLine} color="Text Subtle" />
            </IconButton>
          </Split.Item>
        </Split>
      </Table.Cell>

      {showDelete && (
        <Modal open onRequestClose={() => setShowDelete(false)}>
          <Modal.Header hasBottomSpacing="title-standard">
            <Modal.Title>Delete pulse?</Modal.Title>
            <Modal.Body>
              <Split direction="vertical" paddingY="8">
                {pulse.questions.map((question) => (
                  <Split.Item key={question.id}>{question.prompt}</Split.Item>
                ))}

                <Split.Item>{pulse.survey_start_date}</Split.Item>
                <Split.Item>{pulse.survey_end_date}</Split.Item>
              </Split>
            </Modal.Body>
          </Modal.Header>
          <Modal.Footer>
            <Button variant="opacity" onClick={() => setShowDelete(false)}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={async () => {
                await getService(PulseService).deleteApiV1PulseScheduledDeletePost(pulse.id);
                queryClient.invalidateQueries({queryKey: ['pulse', 'scheduled']});
                setShowDelete(false);
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Table.Row>
  );
};
