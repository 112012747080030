import {WinLine} from '@dropbox/dig-icons/assets';
import {pulseUserAtom} from 'atoms/auth';
import {AddBadgeModal} from 'components/badges/AddBadgeModal';
import {LoadedManageBadgesList, ManageBadgesList} from 'components/badges/ManageBadgeList';
import {Layout} from 'components/DSYS/Layout';
import {Title} from 'components/shared/ManagePage/Title';
import {isContentAdmin} from 'helpers/utils';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {Suspense, useState} from 'react';
import {queryClient} from 'views/QueryClientWrapper';

export const ManageBadgesView = () => {
  const [addingBadge, setAddingBadge] = useState(false);
  const pulseUser = useAtomValue(pulseUserAtom);
  return (
    <>
      <Layout.Container>
        <Title
          text={t('manage_badges')}
          icon={WinLine}
          addButtonOnClick={
            isContentAdmin(pulseUser?.email) ? () => setAddingBadge(true) : undefined
          }
        />
      </Layout.Container>
      <Suspense fallback={<ManageBadgesList />}>
        <LoadedManageBadgesList />
      </Suspense>
      <AddBadgeModal
        open={addingBadge}
        onClose={() => {
          queryClient.invalidateQueries({queryKey: ['ownedBadges']});
          setAddingBadge(false);
        }}
      />
    </>
  );
};
