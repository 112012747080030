import {strategiesAtom} from 'atoms/strategies';
import {differenceInMinutes} from 'date-fns';
import {OKREntry, OKREntryStatus} from 'helpers/okr/types';
import {useAtomValue} from 'jotai';
import {
  OKR_INITIAL_STATE_KEY_RESULT,
  OKR_INITIAL_STATE_KEY_RESULT_ACHIEVABLE_DESCRIPTION,
  OKR_INITIAL_STATE_KEY_RESULT_ACHIEVABLE_TITLE,
  OKR_INITIAL_STATE_KEY_RESULT_CONCRETE_DESCRIPTION,
  OKR_INITIAL_STATE_KEY_RESULT_CONCRETE_TITLE,
  OKR_INITIAL_STATE_KEY_RESULT_DESCRIPTION,
  OKR_INITIAL_STATE_KEY_RESULT_MEASURABLE_DESCRIPTION,
  OKR_INITIAL_STATE_KEY_RESULT_MEASURABLE_TITLE,
  OKR_INITIAL_STATE_OBJECTIVE,
  OKR_INITIAL_STATE_OBJECTIVE_ACTION_DESCRIPTION,
  OKR_INITIAL_STATE_OBJECTIVE_ACTION_TITLE,
  OKR_INITIAL_STATE_OBJECTIVE_DESCRIPTION,
  OKR_INITIAL_STATE_OBJECTIVE_IMPORTANCE_DESCRIPTION,
  OKR_INITIAL_STATE_OBJECTIVE_IMPORTANCE_TITLE,
  OKR_INITIAL_STATE_OBJECTIVE_OUTCOME_DESCRIPTION,
  OKR_INITIAL_STATE_OBJECTIVE_OUTCOME_TITLE,
} from 'strings';

// Initial state for the OKR objective state
export const INITIAL_OKR_OBJECTIVE_STATE: OKREntry = {
  status: OKREntryStatus.STARTED,
  title: OKR_INITIAL_STATE_OBJECTIVE,
  description: OKR_INITIAL_STATE_OBJECTIVE_DESCRIPTION,
  details: [
    {
      title: OKR_INITIAL_STATE_OBJECTIVE_ACTION_TITLE,
      iconColor: '#F77751',
      description: OKR_INITIAL_STATE_OBJECTIVE_ACTION_DESCRIPTION,
      imageName: 'action',
    },
    {
      title: OKR_INITIAL_STATE_OBJECTIVE_OUTCOME_TITLE,
      description: OKR_INITIAL_STATE_OBJECTIVE_OUTCOME_DESCRIPTION,
      iconColor: '#624894',
      imageName: 'outcome',
    },
    {
      title: OKR_INITIAL_STATE_OBJECTIVE_IMPORTANCE_TITLE,
      description: OKR_INITIAL_STATE_OBJECTIVE_IMPORTANCE_DESCRIPTION,
      iconColor: '#9FC418',
      imageName: 'importance',
    },
  ],
};

// Initial state for the OKR key result state
export const INITIAL_OKR_KEY_RESULT_STATE: OKREntry = {
  status: OKREntryStatus.STARTED,
  title: OKR_INITIAL_STATE_KEY_RESULT,
  description: OKR_INITIAL_STATE_KEY_RESULT_DESCRIPTION,
  details: [
    {
      title: OKR_INITIAL_STATE_KEY_RESULT_ACHIEVABLE_TITLE,
      iconColor: '#0061FE',
      description: OKR_INITIAL_STATE_KEY_RESULT_ACHIEVABLE_DESCRIPTION,
      imageName: 'progressiveVerb',
    },
    {
      title: OKR_INITIAL_STATE_KEY_RESULT_CONCRETE_TITLE,
      description: OKR_INITIAL_STATE_KEY_RESULT_CONCRETE_DESCRIPTION,
      iconColor: '#EECC5D',
      imageName: 'achievable',
    },
    {
      title: OKR_INITIAL_STATE_KEY_RESULT_MEASURABLE_TITLE,
      description: OKR_INITIAL_STATE_KEY_RESULT_MEASURABLE_DESCRIPTION,
      iconColor: '#1B634B',
      imageName: 'metric',
    },
  ],
};

// Route paths
export const ROUTE_PATHS = {
  HOME: '/',
  GOALS: '/goals',
  GOALS_V2_ONBOARDING: '/goals/new',
  GOALS_V2_JOIN: '/goals/join',
  PEOPLE: '/people',
  PROFILE: '/people/:userid',
  PROJECT_DIGEST: '/digest',
  PROJECTS: '/projects',
  PULSES: '/pulse',
  PULSE_ID: '/pulse/:id',
  PULSE: '/pulse/:slug/:id',
  PULSE_GROUP: '/pulse/:group/:slug/:id',
  PROJECT: '/projects/:identifier',
  PROJECT_NEW: '/projects/new',
  PROJECT_EDIT: '/projects/:identifier/edit',
  TEAMS: '/teams',
  CHECKUP: '/checkup',
  CHECKUP_ID: '/checkup/:id',
  TEAM: '/teams/:slug',
  TEAM_EDIT: '/teams/:slug/edit',
  TEAM_NEW: '/teams/new',
  STRATEGIES: '/strategies',
  GRAMMARLY: '/okr/check',
  NEW_GOAL: '/goals/create',
  EDIT_GOAL: '/goals/:goalId/edit',
  SETTINGS: '/settings',
  REVIEW: '/review',
  EDITOR: '/editor',
  GOAL_DETAIL: '/goals/:goalid',
  GOAL_KR_DETAIL: '/goals/:goalid/:keyresultid',
  WAITLIST: '/waitlist',
  GRATITUDE_BROWSE: '/gratitude',
  GRATITUDE_INBOX: '/gratitude/inbox',
  GRATITUDE_OUTBOX: '/gratitude/outbox',
  LOGOUT: '/logout',
  ADMIN_CELERY_TASKS: '/admin/celery_tasks',
  ADMIN_CARD_MANAGEMENT: '/manage-cards',
  ADMIN_DELEGATES: '/admin/delegates',
  ADMIN_NOTIFICATIONS: '/admin/notifications',
  ADMIN_PULSE: '/admin/pulse',
  DSYS: '/dsys',
  MANAGE_BADGES: '/manage-badges',
  NEWS: '/news',
  NEWS_POST: '/news/:identifier',
  NEWS_NEW: '/news/new',
  NEWS_EDIT: '/news/:identifier/edit',
  DASHBOARDS: '/dashboard',
  DASHBOARDS_TEAM: '/dashboard/:teamSlug',
};

export const STEAM_TEAM_SLUG = 's-team';

export const STEAM_TEAM_NAME = 'S-team';

export const CURRENT_YEAR = new Date().getFullYear();

const CURRENT_QUARTER = Math.ceil((new Date().getMonth() + 1) / 3);

const generateTimeframeOptions = (count: number) => {
  const options: {[key: string]: string} = {};
  let quarter = 4;
  let year = 2023;

  for (let i = 0; i < count; i++) {
    // create a date for each next quarter
    const date = new Date();
    date.setDate(1); // we need to set date before setting month since day 31 can be invalid for some months
    date.setMonth((quarter - 1) * 3);
    date.setFullYear(year);
    date.setHours(0, 0, 0, 0);

    const distance = differenceInMinutes(date, new Date());
    let value;

    if (quarter === CURRENT_QUARTER && year === CURRENT_YEAR) {
      // ends in (date of end of quarter
      const endofQuarterDate = new Date(date);
      endofQuarterDate.setMonth(endofQuarterDate.getMonth() + 3);
      endofQuarterDate.setDate(endofQuarterDate.getDate() - 1);
      value = `Ends ${endofQuarterDate.toLocaleString('default', {
        month: 'short',
      })} ${endofQuarterDate.getDate()}`;
    } else if (distance < 0) {
      // ended end of quarter date
      const endofQuarterDate = new Date(date);
      endofQuarterDate.setMonth(endofQuarterDate.getMonth() + 3);
      endofQuarterDate.setDate(endofQuarterDate.getDate() - 1);

      value = `Ended ${endofQuarterDate.toLocaleString('default', {
        month: 'short',
      })} ${endofQuarterDate.getDate()}`;
    } else {
      value = `Starts ${date.toLocaleString('default', {month: 'short'})} ${date.getDate()}`;
    }

    options[`Q${quarter} FY${year - 2000}`] = value;
    quarter = (quarter % 4) + 1;
    if (quarter === 1) {
      year++;
    }
  }

  return options;
};

export const TIMEFRAME_OPTIONS = generateTimeframeOptions(10);

export const DEFAULT_TIMEFRAME = `Q${CURRENT_QUARTER} FY${CURRENT_YEAR - 2000}`;

// Function to generate strategy timeframe options dynamically
export const useStrategyTimeframeOptions = () => {
  const strategies = useAtomValue(strategiesAtom);
  return Object.keys(strategies).sort().reverse(); // Assuming you want them in descending order
};

export const GOOD_AI_GRADE = 'A';

type Timeframe = {[key: string]: string};

export const GROUPED_TIMEFRAME_OPTIONS: {
  previous: {[year: string]: Timeframe[]};
  current: Timeframe[];
  upcoming: {[year: string]: Timeframe[]};
} = Object.entries(TIMEFRAME_OPTIONS).reduce(
  (acc: any, [timeframe, date]) => {
    const year = `20${timeframe.match(/FY(\d+)/)?.[1]}`;
    const yearNumber = parseInt(year, 10);

    let type = 'previous';
    if (yearNumber === CURRENT_YEAR) {
      type = 'current';

      acc[type].push({timeframe, date});
      return acc;
    } else if (yearNumber > CURRENT_YEAR) {
      type = 'upcoming';
    }

    // eslint-disable-next-line no-param-reassign
    acc[type][year] = acc[type][year] || [];
    acc[type][year].push({timeframe, date});

    return acc;
  },
  {previous: {}, current: [], upcoming: {}}
);

type Quarter = {timeframe: string; dateRange: string};

interface QuartersOutput {
  previousQuarters: {[year: string]: Quarter[]};
  currentQuarters: Quarter[];
  archivedQuarters: {[year: string]: Quarter[]};
}

export function generateQuarterData(): QuartersOutput {
  const result: QuartersOutput = {
    previousQuarters: {},
    currentQuarters: [],
    archivedQuarters: {},
  };

  const getDateRange = (quarter: number, year: number): string => {
    const startMonth = (quarter - 1) * 3;
    const start = new Date(year, startMonth, 1);
    const end = new Date(year, startMonth + 3, 0); // Last day of quarter
    return `${start.toLocaleString('default', {month: 'short'})} ${start.getDate()} - ${end.toLocaleString('default', {month: 'short'})} ${end.getDate()}`;
  };

  const createQuarterObject = (quarter: number, year: number): Quarter => {
    return {
      timeframe: `Q${quarter} FY${year - 2000}`,
      dateRange: getDateRange(quarter, year),
    };
  };

  // Generate current quarters: previous, current, and next
  const currentQuarters = [];
  const previousQuarter = ((CURRENT_QUARTER - 2 + 4) % 4) + 1; // Previous quarter
  const nextQuarter = (CURRENT_QUARTER % 4) + 1; // Next quarter

  const currentYearAdjustments = [
    {quarter: previousQuarter, year: CURRENT_QUARTER === 1 ? CURRENT_YEAR - 1 : CURRENT_YEAR},
    {quarter: CURRENT_QUARTER, year: CURRENT_YEAR},
    {quarter: nextQuarter, year: CURRENT_QUARTER === 4 ? CURRENT_YEAR + 1 : CURRENT_YEAR},
  ];

  currentQuarters.push(
    ...currentYearAdjustments.map((q) => createQuarterObject(q.quarter, q.year))
  );
  result.currentQuarters = currentQuarters.reverse();

  // Generate previous quarters
  const previousQuarterInfo = currentYearAdjustments[0];
  const {quarter: prevQuarterNum, year: prevQuarterYear} = previousQuarterInfo;

  if (prevQuarterNum === 1) {
    // Include all quarters of the previous year
    result.previousQuarters[prevQuarterYear] = [];
    for (let q = 1; q <= 4; q++) {
      result.previousQuarters[prevQuarterYear].push(createQuarterObject(q, prevQuarterYear));
    }
  } else {
    // Include quarters before the previous quarter in the same year
    result.previousQuarters[prevQuarterYear] = [];
    for (let q = 1; q < prevQuarterNum; q++) {
      result.previousQuarters[prevQuarterYear].push(createQuarterObject(q, prevQuarterYear));
    }
  }

  const reversedPreviousQuarters = Object.fromEntries(
    Object.entries(result.previousQuarters).map(([year, quarters]) => [year, quarters.reverse()])
  );

  result.previousQuarters = reversedPreviousQuarters;

  // Generate archived quarters: years before the year of previousQuarters down to 2023
  for (let year = prevQuarterYear - 1; year >= 2023; year--) {
    result.archivedQuarters[year] = [];
    for (let q = 1; q <= 4; q++) {
      result.archivedQuarters[year].push(createQuarterObject(q, year));
    }
  }

  // remove Q1 and Q2 for 2023 in the archivedQuaters, which is the first indexc and second index
  if (result.archivedQuarters[2023]) {
    result.archivedQuarters[2023].splice(0, 2);
  }

  const reversedArchivedQuarters = Object.fromEntries(
    Object.entries(result.archivedQuarters).map(([year, quarters]) => [year, quarters.reverse()])
  );
  result.archivedQuarters = reversedArchivedQuarters;

  return result;
}
