import {sprig, SprigAPI} from '@sprig-technologies/sprig-browser';
import {isProd} from 'helpers/environment';

export const getSprig = (email: string) => {
  const SPRIG_DEV_ENVIRONMENT_ID = 'n_KU5RfA_gSt';
  const SPRIG_PROD_ENVIRONMENT_ID = 'VHeWMkMksnC8';
  const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

  const environmentId =
    email?.endsWith('@dbx51.com') || !isProd ? SPRIG_DEV_ENVIRONMENT_ID : SPRIG_PROD_ENVIRONMENT_ID;

  return sprig.configure({
    environmentId,
    userId: email,
    customStyles: `
      .ul-app {
        background-color: ${isDarkMode ? '#1a1918ff' : '#ffffffff'};
        padding-top: 2px
      }
      .ul-footer {
        display: none;
      } 
    `,
  }) as SprigAPI;
};
