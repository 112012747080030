import {Suspense} from 'react';
import {NewsView} from 'views/news/NewsView';

export const News = () => {
  return (
    <Suspense fallback={null}>
      <NewsView />
    </Suspense>
  );
};
