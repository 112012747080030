import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Split, Stack} from '@dropbox/dig-foundations';
import {useEffect} from 'react';

import {DrawerBackButton, DrawerCloseButton} from './DrawerButtons';

export const DrawerHeader = ({
  headerTitle,
  headerIcon,
  onBack,
  onClose,
}: {
  headerTitle: string;
  headerIcon: React.ReactNode;
  onBack?: () => void;
  onClose: () => void;
}) => {
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEsc);
    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  return (
    <Split alignY="top">
      <Split.Item width="fill">
        <Stack gap="12">
          {onBack && <DrawerBackButton onClick={onBack} />}
          <Box display="flex" alignItems="center">
            {headerIcon}
            <Box as={Text} isBold size="large" marginLeft="8">
              {headerTitle}
            </Box>
          </Box>
        </Stack>
      </Split.Item>

      <Split.Item>
        <DrawerCloseButton onClose={onClose} />
      </Split.Item>
    </Split>
  );
};
