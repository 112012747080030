import {Tabs} from '@dropbox/dig-components/dist/tabs';
import {Box} from '@dropbox/dig-foundations';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {t} from 'i18next';
import {useEffect, useState} from 'react';
import {queryClient} from 'views/QueryClientWrapper';

import {NotificationArchive} from './NotificationArchive';
import {NotificationInbox} from './NotificationInbox';
import {NotificationUpcoming} from './NotificationUpcoming';

export const NotificationCenter = ({handleSelection}: {handleSelection: () => void}) => {
  const [selectedTab, setSelectedTab] = useState('inbox');
  const [maxHeight, setMaxHeight] = useState(Math.max(380, window.innerHeight * 0.6 - 100));

  useEffect(() => {
    const handleResize = () => {
      setMaxHeight(Math.max(380, window.innerHeight * 0.6 - 100));
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    analyticsLogger().logEvent('NOTIFICATION_TRAY_OPENED', {tab: selectedTab});
    queryClient.invalidateQueries({queryKey: ['notifications']});
  }, [selectedTab]);

  return (
    <Tabs selectedTab={selectedTab} onSelection={setSelectedTab} style={{minHeight: 386}}>
      <Box marginX="8">
        <Tabs.Group>
          <Tabs.Tab id="inbox">{t('notification_inbox_tab')}</Tabs.Tab>
          <Tabs.Tab id="upcoming">{t('notification_upcoming_tab')}</Tabs.Tab>
          <Tabs.Tab id="archive">{t('notification_archive_tab')}</Tabs.Tab>
        </Tabs.Group>
      </Box>
      <Box style={{maxHeight}} overflowY="auto">
        <Tabs.Panel tabId="inbox">
          <NotificationInbox handleSelection={handleSelection} />
        </Tabs.Panel>
        <Tabs.Panel tabId="upcoming">
          <NotificationUpcoming />
        </Tabs.Panel>
        <Tabs.Panel tabId="archive">
          <NotificationArchive handleSelection={handleSelection} />
        </Tabs.Panel>
      </Box>
    </Tabs>
  );
};
