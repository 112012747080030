import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Truncate} from '@dropbox/dig-components/dist/truncate';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box, Cluster, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  ChevronRightLine,
  DeleteLine,
  EditLine,
  MoreHorizontalLine,
} from '@dropbox/dig-icons/assets';
import {GoalData, KeyResult} from 'client';
import {Facepile} from 'components/DSYS/Facepile';
import {Title} from 'components/DSYS/Title';
import {t} from 'i18next';
import {emailToLdap} from 'utilities';
import {SubtleKeyResultIcon, SubtleObjectiveIcon} from 'views/goals_v2/icons';

import styles from './NewGoalContent.module.css';

const calculateMaxWidth = (parentKeyResult?: KeyResult) => {
  if (!parentKeyResult) {
    return 596 - 24;
  }
  if (parentKeyResult.contributors.length === 0) {
    return 596 - 24;
  } else if (parentKeyResult.contributors.length === 1) {
    return 600 - 32 - 24;
  } else if (parentKeyResult.contributors.length === 2) {
    return 600 - 32 * 2 - 24;
  } else {
    return 600 - 32 * 2 - 24;
  }
};

export const ParentGoalSection = ({
  isDrawerOpen,
  onEditClick,
  onDeleteClick,
  parentGoal,
  parentKeyResult,
}: {
  isDrawerOpen: boolean;
  onEditClick: () => void;
  onDeleteClick: () => void;
  parentGoal?: GoalData;
  parentKeyResult?: KeyResult;
}) => {
  const isActive = parentGoal || parentKeyResult || isDrawerOpen;

  return (
    <Stack gap="8">
      <Cluster alignX="between">
        <Box as={Title} size="small">
          {t('parent_goal')}
        </Box>
        <Box as={Text} size="small" color="Text Subtle">
          {t('optional')}
        </Box>
      </Cluster>
      <Box
        borderRadius="Medium"
        borderStyle="Solid"
        borderColor={isActive ? 'transparent' : 'Border Subtle'}
        backgroundColor={isActive ? 'Opacity Surface - State 1' : 'transparent'}
        padding="12"
        display="flex"
        alignItems="center"
        style={{gap: '8px'}}
        className={styles.parentGoalSection}
        onClick={(e) => {
          if (!isActive) {
            e.stopPropagation();
            onEditClick();
          }
        }}
      >
        <Box
          as="div"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box as="div" maxWidth="100%">
            <LabelGroup
              withLeftAccessory={
                parentKeyResult ? <SubtleKeyResultIcon /> : <SubtleObjectiveIcon />
              }
              align="top"
              withText={
                <Text isBold size="large">
                  <Truncate
                    maxWidth={calculateMaxWidth(parentKeyResult)}
                    alwaysShowTooltip={false}
                    tooltipControlProps={{placement: 'top', openDelay: 300}}
                  >
                    {parentKeyResult
                      ? parentKeyResult.title
                      : parentGoal
                        ? parentGoal.title
                        : t('add_a_parent_goal')}
                  </Truncate>
                </Text>
              }
              withSubtext={
                <Text color="subtle" size="small">
                  {parentGoal
                    ? `${parentGoal.timeframe} • ${parentGoal.users?.[0].display_name}`
                    : t('align_the_work')}
                </Text>
              }
            />
          </Box>
          <Box>
            {parentGoal || parentKeyResult ? (
              <Box display="flex" alignItems="center">
                <Facepile
                  ownerIsFirst
                  ldaps={[
                    emailToLdap(parentGoal?.users?.[0].email) ?? '',
                    ...(parentKeyResult
                      ? (parentKeyResult.contributors.map((employeeLdap) => employeeLdap.ldap) ??
                        [])
                      : []),
                  ].filter(Boolean)}
                />
                <ActionMenu onEditClick={onEditClick} onDeleteClick={onDeleteClick} />
              </Box>
            ) : (
              !isDrawerOpen && (
                <IconButton variant="transparent" onClick={onEditClick}>
                  <UIIcon src={ChevronRightLine} />
                </IconButton>
              )
            )}
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

const ActionMenu = ({
  onEditClick,
  onDeleteClick,
}: {
  onEditClick: () => void;
  onDeleteClick: () => void;
}) => {
  const handleSelection = (value: string) => {
    switch (value) {
      case 'edit':
        onEditClick();
        break;
      case 'delete':
        onDeleteClick();
        break;
      default:
        break;
    }
  };
  return (
    <Menu.Wrapper onSelection={handleSelection}>
      {({getContentProps, getTriggerProps}) => (
        <>
          <IconButton {...getTriggerProps()} variant={'borderless'} shape="circular">
            <UIIcon src={MoreHorizontalLine} size="large" />
          </IconButton>
          <Menu.Content {...getContentProps()} minWidth={200}>
            <Menu.Segment>
              <Menu.ActionItem
                key="edit"
                value="edit"
                withLeftAccessory={<UIIcon src={EditLine} />}
              >
                {t('edit')}
              </Menu.ActionItem>

              <Menu.ActionItem
                key="delete"
                value="delete"
                withLeftAccessory={<UIIcon src={DeleteLine} />}
              >
                {t('delete')}
              </Menu.ActionItem>
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};
