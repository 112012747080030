/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmployeeWithDelegates } from '../models/EmployeeWithDelegates';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Read User Me
     * @returns User Successful Response
     * @throws ApiError
     */
    public static readUserMeApiV1AuthMeGet(): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/auth/me',
        });
    }

    /**
     * Get Token
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getTokenApiV1AuthTokenGet(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/auth/token',
        });
    }

    /**
     * Set Delegates
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setDelegatesApiV1DelegatesPost(
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/delegates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Delegates
     * @returns EmployeeWithDelegates Successful Response
     * @throws ApiError
     */
    public static getDelegatesApiV1DelegatesAdminGet(): CancelablePromise<Array<EmployeeWithDelegates>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/delegates/admin',
        });
    }

    /**
     * Set Delegates Admin
     * @param requestBody
     * @returns EmployeeWithDelegates Successful Response
     * @throws ApiError
     */
    public static setDelegatesAdminApiV1DelegatesAdminPost(
        requestBody: EmployeeWithDelegates,
    ): CancelablePromise<EmployeeWithDelegates> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/delegates/admin',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
