import {Checkbox} from '@dropbox/dig-components/dist/controls';
import {FormLabel} from '@dropbox/dig-components/dist/form_row';
import {Toggletip} from '@dropbox/dig-components/tooltips';
import {Split} from '@dropbox/dig-foundations';
import {t} from 'i18next';

import styles from './ContinueGoal.module.css';

export const ContinueGoalToggle = ({
  isContinueGoalToggled,
  setIsContinueGoalToggled,
  enforceEnableContinueGoalToggle,
}: {
  isContinueGoalToggled: boolean;
  setIsContinueGoalToggled: (isToggled: boolean) => void;
  enforceEnableContinueGoalToggle: boolean;
}) => {
  const continueGoalCheckboxId = 'continue-goal-checkbox';
  return (
    <Split>
      <Split.Item>
        <Checkbox
          id={continueGoalCheckboxId}
          checked={isContinueGoalToggled}
          onClick={() => setIsContinueGoalToggled(!isContinueGoalToggled)}
          disabled={enforceEnableContinueGoalToggle}
        />
      </Split.Item>
      <Split.Item>
        <FormLabel
          tagName="label"
          htmlFor={continueGoalCheckboxId}
          subtext={<Toggletip title={t('continue_goal_toggle_description')} />}
          className={styles.continueGoalToggleLabel}
        >
          {t('continue_goal_toggle_title')}
        </FormLabel>
      </Split.Item>
    </Split>
  );
};
