import {AvatarNotification} from './types/AvatarNotification';
import {BadgeNomination} from './types/BadgeNomination';
import {BadgeReceived} from './types/BadgeReceived';
import {ContributorAssignNotification} from './types/ContributorAssignNotification';
import {ContributorJoinNotification} from './types/ContributorJoinNotification';
import {GenericNotification} from './types/GenericNotification';
import {GoalCommentAdded, KeyResultCommentAdded} from './types/GoalCommentAdded';
import {OKRAction} from './types/OKRAction';
import {NotificationCardContext, NotificationProvider} from './utils/useNotificationContext';

// Special notifications, otherwise use the GenericNotification
const NotificationComponentMapping = {
  // Badges require looking up badge data
  badge_received: BadgeReceived,
  badge_nomination: BadgeNomination,

  // OKR notifications require lookups to goals
  okr_write: OKRAction,
  okr_update: OKRAction,
  okr_grade: OKRAction,

  // AvatarNotification has special ldap employee lookup
  okr_review: AvatarNotification,

  // GoalCommentAdded has comment thread lookups
  goal_comment_added: GoalCommentAdded,
  key_result_comment_added: KeyResultCommentAdded,

  // goal contributor join has special ldap employee lookup
  kr_contributor_join: ContributorJoinNotification,
  kr_contributor: ContributorAssignNotification,
};

export const NotificationRenderer = (props: NotificationCardContext) => {
  const NotificationComponent =
    NotificationComponentMapping[
      props.notification.type as keyof typeof NotificationComponentMapping
    ] ?? GenericNotification;

  return (
    <NotificationProvider {...props}>
      <NotificationComponent />
    </NotificationProvider>
  );
};
