import {Text} from '@dropbox/dig-components/typography';
import {Box, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CommentLine} from '@dropbox/dig-icons/assets';
import {CommentsMini} from '@dropbox/dig-illustrations';
import {Goal, KeyResult, Thread} from 'client';
import {DrawerCommentInput} from 'components/comments/CommentInput';
import {DrawerCommentThread} from 'components/comments/Comments';
import {Eyebrow} from 'components/DSYS/Eyebrow';
import {useEffect, useState} from 'react';

import styles from './Drawer.module.css';
import {DrawerSectionTitle} from './DrawerSection';
import {deactivateThread} from './utils';

const sortThreads = (a: Thread, b: Thread) => {
  return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
};

export const CommentDrawerBody = ({
  threads,
  goal,
  keyResult,
}: {
  threads: Thread[];
  goal: Goal;
  keyResult?: KeyResult;
}) => {
  const [activatedThread, setActivatedThread] = useState<Thread['id'] | null>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (!activatedThread) return;

      const commentThread = document.querySelector(`.comment-thread-${activatedThread}`);

      if (
        !commentThread?.contains(event.target as HTMLElement) &&
        deactivateThread(event.target as HTMLElement)
      ) {
        setActivatedThread(null);
      }
    };

    document.body.addEventListener('click', handleClick);

    return () => {
      document.body.removeEventListener('click', handleClick);
    };
  }, [activatedThread]);

  return (
    <>
      <Stack>
        <CommentsHeader />
        {threads.length == 0 && <EmptyComments />}
        <Box marginBottom="20">
          <DrawerCommentInput
            goal={goal}
            keyResult={keyResult}
            setActivatedThread={setActivatedThread}
          />
        </Box>
        <Box display="flex" flexDirection="column" style={{gap: '12px'}}>
          {threads
            .sort(sortThreads)
            .filter((thread) => thread.is_resolved === false)
            .map((thread: Thread) => (
              <DrawerCommentThread
                key={thread.id}
                thread={thread}
                goal={goal}
                activtedThread={activatedThread}
                setActivatedThread={setActivatedThread}
              />
            ))}
        </Box>
      </Stack>
    </>
  );
};

const CommentsHeader = () => {
  return (
    <DrawerSectionTitle>
      <Box display="flex" alignItems="center" style={{gap: '4px'}}>
        <Box as={UIIcon} src={CommentLine} color="Text Subtle" size="small" />
        <Box as={Eyebrow} display="block">
          COMMENTS
        </Box>
      </Box>
    </DrawerSectionTitle>
  );
};

const EmptyComments = () => {
  return (
    <Box
      display="flex"
      alignItems="flex-start"
      paddingLeft="16"
      paddingRight="16"
      paddingTop="8"
      paddingBottom="8"
      style={{gap: '16px'}}
    >
      <Box paddingTop="4" paddingBottom="4" className={styles.commentsMini}>
        <CommentsMini altText="commentMini" width="48" height="48" />
      </Box>
      <Stack paddingTop="4" paddingBottom="4">
        <Box as={Text} variant="paragraph" isBold display="block">
          Collaborate with your manager and team
        </Box>
        <Box as={Text} variant="paragraph" color="Text Subtle">
          Add a comment and collaborate on any goal
        </Box>
      </Stack>
    </Box>
  );
};
