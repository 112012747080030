import {
  AiEventService,
  ApiError,
  BadgeService,
  CardService,
  EmployeeService,
  GoalService,
  GratitudeService,
  KeyResultService,
  LoginService,
  TeamService,
  UserService,
} from 'client';
import {OpenAPI} from 'client/core/OpenAPI';
import {CurrentEnvironment, getCurrentEnvironment} from 'helpers/environment';

import standardConfig from './config.json';
import prodConfig from './config.prod.json';
import stagingConfig from './config.staging.json';

const configData =
  getCurrentEnvironment() === CurrentEnvironment.Development
    ? {...standardConfig}
    : getCurrentEnvironment() === CurrentEnvironment.Production
      ? {...prodConfig}
      : {...stagingConfig};

OpenAPI.BASE = configData.AUTH_V1_BACKEND_URL;
OpenAPI.CREDENTIALS = 'include';
OpenAPI.WITH_CREDENTIALS = true;

export const getLoginUrl = () => {
  return configData.LOGIN_URL;
};

export const getLogoutUrl = () => {
  return configData.LOGOUT_URL;
};

export const getFrontendUrl = () => {
  return configData.FRONTEND_URL;
};

export const getBackendUrl = () => {
  return configData.BACKEND_URL;
};

export const getWaitlistUrl = () => {
  return configData.WAITLIST_URL;
};

export const getProfileImageUrl = (ldap: string) => {
  return `/api/v1/images/${ldap}.jpg`;
};

export const emailToLdap = (email?: string) => email?.split('@')[0] ?? '';

export const nameToInitials = (name: string) => {
  return name
    ?.split(' ')
    .slice(0, 2)
    .map((word) => word[0])
    .join('')
    .toUpperCase();
};

export const getService = <T,>(ServiceClass: T): T => {
  OpenAPI.BASE = configData.AUTH_V1_BACKEND_URL;
  OpenAPI.CREDENTIALS = 'include';
  OpenAPI.WITH_CREDENTIALS = true;
  return ServiceClass;
};

export const getAuthService = () => {
  OpenAPI.BASE = configData.AUTH_V1_BACKEND_URL;
  OpenAPI.CREDENTIALS = 'include';
  OpenAPI.WITH_CREDENTIALS = true;
  return UserService;
};

export const getLoginService = () => {
  OpenAPI.BASE = configData.AUTH_V1_BACKEND_URL;
  OpenAPI.CREDENTIALS = 'include';
  OpenAPI.WITH_CREDENTIALS = true;
  return LoginService;
};

export const getGoalService = () => {
  OpenAPI.BASE = configData.BACKEND_URL;
  OpenAPI.CREDENTIALS = 'include';
  OpenAPI.WITH_CREDENTIALS = true;
  return GoalService;
};

export const getAIEventService = () => {
  OpenAPI.BASE = configData.BACKEND_URL;
  OpenAPI.CREDENTIALS = 'include';
  OpenAPI.WITH_CREDENTIALS = true;
  return AiEventService;
};

export const getKeyResultService = () => {
  OpenAPI.BASE = configData.BACKEND_URL;
  OpenAPI.CREDENTIALS = 'include';
  OpenAPI.WITH_CREDENTIALS = true;
  return KeyResultService;
};

export const getEmployeeService = () => {
  OpenAPI.BASE = configData.BACKEND_URL;
  OpenAPI.CREDENTIALS = 'include';
  OpenAPI.WITH_CREDENTIALS = true;
  return EmployeeService;
};

export const getTeamsService = () => {
  OpenAPI.BASE = configData.BACKEND_URL;
  OpenAPI.CREDENTIALS = 'include';
  OpenAPI.WITH_CREDENTIALS = true;
  return TeamService;
};

export const getCardsService = () => {
  OpenAPI.BASE = configData.BACKEND_URL;
  OpenAPI.CREDENTIALS = 'include';
  OpenAPI.WITH_CREDENTIALS = true;
  return CardService;
};

export const getGratitudesService = () => {
  OpenAPI.BASE = configData.BACKEND_URL;
  OpenAPI.CREDENTIALS = 'include';
  OpenAPI.WITH_CREDENTIALS = true;
  return GratitudeService;
};

export const getBadgesService = () => {
  return BadgeService;
};

export const isStageTestAccount = (email?: string | null) => {
  return email?.endsWith('@dbx51.com');
};

export const isUserDeactivatedApiError = (error: ApiError) => {
  return error.body?.detail === 'User is deactivated';
};
