export const formatMarkdownForOKR = (objectiveInput: string, keyResults: string[]) => {
  const objective = objectiveInput.replace(/\n/g, '  \n');
  const keyResultList = keyResults.map((keyResult) => {
    return keyResult.replace(/\n/g, '  \n');
  });
  const mutatedKeyResutls = keyResultList.map((keyResult) => {
    return `- ${keyResult}`;
  });
  const keyResultString = mutatedKeyResutls.join('\n');
  return `# Objective\n${objective}\n\n## Key Results\n${keyResultString}`;
};
