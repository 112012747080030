import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Table} from '@dropbox/dig-components/dist/table';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {ProjectTicketShallow} from 'client';
import {EmptyCell} from 'components/shared/table/EmptyCell';
import {calculateProgress} from 'views/projects/utils';

export const ProgressCell = ({
  isProject,
  epics,
  progress,
  total_tickets,
  width,
}: {
  projectId: number;
  isProject: boolean;
  epics: ProjectTicketShallow[];
  progress?: number;
  total_tickets?: number;
  width: number;
}) => {
  const ticketCount = epics.filter((epic) => epic.type !== 'Epic').length + (total_tickets ?? 0);
  const completedCount =
    epics.filter((epic) => epic.type !== 'Epic' && epic.status === 'Done').length + (progress ?? 0);

  if (isProject && !ticketCount) {
    if (epics.length || total_tickets !== undefined) {
      return <EmptyCell />;
    }

    return (
      <Table.Cell>
        <Box
          as={Text}
          size="small"
          color="Text Subtle"
          isBold
          style={{
            textOverflow: 'ellipsis',
            maxWidth: width,
          }}
          overflow="hidden"
          whiteSpace="nowrap"
        >
          No linked epic
        </Box>
      </Table.Cell>
    );

    // return (
    //   <Table.Cell>
    //     <ButtonLink
    //       to={`/projects/${projectId}/edit`}
    //       state={{autofocus: 'epics'}}
    //       size="small"
    //       variant="borderless"
    //       withIconStart={<Box as={UIIcon} src={JiraExternalLogo} color="Text Subtle" />}
    //       style={{marginLeft: -5}}
    //       onClick={(e: any) => e.stopPropagation()}
    //     >
    //       <Box
    //         as={Text}
    //         size="small"
    //         color="Text Subtle"
    //         isBold
    //         style={{
    //           textOverflow: 'ellipsis',
    //           maxWidth: width,
    //         }}
    //         overflow="hidden"
    //         whiteSpace="nowrap"
    //       >
    //         Add epic
    //       </Box>
    //     </ButtonLink>
    //   </Table.Cell>
    // );
  }

  if (!isProject && !ticketCount) {
    return <EmptyCell />;
  }

  return (
    <Table.Cell>
      <LabelGroup
        withText={
          <Box as={Text} isBold size="small">
            {`${calculateProgress(completedCount, ticketCount) ?? '—'}%`}
          </Box>
        }
        withSubtext={
          <Box as={Text} color="Text Subtle" size="xsmall">
            {width < 70
              ? `${completedCount}/${ticketCount}`
              : `${completedCount}/${ticketCount} complete`}
          </Box>
        }
      />
    </Table.Cell>
  );
};
