import {Button} from '@dropbox/dig-components/dist/buttons';
import {Table} from '@dropbox/dig-components/dist/table';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CheckmarkLine} from '@dropbox/dig-icons/assets';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {snackbarAtom} from 'atoms/snackbar';
import {Goal, GoalData, GoalRead, KeyResult} from 'client';
import {AccordionIconButton} from 'components/DSYS/Accordion';
import {useGoals, useKeyResultService} from 'components/goals/hooks';
import {StickyHeader} from 'components/shared/table/Header';
import {ColumnConfig} from 'components/shared/table/useColumnResize';
import {ROUTE_PATHS} from 'constant';
import {isGoalType} from 'helpers/utils';
import {useAtomValue, useSetAtom} from 'jotai';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {emailToLdap} from 'utilities';
import {NoFilterTable, NoResultsTable} from 'views/goals_v2/EmptyGoalsTable';
import {GoalsTableFilter} from 'views/goals_v2/types';
import {filterTable} from 'views/goals_v2/utils';
import {queryClient} from 'views/QueryClientWrapper';

import {GoalsJoinTableRow, hasKeyResultSelected, shouldDisableCheckbox} from './GoalsJoinTableRow';

const getInitialExpandedState = (goals: Goal[]) => {
  const expanded: {[id: string]: boolean} = {};
  goals.forEach((goal) => {
    expanded[`goals-${goal.id}`] = true;
  });
  return expanded;
};

export const filterKeyResults = (goal: Goal, ownerLdap: string) => {
  if (emailToLdap(goal.users?.[0].email) == ownerLdap) {
    return goal.key_results;
  }
  return goal.key_results.filter((kr) => {
    return kr.contributors.some((contributor) => contributor.ldap === ownerLdap);
  });
};

const dedupeGoals = (goals: Goal[]) => {
  const dedupedGoals: Goal[] = [];
  goals.forEach((goal) => {
    if (!dedupedGoals.some((g) => g.id === goal.id)) {
      dedupedGoals.push(goal);
    }
  });
  return dedupedGoals;
};

export const GoalsJoinTable = ({
  filter,
  timeframe,
  columnConfigs,
  onColumnDrag,
  handlerAddFilterButtonClick,
  delegateId,
  delegateLdap,
}: {
  filter: GoalsTableFilter;
  timeframe: string;
  columnConfigs: ColumnConfig[];
  onColumnDrag: (index: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  handlerAddFilterButtonClick: () => void;
  delegateId?: string;
  delegateLdap?: string;
}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<{[id: number]: boolean}>({});
  const [, setSort] = useState({type: 'name', asc: true});
  const {goals} = useGoals({ldaps: filter.people, timeframe: timeframe, throwOnError: false});
  const [expanded, setExpanded] = useState<{[id: string]: boolean}>(getInitialExpandedState(goals)); // expanded goals
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const setSnackbarMessage = useSetAtom(snackbarAtom);

  const {employee} = useAtomValue(loggedInEmployeeAtom);

  const selectedKeyResultsCount = Object.values(selectedCheckboxes).filter(
    (selected) => selected
  ).length;

  const {updateKRContributors} = useKeyResultService({
    timeframe: timeframe,
    ownerLdaps: [employee.ldap, delegateLdap ?? ''],
    forceUpdate: false,
  });

  const undoHandleContribute = async () => {
    const selectedKeyResults = Object.keys(selectedCheckboxes).filter(
      (id) => selectedCheckboxes[parseInt(id)]
    );
    setIsConfirmLoading(true);
    // Create an array of promises for undoing the KR contributors
    const undoPromises = selectedKeyResults
      .map((krId) => {
        const goal = goals.find((g) => g.key_results.some((kr) => kr.id === parseInt(krId)));
        if (goal) {
          const keyResult = goal.key_results.find((kr) => kr.id === parseInt(krId));
          if (keyResult) {
            return updateKRContributors({
              keyResultId: keyResult.id,
              ownerId: goal!.users![0].user_id,
              employeeId: delegateId ? delegateId : employee.user_id,
              join: false,
            });
          }
        }
        return null; // Return null if no undo is needed
      })
      .filter(Boolean); // Filter out null values

    // Execute all undo operations in parallel
    await Promise.all(undoPromises).then(() => {
      setIsConfirmLoading(false);
      setSnackbarMessage({
        text: t('co-owners_updated'),
      });
    });
  };

  const handleContribute = async () => {
    setIsConfirmLoading(true);
    //get all the selected key results ids if the checkbox is true
    const selectedKeyResults = Object.keys(selectedCheckboxes).filter(
      (id) => selectedCheckboxes[parseInt(id)]
    );
    // Create an array of promises for updating KR contributors
    const updatePromises = selectedKeyResults
      .map((krId) => {
        const goal = goals.find((g) => g.key_results.some((kr) => kr.id === parseInt(krId)));
        if (goal) {
          const keyResult = goal.key_results.find((kr) => kr.id === parseInt(krId));
          if (keyResult) {
            return updateKRContributors({
              keyResultId: keyResult.id,
              ownerId: goal!.users![0].user_id,
              employeeId: delegateId ? delegateId : employee.user_id,
              join: true,
            });
          }
        }
        return null; // Return null if no update is needed
      })
      .filter(Boolean); // Filter out null values

    // Execute all updates in parallel
    await Promise.all(updatePromises)
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: ['goals', delegateLdap ?? employee.ldap],
          type: 'all',
        })
      )
      .then(() => {
        setSnackbarMessage({
          text: t('joined_key_result_as_coowner', {count: selectedKeyResults.length}),
          action: {
            text: (
              <Button variant="transparent" isLoading={isConfirmLoading}>
                {t('undo')}
              </Button>
            ),
            onClick: () => {
              undoHandleContribute();
            },
          },
        });

        setIsConfirmLoading(false);
        const navigateUrl = delegateLdap
          ? `${ROUTE_PATHS.GOALS}?t=${timeframe}&people=${delegateLdap}`
          : `${ROUTE_PATHS.GOALS}?t=${timeframe}`;
        navigate(navigateUrl);
      });
  };

  useEffect(() => {
    setExpanded(getInitialExpandedState(goals));
    setSelectedCheckboxes({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeframe, filter.people, delegateId, delegateLdap]);

  const onRowClick = (
    row: GoalData | KeyResult | GoalRead,
    ownerLdap: string,
    loggedInUserLdap: string,
    goalOwnerLdap?: string
  ) => {
    if (isGoalType(row)) {
      if (
        hasKeyResultSelected(
          row as Goal,
          selectedCheckboxes,
          ownerLdap,
          loggedInUserLdap,
          delegateLdap
        )
      ) {
        // set all the key results to selected
        const newSelectedCheckboxes = {...selectedCheckboxes};
        filterKeyResults(row as Goal, ownerLdap).forEach((kr) => {
          if (shouldDisableCheckbox(kr, loggedInUserLdap, delegateLdap, goalOwnerLdap)) {
            return;
          }
          newSelectedCheckboxes[kr.id] = true;
        });
        setSelectedCheckboxes(newSelectedCheckboxes);
      } else {
        // set all the key results to unselected
        const newSelectedCheckboxes = {...selectedCheckboxes};
        filterKeyResults(row as Goal, ownerLdap).forEach((kr) => {
          if (shouldDisableCheckbox(kr, loggedInUserLdap, delegateLdap, goalOwnerLdap)) {
            return;
          }
          newSelectedCheckboxes[kr.id] = !selectedCheckboxes[kr.id];
        });
        setSelectedCheckboxes(newSelectedCheckboxes);
      }
    } else {
      if (shouldDisableCheckbox(row as KeyResult, loggedInUserLdap, delegateLdap, goalOwnerLdap)) {
        return;
      }
      setSelectedCheckboxes({
        ...selectedCheckboxes,
        [row.id]: !selectedCheckboxes[row.id],
      });
    }
  };

  const filteredGoals = dedupeGoals(goals.filter(filterTable(filter)));

  if (filteredGoals.length === 0 && filter.people.length !== 0) {
    return <NoResultsTable />;
  }

  if (filter.people.length === 0) {
    return <NoFilterTable onButtonClick={handlerAddFilterButtonClick} />;
  }

  const renderRows = (rows: GoalData[]) => {
    const rowKeyPrefix = `table_row`;
    return rows.map((row) => (
      <GoalsJoinTableRow
        {...row}
        key={`${rowKeyPrefix}-${row.id}`}
        rowKeyPrefix={rowKeyPrefix}
        row={row}
        goalOwner={row.users![0]}
        onRowClick={onRowClick}
        columnConfigs={columnConfigs}
        expandId={`goals-${row.id}`}
        setExpanded={setExpanded}
        expanded={expanded}
        selectedCheckboxes={selectedCheckboxes}
        indent={0}
        ownerLdap={filter.people[0]}
        delegateLdap={delegateLdap}
        withLeftAccessory={
          <Box
            onClick={(e: any) => {
              e.stopPropagation();
              e.preventDefault();
              setExpanded({
                ...expanded,
                [`goals-${row.id}`]: !expanded[`goals-${row.id}`],
              });
            }}
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
            height="100%"
          >
            <AccordionIconButton
              isHidden={false}
              isActive={expanded[`goals-${row.id}`]}
              toggle={(e) => {
                e.stopPropagation();
                e.preventDefault();
                const toggleExpanded = !expanded[`goals-${row.id}`];
                setExpanded({
                  ...expanded,
                  [`goals-${row.id}`]: toggleExpanded,
                });
              }}
            />
            {expanded[`goals-${row.id}`] && row.key_results.length > 0 && (
              <Box
                as="div"
                style={{
                  width: '1px',
                  height: 'calc(100% - 24px)',
                }}
                backgroundColor="Border Subtle"
                marginLeft="12"
              ></Box>
            )}
          </Box>
        }
      />
    ));
  };

  return (
    <Box
      borderRadius="Medium"
      borderColor="Border Subtle"
      borderStyle="Solid"
      borderWidth="1"
      maxWidth="100%"
      marginX="auto"
      style={{width: '100%', overflowY: 'auto', marginTop: 0, maxHeight: `calc(100vh - 460px)`}}
    >
      <Box as="div" paddingX="24" paddingBottom="8">
        <Table hasDividers={false} spacing="small" verticalAlign="center">
          <StickyHeader
            columnConfigs={columnConfigs}
            sort={undefined}
            setSort={setSort}
            getMouseDownHandler={onColumnDrag}
          />
          <Table.Body>
            <Box as="div" style={{height: 8}}></Box>
            {renderRows(filteredGoals)}
          </Table.Body>
        </Table>
      </Box>
      <Box
        as="div"
        width="100%"
        style={{height: '80px', position: 'sticky', bottom: 0}}
        backgroundColor="Background Base"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        paddingX="24"
        borderTop="Solid"
        borderColor="Border Subtle"
      >
        {selectedKeyResultsCount > 0 && (
          <Box
            as="div"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            style={{
              marginRight: '30px',
              backgroundColor: 'var(--dig-color__success__on-base)',
              padding: '4px 16px 4px 12px',
              gap: '4px',
              borderRadius: '999px',
            }}
          >
            <Box as={UIIcon} src={CheckmarkLine} color="Text Base" />
            <Box as={Text}>{t('key_result_selected', {count: selectedKeyResultsCount})}</Box>
          </Box>
        )}
        <Box display="flex" style={{gap: '8px'}}>
          <Box
            as={Button}
            variant="opacity"
            onClick={() => {
              navigate(-1);
            }}
          >
            {t('back')}
          </Box>
          <Box
            as={Button}
            variant="primary"
            onClick={handleContribute}
            disabled={selectedKeyResultsCount == 0}
            isLoading={isConfirmLoading}
          >
            {t('confirm')}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
