import {Checkbox} from '@dropbox/dig-components/dist/controls';
import {Table} from '@dropbox/dig-components/dist/table';
import {Box, BoxProps} from '@dropbox/dig-foundations';
import {pulseUserAtom} from 'atoms/auth';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {Goal, GoalData, GoalRead, GoalUser, KeyResult} from 'client';
import {AccordionIconButton} from 'components/DSYS/Accordion';
import {ColumnConfig} from 'components/shared/table/useColumnResize';
import {isGoalType} from 'helpers/utils';
import {useAtomValue} from 'jotai';
import {Fragment, ReactNode, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {emailToLdap} from 'utilities';
import {ContributorCell} from 'views/goals_v2/Table/ContributorCell';
import {GoalsNameCell} from 'views/goals_v2/Table/GoalsNameCell';
import {getRowData} from 'views/goals_v2/utils';

import {filterKeyResults} from './GoalsJoinTable';

const indentWidth: {[index: number]: string} = {
  0: '0',
  1: '0',
  2: '23',
  3: '44',
};

export const getKeyResultChildren = (
  keyResultId: number,
  goal: GoalData | KeyResult | GoalRead
) => {
  if (isGoalType(goal) && 'key_results_aligned_goals' in goal) {
    return goal.key_results_aligned_goals?.filter((g) => g.key_result_parent_id === keyResultId);
  }

  return [];
};

export const hasKeyResultSelected = (
  goal: Goal,
  selectedCheckboxes: {[id: string]: boolean},
  ownerLdap?: string,
  loggedInUserLdap?: string,
  delegateLdap?: string
) => {
  // if all the key results of the given goal is in the selectedCheckboxes, return false
  if (goal.key_results.length === 0) {
    return false;
  }
  let keyResults = goal.key_results;
  if (ownerLdap) {
    keyResults = filterKeyResults(goal, ownerLdap);
  }
  // filter out the key result taht should disabled the checkbox
  keyResults = keyResults.filter(
    (kr) => !shouldDisableCheckbox(kr, loggedInUserLdap ?? '', delegateLdap)
  );
  if (keyResults.every((kr) => selectedCheckboxes[kr.id])) {
    return false;
  }
  // if some of the key results of the given goal is in the selectedCheckboxes, return true
  if (keyResults.some((kr) => selectedCheckboxes[kr.id])) {
    return true;
  }
  return false;
};

export const shouldDisableCheckbox = (
  row: GoalData | KeyResult | GoalRead,
  loggedInUserLdap: string,
  delegateLdap?: string,
  goalOwnerLdap?: string
) => {
  if (isGoalType(row)) {
    return false;
  } else {
    // check if the key result contributors include the ownerLdap or delegateLdap
    if (delegateLdap) {
      if (goalOwnerLdap && goalOwnerLdap === delegateLdap) {
        return true;
      }
      return row.contributors.some((contributor) => contributor.ldap === delegateLdap);
    }
    if (goalOwnerLdap && goalOwnerLdap === loggedInUserLdap) {
      return true;
    }
    return row.contributors.some((contributor) => contributor.ldap === loggedInUserLdap);
  }
};

const isAllKeyResultsDisabled = (
  goal: Goal,
  ownerLdap: string,
  loggedInUserLdap: string,
  delegateLdap?: string
) => {
  if (delegateLdap && emailToLdap(goal.users?.[0].email) === delegateLdap) {
    return true;
  } else if (!delegateLdap && emailToLdap(goal.users?.[0].email) === loggedInUserLdap) {
    return true;
  }
  return filterKeyResults(goal, ownerLdap).every((kr) =>
    shouldDisableCheckbox(kr, loggedInUserLdap, delegateLdap)
  );
};

export const GoalsJoinTableRow = ({
  uselessTable,
  expanded,
  columnConfigs,
  withLeftAccessory,
  onRowClick,
  selectedCheckboxes,
  // selectedGoalData,
  indent = 0,
  id,
  expandId,
  rowKeyPrefix,
  row,
  goalOwner,
  ownerLdap,
  delegateLdap,
  setExpanded,
  paddingTop,
  paddingLeft, // ...rest
  rowProps = {},
  isLastRow = false,
}: {
  id: number;
  uselessTable?: boolean;
  rowKeyPrefix: string;
  row: GoalData | KeyResult | GoalRead;
  goalOwner: GoalUser;
  expandId?: string;
  expanded: {[key: string]: boolean};
  selectedCheckboxes: {[id: string]: boolean};
  withLeftAccessory?: ReactNode;
  onRowClick: (
    row: GoalData | KeyResult | GoalRead,
    ownerLdap: string,
    loggedInUserLdap: string,
    goalOwnerLdap?: string
  ) => void;
  ownerLdap: string;
  delegateLdap?: string;
  setExpanded?: (value: React.SetStateAction<{[id: string]: boolean}>) => void;
  indent?: number;
  columnConfigs: ColumnConfig[];
  paddingTop?: BoxProps<'div'>['paddingTop'];
  paddingLeft?: BoxProps<'div'>['paddingLeft'];
  rowProps?: {[key: string]: any};
  isLastRow?: boolean;
  isDraft?: boolean;
}) => {
  const isGoalRow = isGoalType(row);
  const {t} = useTranslation();

  const {delegatedBy} = useAtomValue(loggedInEmployeeAtom);
  const pulseUser = useAtomValue(pulseUserAtom);
  const {currentRowGoalOwner} = getRowData(row, goalOwner, pulseUser?.email, delegatedBy);

  const [selectedGoalChecked, setSelectedGoalChecked] = useState<boolean>(false);

  const {marginLeft, width} = useMemo(() => {
    const left = parseInt(indentWidth[indent] as any, 10);

    return {
      marginLeft: left.toString() as any,
      width: uselessTable
        ? columnConfigs[0].width + 44
        : columnConfigs[0].width + 44 - left - (indent === 1 ? 24 : 0) - (indent === 2 ? -72 : 0),
    };
  }, [columnConfigs, indent, uselessTable]);

  const filteredKeyResults = ((row as Goal)?.key_results ?? []).filter((kr) => {
    if (emailToLdap(goalOwner.email) === ownerLdap) {
      return true;
    }
    return kr.contributors.some((contributor) => contributor.ldap === ownerLdap);
  });

  const isCurrentRowSelected = () => {
    if (isGoalRow) {
      if (row.key_results.length === 0) {
        return selectedGoalChecked;
      }
      return row.key_results.every((kr) => selectedCheckboxes[kr.id]);
    }
    // if the row.id not in the selectedCheckboxes, return false
    if (!selectedCheckboxes[row.id]) {
      return false;
    }
    return selectedCheckboxes[row.id];
  };

  const isKeyResultSelected = (goalRow: Goal) => {
    // if all the key results of the given goal is in the selectedCheckboxes, return false
    if (goalRow.key_results.length === 0) {
      return false;
    }
    let keyResults = goalRow.key_results;
    if (ownerLdap) {
      keyResults = filterKeyResults(goalRow, ownerLdap);
    }
    if (keyResults.every((kr) => selectedCheckboxes[kr.id])) {
      return true;
    }
    // if some of the key results of the given goal is in the selectedCheckboxes, return true
    if (keyResults.some((kr) => selectedCheckboxes[kr.id])) {
      return true;
    }

    return false;
  };

  const renderLeftAccessory = (
    childRow: GoalRead | KeyResult,
    index: number,
    type: 'keyresult' | 'goal',
    hasChildren: boolean
  ) => {
    const expandedRowId = childRow.id;
    const activeExpandId =
      type === 'goal' ? `goals-${expandedRowId}` : `keyresult-${expandedRowId}`;

    const isMiddle: boolean = (
      type === 'keyresult'
        ? 'key_results' in row && index !== filteredKeyResults.length - 1
        : 'children' in row &&
          row.children &&
          Array.isArray(row.children) &&
          index !== row.children.length - 1
    ) as boolean;

    return (
      <Box
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
        }}
        height="100%"
      >
        {indent > 0 && (
          <Box
            as="span"
            height="100%"
            borderColor={isLastRow ? 'transparent' : 'Border Subtle'}
            borderLeft="Solid"
            style={{
              minHeight: '68px',
              width: '12px',
              marginLeft: '12px',
              paddingLeft: '23px',
            }}
            display="block"
          />
        )}
        <Box
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
          height="100%"
        >
          {
            <>
              <Box as="div" style={{display: 'flex'}} height="100%">
                <Box as="div" height="100%">
                  <Box
                    as="span"
                    style={{
                      width: '24px',
                      height: '20px',
                      marginLeft: '12px',
                      borderRadius: isMiddle ? '0' : '0 0 0 4px',
                    }}
                    borderLeft="Solid"
                    borderBottom="Solid"
                    borderColor="Border Subtle"
                    display="block"
                  ></Box>
                  {isMiddle && (
                    <Box
                      as="span"
                      style={{
                        width: '12px',
                        height: 'calc(100% - 20px)',
                        marginLeft: '12px',
                      }}
                      borderLeft="Solid"
                      borderColor="Border Subtle"
                      display="block"
                    ></Box>
                  )}
                </Box>
                {type === 'goal' && (
                  <Box
                    as="div"
                    paddingTop="8"
                    style={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    height="100%"
                  >
                    <AccordionIconButton
                      isHidden={false}
                      isActive={expanded[activeExpandId]}
                      toggle={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        const toggleExpanded = !expanded[activeExpandId];
                        setExpanded!({
                          ...expanded,
                          [activeExpandId]: toggleExpanded,
                        });
                      }}
                    />
                    {expanded[activeExpandId] && hasChildren && (
                      <Box
                        as="div"
                        style={{
                          width: '1px',
                          height: 'calc(100% - 24px)',
                        }}
                        backgroundColor="Border Subtle"
                        marginLeft="12"
                      ></Box>
                    )}
                  </Box>
                )}
              </Box>
            </>
          }
        </Box>
      </Box>
    );
  };

  const renderChildren = () => {
    return (
      <>
        {isGoalRow &&
          filteredKeyResults.map((keyResult, index) => (
            <GoalsJoinTableRow
              key={`${rowKeyPrefix}-kr-${keyResult.id}`}
              rowKeyPrefix={rowKeyPrefix}
              expandId={`keyresult-${keyResult.id}`}
              expanded={expanded}
              setExpanded={setExpanded}
              uselessTable={true}
              columnConfigs={columnConfigs}
              indent={indent + 1}
              id={keyResult.id}
              row={keyResult}
              goalOwner={currentRowGoalOwner}
              onRowClick={onRowClick}
              selectedCheckboxes={selectedCheckboxes}
              ownerLdap={ownerLdap}
              delegateLdap={delegateLdap}
              paddingLeft={paddingLeft}
              paddingTop="0"
              rowProps={rowProps}
              isLastRow={index === filteredKeyResults.length - 1}
              withLeftAccessory={renderLeftAccessory(keyResult, index, 'keyresult', false)}
              isDraft={row.is_draft ?? false}
            />
          ))}
      </>
    );
  };

  return (
    <Fragment key={`${rowKeyPrefix}-${id}`}>
      <Box
        as={Table.Row}
        isSelectable
        onClick={() =>
          onRowClick(row, ownerLdap, pulseUser?.ldap ?? '', emailToLdap(goalOwner.email))
        }
        {...rowProps}
        style={{
          backgroundColor:
            !isGoalRow && isCurrentRowSelected()
              ? 'var(--dig-color__opacity__surface--state-1)'
              : undefined,
        }}
      >
        <GoalsNameCell
          uselessTable={uselessTable}
          withLeftAccessory={withLeftAccessory}
          width={width}
          minWidth={columnConfigs[0].minWidth ? `${columnConfigs[0].minWidth}px` : `${width}px`}
          maxWidth={columnConfigs[0].maxWidth ? `${columnConfigs[0].maxWidth}px` : `${width}px`}
          title={row.title}
          type={isGoalRow ? 'goal' : 'keyresult'}
          subtitle={
            <>
              {isGoalRow && (
                <>
                  <Box display="flex" alignItems="center">
                    {t('key_result', {count: row.key_results.length})}
                  </Box>
                </>
              )}
            </>
          }
          marginLeft={marginLeft}
          paddingLeft={paddingLeft}
          paddingTop={paddingTop}
          onClick={() =>
            onRowClick(row, ownerLdap, pulseUser?.ldap ?? '', emailToLdap(goalOwner.email))
          }
          minHeight={undefined}
          // minHeight={!isGoalRow ? getHeight(latestUpdate.comment) : undefined}
        />
        <ContributorCell
          row={row}
          ownerLdap={emailToLdap(currentRowGoalOwner.email)}
          onClick={() =>
            onRowClick(row, ownerLdap, pulseUser?.ldap ?? '', emailToLdap(goalOwner.email))
          }
          canEdit={true}
        />

        <Table.Cell width="50px" verticalAlign="top">
          <Box paddingTop="12">
            <Box
              as={Checkbox}
              checked={
                isGoalRow
                  ? false
                  : shouldDisableCheckbox(
                      row,
                      pulseUser?.ldap ?? '',
                      delegateLdap,
                      emailToLdap(goalOwner?.email)
                    )
                  ? true
                  : isCurrentRowSelected()
              }
              isIndeterminate={
                isGoalRow
                  ? isAllKeyResultsDisabled(
                      row as Goal,
                      ownerLdap,
                      pulseUser?.ldap ?? '',
                      delegateLdap
                    )
                    ? true
                    : isKeyResultSelected(row as Goal)
                  : false
              }
              onChange={
                isGoalRow && (row as Goal).key_results.length == 0
                  ? () => {
                      setSelectedGoalChecked(!selectedGoalChecked);
                    }
                  : () =>
                      onRowClick(
                        row,
                        ownerLdap,
                        pulseUser?.ldap ?? '',
                        emailToLdap(goalOwner.email)
                      )
              }
              disabled={
                isGoalRow
                  ? isAllKeyResultsDisabled(
                      row as Goal,
                      ownerLdap,
                      pulseUser?.ldap ?? '',
                      delegateLdap
                    )
                  : shouldDisableCheckbox(
                      row,
                      pulseUser?.ldap ?? '',
                      delegateLdap,
                      emailToLdap(goalOwner?.email)
                    )
              }
            />
          </Box>
        </Table.Cell>
      </Box>
      {expandId && expanded[expandId] && renderChildren()}
    </Fragment>
  );
};
