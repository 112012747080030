import {Button} from '@dropbox/dig-components/dist/buttons';
import {Skeleton} from '@dropbox/dig-components/dist/skeleton';
import {atoms, Box, Split, Stack, withShade} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {BriefcaseLine} from '@dropbox/dig-icons/dist/mjs/assets';
import {FolderMini} from '@dropbox/dig-illustrations';
import {useQuery} from '@tanstack/react-query';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {Employee, EmployeeService} from 'client';
import {EmptyState} from 'components/DSYS/EmptyState';
import {Link} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import {DEFAULT_TIMEFRAME} from 'constant';
import {t} from 'i18next';
import {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {getService} from 'utilities';
import {TimeframeSelector} from 'views/goals_v2/TimeframeSelector';
import {ProjectCard} from 'views/projects/ProjectCard';

const limitProjects = 3;

export const ProfileProjects = ({employee}: {employee: Employee}) => {
  const location = useLocation();
  const [projectTimeframe, setProjectTimeframe] = useState<string>(
    location.state?.timeframe ?? DEFAULT_TIMEFRAME
  );
  const [showAll, setShowAll] = useState(false);

  const {data, isLoading} = useQuery({
    queryKey: ['employee', 'projects', employee.user_id, projectTimeframe],
    queryFn: () =>
      getService(EmployeeService).getProjectsApiV1PeopleEmployeeIdProjectsGet(
        employee.user_id,
        projectTimeframe
      ),
  });
  const isOldTimeframe = projectTimeframe !== DEFAULT_TIMEFRAME;

  const profileActionLog = (action: string) =>
    analyticsLogger().logEvent('PROFILE_INTERACTION', {action});

  return (
    <Box
      as={Stack}
      width="100%"
      paddingX="20"
      paddingY="16"
      borderWidth="1"
      borderColor="Border Subtle"
      borderRadius="Medium"
      borderStyle="Solid"
    >
      <Split paddingBottom="8">
        <Split.Item width="fill">
          <Title size={16} withAccessoryStart={<UIIcon src={BriefcaseLine} isOpticallyAligned />}>
            {t('projects')}
          </Title>
        </Split.Item>
        <Split.Item>
          <TimeframeSelector
            selectedTimeframe={projectTimeframe}
            setSelectedTimeframe={(next) => {
              analyticsLogger().logEvent('PROFILE_PROJECT_TIMEFRAME', {timeframe: next});
              setProjectTimeframe(next);
            }}
          />
        </Split.Item>
      </Split>
      {isLoading ? (
        <ProjectSkeleton />
      ) : data?.projects.length ? (
        <>
          {data.projects.slice(0, showAll ? undefined : limitProjects).map((project) => (
            <Box
              key={project.id}
              padding="8"
              {...withShade({duration: 'None'})}
              borderRadius="Medium"
            >
              <Link to={`/projects/${project.id}`} hasNoUnderline>
                <ProjectCard
                  isProfile
                  title={project.title}
                  subtitle={project.subtitle}
                  projectId={project.id}
                  onStatusClick={() => profileActionLog('project')}
                />
              </Link>
            </Box>
          ))}
          {data.total > limitProjects && (
            <Box paddingTop="12">
              <Button variant="transparent" size="small" onClick={() => setShowAll(!showAll)}>
                {showAll
                  ? t('show_less')
                  : t('show_more_count', {count: data.total - limitProjects})}
              </Button>
            </Box>
          )}
        </>
      ) : (
        <EmptyState
          hideBorder
          paddingY="24"
          marginBottom="0"
          title={isOldTimeframe ? 'No projects for this quarter' : 'No projects just yet'}
          body={
            isOldTimeframe
              ? 'Try switching the quarter to another one.'
              : 'Projects will show up here once added, so check again later!'
          }
          image={<FolderMini width={64} altText={''} />}
        />
      )}
    </Box>
  );
};

const ProjectSkeleton = () => (
  <Box display="flex" flexDirection="column">
    <Skeleton.Box height={44} className={atoms({marginY: '8'})} withBorderRadius={'medium'} />
    <Skeleton.Box height={44} className={atoms({marginY: '8'})} withBorderRadius={'medium'} />
    <Skeleton.Box height={44} className={atoms({marginY: '8'})} withBorderRadius={'medium'} />
    <Box marginTop="16">
      <Skeleton.Text size="small" width={50} />
    </Box>
  </Box>
);
