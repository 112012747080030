import {IconButton} from '@dropbox/dig-components/dist/buttons';
import {IconButtonGroup, LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Drawer} from '@dropbox/dig-components/drawer';
import {UIIcon} from '@dropbox/dig-icons';
import {BriefcaseLine, CloseLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {useProject} from 'components/projects/hooks';
import {Suspense, useEffect} from 'react';

import {ProjectUpdates} from './ProjectUpdates';

const SidebarHeader = ({id}: {id: number}) => {
  const {data, isLoading} = useProject({id});

  if (!data || isLoading) {
    return null;
  }

  return (
    <LabelGroup
      withText={
        <Text size="large" isBold>
          {data.project?.title}
        </Text>
      }
      withLeftAccessory={<UIIcon src={BriefcaseLine} />}
    />
  );
};

export const ProjectDrawer = ({
  isOpen,
  sidebarProjectId,
  onClose,
  onDidClose,
}: {
  isOpen: boolean;
  sidebarProjectId?: number;
  onClose: () => void;
  onDidClose: () => void;
}) => {
  useEffect(() => {
    if (isOpen) {
      analyticsLogger().logEvent('PROJECT_DRAWER_OPENED', {project: sidebarProjectId});
    }
  }, [isOpen, sidebarProjectId]);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => event.key === 'Escape' && onClose();

    document.addEventListener('keydown', handleEsc);
    return () => document.removeEventListener('keydown', handleEsc);
  }, [onClose]);

  return (
    <Drawer.OverlayDrawer isOpen={isOpen} width="475px" alignment="right" onDidClose={onDidClose}>
      <Drawer.Container>
        <Drawer.Header>
          <Drawer.Layout>
            <Drawer.LayoutItem width="fill">
              <Text variant="label" isBold>
                {sidebarProjectId && <SidebarHeader id={sidebarProjectId} />}
              </Text>
            </Drawer.LayoutItem>
            <Drawer.LayoutItem shift="right">
              <IconButtonGroup>
                {({getButtonProps}) => (
                  <>
                    <IconButton variant="borderless" {...getButtonProps()} onClick={onClose}>
                      <UIIcon src={CloseLine} />
                    </IconButton>
                  </>
                )}
              </IconButtonGroup>
            </Drawer.LayoutItem>
          </Drawer.Layout>
        </Drawer.Header>
        <Drawer.Body>
          {sidebarProjectId && (
            <Suspense fallback={null}>
              <ProjectUpdates id={sidebarProjectId} />
            </Suspense>
          )}
        </Drawer.Body>
      </Drawer.Container>
    </Drawer.OverlayDrawer>
  );
};
