import {Button} from '@dropbox/dig-components/dist/buttons';
import {t} from 'i18next';
import {useEffect, useState} from 'react';
import {getBackendUrl} from 'utilities';

export const ExportCSVButton = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      window.location.href = getBackendUrl() + '/api/v1/audit/team/csv';

      setTimeout(() => {
        setIsLoading(false);
      }, 6000);
    }
  }, [isLoading]);

  return (
    <Button variant="outline" onClick={() => setIsLoading(true)} isLoading={isLoading}>
      {t('export')}
    </Button>
  );
};
