// eslint-disable-next-line no-restricted-imports
import {AvatarSizes, Facepile as DIGFacepile} from '@dropbox/dig-components/dist/avatar';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Box} from '@dropbox/dig-foundations';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {OptionalTooltip} from 'components/DSYS/OptionalTooltip';
import {sortEmployees, sortEmployeesSelfFirst} from 'helpers/utils';
import {useEmployees} from 'hooks/useEmployee';
import {useAtomValue} from 'jotai';
import {Suspense} from 'react';
import {getBackendUrl, getProfileImageUrl, nameToInitials} from 'utilities';

/**
 * Facepile is for a stack of employee avatars. For a single avatar, see DSYS/Avatar.tsx
 *
 * @param ldaps - list of ldap strings
 * @param size - size of the avatars
 * @param overflow - number of avatars to show before truncating
 * @param ownerIsFirst - whether the owner of the facepile should be first
 * @param showSelf - whether to show the logged in user in the facepile
 * @param hideTooltip - whether to hide the tooltip
 * @param onClick - function to call when the facepile is clicked
 */
export const Facepile = ({
  ldaps,
  size,
  overflow = 3,
  ownerIsFirst,
  showSelf = true,
  hideTooltip,
  onClick,
}: {
  ldaps: string[];
  hideTooltip?: boolean;
  size?: AvatarSizes;
  overflow?: number;
  ownerIsFirst?: boolean;
  showSelf?: boolean;
  onClick?: () => void;
}) => (
  <DIGFacepile size={size}>
    <Suspense fallback={null}>
      <LoadableEmployeeFacepile
        size={size}
        hideTooltip={Boolean(hideTooltip)}
        ownerIsFirst={Boolean(ownerIsFirst)}
        showSelf={Boolean(showSelf)}
        ldaps={ldaps}
        overflow={overflow}
        onClick={onClick}
      />
    </Suspense>
  </DIGFacepile>
);

const LoadableEmployeeFacepile = ({
  ldaps,
  size,
  overflow,
  ownerIsFirst,
  showSelf,
  hideTooltip,
  onClick,
}: {
  ldaps: string[];
  size?: 'large' | 'medium' | 'small' | 'xsmall' | 'standard';
  overflow: number;
  hideTooltip: boolean;
  ownerIsFirst: boolean;
  showSelf: boolean;
  onClick?: () => void;
}) => {
  const {employee} = useAtomValue(loggedInEmployeeAtom);
  const employees = useEmployees({ldaps});

  const sortedEmployees = ownerIsFirst
    ? [
        employees?.[0],
        ...employees.slice(1).sort(showSelf ? sortEmployeesSelfFirst(employee) : sortEmployees),
      ]
    : employees.sort(showSelf ? sortEmployeesSelfFirst(employee) : sortEmployees);

  const splitIndex = ldaps.length > overflow ? overflow - 1 : overflow;

  return (
    <>
      {ldaps?.length > overflow && (
        <OptionalTooltip
          enabled={!hideTooltip}
          title={sortedEmployees
            .slice(splitIndex)
            .map((e) => e.name)
            .join(', ')}
          openDelay={300}
        >
          <DIGFacepile.Item
            colorPalette={{background: 'var(--dig-color__opacity__surface)', foreground: ''}}
          >
            <Box style={{cursor: onClick ? 'pointer' : 'default'}} onClick={onClick}>
              <Text
                isBold
                size={size === 'standard' ? 'small' : size === 'small' ? 'xsmall' : size}
              >
                +{ldaps.length - splitIndex}
              </Text>
            </Box>
          </DIGFacepile.Item>
        </OptionalTooltip>
      )}
      {sortedEmployees
        .slice(0, splitIndex)
        .reverse()
        .filter(Boolean)
        .map((contributor) => (
          <OptionalTooltip
            enabled={!hideTooltip}
            key={contributor.user_id}
            title={
              contributor.is_deactivated ? `${contributor.name} (Deactivated)` : contributor.name
            }
            openDelay={300}
          >
            <DIGFacepile.Item
              style={{
                cursor: onClick ? 'pointer' : 'default',
                filter: contributor.is_deactivated ? 'grayscale(1) opacity(.5)' : undefined,
              }}
              onClick={onClick}
              name={contributor.name}
              isInactive={contributor.is_deactivated ?? false}
              src={
                !contributor.profile_photo_url
                  ? getBackendUrl() + getProfileImageUrl(contributor.ldap)
                  : contributor.profile_photo_url.startsWith('http')
                    ? contributor.profile_photo_url
                    : getBackendUrl() + contributor.profile_photo_url
              }
            >
              {nameToInitials(contributor.name)}
            </DIGFacepile.Item>
          </OptionalTooltip>
        ))}
    </>
  );
};
