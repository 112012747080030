import {TeamInfoFull} from 'client';
import {useLoaderData} from 'react-router-dom';

import {useTeamEdit} from './hooks';
import {TeamModify} from './TeamModify';

export const TeamEdit = () => {
  const team = useLoaderData() as TeamInfoFull;
  const {editTeam, isPending} = useTeamEdit();

  return <TeamModify isEditing onSubmit={editTeam} isPending={isPending} team={team} />;
};
