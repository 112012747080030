import {Chip} from '@dropbox/dig-components/dist/chip';
import {FormRow} from '@dropbox/dig-components/dist/form_row';
import {TextInput, TextInputInputRefObject} from '@dropbox/dig-components/dist/text_fields';
import {Text} from '@dropbox/dig-components/dist/typography';
import {Typeahead} from '@dropbox/dig-components/typeahead';
import {Box} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {SearchLine} from '@dropbox/dig-icons/assets';
import {pulseUserAtom} from 'atoms/auth';
import {loggedInEmployeeAtom} from 'atoms/employee';
import classNames from 'classnames';
import {Employee} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {isDev} from 'helpers/environment';
import {useSearches} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {FunctionComponent, useEffect, useRef, useState} from 'react';

import styles from './PeopleSearchMenu.module.css';

interface Props {
  selectedEmployees: Employee[];
  supportCSV?: boolean;
  onSelectEmployee: (employee: Employee) => void;
  onSelectEmployees: (employee: Employee[]) => void;
  onRemoveEmployee: (employee: Employee) => void;
  className?: string;
  autoFocus?: boolean;
  placeholderText?: string;
  disable?: boolean;
  allowSelf?: boolean;
  showReportingLine?: boolean;
  minHeight?: string;
  isSingleSelect?: boolean;
  searchIconPosition?: 'left' | 'right';
}

export const PeopleSearchMenu: FunctionComponent<Props> = ({
  autoFocus = false,
  selectedEmployees,
  onSelectEmployee,
  onSelectEmployees,
  onRemoveEmployee,
  placeholderText = t('search_dropboxers'),
  className,
  disable = false,
  allowSelf = false,
  showReportingLine = true,
  isSingleSelect = false,
  searchIconPosition = 'right',
  minHeight,
}) => {
  const inputContainerRef = useRef<HTMLElement | null>(null);
  const textInputRef = useRef<HTMLElement | null>(null);
  const {reportingLine} = useAtomValue(loggedInEmployeeAtom);
  const [userInputValue, setUserInputValue] = useState<string>('');
  const [inputTextActive, setInputTextActive] = useState<boolean>(false);

  const searchResult = useSearches({
    ldaps:
      userInputValue === '' && showReportingLine
        ? undefined
        : userInputValue.split(',').map((e) => (!e.includes('@') ? e : e.trim().split('@')[0])),
  });

  const user = useAtomValue(pulseUserAtom);
  const reportingLineWithoutSelf =
    reportingLine.length > 1 ? reportingLine.slice(1) : reportingLine;
  const employeeHints = (
    searchResult.length
      ? searchResult
      : !userInputValue.length && showReportingLine
      ? reportingLineWithoutSelf
      : []
  ).filter((e) => {
    if (isDev) return true;
    if (allowSelf) return true;
    return e.ldap != user?.email.split('@')[0];
  });

  const handleSelection = (ldap: string) => {
    const found = employeeHints.find((e) => e.ldap === ldap);
    if (found) {
      onSelectEmployee(found);
      setUserInputValue('');
      //make sure to not focus on the input after selecting an employee
      if (textInputRef.current && isSingleSelect) {
        textInputRef.current?.blur();
      }
    }
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInputValue(event.currentTarget.value);
  };

  const renderSubtitle = (result?: string | null) => {
    return (
      <>
        <Text size="small" color="faint">
          {result}
        </Text>
      </>
    );
  };

  const onToggle = ({isOpen}: {isOpen: boolean}) => {
    // We can even add a chip during menu close!
    setInputTextActive(isOpen);
  };

  useEffect(() => {
    if (userInputValue.length > 0 && userInputValue.includes(',') && searchResult.length) {
      const foundEmployees = userInputValue.split(',').reduce((acc, email) => {
        const found = employeeHints.find((e) => e.ldap === email.trim().split('@')[0]);
        if (found) {
          setUserInputValue((currentValue) => currentValue.replace(email, ''));
          acc.push(found);
        }
        return acc;
      }, [] as Employee[]);

      onSelectEmployees(foundEmployees);
      setUserInputValue((currentValue) =>
        currentValue
          .split(',')
          .filter((e) => e.length)
          .join(',')
      );
    }
  }, [employeeHints, onSelectEmployees, searchResult, userInputValue]);

  useEffect(() => {
    // Add a listener to keyboard delelte key to remove the last selected employee
    const handleKeyDown = (event: KeyboardEvent) => {
      // check if the text input is active or not
      if (!inputTextActive) {
        return;
      }
      if (event.key === 'Backspace' && userInputValue === '') {
        onRemoveEmployee(selectedEmployees[selectedEmployees.length - 1]);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onRemoveEmployee, selectedEmployees, userInputValue, inputTextActive]);

  const renderTypeaheadRow = (employee: Employee) => {
    return (
      <Typeahead.Row
        key={employee.user_id}
        value={employee.ldap}
        withTitle={<Text isBold>{employee.name}</Text>}
        withSubtitle={renderSubtitle(employee.role)}
        disabled={disable}
        withLeftAccessory={<Avatar user={employee} />}
      />
    );
  };
  return (
    <FormRow>
      <Typeahead.Wrapper
        className={classNames(className, {
          [styles.chips]: selectedEmployees.length > 0,
        })}
        onSelection={handleSelection}
        onToggle={onToggle}
      >
        {({getTriggerProps, getContentProps}) => (
          <>
            <TextInput.Container
              ref={inputContainerRef}
              style={{minHeight: minHeight, maxHeight: minHeight}}
            >
              {searchIconPosition === 'left' && (
                <Box style={{paddingRight: '4px', marginLeft: '-4px'}}>
                  <TextInput.Accessory>
                    <UIIcon src={SearchLine} />
                  </TextInput.Accessory>
                </Box>
              )}
              <TextInput.ChipsContainer>
                {!!selectedEmployees.length &&
                  selectedEmployees.map((employee, i) => (
                    <Chip
                      key={i}
                      onDelete={() => {
                        onRemoveEmployee(employee);
                        if (textInputRef.current) {
                          textInputRef.current.focus();
                        }
                      }}
                      size={'small'}
                    >
                      <Chip.AvatarAccessory>
                        <Avatar user={employee} />
                      </Chip.AvatarAccessory>
                      <Chip.Content>{employee.name}</Chip.Content>
                    </Chip>
                  ))}

                <TextInput.Input
                  {...getTriggerProps({onChange: onInputChange})}
                  placeholder={selectedEmployees.length === 0 ? placeholderText.toString() : ''}
                  value={userInputValue}
                  autoFocus={autoFocus}
                  disabled={disable}
                  ref={textInputRef as React.RefObject<TextInputInputRefObject>}
                  readOnly={isSingleSelect && selectedEmployees.length === 1}
                />
                {searchIconPosition === 'right' && (
                  <TextInput.Accessory>
                    <UIIcon src={SearchLine} />
                  </TextInput.Accessory>
                )}
              </TextInput.ChipsContainer>
            </TextInput.Container>
            <Typeahead.Container {...getContentProps()} triggerRef={inputContainerRef}>
              <Typeahead.Results
                size="large"
                results={employeeHints}
                renderRow={renderTypeaheadRow}
              />
            </Typeahead.Container>
          </>
        )}
      </Typeahead.Wrapper>
    </FormRow>
  );
};
