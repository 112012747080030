import {loggedInEmployeeAtom} from 'atoms/employee';
import {useAtomValue} from 'jotai';
import {GoalsTablesComponent} from 'views/goals_v2/TableComponents/GoalsTablesComponent';
import {GoalsTablePropsWithoutData} from 'views/goals_v2/types';
import {isTeamGoalsSelected} from 'views/goals_v2/utils';

/*
 * Table view that loads goals for your teams
 */
export const TeamsGoalsTableView = (tableProps: GoalsTablePropsWithoutData) => {
  const {filter, quickFilterSelected} = tableProps;
  const {employeeTeams} = useAtomValue(loggedInEmployeeAtom);
  const employeeTeamSlugs = employeeTeams ? employeeTeams.map(({slug}) => slug ?? '') : [];
  const hasTeamGoalsSelected =
    filter && isTeamGoalsSelected(filter, employeeTeamSlugs, quickFilterSelected);
  const teams = hasTeamGoalsSelected && employeeTeams ? employeeTeams : [];

  const keyPrefix = 'teams_goals';
  return <GoalsTablesComponent keyPrefix={keyPrefix} tableProps={tableProps} teams={teams} />;
};
