import {Button, SplitButton} from '@dropbox/dig-components/dist/buttons';
import {Drawer} from '@dropbox/dig-components/dist/drawer';
import {FormRow} from '@dropbox/dig-components/dist/form_row';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Box, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {DeleteLine, UndoLine} from '@dropbox/dig-icons/assets';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {pulseUserAtom} from 'atoms/auth';
import {newsPostIdAtom} from 'atoms/news';
import {snackbarAtom} from 'atoms/snackbar';
import {NewsCategory, NewsPost, NewsPostContent, NewsPostUpdate} from 'client';
import {Layout} from 'components/DSYS/Layout';
import {Title} from 'components/DSYS/Title';
import {
  CategorySearchMenu,
  NewsContentEditor,
  NewsModifyHeader,
  NewsModifyThumbnail,
  NewsModifyTitle,
  NewsPreviewDrawer,
  PermissionsRow,
} from 'components/news/NewsModify';
import {isContentEditor} from 'helpers/utils';
import {t} from 'i18next';
import {useAtomValue, useSetAtom} from 'jotai';
import {NotFound} from 'pages/NotFound';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useCategoriesSuspense} from './hooks';
import styles from './NewsUpdate.module.css';

export const NewsUpdate = ({
  newspost,
  onSubmit,
  onDelete,
  uploadThumbnail,
  deleteThumbnail,
  isLoading,
}: {
  newspost: NewsPostContent;
  onSubmit: (data: {data: NewsPostUpdate}) => Promise<NewsPostContent>;
  onDelete: (newspostId: number) => Promise<NewsPost>;
  uploadThumbnail: (id: number, file: File) => Promise<number>;
  deleteThumbnail: () => Promise<void>;
  isLoading: boolean;
}) => {
  const navigate = useNavigate();
  const setSnackbarMessage = useSetAtom(snackbarAtom);
  const pulseUser = useAtomValue(pulseUserAtom);
  const possibleCategories = useCategoriesSuspense();
  const [value, setValue] = useState<string>();
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const setLoadedNewsPostId = useSetAtom(newsPostIdAtom);
  const [hasThumbnail, setHasThumbnail] = useState(false);

  const [open, setOpen] = useState<undefined | 'delete' | 'unpublish'>(undefined);
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false);
  const close = () => setOpen(undefined);

  const [newsUpdate, setNewsUpdate] = useState<NewsPostUpdate>({
    title: newspost.title,
    category: newspost.category,
    created_at: newspost.created_at,
    body: newspost.body,
    audience: newspost.audience,
    updated_at: '',
    is_draft: newspost.is_draft,
  });

  useEffect(() => {
    setNewsUpdate({...newsUpdate, category: newspost.category});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newspost.category]);

  const canEdit = pulseUser && isContentEditor(pulseUser.email);

  const handleSubmit = async (isUnpublish?: boolean) => {
    const {title, category, created_at, audience, is_draft} = newsUpdate;

    const response = await onSubmit({
      data: {
        title,
        category,
        body: value ?? '',
        is_draft: isUnpublish === undefined ? is_draft : isUnpublish,
        created_at: isUnpublish === false ? new Date().toISOString() : created_at,
        audience,
        updated_at: new Date().toISOString(),
      },
    });

    if (hasThumbnail || selectedFile) {
      if (selectedFile) {
        await uploadThumbnail(response.id, selectedFile);
      }
    } else {
      await deleteThumbnail();
    }

    setSnackbarMessage({text: t('saved')});
    if (isUnpublish) {
      analyticsLogger().logEvent('NEWS_POST_ACTION', {newspost_id: newspost.id, type: 'unpublish'});
    } else if (isUnpublish === false) {
      analyticsLogger().logEvent('NEWS_POST_ACTION', {newspost_id: newspost.id, type: 'publish'});
    } else {
      analyticsLogger().logEvent('NEWS_POST_ACTION', {
        newspost_id: newspost.id,
        type: 'save_as_draft',
      });
    }
    navigate(`/news/${response.id}`, {state: {source: 'news-update'}});
  };

  const handleDelete = async () => {
    const response = await onDelete(newspost.id);

    if (!response) {
      setSnackbarMessage({text: t('couldnt_delete')});
      navigate('/news', {state: {source: 'news-update'}});
    }

    if (selectedFile) {
      await deleteThumbnail();
    }

    setSnackbarMessage({text: t('deleted')});
    setLoadedNewsPostId(undefined);
    analyticsLogger().logEvent('NEWS_POST_ACTION', {newspost_id: newspost.id, type: 'delete'});
    navigate('/news', {state: {source: 'news-update'}});
  };

  if (!pulseUser) {
    return null;
  }

  if (!canEdit) {
    return <NotFound />;
  }

  return (
    <Drawer.InlineFrame
      style={{marginTop: '-52px', marginRight: '-68px'}}
      withDrawer={
        <NewsPreviewDrawer
          isOpen={drawerIsOpen}
          setDrawerIsOpen={(bool) => setDrawerIsOpen(bool)}
          title={newsUpdate.title}
          category={newsUpdate.category.map((cat) => cat.name)}
          body={value ?? newsUpdate.body}
          onDrawerOpen={() => setNewsUpdate((p) => ({...p, body: value ?? ''}))}
          onDrawerClose={() => setNewsUpdate((p) => ({...p, body: value ?? ''}))}
        />
      }
    >
      <div className={styles.form} style={{...(drawerIsOpen && {maxWidth: '737px'})}}>
        <Layout.Container size={drawerIsOpen ? 'full' : 'condensed'}>
          <NewsModifyHeader isEditing />

          <Box>
            <NewsModifyTitle
              title={newsUpdate.title}
              setTitle={(title) => setNewsUpdate((p) => ({...p, title: title}))}
            />

            <CategorySearchMenu
              possibleCategories={possibleCategories}
              selectedCategories={newsUpdate.category.map((cat) => cat.name)}
              onRemoveCategory={(category: string) =>
                setNewsUpdate((p) => ({
                  ...p,
                  category: newsUpdate.category.filter((cat) => cat.name !== category),
                }))
              }
              onSelectCategory={(category: NewsCategory) => {
                newsUpdate.category.push(category);
                setNewsUpdate((p) => ({
                  ...p,
                  category: newsUpdate.category,
                }));
              }}
              id={newspost.id}
            />

            <NewsModifyThumbnail
              id={newspost.id}
              selectedFile={selectedFile}
              setSelectedFile={(file) => setSelectedFile(file)}
              onLoaded={setHasThumbnail}
            />

            <NewsContentEditor
              setValue={(state) => state && setValue(state.toString())}
              value={newsUpdate.body}
              drawerIsOpen={drawerIsOpen}
              setDrawerIsOpen={(bool) => setDrawerIsOpen(bool)}
              isEditing
            />

            <FormRow></FormRow>

            <PermissionsRow
              audience={newsUpdate.audience}
              setNewsModify={(audience) => setNewsUpdate((p) => ({...p, audience: audience}))}
            />

            <FormRow></FormRow>

            <NewsUpdateFooter
              handleSaveChanges={handleSubmit}
              setOpen={(modal) => setOpen(modal)}
              handleCancelClick={() => navigate(-1)}
              newsUpdate={newsUpdate}
              value={value}
              isLoading={isLoading}
            />

            <ActionsModal modal="delete" open={open} close={close} handleClick={handleDelete} />
            <ActionsModal
              modal="unpublish"
              open={open}
              close={close}
              handleClick={() => {
                handleSubmit(true);
              }}
            />
          </Box>
        </Layout.Container>
      </div>
    </Drawer.InlineFrame>
  );
};

const NewsUpdateFooter = ({
  handleSaveChanges,
  setOpen,
  handleCancelClick,
  newsUpdate,
  value,
  isLoading,
}: {
  handleSaveChanges: (isUnpublish?: boolean) => void;
  setOpen: (modal: 'delete' | 'unpublish' | undefined) => void;
  handleCancelClick: () => void;
  newsUpdate: NewsPostUpdate;
  value?: string;
  isLoading: boolean;
}) => (
  <FormRow>
    <Split>
      <Split.Item className={styles.rightButtonContainer} width="fill">
        <Button
          variant="borderless"
          withIconStart={<UIIcon src={DeleteLine} />}
          onClick={() => setOpen('delete')}
        >
          {t('delete')}
        </Button>
      </Split.Item>
      <Split.Item className={styles.leftButtonContainer}>
        <Button
          variant="opacity"
          onClick={handleCancelClick}
          disabled={!newsUpdate.title || !(value ?? '')}
        >
          {t('cancel')}
        </Button>
        {newsUpdate.is_draft ? (
          <SplitButton
            variant="primary"
            onClick={() => handleSaveChanges(false)}
            isLoading={isLoading}
            disabled={!newsUpdate.title || !(value ?? '')}
            renderMenu={({buttonProps}) => (
              <Menu.Wrapper>
                {({getTriggerProps, getContentProps}) => (
                  <>
                    <Button
                      aria-label="Save changes with the option to unpublish"
                      {...buttonProps} // required for props to match the SplitButton styles
                      {...getTriggerProps()} // required for <Menu>
                    />
                    <Menu.Content {...getContentProps()} placement="bottom">
                      <Menu.Segment>
                        <Menu.ActionItem onClick={() => handleSaveChanges()}>
                          {t('save_changes')}
                        </Menu.ActionItem>
                      </Menu.Segment>
                    </Menu.Content>
                  </>
                )}
              </Menu.Wrapper>
            )}
          >
            {t('publish')}
          </SplitButton>
        ) : (
          <SplitButton
            variant="primary"
            onClick={() => handleSaveChanges()}
            isLoading={isLoading}
            disabled={!newsUpdate.title || !(value ?? '')}
            renderMenu={({buttonProps}) => (
              <Menu.Wrapper>
                {({getTriggerProps, getContentProps}) => (
                  <>
                    <Button
                      aria-label="Save changes with the option to unpublish"
                      {...buttonProps} // required for props to match the SplitButton styles
                      {...getTriggerProps()} // required for <Menu>
                    />
                    <Menu.Content {...getContentProps()} placement="bottom">
                      <Menu.Segment>
                        <Menu.ActionItem
                          withLeftAccessory={<UIIcon src={UndoLine} />}
                          onClick={() => {
                            setOpen('unpublish');
                          }}
                        >
                          {t('unpublish')}
                        </Menu.ActionItem>
                      </Menu.Segment>
                    </Menu.Content>
                  </>
                )}
              </Menu.Wrapper>
            )}
          >
            {t('save_changes')}
          </SplitButton>
        )}
      </Split.Item>
    </Split>
  </FormRow>
);

const ActionsModal = ({
  modal,
  open,
  close,
  handleClick,
}: {
  modal: string;
  open: 'delete' | 'unpublish' | undefined;
  close: () => void;
  handleClick: () => void;
}) => (
  <Modal open={open === modal} withCloseButton="close" onRequestClose={close}>
    <Modal.Header>
      <Title>{t(`${modal}_post_confirm`)}</Title>
    </Modal.Header>
    <Modal.Body>{t(`${modal}_post_confirm_subtitle`)}</Modal.Body>
    <Modal.Footer>
      <Button variant="opacity" onClick={close}>
        {t('cancel')}
      </Button>
      <Button
        variant="primary"
        onClick={() => {
          close();
          handleClick();
        }}
      >
        {t(`${modal}`)}
      </Button>
    </Modal.Footer>
  </Modal>
);
