import {ButtonVariant} from '@dropbox/dig-components/dist/buttons';
import {Text} from '@dropbox/dig-components/dist/typography';
import {atoms, Box, Split, Stack} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {TargetLine} from '@dropbox/dig-icons/assets';
import {PlugMini, PuzzlePiecesMini} from '@dropbox/dig-illustrations';
import {analyticsLogger} from 'analytics/analyticsLogger';
import {Layout} from 'components/DSYS/Layout';
import {ButtonLink} from 'components/DSYS/Link';
import {Title} from 'components/DSYS/Title';
import {DEFAULT_TIMEFRAME, ROUTE_PATHS} from 'constant';
import {useDocumentTitle} from 'hooks/useDocumentTitle';
import {t} from 'i18next';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import styles from './GoalsOnboardingView.module.css';

export const GoalsOnboardingView = () => {
  const location = useLocation();

  useDocumentTitle(`${t('add_goal')}`);

  useEffect(() => {
    if (location.state?.source) {
      analyticsLogger().logEvent('GOAL_ONBOARDING_VIEW', {source: location.state.source});
    }
  }, [location.state?.source]);

  return (
    <Layout.Container size="condensed">
      <Stack gap="16">
        <Title size={24} withAccessoryStart={<UIIcon size="large" src={TargetLine} />}>
          {t('add_goal')}
        </Title>
        <Split gap="16" style={{alignItems: 'stretch'}}>
          <Split.Item width="fill">
            <JoinComponent type="JOIN" />
          </Split.Item>
          <Split.Item width="fill">
            <JoinComponent type="CREATE" />
          </Split.Item>
        </Split>
      </Stack>
    </Layout.Container>
  );
};

const JoinOptions = {
  JOIN: {
    Image: () => (
      <Box className={styles.puzzlePieces}>
        <PuzzlePiecesMini altText="puzzlePiece" width={64} height={64} />
      </Box>
    ),
    title: t('join_an_existing_goal'),
    subtitle: t('join_an_existing_goal_subtitle'),
    list: [
      t('quickly_align_with_company_and_manager_goals'),
      t('contribute_to_key_results'),
      t('update_as_contributor'),
    ],
    button: {
      text: t('join_goal'),
      to: ROUTE_PATHS.GOALS_V2_JOIN,
      variant: 'primary',
      source: 'create-join',
    },
  },
  CREATE: {
    Image: () => (
      <Box className={styles.plugMini}>
        <PlugMini altText="plugMini" width={64} height={64} />
      </Box>
    ),
    title: t('create_a_new_goal'),
    subtitle: t('create_a_new_goal_subtitle'),
    list: [
      t('add_a_parent_goal'),
      t('assign_key_results_to_others'),
      t('set_personal_and_private_goals'),
    ],
    button: {
      text: t('create_goal'),
      to: ROUTE_PATHS.NEW_GOAL,
      variant: 'opacity',
      source: 'onboarding',
    },
  },
};

const JoinComponent = ({type}: {type: keyof typeof JoinOptions}) => {
  const location = useLocation();

  const {Image, title, subtitle, list, button} = JoinOptions[type];

  return (
    <Split
      gap="12"
      className={atoms({
        borderStyle: 'Solid',
        borderRadius: 'Small',
        borderColor: 'Border Subtle',
        paddingX: '32',
        paddingY: '24',
        height: '100%',
        marginTop: '16',
        display: 'flex',
      })}
      direction="vertical"
    >
      <Split.Item>
        <Image />
      </Split.Item>
      <Split.Item width="fill" style={{height: '100%'}}>
        <Stack gap="8">
          <Title size={18}>{title}</Title>
          <Text className={atoms({display: 'block'})} color="faint">
            {subtitle}
          </Text>
          <Text className={atoms({display: 'block'})} color="faint">
            <ul style={{listStyleType: 'disc'}} className={atoms({marginLeft: '16'})}>
              {list.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </Text>
        </Stack>
      </Split.Item>
      <Split.Item>
        <ButtonLink
          variant={button.variant as ButtonVariant}
          to={button.to}
          state={{
            source: button.source,
            timeframe: location.state?.timeframe ?? DEFAULT_TIMEFRAME,
            delegateId: location.state?.delegateId,
          }}
        >
          {button.text}
        </ButtonLink>
      </Split.Item>
    </Split>
  );
};
