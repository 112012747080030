/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Badge } from '../models/Badge';
import type { Body_put_image_api_v1_images__ldap__jpg_put } from '../models/Body_put_image_api_v1_images__ldap__jpg_put';
import type { Employee } from '../models/Employee';
import type { EmployeeCard } from '../models/EmployeeCard';
import type { EmployeeLocation } from '../models/EmployeeLocation';
import type { EmployeeProjects } from '../models/EmployeeProjects';
import type { EmployeeWithHierarchy } from '../models/EmployeeWithHierarchy';
import type { GoalRead } from '../models/GoalRead';
import type { NewsPost } from '../models/NewsPost';
import type { PeoplePage } from '../models/PeoplePage';
import type { ProfilePage } from '../models/ProfilePage';
import type { ProjectBasic } from '../models/ProjectBasic';
import type { TeamWithCounts } from '../models/TeamWithCounts';
import type { UpdateEmployeeTeam } from '../models/UpdateEmployeeTeam';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmployeeService {

    /**
     * All Employee Endpoint
     * @param ldap
     * @returns EmployeeCard Successful Response
     * @throws ApiError
     */
    public static getCardApiV1PeopleLdapCardGet(
        ldap: string,
    ): CancelablePromise<EmployeeCard> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/people/{ldap}/card',
            path: {
                'ldap': ldap,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * All Employee Endpoint
     * @returns EmployeeWithHierarchy Successful Response
     * @throws ApiError
     */
    public static getTreeAllApiV1PeopleTreeGet(): CancelablePromise<EmployeeWithHierarchy> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/people/tree',
        });
    }

    /**
     * People Page Endpoint
     * @param currentDate
     * @returns PeoplePage Successful Response
     * @throws ApiError
     */
    public static getPeopleApiV1PeopleGet(
      currentDate?: string,
    ): CancelablePromise<PeoplePage> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/people',
            query: {
                'current_date': currentDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Employees Endpoint
     * @param ldap
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getImageApiV1ImagesLdapJpgGet(
        ldap: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/images/{ldap}.jpg',
            path: {
                'ldap': ldap,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Employees Endpoint
     * @param ldap
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public static putImageApiV1ImagesLdapJpgPut(
        ldap: string,
        formData: Body_put_image_api_v1_images__ldap__jpg_put,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/images/{ldap}.jpg',
            path: {
                'ldap': ldap,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Employees Endpoint
     * @param ldap
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getImageApiV1ImagesFullLdapJpgGet(
      ldap: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/images/full/{ldap}.jpg',
            path: {
                'ldap': ldap,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Employees Endpoint
     * @returns any Successful Response
     * @throws ApiError
     */
    public static uploadImagesApiV1ImagesPost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/images',
        });
    }

    /**
     * Search Endpoint
     * @param name
     * @returns Employee Successful Response
     * @throws ApiError
     */
    public static searchPeopleApiV1PeopleSearchGet(
        name: string,
    ): CancelablePromise<Array<Employee>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/people/search',
            query: {
                'name': name,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Endpoint
     * @param name
     * @param filter
     * @param sParam
     * @param limit
     * @param skip
     * @returns any Successful Response
     * @throws ApiError
     */
    public static searchPeopleApiV1SearchGet(
        name: string,
        filter: string,
        sParam: boolean,
        limit?: number,
        skip?: number,
    ): CancelablePromise<Array<(Employee | ProjectBasic | TeamWithCounts | GoalRead | NewsPost)>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/search',
            query: {
                'name': name,
                'filter': filter,
                's_param': sParam,
                'limit': limit,
                'skip': skip,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Teams
     * @param ldap
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static editTeamsApiV1PeopleLdapTeamsPut(
        ldap: string,
        requestBody: Array<UpdateEmployeeTeam>,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/people/{ldap}/teams',
            path: {
                'ldap': ldap,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * LDAP Endpoint
     * @param ldap
     * @returns Employee Successful Response
     * @throws ApiError
     */
    public static getEmployeeByLdapApiV1PeopleLdapLdapGet(
        ldap: string,
    ): CancelablePromise<Employee> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/people/ldap/{ldap}',
            path: {
                'ldap': ldap,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * LDAPS Endpoint
     * @param requestBody
     * @returns Employee Successful Response
     * @throws ApiError
     */
    public static getEmployeesByLdapsApiV1PeopleLdapsPost(
        requestBody: Array<string>,
    ): CancelablePromise<Array<Employee>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/people/ldaps',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Location
     * @param location
     * @returns EmployeeLocation Successful Response
     * @throws ApiError
     */
    public static locationApiV1PeopleLocationLocationGet(
        location: string,
    ): CancelablePromise<Array<EmployeeLocation>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/people/location/{location}',
            path: {
                'location': location,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * City
     * @param city
     * @returns EmployeeLocation Successful Response
     * @throws ApiError
     */
    public static cityApiV1PeopleCityCityGet(
        city: string,
    ): CancelablePromise<Array<EmployeeLocation>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/people/city/{city}',
            path: {
                'city': city,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Nearby Cities
     * @param city
     * @returns EmployeeLocation Successful Response
     * @throws ApiError
     */
    public static nearbyCitiesApiV1PeopleNearbyCitiesCityGet(
        city: string,
    ): CancelablePromise<Array<EmployeeLocation>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/people/nearby_cities/{city}',
            path: {
                'city': city,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Employee Cities
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateEmployeeCitiesApiV1PeopleUpdateEmployeeCitiesPost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/people/update_employee_cities',
        });
    }

    /**
     * Update Nearby Cities
     * @param milesDistance
     * @param enforceUpdateAll
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateNearbyCitiesApiV1PeopleUpdateNearbyCitiesPost(
        milesDistance: number = 20,
        enforceUpdateAll: boolean = true,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/people/update_nearby_cities',
            query: {
                'miles_distance': milesDistance,
                'enforce_update_all': enforceUpdateAll,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Projects
     * @param employeeId
     * @param timeframe
     * @returns EmployeeProjects Successful Response
     * @throws ApiError
     */
    public static getProjectsApiV1PeopleEmployeeIdProjectsGet(
        employeeId: string,
        timeframe: string,
    ): CancelablePromise<EmployeeProjects> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/people/{employee_id}/projects',
            path: {
                'employee_id': employeeId,
            },
            query: {
                'timeframe': timeframe,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Profile Endpoint
     * @param userId
     * @returns ProfilePage Successful Response
     * @throws ApiError
     */
    public static getProfileApiV1PeopleUserIdGet(
        userId: string,
    ): CancelablePromise<ProfilePage> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/people/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Badges
     * @param userId
     * @returns Badge Successful Response
     * @throws ApiError
     */
    public static getBadgesApiV1PeopleUserIdReceivedGet(
        userId: string,
    ): CancelablePromise<Array<Badge>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/people/{user_id}/received',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Employees
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateEmployeesApiV1PeopleUpdatePost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/people/update',
        });
    }

    /**
     * Kudos
     * @param ldap
     * @param message
     * @returns any Successful Response
     * @throws ApiError
     */
    public static kudosApiV1PeopleKudosLdapPost(
        ldap: string,
        message?: (string | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/people/kudos/{ldap}',
            path: {
                'ldap': ldap,
            },
            query: {
                'message': message,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Seed Slack Users
     * @returns any Successful Response
     * @throws ApiError
     */
    public static seedSlackUsersApiV1PeopleSeedSlackGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/people/seed/slack',
        });
    }

    /**
     * Seed Slack Channels
     * @returns any Successful Response
     * @throws ApiError
     */
    public static seedSlackChannelsApiV1PeopleSeedSlackChannelsGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/people/seed/slack_channels',
        });
    }

}
