export enum CurrentEnvironment {
  Development,
  Amplify,
  Staging,
  Production,
}

const hostname = window.location.hostname;

export const getCurrentEnvironment: () => CurrentEnvironment = () => {
  if (process.env.REACT_APP_ENVIRONMENT) {
    switch (process.env.REACT_APP_ENVIRONMENT) {
      case 'development':
        return CurrentEnvironment.Development;
      case 'staging':
        return CurrentEnvironment.Staging;
      // case 'production':
      //   // Technially this works, but our AWS rules block referrer headerss
      //   return CurrentEnvironment.Production;
    }
  }

  if (hostname.includes('localhost') || hostname.includes('127.0.0.1')) {
    return CurrentEnvironment.Development;
  }

  if (hostname.includes('dropboxpulse') || hostname.includes('amplifyapp')) {
    return CurrentEnvironment.Staging;
  }

  return CurrentEnvironment.Production;
};

export const isDev = getCurrentEnvironment() === CurrentEnvironment.Development;

export const isProd = getCurrentEnvironment() === CurrentEnvironment.Production;
