import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {Toggle} from '@dropbox/dig-components/dist/controls';
import {DatePickerInput} from '@dropbox/dig-components/dist/date_picker';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Table} from '@dropbox/dig-components/dist/table';
import {Select} from '@dropbox/dig-components/dist/text_fields';
import {Box, Split} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {CheckmarkLine, DeleteLine} from '@dropbox/dig-icons/assets';
import {useMutation} from '@tanstack/react-query';
import {NotificationService, ScheduledNotification, ScheduledNotificationUpdate} from 'client';
import {t} from 'i18next';
import {useEffect, useState} from 'react';
import {getService} from 'utilities';
import {
  fromServerDate,
  getTodayUTC9am,
  toCalendarDate,
  toEODISOString,
  toServerDate,
} from 'views/admin/utils';
import {queryClient} from 'views/QueryClientWrapper';

import {AudienceSelect} from './AudienceSelect';
import {DataInput} from './DataInput';

export const NotificationRow = (props: ScheduledNotification) => {
  const [isHover, setIsHover] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showDueDate, setShowDueDate] = useState(Boolean(props.due_at));
  const [notification, setNotification] = useState(props);

  const {mutateAsync: updateScheduledNotification} = useMutation({
    mutationFn: ({data}: {data: ScheduledNotificationUpdate}) =>
      getService(NotificationService).updateApiV1NotificationsScheduledUpdatePost(data),
    onSuccess: () => queryClient.invalidateQueries({queryKey: ['notifications', 'scheduled']}),
  });

  const handleSetSendAt = (value: Date | string) => {
    setHasChanges(true);
    setNotification({
      ...notification,
      send_at: toServerDate(notification.send_at, value),
    });
  };

  const handleSetValue = (key: string, value?: string) => {
    setHasChanges(true);
    setNotification({...notification, [key]: value});
  };

  const handleSave = () => {
    setHasChanges(false);
    updateScheduledNotification({
      data: {
        ...notification,
        due_at: showDueDate ? notification.due_at : null,
      },
    });
  };

  useEffect(() => {
    if (showDueDate !== Boolean(notification.due_at)) {
      setHasChanges(true);
    }
  }, [notification.due_at, showDueDate]);

  return (
    <Table.Row onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      <Table.Cell>
        <AudienceSelect
          value={notification.audience}
          onSelect={(value) => handleSetValue('audience', value)}
        />
      </Table.Cell>
      <Table.Cell>{t(notification.type)}</Table.Cell>
      <Table.Cell>
        <DataInput
          type={notification.type}
          value={notification.data}
          onChange={(value) => handleSetValue('data', value)}
        />
      </Table.Cell>
      <Table.Cell>
        <Split gap="8" alignY="center">
          <Split.Item>
            <DatePickerInput
              id="send-at-date"
              value={toCalendarDate(notification.send_at)}
              onChange={(value) => handleSetSendAt(value ?? getTodayUTC9am())}
            />
          </Split.Item>
          <Split.Item>
            <Select
              id="send-at-time"
              value={fromServerDate(notification.send_at).getUTCHours().toString()}
              onChange={handleSetSendAt}
            >
              {Array.from({length: 24}, (_, i) => i).map((hour) => (
                <Select.Option key={hour} value={hour.toString()}>
                  {`${hour % 12 || 12}${hour < 12 ? 'am' : 'pm'}`}
                </Select.Option>
              ))}
            </Select>
          </Split.Item>
        </Split>
      </Table.Cell>
      <Table.Cell>
        <Split gap="8" alignY="center">
          <Split.Item>
            <Toggle
              checked={showDueDate}
              disabled={notification.type.startsWith('okr_')}
              onChange={() => {
                setShowDueDate(!showDueDate);
                if (showDueDate && !notification.due_at) {
                  handleSetValue('due_at', toEODISOString(getTodayUTC9am().toISOString()));
                }
              }}
            />
          </Split.Item>
          <Split.Item>
            <span style={{visibility: showDueDate ? 'visible' : 'hidden'}}>
              <DatePickerInput
                id="due-at"
                value={toCalendarDate(notification.due_at)}
                onChange={(value) => handleSetValue('due_at', toEODISOString(value?.toISOString()))}
              />
            </span>
          </Split.Item>
        </Split>
      </Table.Cell>
      <Table.Cell>
        <Split gap="4">
          <Split.Item>
            <IconButton
              variant="outline"
              shape="circular"
              style={{visibility: hasChanges ? 'visible' : 'hidden'}}
              disabled={!hasChanges}
              onClick={handleSave}
            >
              <Box as={UIIcon} src={CheckmarkLine} color="Text Subtle" />
            </IconButton>
          </Split.Item>
          <Split.Item>
            <IconButton
              variant="outline"
              shape="circular"
              style={{visibility: isHover ? 'visible' : 'hidden'}}
              onClick={() => setShowDelete(true)}
            >
              <Box as={UIIcon} src={DeleteLine} color="Text Subtle" />
            </IconButton>
          </Split.Item>
        </Split>
      </Table.Cell>

      {showDelete && (
        <Modal open onRequestClose={() => setShowDelete(false)}>
          <Modal.Header hasBottomSpacing="title-standard">
            <Modal.Title>Delete scheduled notification?</Modal.Title>
            <Modal.Body>
              <Split direction="vertical" paddingY="8">
                <Split.Item>
                  {t(notification.type)} — {notification.data}
                </Split.Item>
                <Split.Item>{t(notification.audience)}</Split.Item>
                <Split.Item>{notification.send_at}</Split.Item>
              </Split>
            </Modal.Body>
          </Modal.Header>
          <Modal.Footer>
            <Button variant="opacity" onClick={() => setShowDelete(false)}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={async () => {
                await getService(NotificationService).deleteApiV1NotificationsDeletePost(
                  notification.id
                );
                queryClient.invalidateQueries({queryKey: ['notifications', 'scheduled']});
                setShowDelete(false);
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Table.Row>
  );
};
